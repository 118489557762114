import React, {useEffect} from "react";
import EditOfficeBasics from "./EditOfficeBasics";
import EditOfficeLeaseTerms from "./EditOfficeLeaseTerms";
import EditOfficeLocation from "./EditOfficeLocation";
import EditOfficeAmenities from "./EditOfficeAmenities";
import EditOfficeOwners from "./EditOfficeOwners";
import EditOfficeContacts from "./EditOfficeContacts";
import EditOfficeDeleteProperty from "./EditOfficeDeleteProperty";
import {fetchOfficeEditFormsData} from "../../../../../crud/estates/offices/officeBuilding.crud";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import EditOfficeCommercializationSuspended from "./EditOfficeCommercializationSuspended";
import IsAdmin from "../../../../../utils/IsAdmin";
import EditPropertyManageImages from "../common/EditPropertyManageImages";

function EditOffice({data, loading, parentData, activeTab, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  useEffect(() => {
    console.log(parentData);
    if(!loading && parentData?.id) {
      props.fetchOfficeEditFormsData(parentData?.id);
    }
 // eslint-disable-next-line
  }, []);

  if(loading) {
    return <LoadingScreen/>
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" &&
        <EditOfficeBasics
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "leaseTerms" &&
        <EditOfficeLeaseTerms
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "location" &&
        <EditOfficeLocation
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "images" &&
        <EditPropertyManageImages data={data} view="office"/>
        }
        {activeTab === "commercialization" &&
        <EditOfficeCommercializationSuspended data={data}/>
        }
      <IsAdmin markup="editTitle">
        {activeTab === "amenities" &&
        <EditOfficeAmenities
          officeId={data?.id}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "owner" &&
        <EditOfficeOwners officeId={data?.id}/>
        }
        {activeTab === "contacts" &&
        <EditOfficeContacts
          officeId={data?.id}
          initLeasingTeam={data?.leasingTeam}
        />
        }
        {activeTab === "deleteProperty" &&
        <EditOfficeDeleteProperty officeId={data?.id}/>
        }
      </IsAdmin>
      </>
    );
  }
  return <NoData/>;
}

const mapStateToProps = (store) => ({
  data: store.officeBuilding.editFormsData,
  loading: store.officeBuilding.editFormsDataLoading
});
const mapDispatchToProps = {
  fetchOfficeEditFormsData: (officeId) => fetchOfficeEditFormsData(officeId)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditOffice)
);
