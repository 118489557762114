import React from "react";
import styles from "./index.module.scss";
import SliderHtml from "../../../../partials/components/SliderHtml";
import {FormattedMessage, injectIntl} from "react-intl";
import PhoneNumberShow from "../../../../partials/components/PhoneNumberShow";
import cn from "classnames";
import _ from "lodash";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";

function PropertyHeaderContacts({owner, contacts, propertyID, isIndustrialParkView, isIndustrialWarehouseView, isEditable, intl}) {
  // Owner
  const ownerLogo = owner?.ownerLogo;
  const ownerName = owner?.ownerShortName || owner?.ownerName;
  // Leasing
  const leasingBasicDummyData = [
    {
      email: "example@example.com",
      phone: "+48 000-000-000"
    }
  ];
  const leasingLogo = contacts?.landlordLogo;
  const leasingName = contacts?.landlordShortName || contacts?.landlordName;
  const leasingContactPersons = contacts?.landlordContactPersons ||
    (
      IsPro({
        reverseCheck: true,
        overwriteRule: isEditable
      }) ? leasingBasicDummyData : []
    );
  // Common Data
  const isOwnerLeasingSame = ownerName === leasingName;
  const commonLogo = ownerLogo || leasingLogo;
  const commonName = ownerName || leasingName;

  // Helpers
  const contactItem = (contact, target) => {
    const contactPhone = contact?.phone;
    const contactEmail = contact?.email;
    const isLeasing = target === "leasing";
    const isOwner = target === "owner";
    const logo = isLeasing ? leasingLogo : isOwner ? ownerLogo : null;
    const name = isLeasing ? leasingName : isOwner ? ownerName : null;
    const blurEnable = !IsPro({}) && !isEditable;

    return(
      <div className={cn(
        styles.contactWrapper,
        isOwner ? styles.ownerContactWrapper : undefined
      )}>
        {!isOwnerLeasingSame && logo &&
          <div className={styles.contactLogo}>
            <img src={logo} alt={`${name}`}/>
          </div>
        }
        <div className={styles.contactData}>
          <p>{isLeasing ? <FormattedMessage id="VOCABULARY.Leasing Manager"/> : isOwner ? <FormattedMessage id="VOCABULARY.Property Owner"/> : ""}</p>
          {isOwner && name && <p className={styles.ownerName}>{ownerName}</p>}
          {isLeasing &&
            <BlurText
              type="phone"
              isBlurred={blurEnable}
            >
              {contactPhone ?
              <PhoneNumberShow
                phone={contactPhone}
                propertyID={propertyID}
                isIndustrialParkView={isIndustrialParkView}
                isIndustrialWarehousView={isIndustrialWarehouseView}
              /> : ""
              }
            </BlurText>
          }
          {isLeasing &&
            <BlurText
              type="email"
              isBlurred={blurEnable}
            >
              {contactEmail ? <a href={`mailto:${contactEmail}`}>{contactEmail}</a> : ""}
            </BlurText>
          }
        </div>
        {isLeasing &&
        <IsPro reverseCheck overwriteRule={isEditable}>
          <div className={styles.unlockWrapper}>
            <PaywallModal
              triggerLabel={<FormattedMessage id="VOCABULARY.Show"/>}
              triggerSlim
              triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Show"})}
              withIcon
            />
          </div>
        </IsPro>
        }
      </div>
    );
  }
  const getContacts = (target) => {
    let result = [];
    if(target === "leasing" && leasingContactPersons?.length > 0) {
      leasingContactPersons.map(contact => result.push(contactItem(contact, target)));
    }
    return result;
  };

  if(isOwnerLeasingSame) {
    return(
      <div className={cn(
        styles.propertyContactsWrapper,
        isOwnerLeasingSame ? styles.variant2 : undefined
      )}>
        {commonLogo && <div className={styles.logoWrapper}><img src={commonLogo} alt={`${commonName}`}/></div>}
        <div className={cn(
          styles.dataWrapper,
          !commonLogo ? styles.fullWidth : undefined
        )}>
          {!_.isEmpty(owner) && contactItem(null, "owner")}
          {
          leasingContactPersons?.length > 1 ?
            <SliderHtml data={getContacts("leasing")} alternative/> :
              leasingContactPersons?.length === 1 && contactItem(leasingContactPersons?.[0], "leasing")
          }
        </div>
      </div>
    );
  }
  return(
    <div className={cn(
            styles.propertyContactsWrapper,
            isOwnerLeasingSame ? styles.variant2 : styles.variant1
    )}>
      {!_.isEmpty(owner) && contactItem(null, "owner")}
      {
        leasingContactPersons?.length > 1 ?
          <SliderHtml data={getContacts("leasing")} alternative/> :
          leasingContactPersons?.length === 1 && contactItem(leasingContactPersons?.[0], "leasing")
      }
    </div>
  );
}

export default injectIntl(PropertyHeaderContacts);