import React from "react";
import {TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "app/partials/components/ButtonCustom";
import axios from "axios";
import {addSubscribersXlsError} from "app/crud/subscribers.crud";
import styles from "./index.module.scss";
import IconColorMUI from "app/partials/components/IconColorMUI";
import cx from "classnames";
import {downloadFileFromUrl} from "app/utils/helpers";
import {toAbsoluteUrl} from "_metronic";
import {
  shareOfferIndustrialOnline,
  updateOfferIndustrialClients,
} from "app/crud/estates/industrial/offers-industrial.crud";
import LoadingScreen from "../../LoadingScreen";
import InputCheckbox from "../../InputCheckbox";

class FileUpload extends React.Component {
  static defaultProps = {
    multiple: false,
  };

  constructor(props) {
    super(props);
    this.fileUploadRef = React.createRef();
  }

  handleAddFile = () => {
    this.fileUploadRef.current.click();
  };

  handleChangeFiles = (e) => {
    const { target } = e;

    if (target.files) {
      Object.values(target.files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
          this.props.onAddFile({
            data: readerEvent.target.result,
            type: file.type,
            name: file.name,
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  render() {
    return (
      <>
        <input
          ref={this.fileUploadRef}
          type="file"
          id="file"
          accept=".xlsx,.xls"
          style={{ display: "none" }}
          multiple={this.props.multiple}
          onChange={this.handleChangeFiles}
        />
        {this.props.caller(this.handleAddFile)}
      </>
    );
  }
}

class Tab1 extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      subscriberEmail: "",
      subscribersList: [],
      sharedSuccess: false,
      addMessage: false,
      messageContent: "",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.shared &&
      !prevProps.shared &&
      prevState.sharedSuccess !== this.props.shared
    ) {
      this.setState(
        {
          sharedSuccess: this.props.shared,
        },
        () =>
          setTimeout(() => {
            this.setState({
              subscriberEmail: "",
              subscribersList: [],
              sharedSuccess: false,
              addMessage: false,
              messageContent: "",
            });
          }, 5000)
      );
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    if (event.target.value.length <= 512) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };
  handleAddSubscriberEmail = (event) => {
    event.preventDefault();
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/;
    const { subscribersList, subscriberEmail } = this.state;
    if (subscriberEmail.length && subscriberEmail.match(mailFormat)) {
      subscribersList.push(subscriberEmail);
      this.setState({
        subscriberEmail: "",
      });
    }
  };
  handleAddFile = ({ data, type }) => {
    const { addSubscribersXlsError } = this.props;
    const headers = {
      "Content-Type": type,
      "Content-Disposition": `attachment; filename=upload-subscribers`,
    };
    return axios
      .post(`/subscribers-v2/emails_from_xls/`, data, {
        headers,
      })
      .then(({ data }) => {
        if (data && data.emails) {
          this.setState({
            subscribersList: [...this.state.subscribersList, ...data.emails],
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        addSubscribersXlsError();
      });
  };
  handleRemoveFollower = (follower) => {
    this.setState({
      subscribersList: this.state.subscribersList.filter(
        (subscriber) => subscriber !== follower
      ),
    });
  };
  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  handleShareOffer = () => {
    const {
      userId,
      offerID,
      offerClients,
      updateOfferClients,
      shareOfferOnline,
    } = this.props;
    const { subscribersList, addMessage, messageContent } = this.state;
    const data = {
      userPkSharing: userId,
      usersToSendEmail: subscribersList,
      message: addMessage ? messageContent : "",
    };
    shareOfferOnline(data, offerID);

    // Update Offer Subscribers list
    const updatedSubscribersList = [...offerClients, ...subscribersList];
    const dataUpdateSubscribers = {
      emails: updatedSubscribersList || [],
    };
    updateOfferClients(offerID, dataUpdateSubscribers, true);
  };

  render() {
    const { offerID, shareLoading, shared, intl } = this.props;

    if (offerID) {
      const {
        subscriberEmail,
        subscribersList,
        sharedSuccess,
        addMessage,
        messageContent,
      } = this.state;
      if (shared && sharedSuccess) {
        return (
          <div className={styles.addFollowersSharedSuccessWrapper}>
            <span className={styles.sharedSuccessIcon}>
              <IconColorMUI icon="check" color="danger" />
            </span>
            <span className={styles.sharedSuccessMessage}>
              <FormattedMessage
                id="You successfully shared Offer Online with provided recipients."
                values={{
                  strong: (
                    <strong>
                      {intl.formatMessage({ id: "Offer Online" })}
                    </strong>
                  ),
                }}
              />
            </span>
          </div>
        );
      }
      return (
        <>
          <div className={styles.addFollowerWrapper}>
            <form onSubmit={this.handleAddSubscriberEmail}>
              <TextField
                id="invite_new_subscriber"
                name="subscriberEmail"
                label={
                  <FormattedMessage id="OFFERS.PHRASE.ADD_RECIPIENT_ADDRESS_EMAIL" />
                }
                value={subscriberEmail}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </form>
            <ButtonCustom
              label={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
              color="neutral"
              onClick={this.handleAddSubscriberEmail}
              btnStyle={{ height: "52px" }}
            />
            <FileUpload
              onAddFile={this.handleAddFile}
              caller={(onClick) => (
                <ButtonCustom
                  label={
                    <FormattedMessage id="GENERAL.PHRASE.UPLOAD_A_XLS_FILE" />
                  }
                  color="success"
                  icon="add"
                  onClick={onClick}
                  btnStyle={{ height: "52px" }}
                />
              )}
            />
          </div>
          <div className={styles.downloadSampleWrapper}>
            <span
              className={styles.btnDownload}
              onClick={() =>
                downloadFileFromUrl(
                  toAbsoluteUrl("media/REDD_Import_Followers_Sample_File.xls")
                )
              }
            >
              <IconColorMUI icon="file_download" />
              <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.DOWNLOAD_SAMPLE_FILE" />
            </span>
          </div>
          <div
            className={cx(
              styles.listFollowers,
              subscribersList && !subscribersList.length && styles.flex
            )}
          >
            {subscribersList && subscribersList.length ? (
              subscribersList.map((subscriber, index) => {
                return (
                  <div key={index} className={styles.follower}>
                    <span
                      className={styles.followerRemove}
                      onClick={() => this.handleRemoveFollower(subscriber)}
                    >
                      <IconColorMUI icon="close" color="danger" />
                    </span>
                    {subscriber}
                  </div>
                );
              })
            ) : (
              <Typography variant="body2">
                <FormattedMessage id="OFFERS.PHRASE.ADD_PEOPLE_MESSAGE" />
              </Typography>
            )}
          </div>
          {addMessage && (
            <TextField
              id="messageContent"
              name="messageContent"
              label={
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MESSAGE" />
              }
              value={messageContent}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
              multiline
              rows="4"
            />
          )}
          <div className={styles.shareActions}>
            <InputCheckbox
              label={
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.ADD_MESSAGE" />
              }
              name="addMessage"
              value={addMessage}
              onCheckboxChange={this.handleCheckboxChange}
            />
            {shareLoading ? (
              <LoadingScreen />
            ) : (
              <ButtonCustom
                label={<FormattedMessage id="GENERAL.PHRASE.SHARE" />}
                color="danger"
                solid
                onClick={() => this.handleShareOffer()}
                disabled={subscribersList && subscribersList.length <= 0}
                inactive={subscribersList && subscribersList.length <= 0}
              />
            )}
          </div>
        </>
      );
    }
    return true;
  }
}

const mapStateToProps = ({ user, offersIndustrial }) => ({
  userId: user.data.pk,
  shared: offersIndustrial.shared,
  shareLoading: offersIndustrial.shareLoading,
});
const mapDispatchToProps = {
  addSubscribersXlsError: () => addSubscribersXlsError(),
  shareOfferOnline: (data, offerID) =>
    shareOfferIndustrialOnline(data, offerID),
  updateOfferClients: (offerID, data, notificationDisabled) =>
    updateOfferIndustrialClients(offerID, data, notificationDisabled),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tab1));
