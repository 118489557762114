import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ROUTES} from "app/constants";
import ShortlistRemoveParks from "../../Shortlists/ShortlistRemoveParks";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import BadgeValue from "app/partials/components/BadgeValue";
import INDUSTRIAL_BUILDING_TYPE from "app/constants/INDUSTRIAL_BUILDING_TYPE";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";

const buildingTypes = Object.values(INDUSTRIAL_BUILDING_TYPE);

class TableOffersParksCells extends React.Component {
  render() {
    const { row, shortlistId } = this.props;

    const buildingType = row.buildingTypes
      ?.map((buildingType) => buildingTypes.find((type) => type.id === buildingType))
      ?.map((item) => <span 
      style={{
      backgroundColor: "rgba(10, 187, 135, 0.1)",
      padding: "0.5rem 1rem",
      borderRadius: "4px",
      color: "#0abb87",
      marginRight: "5px"
    }} 
      
      > <FormattedMessage key={item.id} id={item.name} /> </span> )
    const currentDate = new Date().getTime();
    const parkDate = new Date(row?.availableFrom).getTime();
    const isAvailableImmediately = parkDate <= currentDate;

    return (
      <>
        <TableCell component="th" id={row.id} scope="row">
          <PropertyCardPreview
            propertyID={row.id}
            title={row.name}
            thumb={row.thumbnail}
            route={ROUTES.INDUSTRIAL_PARK}
          />
        </TableCell>
        <TableCell>
          <BadgeValue
            value={toNumberFormat(row.totalAreaWarehouseAvailable, "area")}
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE" />}
            labelColor="success"
            minWidth="8.5rem"
          />
        </TableCell>
        <TableCell align="left">{row.numberOfWarehouses}</TableCell>
        <TableCell align="left">{handleEmptyValue(buildingType)}</TableCell>
        <TableCell align="left">
          <UpdatedAtPreview
            level={(row.availableFrom || isAvailableImmediately) ? 1 : 2}
            date={row?.availableFrom ? (isAvailableImmediately ? <FormattedMessage id="GENERAL.PHRASE.IMMEDIATELY"/> : row?.availableFrom) : <FormattedMessage id="GENERAL.PHRASE.IMMEDIATELY"/>}
            dateString={!row?.availableFrom || isAvailableImmediately}
            alternative
          />
        </TableCell>
        <TableCell align="left">
          <UpdatedAtPreview level={1} date={row.updatedAt} alternative />
        </TableCell>
        <TableCell align="right">
          <ShortlistRemoveParks
            shortlistId={shortlistId}
            parks={[row.id]}
            directIcon
          />
        </TableCell>
      </>
    );
  }
}

export default injectIntl(connect(null, null)(TableOffersParksCells));
