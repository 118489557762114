// FETCH
export const fetchIndustrialWarehouseBasic = (warehouseID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_REQUEST',
  payload: {
    warehouseID,
    backgroundLoading
  },
});
export const fetchIndustrialWarehouseUnits = (warehouseID, ordering, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST',
  payload: {
    warehouseID,
    ordering,
    backgroundLoading
  },
});
export const fetchIndustrialWarehouseLeaseTerms = (warehouseID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_REQUEST',
  payload: {
    warehouseID,
    backgroundLoading
  },
});
export const fetchIndustrialWarehouseTechnicalDetails = (warehouseID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_REQUEST',
  payload: {
    warehouseID,
    backgroundLoading
  },
});
export const fetchIndustrialWarehouseAmenities = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseMetrics = (warehouseID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_METRICS_REQUEST',
  payload: {
    warehouseID,
    backgroundLoading
  },
});
export const fetchIndustrialWarehouseChanges = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseFiles = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseSimilarProperties = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseOwner = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseLeasingContacts = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_REQUEST',
  payload: {
    warehouseID
  },
});
export const fetchIndustrialWarehouseEditFormsData = (warehouseID) => ({
  type: 'FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_REQUEST',
  payload: {
    warehouseID
  },
});

// AUTO ACTIONS
export const resetIndustrialWarehouseState = () => ({
  type: 'RESET_INDUSTRIAL_WAREHOUSE_STATE_REQUEST'
});
export const resetIndustrialWarehouseLoadingStates = () => ({
  type: 'RESET_INDUSTRIAL_WAREHOUSE_LOADING_STATES_REQUEST'
});