import React from "react";
import {Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {updateOfferIndustrialClients} from "app/crud/estates/industrial/offers-industrial.crud";
import cx from "classnames";
import styles from "./index.module.scss";
import IconColorMUI from "../../IconColorMUI";

class Tab2 extends React.Component {
  constructor(props) {
    super(props);
    this.childTransfer = React.createRef();
    this.state = {
      subscriberEmail: "",
      subscribersList: props.offerClients || []
    };
  }
  handleChange = (event) => {
    this.setState({
      subscriberEmail: event.target.value
    })
  };
  handleRemoveFollower = (follower) => {
    this.setState({
      subscribersList: this.state.subscribersList.filter(subscriber => subscriber !== follower)
    }, () => {
        const {offerID, updateOfferClients} = this.props;
        const {subscribersList} = this.state;
        const data = {
          emails: subscribersList || [],
        };
        updateOfferClients(offerID, data);
    });
  };

  render() {
    const {subscribersList} = this.state;
    return(
      <>
      <div className={cx(styles.listFollowers, subscribersList && !subscribersList.length && styles.flex)}>
            {subscribersList && subscribersList.length ? subscribersList.map((subscriber, index) => {
              return(
                <div key={index} className={styles.follower}><span className={styles.followerRemove} onClick={() => this.handleRemoveFollower(subscriber)}><IconColorMUI icon="close" color="danger"/></span>{subscriber}</div>
              );
            }) : <Typography variant="body2"><FormattedMessage id="OFFERS.PHRASE.MANAGE_PEOPLE_MESSAGE"/></Typography>}
          </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  updateOfferClients: (offerID, data) => updateOfferIndustrialClients(offerID, data)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(Tab2)
);