import React, {useEffect} from "react";
import {fetchIndustrialWarehouseEditFormsData} from "app/crud/estates/industrial/industrialWarehouse.crud";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import EditWarehouseBasics from "./EditWarehouseBasics";
import EditWarehouseLeaseTerms from "./EditWarehouseLeaseTerms";
import EditWarehouseLocation from "./EditWarehouseLocation";
import EditWarehouseContacts from "./EditWarehouseContacts";
import EditWarehouseOwners from "./EditWarehouseOwners";
import EditPropertyManageImages from "../common/EditPropertyManageImages";
import EditWarehouseAmenities from "./EditWarehouseAmenities";
import EditWarehouseTechnicalDetails from "./EditWarehouseTechnicalDetails";

function EditWarehouse({data, loading, parentData, activeTab, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  useEffect(() => {
    if(!loading && parentData?.id) {
      props.fetchIndustrialWarehouseEditFormsData(parentData?.id);
    }
 // eslint-disable-next-line
  }, []);

  if(loading) {
    return <LoadingScreen/>
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" &&
        <EditWarehouseBasics
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "leaseTerms" &&
        <EditWarehouseLeaseTerms
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "technicalDetails" &&
        <EditWarehouseTechnicalDetails
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "location" &&
        <EditWarehouseLocation
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "images" &&
        <EditPropertyManageImages data={data} view="industrialWarehouse"/>
        }
        {activeTab === "amenities" &&
        <EditWarehouseAmenities
          data={data}
          warehouseID={data?.id}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "owner" &&
        <EditWarehouseOwners warehouseID={data?.id}/>
        }
        {activeTab === "contacts" &&
        <EditWarehouseContacts
          warehouseID={data?.id}
        />
        }
      </>
    );
  }
  return <NoData/>;
}

const mapStateToProps = (store) => ({
  data: store.industrialWarehouse.editFormsData,
  loading: store.industrialWarehouse.editFormsDataLoading
});
const mapDispatchToProps = {
  fetchIndustrialWarehouseEditFormsData: (warehouseID) => fetchIndustrialWarehouseEditFormsData(warehouseID)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditWarehouse)
);
