import React from "react";
import {Grid, TableCell} from "@material-ui/core";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import BadgeValue from "../../../../partials/components/BadgeValue";
import {toNumberFormat} from "../../../../utils/helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import TableDrawer from "../../../../partials/components/TableDrawer";
import TABLE_CELLS from "../../../../partials/components/TableProperties/TableCells/TABLE_CELLS";
import connect from "react-redux/es/connect/connect";
import {fetchIndustrialParkWarehouses} from "../../../../crud/estates/industrial/industrial.crud";
import PropertyExpandedCardPreview from "../../../../partials/components/_DataParts/PropertyExpandedCardPreview";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import IPI_PLAN_SOURCE from "../../../../constants/IPI_PLAN_SOURCE";
import ModalIPI from "../../../../partials/components/IPI/ModalIPI";
import LeadsManagementAddProperty from "../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import ParkAddToOffer from "../../components/ParkAddToOffer";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import IsAdmin from "../../../../utils/IsAdmin";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";
import styles from "../../../../partials/components/TableProperties/index.module.scss";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";

class ParksPageTableRow extends React.Component {
  render() {
    const {row, parkWarehouses, parkWarehousesLoading, fetchIndustrialParkWarehouses} = this.props;

    return (
      <>
        <TableCell>
          <PropertyCardPreview
            propertyID={row.id}
            title={row.name}
            semiTitle={row.marketName}
            semiTitle2={row.addressCity}
            thumb={row.thumbnail}
            route={ROUTES.INDUSTRIAL_PARK}
          />
        </TableCell>
        <TableCell>
          <BadgeValue
            value={toNumberFormat(row.rentWarehouseFrom, 'currency', '€', 2)}
            label={<FormattedMessage id="VOCABULARY.Asking rent"/>}
          />
          <BadgeValue
            value={toNumberFormat(row.serviceWarehouseFrom, 'currency', 'PLN', 2)}
            label={<FormattedMessage id="VOCABULARY.Service charge"/>}
          />
        </TableCell>
        <TableCell>
          <div className={styles.spaceDataWrapper}>
            <HighlightLabel color="neutral2">
              <BlurText
                type="area"
                isBlurred={IsPro({
                  reverseCheck: true,
                  overwriteRule: row?.isEditable
                })}
              >
                {toNumberFormat(row?.totalAreaWarehouseAvailable, "area")}
              </BlurText>
            </HighlightLabel>
            <TableDrawer
              titleAdvance
              title={
                <PropertyExpandedCardPreview
                  propertyID={row.id}
                  title={row.name}
                  semiTitle={row.address}
                  semiTitle2={row.addressCity}
                  thumb={row.thumbnail}
                  route={ROUTES.INDUSTRIAL_PARK}
                />
              }
              headRows={
                [
                  {
                    id: 'building_name',
                    numeric: false,
                    disablePadding: false,
                    label: 'VOCABULARY.Building',
                    sort: false
                  },
                  {
                    id: 'status',
                    numeric: false,
                    disablePadding: false,
                    label: 'INDUSTRIAL.TABLE.STATUS',
                    sort: false
                  },
                  {
                    id: 'available_area',
                    numeric: false,
                    disablePadding: false,
                    label: 'INDUSTRIAL.TABLE.AVAILABLE_AREA',
                    sort: false
                  },
                  {
                    id: 'total_area',
                    numeric: false,
                    disablePadding: false,
                    label: 'INDUSTRIAL.TABLE.TOTAL_AREA',
                    sort: false
                  },
                  {
                    id: 'available_units',
                    numeric: true,
                    disablePadding: false,
                    label: 'INDUSTRIAL.TABLE.NO_OF_AVAILABLE_UNITS',
                    sort: true
                  },
                  {
                    id: 'available_at',
                    numeric: false,
                    disablePadding: false,
                    label: 'INDUSTRIAL.TABLE.BUILDING_AVAILABILITY',
                    sort: false
                  },
                ]
              }
              bodyRows={parkWarehouses}
              bodyRowsReady={parkWarehousesLoading}
              target={TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES}
              parentData={row}
              parentPreviewData={row}
              parentId={row.id}
              onClick={fetchIndustrialParkWarehouses}
              isEditable={true}
            >
              <HighlightLabel
                color="success"
                tooltip={<FormattedMessage id="INDUSTRIAL.PHRASE.VIEW_BUILDINGS"/>}
                disableTooptipInfo
                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                hover
              >
                {row.numberOfWarehouses}
                <IconColorMUI
                  icon="warehouse"
                  color="success"
                  style={{marginLeft: "5px"}}
                />
              </HighlightLabel>
            </TableDrawer>
          </div>
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={1} date={row?.updatedAtByUser} alternative />
        </TableCell>
        <TableCell alight="right">
          <Grid container alignItems="center" justifyContent="flex-end" wrap="nowrap">
            {row?.parkIpiPlanImage &&
              <Grid item>
                <ModalIPI
                  title={row?.name}
                  planSource={IPI_PLAN_SOURCE.PARK}
                  planPark={row?.parkIpiPlanImage}
                  parkID={row?.id}
                  isEditable={row?.ipiEditable}
                  directIcon
                  btnIcon="texture"
                />
              </Grid>
            }
            <IsAdmin>
              <Grid item>
                <LeadsManagementAddProperty propertyID={row?.id}/>
              </Grid>
            </IsAdmin>
            <ParkAddToOffer
              parkId={row?.id}
              isAddToNewOfferEnable={row?.offerEnable}
              isAddToExistingOfferEnable={row?.offerEnable}
              btnTrigger={
                <ModalTrigger
                  icon="library_add"
                  label={<FormattedMessage id="VOCABULARY.Add to offer"/>}
                  color="neutral"
                  directIcon
                />
              }
            />
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = store => ({
  parkWarehouses: store.industrial.parkWarehouses,
  parkWarehousesLoading: store.industrial.parkWarehousesLoading,
  parkWarehousesLoaded: store.industrial.parkWarehousesLoaded
});
const mapDispatchToProps = {
  fetchIndustrialParkWarehouses: (parkID) => fetchIndustrialParkWarehouses(parkID)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ParksPageTableRow)
);