import React from "react";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import ACQUISITIONS_AGREEMENT_TYPES from "../../../constants/ACQUISITIONS_AGREEMENT_TYPES";

export default class TransactionTypePreview extends React.Component {
    renderTransactionType = (type) => {
        let color = "";
        let tooltip = "";

        if(type === ACQUISITIONS_AGREEMENT_TYPES.OTHER) {
            color = "warning";
            tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_OTHER";
        }
        else if(type === ACQUISITIONS_AGREEMENT_TYPES.PRELIMINARY_AGREEMENT) {
            color = "neutral";
            tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_PRELIMINARY";
        }
        else if(type === ACQUISITIONS_AGREEMENT_TYPES.FINAL_AGREEMENT) {
            color = "success";
            tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_FINAL";
        }

        if(color !== "" && tooltip !== "") {
            return (
                <ModalTrigger
                    icon="circle"
                    label={<FormattedMessage id={tooltip}/>}
                    color={color}
                    directIcon
                    defaultCursor
                    iconSizeInherit
                    style={{fontSize: "0.8rem"}}
                />
            );
        }
        return null;
    };

    render() {
        const {type} = this.props;
        return this.renderTransactionType(type);
    }
}