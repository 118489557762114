import React from "react";
import {ModalTrigger} from "../../ModalCustom/ModalTrigger";
import {FormattedMessage, injectIntl} from "react-intl";
import ModalCustom from "../../ModalCustom";
import {InputUpload} from "../../InputUpload";
import connect from "react-redux/es/connect/connect";
import {
  uploadIndustrialParkIPIPlan,
  uploadIndustrialWarehouseIPIPlan
} from "app/crud/estates/industrial/industrial.crud";
import IPI_PLAN_SOURCE from "../../../../constants/IPI_PLAN_SOURCE";
import LoadingScreen from "../../LoadingScreen";
import styles from "./index.module.scss";
import ConfirmationDialog from "../../ConfirmationDialog";
import {Typography} from "@material-ui/core";
import cn from "classnames";

class UploadIpiPlan extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            planFile: null,
            confirmationOpen: false
        }
    }

    handleCloseModal = () => {
        this.child.current.handleClose();
    };
    handleUploadPlan = (planFile) => {
        this.setState({
            planFile
        });
    }
    handleSave = () => {
        this.setState({
            confirmationOpen: true
        });
    }
    handleConfirmationGranted = () => {
        const {
            planTarget,
            planTargetID,
            planSource,
            planSourceID,
            uploadIndustrialParkIPIPlan,
            uploadIndustrialWarehouseIPIPlan
        } = this.props;
        const {planFile} = this.state;
        this.setState({
            confirmationOpen: false
        }, () => {
            const isParkTarget = planTarget === IPI_PLAN_SOURCE.PARK;
            const isWarehouseTarget = planTarget === IPI_PLAN_SOURCE.WAREHOUSE;
            const onSavePlan = isParkTarget ? uploadIndustrialParkIPIPlan : isWarehouseTarget && uploadIndustrialWarehouseIPIPlan;
            const source = planSource || planTarget;
            const sourceID = planSourceID || planTargetID;

            if (onSavePlan && planFile && planTargetID) {
                onSavePlan(planTargetID, planFile, source, sourceID);
            }
        });
    }

    render() {
        const {planTarget, btnLabel, btnColor, btnIcon, ipiPlanUploading} = this.props;
        const isParkTarget = planTarget === IPI_PLAN_SOURCE.PARK;
        const isWarehouseTarget = planTarget === IPI_PLAN_SOURCE.WAREHOUSE;

        return (
            <>
                <ModalCustom
                    ref={this.child}
                    btn={
                        <ModalTrigger
                            icon={btnIcon ? btnIcon : "add"}
                            label={btnLabel ? btnLabel : <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_PLAN"/>}
                            color={btnColor || "danger"}
                            directBtn
                        />
                    }
                    title={isParkTarget ?
                        <FormattedMessage id="INDUSTRIAL.PHRASE.UPLOAD_IPI_PARK_PLAN"/> : isWarehouseTarget &&
                        <FormattedMessage id="INDUSTRIAL.PHRASE.UPLOAD_IPI_WAREHOUSE_PLAN"/>}
                    handleSave={this.handleSave}
                    btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
                    disabled={ipiPlanUploading}
                >
                    <div className={cn(styles.planUploadWrapper, styles.uploaderWrapper)}>
                        <InputUpload
                            onFileUpload={this.handleUploadPlan}
                            width="100%"
                            height="auto"
                            noFrame
                            responsiveBox
                            fileTarget="ipiPlanImage"
                        />
                        {ipiPlanUploading &&
                            <div className={styles.planUploading}><LoadingScreen/></div>
                        }
                    </div>
                </ModalCustom>
                <ConfirmationDialog
                    open={this.state.confirmationOpen}
                    onConfirm={this.handleConfirmationGranted}
                    message={
                    <>
                        <Typography style={{marginBottom: "1rem"}}><FormattedMessage id="INDUSTRIAL.PHRASE.IPI_UPLOAD_PLAN_CONFIRMATION_MESSAGE"/></Typography>
                        <Typography><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
                    </>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = store => ({
    ipiPlanUploading: store.industrial.ipiPlanUploading
})
const mapDispatchToProps = {
    uploadIndustrialParkIPIPlan: (parkID, file, source, sourceID) => uploadIndustrialParkIPIPlan(parkID, file, source, sourceID),
    uploadIndustrialWarehouseIPIPlan: (warehouseID, file, source, sourceID) => uploadIndustrialWarehouseIPIPlan(warehouseID, file, source, sourceID)
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UploadIpiPlan)
)