import {ROUTES} from "app/constants";
import APP_SUBSCRIPTIONS from "../../app/constants/APP_SUBSCRIPTIONS";
import ACCOUNT_TYPE from "../../app/constants/ACCOUNT_TYPE";

function replaceSlash(string) {
  return string.replace("/", "");
}
export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: replaceSlash(ROUTES.DASHBOARD_PAGE),
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Offices",
        root: true,
        alignment: "left",
        translate: "MENU.OFFICES",
        submenu: [
          {
            title: "Buildings",
            page: replaceSlash(ROUTES.BUILDINGS_LIST_PAGE),
            translate: "MENU.BUILDINGS",
          },
          {
            title: "Units",
            page: replaceSlash(ROUTES.MODULES_LIST_PAGE),
            translate: "MENU.UNITS",
            premiumOnly: true,
          },
          {
            title: "Coworks",
            page: replaceSlash(ROUTES.COWORKS_PAGE),
            translate: "MENU.COWORKS",
          },
        ],
      },
      {
        title: "Investment",
        root: true,
        alignment: "left",
        translate: "MENU.INVESTMENT",
        submenu: [
          {
            title: "Owners",
            translate: "MENU.OWNERS",
            page: replaceSlash(ROUTES.OWNERS_LIST_PAGE),
            premiumOnly: true,
          },
          {
            title: "Acquisitions",
            translate: "MENU.ACQUISITIONS",
            page: replaceSlash(ROUTES.ACQUISITIONS_PAGE),
            premiumOnly: true,
          },
        ],
      },
      {
        title: "Analytics",
        root: true,
        alignment: "left",
        translate: "MENU.ANALYTICS",
        submenu: [
          {
            title: "Analytics 1",
            translate: "MENU.THE_LARGEST_SURFACE_RELEASES",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/1",
            premiumOnly: true,
          },
          {
            title: "Analytics 2",
            translate: "MENU.RENT_REDUCTIONS",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/2",
            premiumOnly: true,
          },
          {
            title: "Analytics 3",
            translate: "MENU.MOST_RENTED_BUILDINGS",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/3",
            premiumOnly: true,
          },
        ],
      },
      {
        title: "Transparency",
        root: true,
        alignment: "left",
        page: replaceSlash(ROUTES.TRANSPARENCY_PAGE),
        translate: "MENU.TRANSPARENCY",
      },
      {
        title: "Team",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: replaceSlash(ROUTES.TEAM_PAGE),
        translate: "MENU.TEAM",
      },
      {
        title: "Subscribers",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: replaceSlash(ROUTES.SUBSCRIBERS_PAGE),
        translate: "MENU.SUBSCRIBERS",
      },
      {
        title: "Shortlists",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: replaceSlash(ROUTES.SHORTLISTS_PAGE),
        translate: "MENU.SHORTLISTS",
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        page: replaceSlash(ROUTES.DASHBOARD_PAGE),
        translate: "MENU.DASHBOARD",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {section: "Analyse", translate: "MENU.ANALYSE"},
      {
        title: "Buildings",
        page: replaceSlash(ROUTES.BUILDINGS_LIST_PAGE),
        translate: "MENU.BUILDINGS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Units",
        page: replaceSlash(ROUTES.MODULES_LIST_PAGE),
        translate: "MENU.UNITS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.PRO
        }
      },
      {
        title: "Offers",
        page: replaceSlash(ROUTES.SHORTLISTS_PAGE),
        translate: "MENU.MY_OFFERS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.PRO
        }
      },
      {section: "finne Leads"},
      {
        title: "Tenant Enquiries",
        page: replaceSlash(ROUTES.LEADS_TENANT_INTRODUCTIONS),
        translate: "MENU.LEADS_TENANT_INTRODUCTIONS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC,
          accountType: ACCOUNT_TYPE.LANDLORD
        },
        isFeatured: true,
        navMetricsKey: "tenantIntroductions"
      },
      {
        title: "Potential Tenants",
        page: replaceSlash(ROUTES.LEADS_POTENTIAL_TENANTS),
        translate: "MENU.LEADS_POTENTIAL_TENANTS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC,
          accountType: ACCOUNT_TYPE.LANDLORD
        },
        isFeatured: true,
        navMetricsKey: "potentialTenants"
      },
      {section: "Competition", translate: "MENU.COMPETITION"},
      {
        title: "Competition Analysis",
        page: replaceSlash(ROUTES.COMPETITION_ANALYSIS_PAGE),
        translate: "MENU.COMPETITION_ANALYSIS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.PRO,
          accountType: ACCOUNT_TYPE.LANDLORD
        }
      },
      {
        title: "Latest Transactions",
        page: replaceSlash(ROUTES.LATEST_TRANSACTIONS),
        translate: "MENU.LATEST_TRANSACTIONS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Supply Boost",
        page: replaceSlash(ROUTES.SUPPLY_BOOST),
        translate: "MENU.SUPPLY_BOOST",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Other",
        root: true,
        bullet: "line",
        translate: "MENU.OTHER",
        reddClickEvent: 'menu-dropdown-others-click',
        submenu: [
          {
            title: "Offers Analysis",
            page: replaceSlash(ROUTES.SHORTLISTS_LANDLORD_PAGE),
            translate: "MENU.SHORTLISTS_ANALYSIS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          },
          {
            title: "Followers",
            page: replaceSlash(ROUTES.SUBSCRIBERS_PAGE),
            translate: "MENU.MY_FOLLOWERS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          },
          {
            title: "Property Particulars Analysis",
            page: replaceSlash(ROUTES.PROPERTY_PARTICULARS_ANALYSIS),
            translate: "MENU.PROPERTY_PARTICULARS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          },
          {
            title: "Owners",
            page: replaceSlash(ROUTES.OWNERS_LIST_PAGE),
            translate: "MENU.OWNERS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
          {
            title: "Acquisitions",
            page: replaceSlash(ROUTES.ACQUISITIONS_PAGE),
            translate: "MENU.ACQUISITIONS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
          {
            title: "Analytics 1",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/1",
            translate: "MENU.THE_LARGEST_SURFACE_RELEASES",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
          {
            title: "Analytics 3",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/3",
            translate: "MENU.MOST_RENTED_BUILDINGS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
          {
            title: "Analytics 2",
            page: replaceSlash(ROUTES.ANALYTICS_PAGE) + "/2",
            translate: "MENU.RENT_REDUCTIONS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
        ],
      },
      {
        title: "Admin",
        root: true,
        bullet: "line",
        translate: "MENU.ADMIN",
        reddClickEvent: 'menu-dropdown-admin-click',
        submenu: [
          {
            title: "Leads Management",
            page: replaceSlash(ROUTES.LEADS_MANAGEMENT),
            translate: "MENU.LEADS_MANAGEMENT",
            permissions: {
              accountType: ACCOUNT_TYPE.ADMIN
            },
            isFeatured: true,
            featuredLabel: "AO"
          },
          {
            title: "Manage Account Requests",
            page: replaceSlash(ROUTES.ADMIN_MANAGE_ACCOUNT_REQUESTS),
            translate: "MENU.ADMIN_MANAGE_ACCOUNT_REQUESTS",
            permissions: {
              accountType: ACCOUNT_TYPE.ADMIN
            },
            isFeatured: true,
            featuredLabel: "AO"
          },
          {
            title: "Add User",
            page: replaceSlash(ROUTES.ADMIN_ADD_USER),
            translate: "MENU.ADD_USER",
            permissions: {
              accountType: ACCOUNT_TYPE.ADMIN
            },
            isFeatured: true,
            featuredLabel: "AO"
          },
          {
            title: "Users",
            page: replaceSlash(ROUTES.TEAM_PAGE),
            translate: "MENU.USERS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.BASIC
            }
          },
          {
            title: "Help Center",
            page:
          "https://www.notion.so/reddgroup/PODSTAWOWA-BAZA-WIEDZY-87da329dafc246d39f7d98f05b3e86ef",
            pageExternal: true,
            permissions: {
              subscription: APP_SUBSCRIPTIONS.BASIC
            }
          },
        ],
      },
    ],
  },
  asideIndustrial: {
    self: {},
    items: [
      {
        title: "Dashboard",
        page: replaceSlash(ROUTES.DASHBOARD_PAGE),
        translate: "MENU.DASHBOARD",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {section: "Analyse", translate: "MENU.ANALYSE"},
      {
        title: "Parks",
        page: replaceSlash(ROUTES.INDUSTRIAL_PARKS),
        translate: "MENU.PARKS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Buildings",
        page: replaceSlash(ROUTES.INDUSTRIAL_BUILDINGS),
        translate: "MENU.BUILDINGS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Offers Industrial",
        page: replaceSlash(ROUTES.INDUSTRIAL_SHORTLISTS_PAGE),
        translate: "MENU.MY_OFFERS",
        permissions: {
        subscription: APP_SUBSCRIPTIONS.PRO
      }
      },
      {section: "finne Leads"},
      {
        title: "Tenant Enquiries",
        page: replaceSlash(ROUTES.LEADS_TENANT_INTRODUCTIONS),
        translate: "MENU.LEADS_TENANT_INTRODUCTIONS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC,
          accountType: ACCOUNT_TYPE.LANDLORD
        },
        isFeatured: true,
        navMetricsKey: "tenantIntroductions"
      },
      {
        title: "Potential Tenants",
        page: replaceSlash(ROUTES.LEADS_POTENTIAL_TENANTS),
        translate: "MENU.LEADS_POTENTIAL_TENANTS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC,
          accountType: ACCOUNT_TYPE.LANDLORD
        },
        isFeatured: true,
        navMetricsKey: "potentialTenants"
      },
      {section: "Competition", translate: "MENU.COMPETITION"},
      {
        title: "Symmco",
        page: replaceSlash(ROUTES.SYMMCO),
        translate: "MENU.SYMMCO",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.PRO,
          accountType: ACCOUNT_TYPE.LANDLORD
        }
      },
      {
        title: "Latest Transactions",
        page: replaceSlash(ROUTES.LATEST_TRANSACTIONS),
        translate: "MENU.LATEST_TRANSACTIONS",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Supply Boost",
        page: replaceSlash(ROUTES.SUPPLY_BOOST),
        translate: "MENU.SUPPLY_BOOST",
        permissions: {
          subscription: APP_SUBSCRIPTIONS.BASIC
        }
      },
      {
        title: "Other",
        root: true,
        translate: "MENU.OTHER",
        reddClickEvent: 'menu-dropdown-others-click',
        submenu: [
          {
            title: "Property Particulars Analysis",
            page: replaceSlash(ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ANALYSIS),
            translate: "MENU.PROPERTY_PARTICULARS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          },
          {
            title: "Offers Analysis Industrial",
            page: replaceSlash(ROUTES.INDUSTRIAL_SHORTLISTS_LANDLORD_PAGE),
            translate: "MENU.SHORTLISTS_ANALYSIS_INDUSTRIAL",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          },
          {
            title: "Followers",
            page: replaceSlash(ROUTES.SUBSCRIBERS_PAGE),
            translate: "MENU.MY_FOLLOWERS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO,
              accountType: ACCOUNT_TYPE.LANDLORD
            }
          }
        ],
      },
      {
        title: "Admin",
        root: true,
        translate: "MENU.ADMIN",
        reddClickEvent: 'menu-dropdown-admin-click',
        submenu: [
          {
            title: "Leads Management",
            page: replaceSlash(ROUTES.LEADS_MANAGEMENT),
            translate: "MENU.LEADS_MANAGEMENT",
            permissions: {
              accountType: ACCOUNT_TYPE.ADMIN
            },
            isFeatured: true,
            featuredLabel: "AO"
          },
          {
            title: "Add User",
            page: replaceSlash(ROUTES.ADMIN_ADD_USER),
            translate: "MENU.ADD_USER",
            permissions: {
              accountType: ACCOUNT_TYPE.ADMIN
            },
            isFeatured: true,
            featuredLabel: "AO"
          },
          {
            title: "Users",
            page: replaceSlash(ROUTES.TEAM_PAGE),
            translate: "MENU.USERS",
            permissions: {
              subscription: APP_SUBSCRIPTIONS.PRO
            }
          },
          {
            title: "Help Center",
            page:
          "https://www.notion.so/reddgroup/PODSTAWOWA-BAZA-WIEDZY-87da329dafc246d39f7d98f05b3e86ef",
            pageExternal: true,
            permissions: {
              subscription: APP_SUBSCRIPTIONS.BASIC
            }
          },
        ],
      },
    ],
  },
};
