import React from "react";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

const initialState = {
  publicTransport: false,
  financialStatement: false,
  marketComparison: false,
  mediaNews: false,
  tenants: false,
  demography: false,
  mobileAccess: false,
  effectiveRent: false,
  sent: false
}
export default class PropertyFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    }
  }
  // Handlers
  handleOptionSelect = (option) => {
    this.setState({
      [option]: !this.state[option]
    });
  }
  handleSendFeedback = () => {
    const {callReddClickEvent, match, currentApplicationView, view} = this.props;
    const {publicTransport, financialStatement, marketComparison, mediaNews, tenants, demography, mobileAccess, effectiveRent} = this.state;
    const sendActive = publicTransport || financialStatement || marketComparison || mediaNews || tenants || demography || mobileAccess || effectiveRent;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const isIndustrialWarehouseView = view === "industrialWarehouse";
    const propertyID = match?.params?.id;
    const selectedOptions = [
      ...publicTransport ? ["Public transport"] : [],
      ...financialStatement ? ["Financial Statement"] : [],
      ...marketComparison ? ["Market comparison"] : [],
      ...mediaNews ? ["Media news"] : [],
      ...tenants ? ["Tenants"] : [],
      ...demography ? ["Demography"] : [],
      ...mobileAccess ? ["Mobile Access"] : [],
      ...effectiveRent ? ["Effective rent"] : []
    ].join(", ");

    if(propertyID && callReddClickEvent && sendActive) {
      this.setState({
        sent: true
      }, () => {
        if(isIndustrialView) {
          if(isIndustrialWarehouseView) {
            callReddClickEvent(
              "industrial-warehouse-page-additional-useful-data",
              "warehouse",
              null,
              propertyID,
              {
                interestedIn: selectedOptions
              },
              true
            );
          }
          else {
            callReddClickEvent(
              "industrial-park-page-additional-useful-data",
              "park",
              null,
              propertyID,
              {
                interestedIn: selectedOptions
              },
              true
            );
          }
        }
        else {
          callReddClickEvent(
            "office-building-page-additional-useful-data",
            "office",
            null,
            propertyID,
            {
              interestedIn: selectedOptions
            },
            true
          );
        }
      });
      // Reset State
      setTimeout(() => {
        this.setState({
          ...initialState
        })
      }, 5000);
    }
  }

  render() {
    const {currentApplicationView} = this.props;
    const {publicTransport, financialStatement, marketComparison, mediaNews, tenants, demography, mobileAccess, effectiveRent, sent} = this.state;
    const sendActive = publicTransport || financialStatement || marketComparison || mediaNews || tenants || demography || mobileAccess || effectiveRent;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    return(
      <div className={styles.propertyFeedbackWrapper}>
        <h2><FormattedMessage id="VOCABULARY.What additional data would help?"/></h2>
        <div className={styles.feedbackOptionsWrapper}>
          <button
            className={effectiveRent ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("effectiveRent")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>💲</span>
            <FormattedMessage id="VOCABULARY.Effective rent"/>
          </button>
          <button
            className={financialStatement ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("financialStatement")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>📊</span>
            <FormattedMessage id="VOCABULARY.Financial statements"/>
          </button>
          <button
            className={tenants ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("tenants")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>🔑</span>
            <FormattedMessage id="VOCABULARY.Tenants"/>
          </button>
          <button
            className={marketComparison ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("marketComparison")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>📈</span>
            <FormattedMessage id="VOCABULARY.Competition analysis"/>
          </button>
          <button
            className={mediaNews ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("mediaNews")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>📺</span>
            <FormattedMessage id="VOCABULARY.News"/>
          </button>
          {isIndustrialView &&
          <button
            className={demography ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("demography")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>👪</span>
            <FormattedMessage id="VOCABULARY.Demography"/>
          </button>
          }
          <button
            className={publicTransport ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("publicTransport")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>🚉</span>
            <FormattedMessage id="VOCABULARY.Public transportation"/>
          </button>
          <button
            className={mobileAccess ? styles.selected : undefined}
            onClick={() => this.handleOptionSelect("mobileAccess")}
          >
            <span role="img" aria-label="REDD" className={styles.icon}>📱</span>
            <FormattedMessage id="VOCABULARY.Mobile access"/>
          </button>
        </div>
        <div className={styles.feedbackActionsWrapper}>
          {sent ?
          <p className={styles.feedbackMessage}><FormattedMessage id="VOCABULARY.Thank you for your feedback"/></p> :
            <ButtonCustom
              label={<FormattedMessage id="VOCABULARY.Send to us"/>}
              color={sendActive ? "danger" : "default"}
              solid={sendActive}
              inactive={!sendActive}
              onClick={() => this.handleSendFeedback()}
            />
          }
        </div>
      </div>
    );
  }
}