import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchPropertyParticularsAnalytics,
  fetchPropertyParticularsAnalyticsCharts,
  shareUpdatedPropertyParticulars
} from "app/crud/propertyParticulars/offices.crud";
import PropertyParticularsAnalysisPage from "./PropertyParticularsAnalysisPage";

const mapStateToProps = (state) => ({
  user: state.user.data,
  data: state.ppOnlineOffices.ppAnalytics,
  loading: state.ppOnlineOffices.ppAnalyticsLoading,
  chartsData: state.ppOnlineOffices.ppAnalyticsCharts,
  loadingCharts: state.ppOnlineOffices.loadingAnalyticsCharts,
  sharingUpdated: state.ppOnlineOffices.sharingUpdated
});
const mapDispatchToProps = {
  fetchPropertyParticularsAnalytics: (teamID) => fetchPropertyParticularsAnalytics(teamID),
  fetchPropertyParticularsAnalyticsCharts: (teamID, year) => fetchPropertyParticularsAnalyticsCharts(teamID, year),
  shareUpdatedPropertyParticulars: (teamID) => shareUpdatedPropertyParticulars(teamID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticularsAnalysisPage)
);
