import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader,} from "app/partials/content/Portlet";
import BadgeTitle from "app/partials/components/BadgeTitle";
import {FormattedMessage, injectIntl} from "react-intl";
import statsStyles from "../../../offices/BuildingsPage/components/index.module.scss";
import clsx from "clsx";
import TableCustom from "../../../../partials/components/TableCustom";
import {handleEmptyValue, toDateFormat, toNumberFormat,} from "../../../../utils/helpers";
import LoadingScreen from "app/partials/components/LoadingScreen";
import ROUTES from "app/constants/ROUTES";
import CopyLinkToClipboard from "app/partials/components/CopyLinkToClipboard";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import ReactVisibilitySensor from "react-visibility-sensor";
import NoData from "../../../../partials/components/NoData/NoData";

class OffersOnlineAnalysisPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      views: true,
      initLoaded: false
    };
  }
  // Handlers
  handleToggleViews = () => {
    this.setState({
      views: !this.state.views,
    });
  };
  handleTableBody = (view) => {
    const {
      intl,
      data,
      dataDetails,
      loadingDetails,
      onFetchDataDetails,
      DetailsComponent,
    } = this.props;

    const sourceData =
      data &&
      (data[view].length || Object.keys(data[view]).length) &&
      data[view];
    return sourceData
      ? sourceData.map((row) => {
          return {
            data: [
              handleEmptyValue(row.name),
              <CopyLinkToClipboard
                route={
                  window.location.pathname.includes(
                    ROUTES.INDUSTRIAL_OFFERS_ONLINE_ANALYSIS
                  )
                    ? ROUTES.INDUSTRIAL_OFFERS_ONLINE
                    : ROUTES.OFFERS_ONLINE
                }
                routeParams={[row.id]}
                label="OFFERS.TABLE.OFFER_PREVIEW"
                redirect
              />,
              toNumberFormat(row.views),
              toDateFormat(row.lastVisit),
              handleEmptyValue(row.lastVisitor),
              <DetailsComponent
                intl={intl}
                offerID={row.id}
                offerName={row.name}
                data={dataDetails}
                loading={loadingDetails}
                onFetchData={onFetchDataDetails}
              />,
            ],
          };
        })
      : [];
  };
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {onFetchData, loading} = this.props;
    const {initLoaded} = this.state;

    if (isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => onFetchData());
    }
  };

  render() {
    const { data, loading } = this.props;
    const { views } = this.state;
    const dataSource = views ? "views" : "contacts";
    const tHead = [
      {
        label: <FormattedMessage id="OFFERS.TABLE.OFFER_NAME" />,
        number: false,
      },
      {
        label: <FormattedMessage id="OFFERS.TABLE.PREVIEW" />,
        number: true,
      },
      {
        label: views ? (
          <FormattedMessage id="OFFERS.TABLE.VIEWS" />
        ) : (
          <FormattedMessage id="OFFERS.TABLE.CONTACT_REVEALS" />
        ),
        number: true,
      },
      {
        label: <FormattedMessage id="OFFERS.TABLE.LAST_VISIT" />,
        number: true,
      },
      {
        label: <FormattedMessage id="OFFERS.TABLE.LAST_VISITOR" />,
        number: false,
      },
      {
        label: "",
        number: true,
      },
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <Typography variant="h3">
                  <BadgeTitle
                    badge={<FormattedMessage id="OFFERS.PHRASE.OFFERS_STATISTICS_PREFIX" />}
                  >
                    <FormattedMessage id="OFFERS.PHRASE.OFFERS_STATISTICS" />
                  </BadgeTitle>
                </Typography>
              </ReactVisibilitySensor>
              }
              toolbar={
                <div style={{ display: "flex" }}>
                  <Grid container spacing={1} alignItems="center" style={{flexWrap: "nowrap"}}>
                    <Grid item>
                      <button
                        className={clsx(
                          statsStyles.customBtn,
                          views && statsStyles.active,
                          statsStyles.light
                        )}
                        onClick={() => {
                          this.handleToggleViews();
                          this.props.dispatch(callReddClickEvent(
                            "offers-online-analysis-views",
                            "offer"
                          ));
                        }}
                      >
                        <Typography>
                          <span>
                            <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.VIEWS" />
                          </span>
                        </Typography>
                      </button>
                    </Grid>
                    <Grid item>
                      <button
                        className={clsx(
                          statsStyles.customBtn,
                          !views && statsStyles.active,
                          statsStyles.light
                        )}
                        onClick={() => {
                          this.handleToggleViews();
                          this.props.dispatch(callReddClickEvent(
                            "offers-online-analysis-clicks",
                            "offer"
                          ));
                        }}
                      >
                        <Typography>
                          <span>
                            <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CLICKS" />
                          </span>
                        </Typography>
                      </button>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <PortletBody fit>
              {loading ? <LoadingScreen/> : data?.[dataSource]?.length > 0 ? (
                <TableCustom
                  tHead={tHead}
                  tBody={this.handleTableBody(dataSource)}
                  stickyHeader
                  maxHeight={570}
                  theadStyleOrigin
                />
              ) : <NoData/>}
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(
  connect()(OffersOnlineAnalysisPortfolio)
);