import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import AMENITIES from "app/constants/AMENITIES";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import {snakeToCamelCase} from "app/utils/helpers";
import cn from "classnames";
import EditModal from "app/partials/components/EditModal";

export default class PropertyAmenities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialWarehouseAmenities, amenities, amenitiesLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !amenitiesLoading && _.isEmpty(amenities) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialWarehouseAmenities(id));
    }
  }

  render() {
    const {parentRef, amenities, amenitiesLoading, basic} = this.props;
    const isOfficeEditable = basic?.isEditable;

    return(
      <div className={styles.amenitiesContainer}>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            parentRef={parentRef}
            title={<FormattedMessage id="VOCABULARY.Amenities"/>}
            titleActions={
            isOfficeEditable &&
            <EditModal
              target="warehouse"
              parentData={basic}
              isPropertyEditable={isOfficeEditable}
              initialTab="amenities"
            />
            }
            style={{marginBottom: "0"}}
          />
        </ReactVisibilitySensor>
        {
          amenitiesLoading ? <LoadingScreen/> :
            amenities ?
              <div className={styles.propertyAmenitiesWrapper}>
                {Object.keys(amenities)?.map((amenity, index) => {
                  const icon = AMENITIES?.filter(item => item?.id === amenity)?.[0]?.icon;
                  const isActive = amenities?.[amenity];

                  return(
                    <span
                      key={index}
                      className={cn(
                        styles.amenityWrapper,
                        isActive ? styles.active : undefined
                      )}
                    >
                      {icon && <span className={styles.amenityIcon}>{icon}</span>}
                      <FormattedMessage id={snakeToCamelCase(amenity)}/>
                    </span>
                  );
                })}
              </div> :
              <NoData/>
        }
      </div>
    );
  }
}