import React from "react";
import styles from "./index.module.scss";
import {toNumberFormat} from "../../../utils/helpers";
import IsApplicationView from "../../../utils/IsApplicationView";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

export default function BlurText({isBlurred, type = "string", customBlurValue, blurStrength = "6px",  ...props}) {
  const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
  const areaValue = isOfficeView ? "1234" : "12345"
  const blurValues = [
    {
      type: "string",
      value: "Lorem ipsum"
    },
    {
      type: "phone",
      value: "+48 000-000-000"
    },
    {
      type: "email",
      value: "example@example.com"
    },
    {
      type: "area",
      value: toNumberFormat(areaValue, 'area')
    },
    {
      type: "percentage",
      value: toNumberFormat("123", null, '%')
    }
  ];

  if(isBlurred) {
    const requestedBlur = blurValues.filter(blur => blur?.type === type)?.[0];
    const blurValue = customBlurValue ? customBlurValue : requestedBlur ? requestedBlur?.value : "";

    return <span className={styles.blurValue} style={blurStrength ? {"--blurStrength": blurStrength} : undefined}>{blurValue}</span>;
  }
  return props?.children;
}