import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {deleteOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "../../../ButtonCustom";

class EditOfficeDeleteProperty extends React.Component {
  // Handlers
  handleSave = () => {
    const {deleteOffice, officeId} = this.props;
    deleteOffice(officeId);
  };

  render() {
    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography variant="h3" style={{textAlign: "center"}}>
            <FormattedMessage id="BUILDING.PHRASE.REMOVE_BUILDING_MESSAGE"/>
            <br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
        </Grid>
        <Grid item>
          <ButtonCustom
            label={<FormattedMessage id="VOCABULARY.Delete building"/>}
            color="danger"
            solid
            onClick={() => this.handleSave()}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  deleteOffice: officeID => deleteOffice(officeID)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(EditOfficeDeleteProperty)
);