import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Grid, Typography} from "@material-ui/core";
import BuildingCardPreview from "../_DataParts/BuildingCardPreview";
import styles from "./index.module.scss";
import cx from "classnames";
import LandlordStats from "../../../pages/offices/BuildingsPage/components/LandlordStats";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";

const allowedYears = ["2021", "2022", "2023", "2024"];
class OfficeReddClicks extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      defaultYear: allowedYears[3],
      modalOpen: false
    };
  }

  handleModalToggleState = (open) => {
    this.setState({
      modalOpen: open
    })
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleFetchRCYears = (year) => {
    this.setState({
      defaultYear: year
    });
  };

  render() {
    const {
      officeData,
      officeId
    } = this.props;
    const { defaultYear, modalOpen } = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <ModalTrigger
          icon="insights"
          label={<FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_STATS"/>}
          color="danger"
          directIcon
        />
        }
        title={
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <BuildingCardPreview
                name={officeData.name}
                street={officeData.addressStreet}
                thumb={officeData.mainPhotoThumbnail}
              />
            </Grid>
            <Grid item>
              <span className={styles.separator}/>
            </Grid>
            <Grid item>
              <FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_STATS"/>
            </Grid>
          </Grid>
        }
        onToggle={this.handleModalToggleState}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        fullScreen
        toolbar={
          <div>
          <Grid container spacing={1} alignItems="center">
            {allowedYears && allowedYears.map((year, index) => {
            return(
                <Grid key={index} item>
                  <button className={cx(styles.customBtn, year === defaultYear && styles.active)} onClick={() => this.handleFetchRCYears(year)}>
                    <Typography><span>{year}</span></Typography>
                  </button>
                </Grid>
            );
            })}
          </Grid>
          </div>
        }
      >
        <LandlordStats active={modalOpen} activeYear={defaultYear} targetId={officeId} target="office"/>
      </ModalCustom>
    );
  }
}

export default injectIntl(
  connect()(OfficeReddClicks)
);