import React from "react";
import clsx from "clsx";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {metronic, toAbsoluteUrl} from "_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import styles from "./index.module.scss";
import cn from "classnames";

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("media/flags/flag-en.png")
  },
  {
    lang: "pl",
    name: "Polish",
    flag: toAbsoluteUrl("media/flags/flag-pl.png")
  }
];

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleToggleMenu = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { lang, iconType, setLanguage, cutomHandler, preventReload, currentLanguage: customLanguage } = this.props;
    const {open} = this.state;
    const langToUse = customLanguage || lang
    const currentLanguage = languages.find(x => x.lang === langToUse);
    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} onClick={this.handleToggleMenu} id="dropdown-toggle-my-cart">
          <span
            className={clsx("kt-header__topbar-icon", {
              "kt-header__topbar-icon--brand": iconType === "brand"
            })}
          >
            <img src={currentLanguage.flag} alt={currentLanguage.name} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className={`dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround ${this.props.alignToLeft && "dropdown-menu-align-to-left"}`}
            show={open}
        >
          <ul className="kt-nav">
            {languages.map(language => (
              <li
                key={language.lang}
                className={clsx("kt-nav__item", {
                  "kt-nav__item--active": language.lang === currentLanguage.lang
                })}
              >
                <span
                  onClick={() => {
                    cutomHandler?.(language.lang) || setLanguage(language.lang);
                    this.handleToggleMenu();
                    if(!preventReload) {
                      setTimeout(() => window.location.reload(), 400);
                    }
                  }}
                  className={clsx("kt-nav__link", {
                    "kt-nav__link--active":
                      language.lang === currentLanguage.lang
                  })}
                >
                  <span className="kt-nav__link-icon">
                    <img src={language.flag} alt={language.name} />
                  </span>
                  <span className={cn("kt-nav__link-text", styles.langLabel)}>{language.name}</span>
                </span>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(
  mapStateToProps,
  metronic.i18n.actions
)(LanguageSelector);
