import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableDrawer from "app/partials/components/TableDrawer/index";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import {toNumberFormat} from "app/utils/helpers";
import {fetchOfficeUnits} from "app/crud/estates/offices/units.crud";
import {Grid} from "@material-ui/core";
import styles from "../index.module.scss";
import OfficeReddClicks from "app/partials/components/Offices/OfficeReddClicks";
import BadgeValue from "app/partials/components/BadgeValue";
import TABLE_CELLS from "./TABLE_CELLS";
import PropertyStatusPreview from "../../_DataParts/PropertyStatusPreview";
import HighlightLabel from "../../HighlightLabel";
import IconColorMUI from "../../IconColorMUI";
import UpdatedAtPreview from "../../_DataParts/UpdatedAtPreview";
import ProgressBox from "../../ProgressBox";
import BlurText from "../../BlurText";
import IsPro from "../../../../utils/IsPro";
import EditModal from "../../EditModal";

class TableOfficeCells extends React.Component {
  render() {
    const {
      row,
      officeID,
      officeUnits,
      fetchOfficeUnits,
      officeUnitsLoading
    } = this.props;
    const isOfficeEditable = row && row.isEditable;

    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          <BuildingCardPreview
            name={row?.name}
            street={row?.addressStreet}
            city={row?.addressCity}
            thumb={row?.mainPhotoThumbnail}
            officeID={officeID}
          />
        </TableCell>
        <TableCell>
          <PropertyStatusPreview status={row?.status} />
        </TableCell>
        <TableCell>
          <BadgeValue
            value={toNumberFormat(row?.rentFromEur, "currency")}
            label={<FormattedMessage id="VOCABULARY.Asking rent" />}
          />
          <BadgeValue
            value={toNumberFormat(row?.serviceChargesPln, "currency", "PLN")}
            label={<FormattedMessage id="VOCABULARY.Service charge" />}
          />
        </TableCell>
        <TableCell align="right">
          <div className={styles.spaceDataWrapper}>
            <HighlightLabel color="neutral2">
              <BlurText
                type="area"
                isBlurred={!IsPro({}) && !isOfficeEditable}
              >
                {toNumberFormat(row?.totalAreaAvailable, "area")}
              </BlurText>
            </HighlightLabel>
            <TableDrawer
              titleAdvance
              headRows={[
                ...isOfficeEditable
                  ? [
                      {
                        id: "checkable",
                        numeric: false,
                        disablePadding: false,
                        label: "",
                        sort: false,
                      },
                    ]
                  : [],
                {
                  id: "area",
                  numeric: true,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.AREA",
                  sort: false,
                },
                {
                  id: "entrance",
                  numeric: false,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.ENTRANCE",
                  sort: true,
                },
                {
                  id: "floor",
                  numeric: true,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.FLOOR",
                  sort: false,
                },
                {
                  id: "type",
                  numeric: false,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.TYPE",
                  sort: false,
                },
                {
                  id: "status",
                  numeric: false,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.STATUS",
                  sort: false,
                },
                {
                  id: "available_from",
                  numeric: false,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.AVAILABLE_FROM",
                  sort: false,
                },
                {
                  id: "updated_at_by_user",
                  numeric: false,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.UPDATED_AT",
                  sort: false,
                },
                {
                  id: "rent_esitmation",
                  numeric: true,
                  disablePadding: false,
                  label: "UNITS.PHRASE.FORECAST_FEES_PREMIUM",
                  sort: false,
                },
                {
                  id: "actions",
                  numeric: true,
                  disablePadding: false,
                  label: "",
                  sort: false,
                },
              ]}
              bodyRows={officeUnits}
              bodyRowsReady={officeUnitsLoading}
              target={TABLE_CELLS.OFFICE_UNITS}
              isEditable={isOfficeEditable}
              parentId={row?.id}
              parentData={{
                commonAreaFactor: row?.commonAreaFactor,
                rentFromEur: row?.rentFromEur,
                serviceChargesPln: row?.serviceChargesPln,
                lackOfAvailableArea: row?.lackOfAvailableArea,
                minRentalYears: row?.minRentalYears,
                minRentalYearsToNegotiate: row?.minRentalYearsToNegotiate,
                retailRentPriceEur: row?.retailRentPriceEur,
                rentToNegotiate: row?.rentToNegotiate,
                officeName: row?.name,
                isEditable: row?.isEditable,
                status: row?.status,
                commercializationSuspended: row?.commercializationSuspended,
                commercializationSuspendedDate:
                  row?.commercializationSuspendedDate,
                numberOfUnitsAvailable: row?.numberOfUnitsAvailable
              }}
              parentPreviewData={row}
              onClick={fetchOfficeUnits}
            >
              <HighlightLabel
                color={row?.commercializationSuspended ? "danger" : "success"}
                tooltip={
                  row?.commercializationSuspended ? (
                    <FormattedMessage
                      id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_UNTIL"
                      values={{ date: row?.commercializationSuspendedDate }}
                    />
                  ) : (
                    <FormattedMessage id="BUILDING.PHRASE.VIEW_AVAILABLE_UNITS" />
                  )
                }
                disableTooptipInfo
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                hover
              >
                {row?.numberOfUnitsAvailable}
                <IconColorMUI
                  icon="rounded_corner"
                  color={
                    row?.commercializationSuspended ? "danger" : "success"
                  }
                  style={{ marginLeft: "5px" }}
                />
              </HighlightLabel>
            </TableDrawer>
          </div>
        </TableCell>
        <TableCell align="right">
          <ProgressBox
            percent={row?.occupancyRateV2}
            isBlurred={!IsPro({}) && !isOfficeEditable}
          />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={1} date={row?.updatedAtByUser} alternative />
        </TableCell>
        <TableCell align="right">
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            {isOfficeEditable &&
              <EditModal
                {...this.props}
                target="office"
                parentData={row}
                isPropertyEditable={isOfficeEditable}
                tooltipPlacement="left"
              />
            }
            {isOfficeEditable && <OfficeReddClicks officeId={row?.id} officeData={row} />}
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isStaff: state.user.data.isStaff,
  userWatchlist: state.user.data.watchList,
  officeUnits: state.units.officeUnits,
  officeUnitsLoading: state.units.officeUnitsLoading
});
const mapDispatchToProps = {
  fetchOfficeUnits: (officeId) => fetchOfficeUnits(officeId),
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TableOfficeCells)
);
