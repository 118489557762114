import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "../../../ButtonCustom";
import {removeIndustrialWarehouseUnit} from "app/crud/estates/industrial/industrial.crud";

class EditWarehouseUnitDeleteUnit extends React.Component {
  // Handlers
  handleSave = () => {
    const { unitId, removeIndustrialWarehouseUnit } = this.props;
    removeIndustrialWarehouseUnit(unitId);
  };

  render() {
    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography variant="h3" style={{textAlign: "center"}}>
            <FormattedMessage id="UNITS.PHRASE.REMOVE_UNIT_MESSAGE"/>
            <br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
        </Grid>
        <Grid item>
          <ButtonCustom
            label={<FormattedMessage id="VOCABULARY.Delete unit"/>}
            color="danger"
            solid
            onClick={() => this.handleSave()}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  removeIndustrialWarehouseUnit: (unitId) => removeIndustrialWarehouseUnit(unitId),
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(EditWarehouseUnitDeleteUnit)
);