import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {PDFViewer} from "@react-pdf/renderer";
import styles from "./index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import PdfDocument from "./PdfDocument";
import {fetchShortlistPdf} from "../../../../crud/offers.crud";
import PdfDocumentHorizontal from "./PdfDocumentHorizontal";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import cx from "classnames";
import {callReddClickEvent} from "app/crud/user.crud";

class GenerateShortlistPDFMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  render() {
    const {
      directBtn,
      directIcon,
      shortlistId,
      shortlistName,
      fetchShortlistPdf,
      shortlistPdfData,
      shortlistPdfLoading,
      user,
      inactive,
      callReddClickEvent
    } = this.props;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            color={inactive ? "default" : "success"}
            label={<FormattedMessage id="SHORTLIST.PHRASE.PDF"/>}
            directBtn={directBtn}
            directIcon={directIcon}
            inactive={inactive}
          />
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.CHOOSE_ORIENTATION"/>}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        maxWidth="lg"
        onOpen={() => callReddClickEvent(
          "offers-pdf-button",
          "offer",
          null,
          shortlistId
        )}
        inactive={inactive}
      >
        <div className={styles.generatePDFSelectWrapper}>
          <ModalCustom
            ref={this.child}
            btn={
              <div className={styles.generatePDFItem}>
                <button
                  className={cx(styles.btnGeneratePDF, styles.vertical)}
                  onClick={() => fetchShortlistPdf(shortlistId)}
                >
                  <IconColorMUI icon="picture_as_pdf" color="white" size="40px"/>
                </button>
                <p><FormattedMessage id="GENERAL.PHRASE.VERTICAL"/></p>
              </div>
            }
            title={<FormattedMessage id="SHORTLIST.PHRASE.OFFER_NAME" values={{offerName: shortlistName}}/>}
            btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
            maxWidth="lg"
          >
            {!shortlistPdfLoading && shortlistPdfData ? (
              <div>
                <LoadingScreen absolute/>
                <PDFViewer style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                  <PdfDocument data={shortlistPdfData} user={user} shortlistName={shortlistName}
                               intl={this.props.intl}/>
                </PDFViewer>
              </div>
            ) : <LoadingScreen/>}
          </ModalCustom>
          <ModalCustom
            ref={this.child}
            btn={
              <div className={styles.generatePDFItem}>
                <button
                  className={cx(styles.btnGeneratePDF, styles.horizontal)}
                  onClick={() => fetchShortlistPdf(shortlistId)}
                >
                  <IconColorMUI icon="picture_as_pdf" color="white" size="40px"/>
                </button>
                <p><FormattedMessage id="GENERAL.PHRASE.HORIZONTAL"/></p>
              </div>
            }
            title={<FormattedMessage id="SHORTLIST.PHRASE.OFFER_NAME" values={{offerName: shortlistName}}/>}
            btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
            maxWidth="lg"
          >
            {!shortlistPdfLoading && shortlistPdfData ? (
              <div>
                <LoadingScreen absolute/>
                <PDFViewer style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                  <PdfDocumentHorizontal data={shortlistPdfData} shortlistName={shortlistName} user={user}
                                         intl={this.props.intl}/>
                </PDFViewer>
              </div>
            ) : <LoadingScreen/>}
          </ModalCustom>
        </div>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  shortlistPdfData: store.shortlists.shortlistPdf,
  shortlistPdfLoading: store.shortlists.shortlistPdfLoading,
  user: store.user.data
});
const mapDispatchToProps = {
  fetchShortlistPdf: (shortlistID) => fetchShortlistPdf(shortlistID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId) => callReddClickEvent(clickType, contentType, appLabel, objectId)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GenerateShortlistPDFMultiSelect)
);