import GoogleMap from "./GoogleMap/index.js";
import React from "react";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import styles from "./MapView.module.scss";
import {withState} from "recompose";
import TableDrawer from "app/partials/components/TableDrawer";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {toAbsoluteUrl} from "_metronic";
import {Marker} from "react-google-maps";
import {MarkerClusterer} from "react-google-maps/lib/components/addons/MarkerClusterer";
import LoadingScreen from "app/partials/components/LoadingScreen";
import clsx from "clsx";
import GOOGLE_MAPS_OPTIONS from "app/constants/GOOGLE_MAPS_OPTIONS";

// Initial map LatLng and zoom settings
const WARSAW_LOCATION = { lat: 52.237049, lng: 21.017532 };
const DEFAULT_ZOOM = 11;

// Component that handle Map View for Properties data (offices, units)

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    // Initial Map Settings
    this.state = {
      zoom: DEFAULT_ZOOM,
      center: WARSAW_LOCATION,
      drawerOpen: false,
      clusteringGrid: 60,
      sourceMarker: false,
      openInfoWindowMarkerId: '',
      isOfficeEditable: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.selectedOffice === null && nextProps.selectedOffice !== null) {
      const lat = Number(nextProps.selectedOffice.lat);
      const lng = Number(nextProps.selectedOffice.lng);
      if(lat !== 0 && lng !== 0) {
        const SELECTED_LOCATION = { lat: lat, lng: lng };
          this.setState({
            center: SELECTED_LOCATION,
            zoom: 17,
            clusteringGrid: 0
          }, () => {
            this.handleSelectedData(nextProps.selectedOffice.officeId, lat, lng);
            this.props.onSelectOffice(nextProps.selectedOffice.unitId);
          });
      }
    }
  }

  // Handle Map zoom and cluster grid
  handleZoomChange = () => {
    const zoomLevel = this.mapRef.current.getZoom();
    this.setState({
      zoom: zoomLevel,
      clusteringGrid: zoomLevel < 17 ? 50 : 0
    });
  };
  // Function to handle map fit bounds
  fitToBounds = (markers) => {
    if(markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((marker) => {
        bounds.extend(new window.google.maps.LatLng(
          marker.lat,
          marker.lng
        ));
        return true;
      });
      this.mapRef.current.fitBounds(bounds);
    }
  };
  // Handle TableDrawer toggle and content
  handleSelectedData = (officeId, lat, lng) => {
    this.props.getOffice && this.props.getOffice(officeId);
    this.setState({
      center: { lat: lat, lng: lng },
      zoom: 17,
      clusteringGrid: 0,
      drawerOpen: true,
    });
  };
  // Handle close TableDrawer
  handleDrawerClose = () => {
    const {selectedOffice, onSelectOffice, onCloseSelectedOffice} = this.props;
    this.setState({
      drawerOpen: false,
      sourceMarker: false,
      isOfficeEditable: false
    }, () => {
      if(selectedOffice !== null) {
        onSelectOffice(null);
        onCloseSelectedOffice(null);
      }
    });
  };
  // Map interactions
  handleMarkerClick = (officeId, lat, lng, index) => {
    const {getOffice, getOfficeUnits} = this.props;

    if(officeId) {
      getOffice && getOffice(officeId);
      getOfficeUnits && getOfficeUnits(officeId);
      this.setState({
        sourceMarker: true,
        center: { lat: lat, lng: lng },
        zoom: 17,
        clusteringGrid: 0,
        drawerOpen: true
      });
    } else {
      this.setState({
        openInfoWindowMarkerId: index
      });
    }
  };
  handleCloseInfoWindow = () => {
    this.setState({
      openInfoWindowMarkerId: ''
    });
  };
  // Render Marker - icon type based on permissions and buildings status
  renderMarkerIcon = (markerColor, isActive) => {
    const pinGreen = toAbsoluteUrl("media/icons/markers/pin-green.png");
    const pinOrange = toAbsoluteUrl("media/icons/markers/pin-orange.png");
    const pinRed = toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const pinBlue = toAbsoluteUrl("media/icons/markers/pin-blue.png");
    const pinBlack = toAbsoluteUrl("media/icons/markers/pin-black.png");
    let pin = pinBlack;
    if(isActive) {
      pin = markerColor !== null ? pinBlack : pinRed;
    } else if(markerColor === "green") {
      pin = pinGreen;
    } else if(markerColor === "orange") {
      pin = pinOrange;
    } else if(markerColor === "red") {
      pin = pinRed;
    } else if(markerColor === "blue") {
      pin = pinBlue;
    }
    return pin;
  };

  render() {
    const {
      markers,
      selectedOffice,
      options,
      office,
      clustersLoading,
      officeLoading,
      limitBarEnable,
      officeUnits,
      officeUnitsLoading
    } = this.props;
    const officeData = selectedOffice || office;
    const {drawerOpen, clusteringGrid} = this.state;
    const isOfficeEditable = officeData?.isEditable;
    const selectedLat = selectedOffice !== null ? Number(selectedOffice.lat) : 0;
    const officeDataReady = !officeLoading && officeData;

    return (
      <div className={clsx(styles.mapWidth, limitBarEnable && styles.limitBarMapHeight)}>
        {clustersLoading ? <LoadingScreen/> : (
          <GoogleMap
          mapRef={this.mapRef}
          options={options}
          center={this.state.center}
          zoom={this.state.zoom}
          onZoomChanged={this.handleZoomChange}
          onFitBounds={() => this.fitToBounds(markers)}
          onClick={() => this.handleCloseInfoWindow()}
        >
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={clusteringGrid}
            styles={[
              {
                url: toAbsoluteUrl('media/icons/clusters/m1.png'),
                height: 52,
                width: 53,
                textColor:"#FFF",
              },
              {
                url: toAbsoluteUrl('media/icons/clusters/m2.png'),
                height: 55,
                width: 56,
                textColor:"#FFF",
              },
              {
                url: toAbsoluteUrl('media/icons/clusters/m3.png'),
                height: 65,
                width: 66,
                textColor:"#FFF",
              }
            ]}
          >
            {markers.map((marker, index) => {
              const lat = Number(marker.lat);
              const lng = Number(marker.lng);

              return (
                <Marker
                  key={index}
                  position={{lat: lat, lng: lng}}
                  onClick={() => this.handleMarkerClick(marker.office, lat, lng, index)}
                  icon={this.renderMarkerIcon(marker.mapColor, selectedLat === lat || this.state.center.lat === lat)}
                  opacity={marker.office ? 1 : 0.5}
                />
            )})}
          </MarkerClusterer>
        </GoogleMap>
        )}
        {/* Table Units List open on marker click or units list item */}
        <TableDrawer
          titleAdvanced
          headRows={
            [
              ...isOfficeEditable ? [{ id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false }] : [],
              { id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false },
              { id: 'entrance', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.ENTRANCE', sort: true },
              { id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false },
              { id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false },
              { id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false },
              { id: 'available_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.AVAILABLE_FROM', sort: false },
              { id: 'update_level_cache', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.UPDATED_AT', sort: false },
              { id: 'rent_esitmation', numeric: true, disablePadding: false, label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM', sort: false },
              { id: 'actions', numeric: true, disablePadding: false, label: '', sort: false },
            ]
          }
          bodyRows={!officeUnitsLoading && officeUnits ? officeUnits.sort((a, b) => {
            if(a["floor"] < b["floor"]) { return -1; }
            if(a["floor"] > b["floor"]) { return 1; }
            return 0;
          }) : []}
          bodyRowsReady={officeUnitsLoading || officeLoading}
          target={TABLE_CELLS.OFFICE_UNITS}
          isEditable={isOfficeEditable}
          parentId={officeDataReady && officeData?.id}
          parentData={officeDataReady && {
            commonAreaFactor: officeData?.commonAreaFactor,
            rentFromEur: officeData?.rentFromEur,
            serviceChargesPln: officeData?.serviceChargesPln,
            lackOfAvailableArea: officeData?.lackOfAvailableArea,
            minRentalYears: officeData?.minRentalYears,
            minRentalYearsToNegotiate: officeData?.minRentalYearsToNegotiate,
            retailRentPriceEur: officeData?.retailRentPriceEur,
            rentToNegotiate: officeData?.rentToNegotiate,
            officeName: officeData?.name,
            isEditable: isOfficeEditable
          }}
          parentPreviewData={officeDataReady && officeData}
          externalDrawerState={drawerOpen}
          handleExternalClose={() => this.handleDrawerClose()}
        />
      </div>
    );
  }
}

export const withCluster = compose(
  defaultProps({
    style: {},
    options: {
      minZoom: 3,
      maxZoom: 20,
      styles: GOOGLE_MAPS_OPTIONS.STYLES,
    },
  }),
  withState('mapProps')
);

export default withCluster(MapView);
