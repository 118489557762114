import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {fetchOfficesDashboardSupplyBoost} from "app/crud/estates/offices/offices.crud";
import { fetchIndustrialDashboardSupplyBoost } from "../../../../crud/estates/industrial/industrial.crud";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import {ROUTES} from "app/constants";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import styles from "../../components/LeadsPages/index.module.scss";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";
import {toNumberFormat} from "../../../../utils/helpers";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import IsApplicationView from "../../../../utils/IsApplicationView";

class DashboardSupplyBoost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {officesSupplyBoost, industrialSupplyBoost} = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const data = isOfficeView ? officesSupplyBoost : isIndustrialView ? industrialSupplyBoost : null;
    const dataPrev = isOfficeView ? prevProps?.officesSupplyBoost : isIndustrialView ? prevProps?.industrialSupplyBoost : null;
    // Re-render TableBody
    if(
      // if offices was empty
      (_.isEmpty(dataPrev) && data?.length > 0) ||
      // if offices was updated
      (!_.isEqual(dataPrev, data))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }
  // Handlers
  handlePrepTableBody = () => {
    const {officesSupplyBoost, industrialSupplyBoost} = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const data = isOfficeView ? officesSupplyBoost : isIndustrialView ? industrialSupplyBoost : null;

    if(data?.length > 0) {
      return data?.map(property => {
        return {
          data: [
            <span className={styles.propertyNameWrapper}>
              <PropertyCardPreview
                propertyID={property?.assetId}
                title={property?.assetName}
                semiTitle={property?.assetAddress}
                thumb={property?.assetThumbnail}
                route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                routeNewTab
                noOverflow
              />
            </span>,
            <HighlightLabel color="warning">{toNumberFormat(property?.area, 'area')}</HighlightLabel>,
            <UpdatedAtPreview
              date={property?.date}
              alternative
            />
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficesDashboardSupplyBoost,
      fetchIndustrialDashboardSupplyBoost,
      officesSupplyBoostLoading,
      industrialSupplyBoostLoading
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});

    if(isVisible && isOfficeView && !officesSupplyBoostLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficesDashboardSupplyBoost());
    }
    if(isVisible && isIndustrialView && !industrialSupplyBoostLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialDashboardSupplyBoost());
    }
  };

  render() {
    const {officesSupplyBoostLoading, industrialSupplyBoostLoading} = this.props;
    const {tBody} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const transactionsLoading = isOfficeView ? officesSupplyBoostLoading : industrialSupplyBoostLoading;
    const tHead = [
      {
        "label": isOfficeView ? <FormattedMessage id="GENERAL.PHRASE.BUILDING"/> : <FormattedMessage id="GENERAL.PHRASE.INDUSTRIAL_PARK"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Area"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Date"/>,
        "number": true
      }
    ];

    return(
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3">
            <FormattedMessage id="MENU.SUPPLY_BOOST"/>
          </Typography>
        </ReactVisibilitySensor>
        }
        fluidHeight
        disableAccordion
        footer={
        !_.isEmpty(tBody) &&
          <>
          <ButtonCustom
            label={<FormattedMessage id="VOCABULARY.Check all"/>}
            color="brandPlainText"
            normalCase
            redirect={ROUTES.SUPPLY_BOOST}
            trackingData={{
              clickType: isOfficeView ?
                "dashboard-supply-boost-see-all-click" :
                "dashboard-industrial-supply-boost-see-all-click",
              contentType: "user"
            }}
            btnStyle={{fontSize: "1.6rem", fontWeight: "400"}}
          />
          </>
        }
      >
        {transactionsLoading ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              propertyCellIndex={0}
              responsive
            />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  officesSupplyBoost: state.offices.officesDashboardSupplyBoost,
  officesSupplyBoostLoading: state.offices.officesDashboardSupplyBoostLoading,
  industrialSupplyBoost: state.industrial.industrialDashboardSupplyBoost,
  industrialSupplyBoostLoading: state.industrial.industrialDashboardSupplyBoostLoading
});
const mapDispatchToProps = {
  fetchOfficesDashboardSupplyBoost: () => fetchOfficesDashboardSupplyBoost(),
  fetchIndustrialDashboardSupplyBoost: () => fetchIndustrialDashboardSupplyBoost()
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardSupplyBoost)