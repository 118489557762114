import React from "react";
import styles from "./index.module.scss";
import PrintModeDetect from "../../../../partials/components/PrintModeDetect";

export default function PropertySectionTitle({title, toolbar, titleActions, style, parentRef}) {
  return(
    <div ref={parentRef} className={styles.propertySectionTitleWrapper} style={style}>
      <div className={styles.titleWrapper}>
        <h2>{title}</h2>
        <PrintModeDetect hide>
        {titleActions && <div className={styles.titleActionsWrapper}>{titleActions}</div>}
        </PrintModeDetect>
      </div>
      <PrintModeDetect hide>
      {toolbar && <div className={styles.toolbarWrapper}>{toolbar}</div>}
      </PrintModeDetect>
    </div>
  );
}