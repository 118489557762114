import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import INDUSTRIAL_FILES_FORMATS from "../../../../constants/INDUSTRIAL_FILES_FORMATS";
import styles from "./index.module.scss";

class IndustrialPreviewFile extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  render() {
    const {fileUrl, fileType} = this.props;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="visibility"
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.PREVIEW_FILE"/>}
            directIcon
            color="warning"
          />
        }
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.PREVIEW_FILE"/>}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        fullScreen
      >
        {fileType === INDUSTRIAL_FILES_FORMATS.TYPE_PNG.id || fileType === INDUSTRIAL_FILES_FORMATS.TYPE_JPG.id ? (
            <div className={styles.filePreviewImgWrapper}>
                <img src={fileUrl} alt="REDD File" className={styles.filePreviewImg}/>
            </div>
        ) : fileType === INDUSTRIAL_FILES_FORMATS.TYPE_PDF.id && (
            <iframe src={fileUrl} title="REDD File" style={{width: "100%", height: "100%", border: 0, boxShadow: "0 0 5px rgba(255,255,255,0.4)"}}/>
        )}
      </ModalCustom>
    );
  }
}

export default injectIntl(
  connect(
    null,
    null
  )(IndustrialPreviewFile)
);