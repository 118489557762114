import React from "react";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import FinneCrossSelling from "../../../../partials/components/FinneCrossSelling";
import {fetchLead} from "../../../../crud/leadsManagement.crud";
import connect from "react-redux/es/connect/connect";

class LeadsManagementProperties extends React.Component {
  render() {
    const {leadID, fetchLead, lead, loadingLead, removingProperty} = this.props;

    return(
      <FinneCrossSelling
        btnTrigger={
        <ModalTrigger
          label={<FormattedMessage id="LM.PHRASE.MANAGE_PROPERTIES"/>}
          color="neutral"
          icon="real_estate_agent"
          directIcon
        />
        }
        modalTitle={<FormattedMessage id="LM.PHRASE.MANAGE_PROPERTIES" />}
        mode="properties"
        data={lead}
        loading={loadingLead}
        removing={removingProperty}
        onOpen={() => fetchLead(leadID)}
      />
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  lead: leadsManagement?.lead,
  loadingLead: leadsManagement?.loadingLead,
  removingProperty: leadsManagement?.removing
});
const mapDispatchToProps = {
  fetchLead: (leadID) => fetchLead(leadID)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementProperties);