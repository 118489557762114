import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default class TooltipCustom extends Component {
  render() {
    const {children, title, placement, disabled } = this.props;
    return (
      <OverlayTrigger
          placement={placement || "top"}
          overlay={disabled ? <span></span> : <Tooltip id={`id-${Math.random(100)}`}>{title}</Tooltip>}
        >
        <span style={{display: 'inline-block'}}>{children}</span>
      </OverlayTrigger>
    );
  }
}