// FETCH
export const fetchIndustrialParkBasic = (parkID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_PARK_BASIC_REQUEST',
  payload: {
    parkID,
    backgroundLoading
  },
});
export const fetchIndustrialParkBuildings = (parkID, ordering, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST',
  payload: {
    parkID,
    ordering,
    backgroundLoading
  },
});
export const fetchIndustrialParkLeaseTerms = (parkID, backgroundLoading) => ({
  type: 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST',
  payload: {
    parkID,
    backgroundLoading
  },
});
export const fetchIndustrialParkMarketplaceStats = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkChanges = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkFiles = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_FILES_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkSimilarProperties = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkOwner = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_OWNER_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkLeasingContacts = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_REQUEST',
  payload: {
    parkID
  },
});
export const fetchIndustrialParkEditFormsData = (parkID) => ({
  type: 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST',
  payload: {
    parkID
  },
});

// AUTO ACTIONS
export const resetIndustrialParkState = () => ({
  type: 'RESET_INDUSTRIAL_PARK_STATE_REQUEST'
});
export const resetIndustrialParkLoadingStates = () => ({
  type: 'RESET_INDUSTRIAL_PARK_LOADING_STATES_REQUEST'
});