import React from "react";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import EditModal from "../../../../../partials/components/EditModal";
import {handleEmptyValue, toNumberFormat} from "../../../../../utils/helpers";

export default class PropertyTechnicalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialWarehouseTechnicalDetails, technicalDetails, technicalDetailsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !technicalDetailsLoading && _.isEmpty(technicalDetails) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialWarehouseTechnicalDetails(id));
    }
  }
  handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  render() {
    const {parentRef, technicalDetails, technicalDetailsLoading, basic, intl} = this.props;
    const storageHeightRange = [
      ...technicalDetails?.storageHeightMin ? [toNumberFormat(technicalDetails?.storageHeightMin)] : [],
      ...technicalDetails?.storageHeightMax ? [toNumberFormat(technicalDetails?.storageHeightMax)] : []
    ];
    const columnGridRange = [
      ...technicalDetails?.columnGridMin ? [technicalDetails?.columnGridMin] : [],
      ...technicalDetails?.columnGridMax ? [technicalDetails?.columnGridMax] : []
    ];
    const flooringCarrierRange = [
      ...technicalDetails?.flooringCarrierMin ? [toNumberFormat(technicalDetails?.flooringCarrierMin)] : [],
      ...technicalDetails?.flooringCarrierMax ? [toNumberFormat(technicalDetails?.flooringCarrierMax)] : []
    ];
    const floorCapacityLoading = intl.formatMessage({id: 'kg/sqm'});

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Technical spec"/>}
          titleActions={
          basic?.isEditable &&
          <EditModal
            target="warehouse"
            initialTab="technicalDetails"
            parentData={basic}
            isPropertyEditable={basic?.isEditable}
            {...this.props}
          />
          }
        />
        <table className={styles.simpleTable}>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Clear height"/></td>
              <td>
                {this.handleValueLoading(technicalDetailsLoading, toNumberFormat(storageHeightRange.join(" - "), 'range', '', ' m'))}
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Column grid"/></td>
              <td>{this.handleValueLoading(technicalDetailsLoading, columnGridRange.join(" - "))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Floor load capacity"/></td>
              <td>{this.handleValueLoading(technicalDetailsLoading, toNumberFormat(flooringCarrierRange.join(" - "), 'range', '', floorCapacityLoading))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Truck parking spaces"/></td>
              <td>{this.handleValueLoading(technicalDetailsLoading, handleEmptyValue(technicalDetails?.parkingSlotsTruck))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Standard parking spaces"/></td>
              <td>{this.handleValueLoading(technicalDetailsLoading, handleEmptyValue(technicalDetails?.parkingSlotsCar))}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Loading docks"/></td>
              <td>{this.handleValueLoading(technicalDetailsLoading, handleEmptyValue(technicalDetails?.docks))}</td>
            </tr>
          </tbody>
        </table>
        </>
      </ReactVisibilitySensor>
    );
  }
}