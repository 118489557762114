import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import BadgeTitle from "app/partials/components/BadgeTitle";
import {FormattedMessage} from "react-intl";
import {getMonthName} from "app/utils/helpers";
import statsStyles from "../../../offices/BuildingsPage/components/index.module.scss";
import clsx from "clsx";
import LineChart from "../../../../partials/components/Charts/LineChart";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import NoData from "../../../../partials/components/NoData/NoData";
import ReactVisibilitySensor from "react-visibility-sensor";

const allowedYears = ["2022", "2023", "2024"];
export default class OffersOnlineAnalysisCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultYear: allowedYears[2],
      initLoaded: false
    };
  }

  // Fetch REDD Clicks by selected years
  handleFetchRCYears = (year) => {
    const {onFetchData} = this.props;
    this.setState({
      defaultYear: year
    }, () => onFetchData(year));
  };
  // Return proper data format for Stats Line Charts
  setChartData = (loading, data) => {
    const months = Array.from({length: 12}, (_, i) => i + 1);
    const handleData = data && data.length ? data : [{"month": 1, "number": 0}];
    if (!loading && handleData) {
      return months.map((time) => {
        const apiValue = handleData.filter(item => item["month"] === time);
        const value = apiValue && apiValue.length ? apiValue[0].number : 0;
        const label = getMonthName(time, this.props.intl.locale);
        return {
          "label": label,
          "value": value
        };
      });
    }
    return null;
  };
  // Get total number of views/redd clicks
  getTotalViews = (data) => {
    let totalViews = 0;
    data.map(item => totalViews = totalViews + item.number);
    return totalViews;
  };
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {onFetchData, loading} = this.props;
    const {initLoaded, defaultYear} = this.state;

    if (isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => onFetchData(defaultYear));
    }
  };

  render() {
    const {data, loading} = this.props;
    const {defaultYear} = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <Typography variant="h3">
                  <BadgeTitle badge={<FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.ANALYSIS"/>}><FormattedMessage
                    id="PROPERTY_PARTICULARS.PHRASE.INTERACTIONS"/></BadgeTitle>
                </Typography>
              </ReactVisibilitySensor>
              }
              toolbar={
                <div style={{display: "flex"}}>
                  <Grid container spacing={1} alignItems="center">
                    {allowedYears && allowedYears.map((year, index) => {
                      return (
                        <Grid key={index} item>
                          <button
                            className={clsx(statsStyles.customBtn, year === defaultYear && statsStyles.active, statsStyles.light)}
                            onClick={() => this.handleFetchRCYears(year)}>
                            <Typography><span>{year}</span></Typography>
                          </button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              }
            />
            <PortletBody>
              {loading ? <LoadingScreen/> : data ?
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <LineChart
                      dataChart={this.setChartData(loading, data["views"])}
                      title={<FormattedMessage id="OFFERS.PHRASE.NO_OF_VIEWS"/>}
                      desc={<Typography variant="h2"><Typography><FormattedMessage
                        id="GENERAL.PHRASE.TOTAL_VIEWS"/>:</Typography> {this.getTotalViews(data["views"])}
                      </Typography>}
                      labelX={"GENERAL.PHRASE.MONTHS"}
                      labelY="GENERAL.PHRASE.VIEWS"
                      height={250}
                      disableSorting
                      roundValues
                      titleMarginDisable
                      chartColors={{background: "rgba(255,184,34, 0.2)", border: "rgb(255,184,34)"}}
                      gridLine={{color: "rgba(0,0,0,0.1)"}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LineChart
                      dataChart={this.setChartData(loading, data["contacts"])}
                      title={<FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.NO_OF_CONTACT_CLICKS"/>}
                      desc={<Typography variant="h2"><Typography><FormattedMessage
                        id="GENERAL.PHRASE.TOTAL_CLICKS"/>:</Typography> {this.getTotalViews(data["contacts"])}
                      </Typography>}
                      labelX={"GENERAL.PHRASE.MONTHS"}
                      labelY="GENERAL.PHRASE.CLICKS"
                      height={250}
                      disableSorting
                      roundValues
                      titleMarginDisable
                      chartColors={{background: "rgba(10,187,135, 0.2)", border: "rgb(10,187,135)"}}
                      gridLine={{color: "rgba(0,0,0,0.1)"}}
                    />
                  </Grid>
                </Grid>
                : <NoData/>
              }
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}