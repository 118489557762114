import {useEffect, useState} from "react";

export default function PrintModeDetect({children, hide, placeholder}) {
  const [isInPrintMode, setIsInPrintMode] = useState(false);

  useEffect(() => {
    const target = window.matchMedia('print');
    target.addEventListener("change", event => updatePrintModeState(event.matches));

    return () => {
      target.removeEventListener("change", event => updatePrintModeState(event.matches))
    }
  }, []);

  // Helpers
  const updatePrintModeState = (isPrintMode) => {
    setIsInPrintMode(isPrintMode);
  }

  if(isInPrintMode) {
    if(hide) {
      return null;
    }
    else if(placeholder) {
      return placeholder;
    }
    else {
      return children || null;
    }
  }
  return children || null;
}