import React from "react";
import TableMUI from "../../../../partials/components/TableMUI";
import {TableCell, TableRow, Typography} from "@material-ui/core";
import {handleTableCellAlign, theExcerpt, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import styles from "../../components/LeadsPages/index.module.scss";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import PropertySubscription from "../../components/LeadsPages/components/PropertySubscription";
import LeadDetailsModal from "./LeadDetailsModal";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import cn from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import ManageLeadStatus from "./ManageLeadStatus";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../utils/IsPro";
import PaywallBannerRowTable from "../../../../partials/components/PayWall/banners/PaywallBannerRowTable";
import PaywallBannerRow from "../../../../partials/components/PayWall/banners/PaywallBannerRow";
import {ReactComponent as IconTime} from "app/media/icons/icon-time.svg";
import TooltipCustom from "../../../../partials/components/TooltipCustom";
import {ReactComponent as IconCharts} from "app/media/icons/icon-mail.svg";
import IsAdmin from "../../../../utils/IsAdmin";

class LeadsView extends React.Component {
  // Handlers
  handleBlankLead = (isHidden, fieldValue, fieldType) => {
    const isEmail = fieldType === 'email';
    const isPhone = fieldType === 'phone';

    if(isHidden) {
      const blurValue = isEmail ? 'lorem.ipsum@example.com' :
        isPhone ? '+48 123 456 789' : <span style={{lineHeight: "1.8rem"}}>Lorem ipsum<br/>+48 000-000-000</span>;
      return <span
        className={cn(
          styles.blurValue,
          isEmail ? styles.contactEmail : undefined,
          isPhone ? styles.contactPhoneNumber : undefined
        )}
      >{blurValue}</span>;
    }
    return (isEmail || isPhone) ? fieldValue : theExcerpt(fieldValue, 35, "-", true);
  }
  handleTrackPropertyRedirect = (leadID, propertyID) => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    callReddClickEvent && callReddClickEvent(
      isOfficeView ? 'finne-leads-offices-lead-property-click' : 'finne-leads-industrial-lead-property-click',
      'lead',
      'marketplace',
      leadID,
      {propertyID}
    );
  }
  // Helpers
  notZero = (value) => {
    const numValue = value ? Number(value).toFixed(0) : null;
    return numValue && numValue !== "0";
  }

  render() {
    const {
      team,
      items,
      itemsPerPage,
      itemsCount,
      loading,
      fetchMarketplaceLeads,
      lead,
      leadLoading,
      fetchMarketplaceLead,
      currentApplicationView,
      callReddClickEvent,
      hasPaid
    } = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const tHead = [
        {id: 'building_name', numeric: false, disablePadding: false, label: isOfficeView ? 'FORM.FIELD.BUILDING' : 'FORM.FIELD.PARK', sort: true},
        {id: 'area', numeric: true, disablePadding: false, label: 'FORM.FIELD.AREA', sort: true},
        {id: 'crm_name', numeric: false, disablePadding: false, label: 'FORM.FIELD.COMPANY', sort: false},
        {id: 'created_at', numeric: false, disablePadding: false, label: 'FORM.FIELD.DATE', sort: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'FORM.FIELD.STATUS', sort: true},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];

    if(team) {
      if(items && items?.length > 0) {
        return(
          <TableMUI
            tHead={tHead}
            itemsPerPage={itemsPerPage}
            totalItems={itemsCount}
            loading={loading}
            initialOrderBy="created_at"
            initialRowsPerPage={20}
            handleFetchData={fetchMarketplaceLeads}
            containerStyle={{maxHeight: "100%"}}
          >
            {items && Array.isArray(items) && items.map((item, index) => {
              const areaRange = [
                ...this.notZero(item?.crmAreaFrom) ? [Number(item?.crmAreaFrom).toFixed(0)] : [],
                ...this.notZero(item?.crmAreaTo) ? [Number(item?.crmAreaTo).toFixed(0)] : []
              ].join(" - ");
              return (
                <React.Fragment
                  key={index}
                >
                  <TableRow
                    hover
                    tabIndex={-1}
                  >
                    <TableCell align={handleTableCellAlign(tHead, 0)}>
                      <span className={styles.propertyNameWrapper}>
                        <PropertyCardPreview
                          propertyID={item?.buildingId}
                          marketID={item?.marketId}
                          route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                          routeNewTab
                        >
                          <span
                            className={styles.propertyName}
                            onClick={() => this.handleTrackPropertyRedirect(item?.id, item?.buildingId)}
                          >{item?.buildingName}</span>
                        </PropertyCardPreview>
                        <PropertySubscription subscription={item?.buildingSubscription}/>
                      </span>
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 1)}>
                      <HighlightLabel color="neutral2">
                        {toNumberFormat(areaRange, 'range', false, 'area')}
                      </HighlightLabel>
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 2)}>
                      {this.handleBlankLead(item?.isHidden, item?.crmName)}
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 4)}>
                      {toDateFormat(item?.createdAt)}
                    </TableCell>
                    <TableCell>
                      <ManageLeadStatus
                        leadID={item?.id}
                        status={item?.status}
                      />
                    </TableCell>
                    <TableCell align={handleTableCellAlign(tHead, 5)}>
                      <div className={styles.optionsWrapper}>
                        <IsAdmin loggedInAs>
                          <TooltipCustom
                            title={
                            <div className={styles.statsTooltip}>
                              <p><FormattedMessage id="VOCABULARY.Email sending statistics"/></p>
                              <table>
                                <tbody>
                                  <tr>
                                    <td><strong><FormattedMessage id="VOCABULARY.Sent count"/>:</strong></td>
                                    <td>{item?.mailingData?.sentCount}</td>
                                  </tr>
                                  <tr>
                                    <td><strong><FormattedMessage id="VOCABULARY.Open count"/>:</strong></td>
                                    <td>{item?.mailingData?.openCount}</td>
                                  </tr>
                                  <tr>
                                    <td><strong><FormattedMessage id="VOCABULARY.Click count"/>:</strong></td>
                                    <td>{item?.mailingData?.clickCount}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <p><FormattedMessage id="VOCABULARY.Sent to"/></p>
                              <span
                                dangerouslySetInnerHTML={{__html: item?.mailingData?.emails.join("\n")}}
                                className={styles.statsMails}
                              />
                            </div>
                            }
                            placement="left"
                            disabled={item?.mailingData?.status === null}
                          >
                            <span className={cn(
                              styles.statsIcon,
                              item?.mailingData?.status === 0 ? styles.danger :
                                item?.mailingData?.status === 1 ? styles.warning :
                                  item?.mailingData?.status === 2 ? styles.success : undefined
                            )}>
                              <IconCharts/>
                            </span>
                          </TooltipCustom>
                        </IsAdmin>
                        {item?.isHidden ?
                        <PaywallModal
                          triggerLabel={<FormattedMessage id="VOCABULARY.Unlock"/>}
                          btnStyle={{width: "9.5rem"}}
                          bannerVariant="default-unlock_lead"
                          triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.Unlock"})}
                          withIcon
                        /> :
                        <LeadDetailsModal
                          leadID={item?.id}
                          data={lead}
                          loading={leadLoading}
                          property={{
                            id: item?.buildingId,
                            name: item?.buildingName,
                            subscription: item?.buildingSubscription,
                            marketId: item?.marketId
                          }}
                          currentApplicationView={currentApplicationView}
                          callReddClickEvent={callReddClickEvent}
                          onFetch={() => fetchMarketplaceLead(item?.id)}
                        />
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                  {
                    IsPro({reverseCheck: true}) &&
                    (index === 3 || ((index === (items?.length-1)-4) && items?.length > 12)) &&
                    <TableRow>
                      <TableCell colSpan={100}>
                        <PaywallBannerRowTable
                          bannerVariant="row_table-leads"
                        />
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              );
            })}
            {!hasPaid && items?.length <= 3 &&
            <TableRow>
              <TableCell colSpan={100}>
                <PaywallBannerRow
                  bannerVariant="row-leads"
                />
              </TableCell>
            </TableRow>
            }
            {hasPaid && items?.length <= 0 &&
            <TableRow>
              <TableCell colSpan={100}>
                <div className={styles.paidNoLeadsWrapper}>
                  <IconTime/>
                  <Typography variant="h3">
                    <FormattedMessage id="We work on acquiring leads for you."/>
                    <br/>
                    <FormattedMessage id="We will notify you as soon as the first enquiries are made."/>
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
            }
          </TableMUI>
        );
      }
      else if((!items || items?.length <= 0) && loading) {
        return <LoadingScreen/>;
      }
      return (
        <PaywallBannerRow
          bannerVariant="row-no_leads"
        />
      );
    }
    return <span/>;
  }
}

export default injectIntl(LeadsView);