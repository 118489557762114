import React from "react";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import TableMUI from "../../../../../partials/components/TableMUI";
import {Grid, TableCell, TableRow} from "@material-ui/core";
import {handleEmptyValue, handleTableCellAlign, toNumberFormat} from "../../../../../utils/helpers";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import NoData from "../../../../../partials/components/NoData/NoData";
import IndustrialParkWarehouseAdd
  from "../../../../../partials/components/TableProperties/TableActions/TableActionsIndustrial/components/IndustrialParkWarehouseAdd";
import {ROUTES} from "../../../../../constants";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";
import PropertyStatusPreview from "../../../../../partials/components/_DataParts/PropertyStatusPreview";
import BadgeValue from "../../../../../partials/components/BadgeValue";
import PropertyAvailabilityPreview from "../../../../../partials/components/_DataParts/PropertyAvailabilityPreview";
import ButtonCustom from "../../../../../partials/components/ButtonCustom";
import {ModalTrigger} from "../../../../../partials/components/ModalCustom/ModalTrigger";
import {Link} from "react-router-dom";
import ProgressBox from "../../../../../partials/components/ProgressBox";
import IsPro from "../../../../../utils/IsPro";
import BlurText from "../../../../../partials/components/BlurText";

export default class PropertyBuildings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      ordering: ''
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkBuildings, buildings, buildingsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if(isVisible && !initLoaded && !buildingsLoading && _.isEmpty(buildings) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkBuildings(id));
      }
    }, 200);
  }

  handleSortTable = (orderBy, order) => {
    const {fetchIndustrialParkBuildings, buildingsLoading, match} = this.props;
    const {id} = match?.params;

    if(orderBy && !buildingsLoading) {
      const ordering = [
        ...order ? [`${order}`] : [],
        ...orderBy ? [`${orderBy}`] : []
      ].join("");

      this.setState({
        ordering
      }, () => fetchIndustrialParkBuildings(id, ordering));
    }
  }

  render() {
    const {parentRef, basic, buildings, buildingsLoading} = this.props;
    const {ordering} = this.state;
    const tHead = [
        {id: 'building_name', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: false},
        {id: 'available_area', numeric: false, disablePadding: false, label: 'VOCABULARY.Available area', sort: false},
        {id: 'occupancy_rate', numeric: true, disablePadding: false, label: 'VOCABULARY.Occupancy rate', sort: false},
        {id: 'status', numeric: false, disablePadding: false, label: 'VOCABULARY.Status', sort: false},
        {id: 'availability', numeric: false, disablePadding: false, label: 'VOCABULARY.Availability', sort: false},
        {id: 'available_units', numeric: true, disablePadding: false, label: 'VOCABULARY.Available units', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];
    const tBody = buildings && buildings?.length > 0 ? buildings : null;
    const isPropertyEditable = basic?.isEditable;

    return(
      <>
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
        intervalCheck={true}
        intervalDelay={1000}
      >
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Buildings"/>}
          toolbar={
          <>
            {basic?.isEditable &&
            <IndustrialParkWarehouseAdd
              parkID={basic?.id}
              parentData={basic}
              ordering={ordering}
              btnTrigger={
              <ButtonCustom
                label={<FormattedMessage id="VOCABULARY.Add building"/>}
                color="danger"
                solid
                slim
              />
              }
            />
            }
          </>
          }
          style={{
            marginBottom: "1.6rem"
          }}
        />
      </ReactVisibilitySensor>
      <TableMUI
        tHead={tHead}
        loading={buildingsLoading}
        initialOrderBy="available_units"
        containerStyle={{minHeight: "20rem"}}
        totalItems={tBody?.length}
        onSort={this.handleSortTable}
        noData={!tBody || tBody?.length <= 0}
        noDataMessage={
          isPropertyEditable ?
            <NoData
              limitedHeight
              absolute
              top="5rem"
              size="body1"
              message={<FormattedMessage id="VOCABULARY.No buildings"/>}
              action={
                basic?.isEditable &&
                <IndustrialParkWarehouseAdd
                  parkID={basic?.id}
                  parentData={basic}
                  ordering={ordering}
                  btnTrigger={
                  <ButtonCustom
                    label={<FormattedMessage id="VOCABULARY.Add building"/>}
                    color="danger"
                    solid
                  />
                  }
                />
              }
            /> :
            <NoData
              size="body1"
              message={<FormattedMessage id="VOCABULARY.No buildings"/>}
              absolute
              top="0"
            />
        }
      >
        {tBody?.length > 0 && tBody?.map((building, index) => {
          return(
            <TableRow key={index}>
              <TableCell align={handleTableCellAlign(tHead, 0)}>
                <PropertyCardPreview
                  propertyID={building?.id}
                  title={building?.name}
                  thumb="disabled"
                  route={ROUTES.INDUSTRIAL_BUILDING}
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 1)}>
                <BadgeValue
                  value={
                  <BlurText
                    type="area"
                    isBlurred={
                      IsPro({
                        reverseCheck: true,
                        overwriteRule: basic?.isEditable
                      })
                    }
                  >
                    <strong>{toNumberFormat(building?.totalAreaWarehouseAvailable, 'area')}</strong>
                  </BlurText>
                  }
                  label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE"/>}
                  labelColor="success"
                  minWidth="8.5rem"
                />
                <BadgeValue
                  value={
                  <BlurText
                    type="area"
                    isBlurred={
                      IsPro({
                        reverseCheck: true,
                        overwriteRule: basic?.isEditable
                      })
                    }
                  >
                    <strong>{toNumberFormat(building?.totalAreaOfficeAvailable, 'area')}</strong>
                  </BlurText>
                  }
                  label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE"/>}
                  labelColor="neutral"
                  minWidth="8.5rem"
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 2)}>
                <ProgressBox
                  percent={building?.occupancyRateV2} style={{width: "50%", marginLeft: "auto"}}
                  isBlurred={
                    IsPro({
                      reverseCheck: true,
                      overwriteRule: basic?.isEditable
                    })
                  }
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 3)}>
                <PropertyStatusPreview status={building?.status}/>
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 4)}>
                <PropertyAvailabilityPreview
                  date={building?.buildingAvailableFrom}
                  availableInMonths={building?.availableFromAdditionalTime}
                  isUnavailable={!building?.numberOfUnitsAvailable || building?.numberOfUnitsAvailable <= 0}
                  parentStatus={building?.status}
                />
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 5)}>
                {handleEmptyValue(building?.numberOfUnitsAvailable)}
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 6)}>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Link to={`${ROUTES.INDUSTRIAL_BUILDING}/${building?.id}/`}>
                      <ModalTrigger
                        color="brandEmpty"
                        label={<FormattedMessage id="VOCABULARY.Industrial view building" />}
                        directBtn
                        style={{fontSize: "1.4rem", width: "16rem"}}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
      </TableMUI>
      </>
    );
  }
}