import React from "react";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import FinneCrossSelling from "../../../../partials/components/FinneCrossSelling";
import {fetchLead, updateLeadInterviewForm} from "../../../../crud/leadsManagement.crud";
import connect from "react-redux/es/connect/connect";

class LeadsManagementInterview extends React.Component {
  render() {
    const {leadID, fetchLead, lead, loadingLead, updateLeadInterviewForm, updating} = this.props;

    return(
      <FinneCrossSelling
        btnTrigger={
        <ModalTrigger
          label={<FormattedMessage id="LM.PHRASE.FILL_INTERVIEW"/>}
          color="warning"
          icon="edit_document"
          directIcon
        />
        }
        modalTitle={<FormattedMessage id="LM.PHRASE.FILL_INTERVIEW" />}
        mode="edit"
        data={lead}
        loading={loadingLead}
        updating={updating}
        onOpen={() => fetchLead(leadID)}
        onInterviewUpdate={(data) => updateLeadInterviewForm(leadID, data)}
      />
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  lead: leadsManagement?.lead,
  loadingLead: leadsManagement?.loadingLead,
  updating: leadsManagement?.updating
});
const mapDispatchToProps = {
  fetchLead: (leadID) => fetchLead(leadID),
  updateLeadInterviewForm: (leadID, data) => updateLeadInterviewForm(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementInterview);