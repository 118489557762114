import React from 'react';
import {FormattedMessage} from 'react-intl';
import styles from './index.module.scss';
import {ReactComponent as IconClose} from "app/media/icons/icon-close.svg";
import cn from "classnames";

export default class NoData extends React.Component {
  render() {
    const {
      message,
      extraMessage,
      absolute,
      limitedHeight,
      action,
      style,
      styleMessage,
      styleExtraMessage,
      withIcon
    } = this.props;
    return(
      <div
        className={cn(
          styles.noDataContainer,
          limitedHeight ? styles.limitedHeight : undefined,
          absolute ? styles.posAbsolute : undefined
        )}
        style={style}
      >
        {withIcon &&
        <span className={styles.iconClose}><IconClose/></span>
        }
        <p className={styles.message} style={styleMessage}>
          {message ? message : <FormattedMessage id="GENERAL.PHRASE.NO_DATA"/>}
        </p>
        {extraMessage &&
        <p className={styles.extraMessage} style={styleExtraMessage}>
          {extraMessage}
        </p>
        }
        {action &&
          <div className={styles.actionsWrapper}>
            {action}
          </div>
        }
      </div>
    );
  }
}