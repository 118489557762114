import {connect} from "react-redux";
import UserWatchlist from "./WatchlistPage";
import {fetchFollowerOffices} from "app/crud/followers.crud";

const mapStateToProps = ({ user, followers }) => ({
  user: user.data,
  updateLoading: user.updateLoading,
  items: followers.items,
  loading: followers.loading
});
const mapDispatchToProps = dispatch => ({
  fetchFollowerOffices: () => dispatch(fetchFollowerOffices()),
});

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(UserWatchlist);
