import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyPage from "./PropertyPage";
import {
  fetchOfficeAmenities,
  fetchOfficeBasic,
  fetchOfficeChanges,
  fetchOfficeLeaseTerms,
  fetchOfficeLeasingContacts,
  fetchOfficeMarketplaceStats,
  fetchOfficeMetrics,
  fetchOfficeOwner,
  fetchOfficeSimilarProperties,
  fetchOfficeUnits,
  resetOfficeBuildingState
} from "app/crud/estates/offices/officeBuilding.crud";
import {fetchOfficePhotos} from "../../../crud/estates/offices/offices.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  basic: store.officeBuilding.basic,
  basicLoading: store.officeBuilding.basicLoading,
  units: store.officeBuilding.units,
  unitsLoading: store.officeBuilding.unitsLoading,
  leaseTerms: store.officeBuilding.leaseTerms,
  leaseTermsLoading: store.officeBuilding.leaseTermsLoading,
  marketplaceStats: store.officeBuilding.marketplaceStats,
  marketplaceStatsLoading: store.officeBuilding.marketplaceStatsLoading,
  amenities: store.officeBuilding.amenities,
  amenitiesLoading: store.officeBuilding.amenitiesLoading,
  metrics: store.officeBuilding.metrics,
  metricsLoading: store.officeBuilding.metricsLoading,
  changes: store.officeBuilding.changes,
  changesLoading: store.officeBuilding.changesLoading,
  similarProperties: store.officeBuilding.similarProperties,
  similarPropertiesLoading: store.officeBuilding.similarPropertiesLoading,
  owner: store.officeBuilding.owner,
  ownerLoading: store.officeBuilding.ownerLoading,
  leasingContacts: store.officeBuilding.leasingContacts,
  leasingContactsLoading: store.officeBuilding.leasingContactsLoading,
  resetting: store.officeBuilding.resetting,
  photos: store.offices.officePhotos,
  photosLoading: store.offices.officePhotosLoading,
  isStaff: store.user.data.isStaff,
  userWatchlist: store.user.data.watchList,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  fetchOfficeBasic: (officeID) => fetchOfficeBasic(officeID),
  fetchOfficeUnits: (officeID, ordering) => fetchOfficeUnits(officeID, ordering),
  fetchOfficeLeaseTerms: (officeID) => fetchOfficeLeaseTerms(officeID),
  fetchOfficeMarketplaceStats: (officeID) => fetchOfficeMarketplaceStats(officeID),
  fetchOfficeAmenities: (officeID) => fetchOfficeAmenities(officeID),
  fetchOfficeMetrics: (officeID) => fetchOfficeMetrics(officeID),
  fetchOfficeChanges: (officeID) => fetchOfficeChanges(officeID),
  fetchOfficeSimilarProperties: (officeID) => fetchOfficeSimilarProperties(officeID),
  fetchOfficeOwner: (officeID) => fetchOfficeOwner(officeID),
  fetchOfficeLeasingContacts: (officeID) => fetchOfficeLeasingContacts(officeID),
  fetchOfficePhotos: (officeId) => fetchOfficePhotos(officeId),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
  resetOfficeBuildingState: resetOfficeBuildingState
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyPage)
);
