import React from "react";
import PropertyHeaderTitle from "app/pages/common/components/PropertyPage/PropertyHeaderTitle";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {BUILDING_STATUS, ROUTES} from "../../../../constants";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import PropertyHeaderMain from "app/pages/common/components/PropertyPage/PropertyHeaderMain";
import PropertyHeaderNav from "app/pages/common/components/PropertyPage/PropertyHeaderNav";
import PropertyHeaderDynamicAnalytics from "./PropertyHeaderDynamicAnalytics";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import _ from "lodash";
import {Tooltip} from "@material-ui/core";
import {returnOfficeBuildingStatus} from "../../../../utils/helpers";
import SimpleMap from "../../../../partials/components/SimpleMap";
import EditModal from "../../../../partials/components/EditModal";
import LeadsManagementAddProperty from "../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import ReactVisibilitySensor from "react-visibility-sensor";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import PrintModeDetect from "../../../../partials/components/PrintModeDetect";
import IsAdmin from "../../../../utils/IsAdmin";
import IsPro from "../../../../utils/IsPro";
import IsAccountType from "../../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../../constants/ACCOUNT_TYPE";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";

class PropertyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      initOwner: false,
      initContacts: false,
      initPhotos: false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      fetchOfficeOwner,
      fetchOfficeLeasingContacts,
      fetchOfficePhotos,
      ownerLoading,
      leasingContactsLoading,
      photosLoading,
      match
    } = this.props;
    const {initLoaded, initOwner, initContacts, initPhotos} = this.state;
    const {id} = match?.params;

    if(initLoaded && !initOwner && !ownerLoading) {
      this.setState({
        initOwner: true
      }, () => fetchOfficeOwner(id));
    }
    if(initLoaded && initOwner && !initContacts && !leasingContactsLoading) {
      this.setState({
        initContacts: true
      }, () => fetchOfficeLeasingContacts(id));
    }
    if(initLoaded && initOwner && initContacts && !initPhotos && !photosLoading) {
      this.setState({
        initPhotos: true
      }, () => fetchOfficePhotos(id));
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficeBasic,
      basic,
      basicLoading,
      resetting,
      match,
      callReddClickEvent
    } = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !basicLoading && _.isEmpty(basic) && id && !resetting) {
      if(id && callReddClickEvent) {
        callReddClickEvent(
          "estates-office-details",
          "office",
          null,
          id
        );
      }
      this.setState({
        initLoaded: true,
      }, () => fetchOfficeBasic(id));
    }
  }
  handleOnCompetitionRedirect = () => {
    const {callReddClickEvent, basic} = this.props;
    const propertyID = basic?.id;

    if(propertyID && callReddClickEvent) {
      callReddClickEvent(
        "office-building-page-compare-with-competition-click",
        "office",
        null,
        propertyID
      );
    }
  }

  render() {
    const {
      parentRef,
      basic,
      basicLoading,
      onScrollToSection,
      owner,
      leasingContacts,
      photos,
      userWatchlist,
      callReddClickEvent,
      intl
    } = this.props;
    const propertyLocation = [
      ...basic?.addressCity ? [basic?.addressCity] : [],
      ...basic?.addressStreet ? [basic?.addressStreet] : []
    ].join(", ");
    const officeStatus = returnOfficeBuildingStatus(basic?.status);
    const isOfficeEditable = basic?.isEditable;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <div className={styles.propertyHeaderContainer}>
          {
            !basicLoading && basic ?
              <>
              <PropertyHeaderTitle
                title={basic?.name}
                location={propertyLocation}
                toolbar={
                <div className={styles.propertyHeaderToolbarWrapper}>
                  <IsAdmin markup="font" markupTooltip="Admin Only">
                    <LeadsManagementAddProperty propertyID={basic?.id} btnListMode/>
                  </IsAdmin>
                  <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
                    <IsPro
                      paywall
                      paywallComponent={
                      <PaywallModal
                        triggerLabel={<FormattedMessage id="VOCABULARY.Compare with competition" />}
                        triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Compare with competition"})}
                        triggerSlim
                        withIcon
                      />
                      }
                    >
                      <ButtonCustom
                        label={<FormattedMessage id="VOCABULARY.Compare with competition" />}
                        redirect={ROUTES.COMPETITION_ANALYSIS_PAGE}
                        redirectTarget="_blank"
                        color="danger"
                        solid
                        slim
                        onClick={() => this.handleOnCompetitionRedirect()}
                      />
                    </IsPro>
                  </IsAccountType>
                </div>
                }
                titleActions={
                <>
                  {basic?.status !== BUILDING_STATUS.STATUS_EXISTS && officeStatus &&
                  <Tooltip
                    title={<span style={{fontSize: "1.2rem"}}>{officeStatus}</span>}
                    placement="top"
                  >
                    <span className={styles.propertyStatus}>
                      <IconColorMUI icon="schedule" size="20px"/>
                    </span>
                  </Tooltip>
                  }
                  {basic?.isEditable &&
                  <EditModal
                    target="office"
                    parentData={basic}
                    isPropertyEditable={isOfficeEditable}
                    {...this.props}
                  />
                  }
                </>
                }
                locationActions={
                <>
                  <span>•</span>
                  <SimpleMap lat={basic?.geoLatitude} lng={basic?.geoLongitude} propertyID={basic?.id} />
                </>
                }
              />
              <PropertyHeaderMain
                data={basic}
                owner={owner}
                contacts={leasingContacts}
                images={photos}
                userWatchlist={userWatchlist}
                isEditable={isOfficeEditable}
              />
              <PrintModeDetect hide>
                <PropertyHeaderNav
                  propertyID={basic?.id}
                  activeItem={0}
                  onScrollTo={onScrollToSection}
                />
              </PrintModeDetect>
              <PropertyHeaderDynamicAnalytics parentRef={parentRef} data={basic} callReddClickEvent={callReddClickEvent}/>
              </> :
              <LoadingScreen absolute/>
          }
        </div>
      </ReactVisibilitySensor>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeader)
);