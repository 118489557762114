import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import ButtonCustom from "app/partials/components/ButtonCustom";
import InputIcon from "app/partials/components/InputIcon";
import {addOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {fetchMarkets, fetchMarketsIndustrial} from "app/crud/user.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import SingleSelect from "app/partials/components/SingleSelect";
import {Grid} from '@material-ui/core';
import _ from "lodash";

class OfficeAddBuilding extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      name: '',
      market: props.user.market,
      nameError: false,
      marketError: false
    };
  }
  componentDidMount = async () => {
    this._isMounted = true;
    const {fetchMarkets, markets, marketsLoading} = this.props;

    if(!marketsLoading && _.isEmpty(markets)) {
      fetchMarkets();
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSelectedMarket = selected => {
    this.setState({
      market: selected
    });
  };
  handleSave = () => {
    const {name, market} = this.state;
    const {addOffice} = this.props;
    this.setState({
      nameError: !name.length,
      marketError: market === null
    }, () => {
      if(name.length && market !== null) {
        addOffice({name, market: market, units: []});
        this.handleCloseModal();
        return true;
      }
    });
  };

  render() {
    const {markets, marketsLoading} = this.props;
    const {name, market, nameError} = this.state;
    if(markets === undefined) {
      return <LoadingScreen/>;
    }
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ButtonCustom label={<FormattedMessage id="BUILDING.PHRASE.ADD_BUILDING"/>} color="success" icon="add"/>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.ADD_BUILDING"/>}
        handleSave={this.handleSave}
      >
        {marketsLoading ? <LoadingScreen/> :
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputIcon label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_NAME"/>} name="new_building_name" value={name} error={nameError} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <SingleSelect
                label={<FormattedMessage id="GENERAL.PHRASE.MARKET"/>}
                inputName="new_building_market"
                options={markets}
                optionsValue="id"
                optionsLabel="name"
                defaultSelect={market}
                onSelectedValues={this.handleSelectedMarket}
                required
            />
          </Grid>
        </Grid>
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store?.user.data,
  markets: store?.user?.markets,
  marketsLoading: store?.user?.marketsLoading,
});
const mapDispatchToProps = {
  addOffice: office => addOffice(office),
  fetchMarkets: fetchMarkets,
  fetchMarketsIndustrial: fetchMarketsIndustrial
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeAddBuilding)
);