import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {onlyNumbers, toNumberFormat} from "app/utils/helpers";
import InputAdornments from "app/partials/components/InputAdornments";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import styles from "app/partials/components/_DataParts/index.module.scss";
import DashboardExtendedDataItem
  from "app/partials/components/DashboardExtendedDataBox/DashboardExtendedDataItem";
import DashboardExtendedDataWrapper
  from "app/partials/components/DashboardExtendedDataBox/DashboardExtendedDataWrapper";

class UnitForecastPremium extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      rentalHolidays: "",
      effectiveRent: "N/A"
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "rentalHolidays" || event.target.name === "rentalPeriod";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    }, () => {
      const {unit, officeData} = this.props;
      const minRentalPeriodNumber = Number(officeData.minRentalYears)*12;
      if(this.state.rentalHolidays && minRentalPeriodNumber && !isNaN(minRentalPeriodNumber)) {
        const isRetail = unit?.unitType === 2;
        const rentFromValue = unit?.isSublease && unit?.subleaseRentEur ? unit?.subleaseRentEur : isRetail && officeData.retailRentPriceEur ? officeData.retailRentPriceEur : officeData.rentFromEur;
        const rentalHolidaysNumber = Number(this.state.rentalHolidays);
        const rentalPeriod = minRentalPeriodNumber - rentalHolidaysNumber;
        const effectiveRent = (rentFromValue/(minRentalPeriodNumber/rentalPeriod)).toFixed(2);
        this.setState({
          effectiveRent
        });
      } else {
        this.setState({
          effectiveRent: "N/A"
        })
      }
    });
  };

  render() {
    const {unit, area, directBtn, directIcon, officeData} = this.props;

    if(unit && area && officeData) {
      const { rentalHolidays, effectiveRent } = this.state;
      const isRetail = unit?.unitType === 2;
      const retailRentPriceEur = officeData.retailRentPriceEur;
      const minRentalPeriodNumber = officeData.minRentalYearsToNegotiate ? null :  Number(officeData.minRentalYears)*12;
      const isAllowed = area !== 0;
      const isCommonArea = officeData.commonAreaFactor !== null;
      const commonArea = isAllowed ? area*Number(officeData.commonAreaFactor)/100 : null;
      const isRentToNegotiate = unit?.isSublease ? unit?.subleaseRentToNegotiate : officeData.rentToNegotiate;
      let forecastRent = isAllowed && officeData.rentFromEur !== null && !isRentToNegotiate ? (area*Number(officeData.rentFromEur)).toFixed(0) : null;
      let forecastRentWithCommon = isAllowed && isCommonArea && officeData.rentFromEur !== null && !isRentToNegotiate ? ((area+commonArea)*Number(officeData.rentFromEur)).toFixed(0) : null;
      let forecastServiceWithCommon = isAllowed && isCommonArea && officeData.serviceChargesPln !== null ? ((area+commonArea)*Number(officeData.serviceChargesPln)).toFixed(0) : null;
      const forecastRentEffective = isAllowed && effectiveRent !== null && effectiveRent !== "N/A" && !isRentToNegotiate ? (area*Number(effectiveRent)).toFixed(0) : null;
      const forecastRentWithCommonEffective = isAllowed && isCommonArea && effectiveRent !== null && effectiveRent !== "N/A" && !isRentToNegotiate ? ((area+commonArea)*Number(effectiveRent)).toFixed(0) : null;
      if(unit?.isSublease) {
        forecastRent = isAllowed && unit?.subleaseRentEur && !isRentToNegotiate ? (area*Number(unit?.subleaseRentEur)).toFixed(0) : null;
        forecastRentWithCommon = isAllowed && isCommonArea && unit?.subleaseRentEur && !isRentToNegotiate ? ((area+commonArea)*Number(unit?.subleaseRentEur)).toFixed(0) : null;
        forecastServiceWithCommon = isAllowed && isCommonArea && unit?.subleaseServiceChargePln !== null ? ((area+commonArea)*Number(unit?.subleaseServiceChargePln)).toFixed(0) : null;
      } else if(isRetail) {
        forecastRent = isAllowed && retailRentPriceEur ? (area*Number(retailRentPriceEur)).toFixed(0) : null;
        forecastRentWithCommon = isAllowed && isCommonArea && retailRentPriceEur ? ((area+commonArea)*Number(retailRentPriceEur)).toFixed(0) : null;
      }
      const askingRent = unit?.isSublease ? (unit?.subleaseRentEur ? unit?.subleaseRentEur : null) : isRetail ? (retailRentPriceEur ? retailRentPriceEur : null) : officeData.rentFromEur;
      const askingService = unit?.isSublease ? (unit?.subleaseServiceChargePln ? unit?.subleaseServiceChargePln : null) : officeData.serviceChargesPln;

      return (
        <ModalCustom
          ref={this.child}
          btn={
            <ModalTrigger
              color="success"
              label={forecastRentWithCommon ? toNumberFormat(forecastRentWithCommon, 'currency') : 'N/A'}
              directBtn={directBtn}
              directIcon={directIcon}
              fullWidth
              style={{fontSize: "1.4rem"}}
            />
          }
          title={officeData.name}
          btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        >
          <Grid container spacing={2}>
            <Grid item style={{borderRight: "2px solid rgba(255,255,255,0.1)"}}>
              <DashboardExtendedDataWrapper direction="column" cols={1} className={styles.spvPreviewWrapper} style={{height: "auto", marginBottom: 25}}>
                <DashboardExtendedDataItem variant="h3" value={toNumberFormat(area, 'area')} label={<FormattedMessage id="GENERAL.PHRASE.AREA"/>}/>
                <DashboardExtendedDataItem variant="h3" value={unit?.floor} label={<FormattedMessage id="GENERAL.PHRASE.FLOOR"/>}/>
                <DashboardExtendedDataItem variant="h3" value={isRentToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> : toNumberFormat(askingRent, 'currency')} label={<FormattedMessage id="BUILDING.PHRASE.ASKING_RENT"/>}/>
                <DashboardExtendedDataItem variant="h3" value={toNumberFormat(effectiveRent, 'currency')} label={<FormattedMessage id="BUILDING.PHRASE.EFFECTIVE_RENT"/>}/>
                <DashboardExtendedDataItem variant="h3" value={toNumberFormat(askingService, 'currency', 'PLN')} label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE" values={{br: ""}}/>}/>
                <DashboardExtendedDataItem variant="h3" value={toNumberFormat(minRentalPeriodNumber)} label={<FormattedMessage id="OWNERS.PHRASE.MIN_RENTAL_PERIOD_MONTHS"/>}/>
              </DashboardExtendedDataWrapper>
              {forecastRent && officeData.minRentalYears &&
              <InputAdornments
                  label={<FormattedMessage id="BUILDING.PHRASE.TRADE_FIXTURES_MONTHS_INPUT"/>}
                  name="rentalHolidays"
                  value={rentalHolidays}
                  onChange={this.handleChange}
                  minWidth="220px"
              />
              }
            </Grid>
            <Grid item>
              <DialogTitle id="alert-dialog-slide-title" style={{padding: 0, marginTop: "-5px"}} disableTypography>
                <Typography variant="h4">
                  <FormattedMessage id="VOCABULARY.Rental estimate" values={{breakingLine: <br/>, subtitle: <Typography variant="body2"><FormattedMessage id="GENERAL.PHRASE.MONTHLY"/></Typography>}}/>
                </Typography>
              </DialogTitle>
              <Table style={{width: "100%"}}>
                <TableHead>
                  <TableRow>
                    <TableCell/>
                    <TableCell align="right"><FormattedMessage id="BUILDING.PHRASE.ASKING_RENT"/></TableCell>
                    <TableCell align="right"><FormattedMessage id="BUILDING.PHRASE.EFFECTIVE_RENT"/></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell><FormattedMessage id="UNITS.PHRASE.ESTIMATED_RENTAL_COST"/></TableCell>
                    <TableCell align="right" className={styles.tableCellText}>{toNumberFormat(forecastRent, 'currency')}</TableCell>
                    <TableCell align="right" className={styles.tableCellText}>{toNumberFormat(forecastRentEffective, 'currency')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage id="UNITS.PHRASE.ESTIMATED_RENTAL_COST_WITH_COMMON_AREA_FACTOR" values={{breakingLine: <br/>}}/></TableCell>
                    <TableCell align="right" className={styles.tableCellText}>{toNumberFormat(forecastRentWithCommon, 'currency')}</TableCell>
                    <TableCell align="right" className={styles.tableCellText}>{toNumberFormat(forecastRentWithCommonEffective, 'currency')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage id="UNITS.PHRASE.ESTIMATED_COST_OD_SERVICE_FEES_WITH_COMMON_AREA_FACTOR" values={{breakingLine: <br/>}}/></TableCell>
                    <TableCell colSpan={2} align="center" className={styles.tableCellText}>{toNumberFormat(forecastServiceWithCommon, 'currency', 'PLN')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {forecastRent && officeData.minRentalYears &&
              <Typography variant="body2" style={{fontStyle: "italic", marginTop: "2rem"}}>*<FormattedMessage id="To calculate the effective rent, enter the number of months of rent holidays in the bottom left-hand corner."/></Typography>
              }
            </Grid>
          </Grid>
        </ModalCustom>
      );
    }
    return null;
  }
}

export default injectIntl(
  connect(
    null,
    null
  )(UnitForecastPremium)
);