import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import InputSwitcher from "../../../../../partials/components/InputSwitcher";
import ButtonCustom from "../../../../../partials/components/ButtonCustom";
import TableMUI from "../../../../../partials/components/TableMUI";
import {Checkbox, Grid, TableCell, TableRow} from "@material-ui/core";
import {handleTableCellAlign} from "../../../../../utils/helpers";
import UnitFloorPreview from "../../../../../partials/components/_DataParts/UnitFloorPreview";
import UnitTypePreview from "../../../../../partials/components/_DataParts/UnitTypePreview";
import UnitStatusPreview from "../../../../../partials/components/_DataParts/UnitStatusPreview";
import {UNIT_STATUS} from "../../../../../constants";
import UnitAvailabilityPreview from "../../../../../partials/components/_DataParts/UnitAvailabilityPreview";
import UpdatedAtPreview from "../../../../../partials/components/_DataParts/UpdatedAtPreview";
import UnitForecastPremium from "../../../../../partials/components/Units/UnitForecastPremium";
import {FormattedMessage} from "react-intl";
import UnitAreaPreview from "../../../../../partials/components/_DataParts/UnitAreaPreview";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import OfficeAddUnit from "../../../../../partials/components/Offices/OfficeAddUnit";
import EditModal from "../../../../../partials/components/EditModal";
import UnitAddToOffer from "../../../../../partials/components/Units/UnitAddToOffer";
import TableActionsLandlordOfficeUnits
  from "../../../../../partials/components/TableProperties/TableActions/TableActionsLandlord/TableActionsLandlordOfficeUnits";
import LackOfAvailableArea from "../../../../../partials/components/LackOfAvailableArea/LackOfAvailableArea";
import NoData from "../../../../../partials/components/NoData/NoData";
import PrintModeDetect from "../../../../../partials/components/PrintModeDetect";
import IsPro from "../../../../../utils/IsPro";
import PaywallBannerSimple from "../../../../../partials/components/PayWall/banners/PaywallBannerSimple";
import PaywallBlurContent from "../../../../../partials/components/PayWall/components/PaywallBlurContent";
import {ModalTrigger} from "../../../../../partials/components/ModalCustom/ModalTrigger";

export default class PropertyUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnavailable: "",
      initLoaded: false,
      ordering: '',
      selected: []
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {unitsLoading, fetchOfficeLeaseTerms, leaseTerms, leaseTermsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(initLoaded && !unitsLoading && !leaseTermsLoading && _.isEmpty(leaseTerms)) {
      fetchOfficeLeaseTerms(id);
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchOfficeUnits, units, unitsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if(isVisible && !initLoaded && !unitsLoading && _.isEmpty(units) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchOfficeUnits(id));
      }
    }, 200);
  }
  handleToggleUnavailable = (e) => {
    this.setState({
      showUnavailable: e?.target?.checked ? e.target.value : "",
    });
  };
  handleSortTable = (orderBy, order) => {
    const {fetchOfficeUnits, unitsLoading, match} = this.props;
    const {id} = match?.params;

    if(orderBy && !unitsLoading) {
      const ordering = [
        ...order ? [`${order}`] : [],
        ...orderBy ? [`${orderBy}`] : []
      ].join("");

      this.setState({
        ordering
      }, () => fetchOfficeUnits(id, ordering));
    }
  }
  handleSelectRow = (event) => {
    const {selected} = this.state;
    const isChecked = event?.target?.checked;
    const value = Number(event?.target?.value);
    let selectedUpdated;

    if(isChecked) {
      selectedUpdated = [
        ...selected,
        value
      ];
    }
    else {
      selectedUpdated = selected.filter(item => item !== value);
    }
    this.setState({
      selected: selectedUpdated
    });
  }
  handleSelectAll = (tBody, checked) => {
    const selectedUpdated = checked ? tBody?.map(item => item?.id) : [];
    this.setState({
      selected: selectedUpdated
    });
  }
  handleOnRefreshComplete = () => {
    this.setState({
      selected: []
    });
  }
  // Helpers
  isSelected = (item) => this.state.selected?.indexOf(item) !== -1;

  render() {
    const {parentRef, basic, leaseTerms, units, unitsLoading} = this.props;
    const {showUnavailable, ordering, selected} = this.state;
    const tHead = [
        {id: 'type', numeric: false, disablePadding: false, label: 'VOCABULARY.Type', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'VOCABULARY.Size', sort: true},
        {id: 'floor', numeric: true, disablePadding: false, label: 'VOCABULARY.Floor', sort: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'VOCABULARY.Status', sort: false},
        {id: 'available_from', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.AVAILABLE_FROM', sort: true},
        {id: 'updated_at', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.UPDATED_AT', sort: true},
        {id: 'forecast_fees', numeric: true, disablePadding: false, label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];
    const tBody = units && units?.length > 0 ? (
      showUnavailable ? units?.filter(unit => unit?.status === UNIT_STATUS.UNAVAILABLE) :
        units?.filter(unit => unit?.status !== UNIT_STATUS.UNAVAILABLE)
    ) : null;
    const totalAvailableItems = units?.filter(unit => unit?.status !== UNIT_STATUS.UNAVAILABLE)?.length;
    const isOfficeEditable = basic?.isEditable;

    return(
      <>
      <PropertySectionTitle
        parentRef={parentRef}
        title={<FormattedMessage id="VOCABULARY.Available units"/>}
        titleActions={
        <>
          {isOfficeEditable &&
          <EditModal
            target="office"
            parentData={basic}
            isPropertyEditable={isOfficeEditable}
            initialTab="commercialization"
          />
          }
        {selected?.length > 0 && isOfficeEditable &&
        <TableActionsLandlordOfficeUnits
          parentId={basic?.id}
          units={selected}
          totalItems={tBody?.length}
          totalAvailableItems={totalAvailableItems}
          isShowUnavailable={showUnavailable}
          ordering={ordering}
          onRefreshComplete={this.handleOnRefreshComplete}
        />
        }
        </>
        }
        toolbar={
        <>
          {isOfficeEditable &&
          <InputSwitcher
            checked={showUnavailable === "true"}
            value="true"
            label={<FormattedMessage id="VOCABULARY.Show unavailable units"/>}
            onChange={this.handleToggleUnavailable}
          />
          }
          {isOfficeEditable && (basic?.numberOfUnitsAvailable <= 0 || basic?.lackOfAvailableArea) &&
          <LackOfAvailableArea
            propertyID={basic?.id}
            lackOfAvailableArea={basic?.lackOfAvailableArea}
            buildingAvailableFrom={basic?.buildingAvailableFrom}
            target="office"
            directIcon
            modeSwitcher
          />
          }
          {isOfficeEditable &&
          <OfficeAddUnit
            officeId={basic?.id}
            officeStatus={basic?.status}
            lackOfAvailableArea={basic?.lackOfAvailableArea}
            ordering={ordering}
            btnTrigger={
            <ButtonCustom
              label={<FormattedMessage id="VOCABULARY.Add unit"/>}
              color="danger"
              inactive={basic?.lackOfAvailableArea}
              solid
              slim
            />
            }
          />
          }
        </>
        }
        style={{
          marginBottom: "1.6rem"
        }}
      />
      <IsPro
        paywall
        paywallComponent={
          <PaywallBlurContent
            blurImage="media/placeholders/table-office-units-blur-placeholder.png"
            style={{height: "40rem"}}
          >
            <PaywallBannerSimple/>
          </PaywallBlurContent>
        }
        overwriteRule={basic?.isEditable}
      >
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
          intervalCheck={true}
          intervalDelay={1000}
        >
          <TableMUI
            tHead={tHead}
            checkable={isOfficeEditable}
            loading={unitsLoading}
            initialOrderBy="updated_at"
            containerStyle={{minHeight: "32rem"}}
            selected={selected}
            totalItems={tBody?.length}
            onSort={this.handleSortTable}
            onSelectAll={(checked) => this.handleSelectAll(tBody, checked)}
            noData={!tBody || tBody?.length <= 0}
            noDataMessage={
              isOfficeEditable ?
                <NoData
                  absolute
                  withIcon
                  style={{paddingTop: "6.4rem"}}
                  message={<FormattedMessage id="There are currently no modules available in the building."/>}
                  extraMessage={
                  basic?.lackOfAvailableArea &&
                    <>
                      <FormattedMessage id="To add a new unit, you must first uncheck - "/>
                      <strong><FormattedMessage id="Lack of available area."/></strong>
                    </>
                  }
                  action={
                    isOfficeEditable &&
                    <OfficeAddUnit
                      officeId={basic?.id}
                      officeStatus={basic?.status}
                      lackOfAvailableArea={basic?.lackOfAvailableArea}
                      ordering={ordering}
                      btnTrigger={
                      <ButtonCustom
                        label={<FormattedMessage id="VOCABULARY.Add unit"/>}
                        color="danger"
                        inactive={basic?.lackOfAvailableArea}
                        solid
                        slim
                      />
                      }
                    />
                  }
                /> :
                <NoData
                  message={<FormattedMessage id="There are currently no modules available in the building."/>}
                  absolute
                  withIcon
                  style={{paddingTop: "6.4rem"}}
                />
            }
          >
            {tBody?.length > 0 && tBody?.map((unit, index) => {
              return(
                <TableRow key={index}>
                  <PrintModeDetect hide>
                    {isOfficeEditable &&
                    <TableCell style={{width: "3.5rem", paddingRight: "0"}}>
                      <Checkbox
                        value={unit?.id}
                        checked={this.isSelected(unit?.id)}
                        inputProps={{"aria-labelledby": unit?.id}}
                        onChange={this.handleSelectRow}
                      />
                    </TableCell>
                    }
                  </PrintModeDetect>
                  <TableCell align={handleTableCellAlign(tHead, 0)}>
                    <UnitTypePreview
                      unitType={unit?.unitType}
                      isSublease={unit?.isSublease}
                      simpleWithIcon
                    />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 1)}>
                    <UnitAreaPreview
                      data={unit}
                      parentData={basic}
                      isOfficeEditable={isOfficeEditable}
                      style={{fontWeight: 600}}
                      flexibleUnitForm={
                        <EditModal
                          target="unit"
                          parentData={unit}
                          triggerDots
                          isPropertyEditable={isOfficeEditable}
                          initialTab="flexibleUnits"
                          extraData={{
                            ordering,
                            officeId: basic?.id
                          }}
                          triggerCustom={
                            <ModalTrigger
                              icon="tab_unselected"
                              label={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
                              directIcon
                              color={unit?.canBeDivided ? "danger" : "gray"}
                            />
                          }
                        />
                      }
                    />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 2)}>
                    <UnitFloorPreview floor={unit?.floor} />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 3)}>
                    <UnitStatusPreview unitStatus={unit?.status} />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 4)}>
                    <UnitAvailabilityPreview
                      date={unit?.availableFrom}
                      isReserved={unit?.status === UNIT_STATUS.RESERVED}
                      isUnavailable={unit?.status === UNIT_STATUS.UNAVAILABLE}
                      availableInMonths={unit?.availableFromAdditionalTime}
                      parentStatus={basic?.status}
                    />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 5)}>
                    <UpdatedAtPreview level={unit?.updateLevel} date={unit?.updatedAt} alternative />
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 6)}>
                    <UnitForecastPremium
                      unit={unit}
                      area={unit?.area}
                      officeData={leaseTerms}
                      directBtn
                    />
                  </TableCell>
                  <PrintModeDetect hide>
                    <TableCell align={handleTableCellAlign(tHead, 7)}>
                      <Grid container spacing={2} alignItems="center" justifyContent="flex-end" wrap="nowrap">
                        {unit?.status !== UNIT_STATUS.UNAVAILABLE &&
                        <UnitAddToOffer
                          propertyID={basic?.id}
                          unitId={unit?.id}
                        />
                        }
                        <Grid item>
                          <EditModal
                            target="unit"
                            parentData={unit}
                            triggerDots
                            isPropertyEditable={isOfficeEditable}
                            initialTab={isOfficeEditable ? "basic" : "note"}
                            extraData={{
                              ordering,
                              officeId: basic?.id
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </PrintModeDetect>
                </TableRow>
              );
            })}
          </TableMUI>
        </ReactVisibilitySensor>
      </IsPro>
      </>
    );
  }
}