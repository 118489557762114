import React from "react";
import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {addLeadProperties, fetchLeadsPreview} from "../../../../../crud/leadsManagement.crud";
import LoadingScreen from "../../../LoadingScreen";
import APPLICATION_VIEWS from "../../../../../constants/APPLICATION_VIEWS";

class EditUnitLeadsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      leadID: null,
    };
    this.initialState = this.state;

    if(!props?.loadingLeads && props.fetchLeadsPreview) {
      props.fetchLeadsPreview();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      // this.handleSave();
    }
    // Detect Office Updating
    // if(prevProps.officeUpdating !== this.props.officeUpdating) {
    //   this.props.onRequestSaving(this.props.officeUpdating);
    // }
  }

  // Handlers
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      leadID: event.target.value,
    });
  };
  handleSave = () => {
    const {addLeadProperties, extraData, propertyData, isUnitView, isIndustrialBuildingsView, currentApplicationView} = this.props;
    const {leadID} = this.state;
    const propertyID = extraData?.officeId;

    if (leadID && propertyID && addLeadProperties) {
      const properties = Array.isArray(propertyID) ? propertyID : [propertyID];
      let unitParentsIDS = [];
      let industrialBuildingsParentsIDS = [];
      let finalProperties = properties;
      // Handle unit select
      if(isUnitView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => properties?.includes(item?.id));
        selectedUnitsData.map(unitData => unitParentsIDS.push(unitData?.office?.id));

        if(!_.isEmpty(unitParentsIDS)) {
          finalProperties = _.uniq(unitParentsIDS);
        }
      }
      else if(isIndustrialBuildingsView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => properties?.includes(item?.id));
        selectedUnitsData.map(unitData => industrialBuildingsParentsIDS.push(unitData?.park));

        if(!_.isEmpty(industrialBuildingsParentsIDS)) {
          finalProperties = _.uniq(industrialBuildingsParentsIDS);
        }
      }
      // Create data to send
      const data = {
        ...currentApplicationView === APPLICATION_VIEWS.OFFICES ? {"offices": finalProperties} : {},
        ...currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? {"parks": finalProperties} : {}
      };
      addLeadProperties(leadID, data);
    }
  };

  render() {
    const {loadingLeads, leads} = this.props;
    const {open, leadID} = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/></Typography>
          </Grid>
        <Grid item xs={12}>
          {loadingLeads ? <LoadingScreen/> :
            <FormControl>
              <InputLabel htmlFor="new_user_market">
                <FormattedMessage id="LM.PHRASE.SELECT_LEAD"/>
              </InputLabel>
              <Select
                open={open}
                onClose={this.handleToggleSelect}
                onOpen={this.handleToggleSelect}
                value={leadID}
                onChange={this.handleChange}
                inputProps={{
                  name: "lead",
                  id: "lead_assign",
                }}
              >
                {leads?.map((lead, index) => {
                  const areaRange = [
                    ...lead?.crmAreaFrom ? [lead?.crmAreaFrom] : [],
                    ...lead?.crmAreaTo ? [lead?.crmAreaTo] : []
                  ].join("-");
                  const location = [
                    ...lead?.market ? [lead?.market] : [],
                    ...lead?.region ? [lead?.region] : []
                  ].join(", ");
                  const combinedLabel = [
                    ...lead?.crmEmail ? [lead?.crmEmail] : [],
                    areaRange,
                    location
                  ].join(" | ");
                  const label = lead?.leadName ? lead?.leadName : combinedLabel;

                  return (
                    <MenuItem key={index} value={lead?.id}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({leadsManagement, user}) => ({
  leads: leadsManagement?.leadsPreview,
  loadingLeads: leadsManagement?.loadingLeadsPreview,
  adding: leadsManagement?.adding,
  currentApplicationView: user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchLeadsPreview: () => fetchLeadsPreview(),
  addLeadProperties: (leadID, data) => addLeadProperties(leadID, data)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUnitLeadsManagement)
);