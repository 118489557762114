import React from "react";
import {FormattedMessage} from "react-intl";
import {UNIT_TYPES} from "app/constants";
import BetaLabel from "app/partials/components/BetaLabel";
import {Icon} from "@material-ui/core";
import TooltipCustom from "app/partials/components/TooltipCustom";
import styles from "./index.module.scss";

export default class UnitTypePreview extends React.Component {
  render() {
    const {unitType, isSublease, industrialType, simple, simpleWithIcon} = this.props;
    return (
      <span className={styles.unitTypeWrapper}>
        {isSublease &&
          <BetaLabel
            top={simpleWithIcon ? "0" : "-17px"}
            right={simpleWithIcon ? "0" : "0"}
            style={simpleWithIcon ? {transform: "translate(10%, -100%)"} : undefined}
          >
            <FormattedMessage id="UNITS.PHRASE.SUBLEASE"/>
          </BetaLabel>
        }
        {
          industrialType ? (
          industrialType === "office" ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon>business</Icon> <FormattedMessage id="GENERAL.PHRASE.OFFICE"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE"/>}><Icon>business</Icon></TooltipCustom>
          ) :
            (
              simple ? <FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/> :
                simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon>warehouse</Icon> <FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/></span> :
                  <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/>}><Icon>warehouse</Icon></TooltipCustom>
            )
          ) : (
          unitType === UNIT_TYPES.RETAIL ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.RETAIL"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon>storefront</Icon> <FormattedMessage id="GENERAL.PHRASE.RETAIL"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.RETAIL"/>}><Icon>storefront</Icon></TooltipCustom>
          ) :
          unitType === UNIT_TYPES.OFFICE_RETAIL ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon>flip</Icon> <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/>}><Icon>flip</Icon></TooltipCustom>
          ) :
            (
              simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
                simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon>business</Icon> <FormattedMessage id="GENERAL.PHRASE.OFFICE"/></span> :
                  <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE"/>}><Icon>business</Icon></TooltipCustom>
            )
          )
        }
      </span>
    );
  }
}