import React from "react";
import {Grid} from "@material-ui/core";
import styles from "./index.module.scss";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconArrow} from "app/media/icons/arrow-left.svg";
import PrintModeDetect from "../../../../partials/components/PrintModeDetect";

export default function PropertyHeaderTitle({title, location, toolbar, titleActions, locationActions, goBackRoute, goBackName}) {
  return(
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item>
        {goBackRoute && goBackName &&
          <Link to={`${goBackRoute}`} className={styles.btnGoBack}>
            <IconArrow/>
            <FormattedMessage id="VOCABULARY.GO_TO"/>
            <span className={styles.goBackTitle}>{goBackName}</span>
          </Link>
        }
        <div className={styles.propertyTitleWrapper}>
          {title && <h1>{title}</h1>}
          <PrintModeDetect hide>
          {titleActions &&
            <div className={styles.propertyTitleActionsWrapper}>{titleActions}</div>
          }
          </PrintModeDetect>
        </div>
        <div className={styles.propertyLocationWrapper}>
          {location && <p>{location}</p>}
          <PrintModeDetect hide>
          {locationActions &&
            <div className={styles.propertyLocationActionsWrapper}>{locationActions}</div>
          }
          </PrintModeDetect>
        </div>
      </Grid>
      <PrintModeDetect hide>
      {toolbar &&
      <Grid item>
        <div className={styles.toolbarWrapper}>
          {toolbar}
        </div>
      </Grid>
      }
      </PrintModeDetect>
    </Grid>
  );
}