import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableDrawer from "app/partials/components/TableDrawer/index";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import BuildingUnitsCountPreview from "app/partials/components/_DataParts/BuildingUnitsCountPreview";
import {toNumberFormat} from "app/utils/helpers";
import styles from "../index.module.scss";
import {fetchOfficeUnits} from "app/crud/estates/offices/units.crud";
import {callReddClickEvent} from "app/crud/user.crud";

class TableOfficesPremiumCells extends React.Component {
  render() {
    const {
      row,
      officeID,
      dataTarget,
      officeUnits,
      officeUnitsLoading,
      fetchOfficeUnits,
      callReddClickEvent
    } = this.props;
    const isOfficeEditable = row?.isEditable;
    const premiumValue =
      dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RELEASES ? 'recentFreedModulesNo' :
        dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENT_CHANGED ? 'recentRentChange' :
          dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENTED ? 'recentRentedModulesNo' :
            dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_SOLD_OFFICES ? 'lastTransactionDate' : '';
    const isAnalytics1 = premiumValue === 'recentFreedModulesNo';
    const isAnalytics2 = premiumValue === "recentRentChange";
    const isAnalytics3 = premiumValue === "recentRentedModulesNo";

    return (
      <>
        <TableCell component="th" scope="row">
          <BuildingCardPreview
            name={row?.name}
            street={row?.addressStreet}
            city={row?.addressCity}
            thumb={row?.mainPhotoThumbnail}
            officeID={officeID}
            onTrackOpen={() => {
              isAnalytics1 && callReddClickEvent(
                "market-insights-office-supply-boost-follow",
                "office",
                null,
                officeID
              );
              isAnalytics2 && callReddClickEvent(
                "market-insights-office-rent-reductions-follow",
                "office",
                null,
                officeID
              );
              isAnalytics3 && callReddClickEvent(
                "market-insights-office-commercialized-area-follow",
                "office",
                null,
                officeID
              );
            }}
          />
        </TableCell>
        <TableCell align="right" className={styles.premiumCell}>
          {dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENT_CHANGED ? toNumberFormat(row[premiumValue], 'currency') : row[premiumValue]}
        </TableCell>
        <TableCell align="right">
          <TableDrawer
            titleAdvance
            headRows={
              [
                ...isOfficeEditable ? [{
                  id: 'checkable',
                  numeric: false,
                  disablePadding: false,
                  label: '',
                  sort: false
                }] : [],
                {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false},
                {id: 'entrance', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.ENTRANCE', sort: true},
                {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false},
                {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
                {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
                {
                  id: 'available_from',
                  numeric: true,
                  disablePadding: false,
                  label: 'BUILDING.PHRASE.AVAILABLE_FROM',
                  sort: false
                },
                {
                  id: 'update_level_cache',
                  numeric: false,
                  disablePadding: false,
                  label: 'BUILDING.PHRASE.UPDATED_AT',
                  sort: false
                },
                {
                  id: 'rent_esitmation',
                  numeric: true,
                  disablePadding: false,
                  label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM',
                  sort: false
                },
                {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
              ]
            }
            bodyRows={officeUnits}
            bodyRowsReady={officeUnitsLoading}
            target={TABLE_CELLS.OFFICE_UNITS}
            parentId={row?.id}
            parentData={{
              commonAreaFactor: row?.commonAreaFactor,
              rentFromEur: row?.rentFromEur,
              serviceChargesPln: row?.serviceChargesPln,
              lackOfAvailableArea: row?.lackOfAvailableArea,
              minRentalYears: row?.minRentalYears,
              minRentalYearsToNegotiate: row?.minRentalYearsToNegotiate,
              retailRentPriceEur: row?.retailRentPriceEur,
              rentToNegotiate: row?.rentToNegotiate,
              officeName: row?.name,
              isEditable: row?.isEditable
            }}
            parentPreviewData={row}
            onClick={fetchOfficeUnits}
            isEditable={row?.isEditable}
          >
            <BuildingUnitsCountPreview units={row?.numberOfUnitsAvailable}/>
          </TableDrawer>
        </TableCell>
        <TableCell align="right">{toNumberFormat(row?.totalAreaNoSubleaseOfficeAvailable, 'area')}</TableCell>
        <TableCell align="right">{toNumberFormat(row?.rentFromEur, 'currency')}</TableCell>
        <TableCell align="right">{toNumberFormat(row?.serviceChargesPln, 'currency', 'PLN')}</TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userWatchlist: state.user.data.watchList,
  officeUnits: state.units.officeUnits,
  officeUnitsLoading: state.units.officeUnitsLoading,
  isStaff: state.user.data.isStaff
});
const mapDispatchToProps = {
  fetchOfficeUnits: (officeId) => fetchOfficeUnits(officeId),
  callReddClickEvent: (clickType, contentType, appLabel, objectId) => callReddClickEvent(clickType, contentType, appLabel, objectId)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableOfficesPremiumCells)
);
