import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {addOfficeUnit} from "app/crud/estates/offices/units.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import format from "date-fns/format";
import {Grid, Typography} from "@material-ui/core";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {BUILDING_STATUS, UNIT_STATUS, UNIT_TYPES} from "app/constants";
import InputSwitcher from "app/partials/components/InputSwitcher";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {areaFormat, onlyNumbers} from "app/utils/helpers";
import ConfirmationDialog from "../ConfirmationDialog";
import AlertFormDialog from "../AlertFormDialog";
import LoadingScreen from "../LoadingScreen";
import TooltipCustom from "../TooltipCustom";

class OfficeAddUnit extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      area: '',
      entrance: '',
      floor: '',
      availableFrom: format(new Date(), 'yyyy-MM-dd'),
      status: 0,
      unitType: 1,
      canBeDivided: false,
      isSublease: false,
      subleaseContactName: '',
      subleaseContactPhone: '',
      subleaseContactEmail: '',
      subleaseRent: '',
      subleaseRentToNegotiate: false,
      subleaseAvailableFrom: null,
      subleaseAvailableTo: null,
      subleaseParkingPlaces: '',
      confirmationOpen: false,
      confirmationAction: false,
      alertFormOpen: false,
      alertFormDateOpen: false,
      alertRequiredFields: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {addingUnit, unitAdded} = this.props;

    if(prevProps?.addingUnit && !addingUnit && unitAdded) {
      this.handleCloseModal();
    }
  }

  handleSelectedType = (selected) => {
    this.setState({
      unitType: selected
    });
  };
  handleCloseModal = () => {
    this.setState({
      unitType: 1
    }, () => this.child.current.handleClose());
  };
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      alertFormOpen: false,
      alertFormDateOpen: false,
      alertRequiredFields: false,
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      alertFormDateOpen: false,
      alertRequiredFields: false,
      [event.target.name]: event.target.value
    });
  };
  handleChangeSwitcher = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  };
  handleSelectedStatus = (selected) => {
    this.setState({
      alertRequiredFields: false,
      status: selected
    });
  };
  handleSave = () => {
    const {officeId, officeStatus, addOfficeUnit, ordering} = this.props;
    const {
      area,
      entrance,
      floor,
      availableFrom,
      status,
      unitType,
      canBeDivided,
      isSublease,
      subleaseContactName,
      subleaseContactPhone,
      subleaseContactEmail,
      subleaseRent,
      subleaseRentToNegotiate,
      subleaseAvailableFrom,
      subleaseAvailableTo,
      subleaseParkingPlaces,
      confirmationAction
    } = this.state;
    const newUnit = {
      "office": officeId,
      "area": area,
      "entrance": entrance,
      "floor": floor,
      "availableFrom": availableFrom ? new Date(availableFrom).toISOString() : null,
      "status": status,
      "retail": unitType === 2,
      "unitType": unitType !== 0 ? unitType : null,
      "canBeDivided": canBeDivided,
      "isSublease": isSublease,
      subleaseContactName: subleaseContactName,
      subleaseContactPhone: subleaseContactPhone,
      subleaseContactEmail: subleaseContactEmail,
      subleaseRent: subleaseRent || null,
      subleaseRentToNegotiate: subleaseRentToNegotiate,
      subleaseAvailableFrom: subleaseAvailableFrom,
      subleaseAvailableTo: subleaseAvailableTo,
      subleaseParkingPlaces: subleaseParkingPlaces || null
    };
    const isNotExisting = officeStatus !== BUILDING_STATUS.STATUS_EXISTS;
    const unitDate = availableFrom ? new Date(availableFrom) : new Date();
    const todaysDate = new Date();

    if(!confirmationAction && Number(floor) > 40) {
      this.setState({
        confirmationOpen: true
      });
    }
    else if(Number(area) < 5) {
      this.setState({
        alertFormOpen: true
      });
    }
    else if(isNotExisting && unitDate.setHours(0,0,0,0) <= todaysDate.setHours(0,0,0,0)) {
      this.setState({
        alertFormDateOpen: true
      });
    }
    else if(floor === "" || status === "" || unitType === "") {
      this.setState({
        alertRequiredFields: true
      });
    }
    else {
      addOfficeUnit(newUnit, ordering);
    }
  };

  handleConfirmHighFloor = () => {
    this.setState({
      confirmationAction: true,
      confirmationOpen: false
    }, () => this.handleSave());
  };

  render() {
    const {lackOfAvailableArea, tooltipDisabled, btnTrigger, addingUnit} = this.props;
    const {
      area,
      entrance,
      floor,
      availableFrom,
      canBeDivided,
      isSublease,
      subleaseContactName,
      subleaseContactPhone,
      subleaseContactEmail,
      subleaseRent,
      subleaseRentToNegotiate,
      subleaseAvailableFrom,
      subleaseAvailableTo,
      subleaseParkingPlaces
    } = this.state;

    if(lackOfAvailableArea) {
      if(tooltipDisabled) {
        return <span><ButtonCustom label={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>} color="default" icon="add" inactive/></span>;
      }
      return(
        <TooltipCustom title={<FormattedMessage id="BUILDING.PHRASE.LACK_OF_AVAILABLE_AREA_MESSAGE"/>}>
          <span>{btnTrigger ? btnTrigger : <ButtonCustom label={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>} color="warning" icon="add"/>}</span>
        </TooltipCustom>
      )
    }
    return(
      <>
      <ModalCustom
        ref={this.child}
        btn={
        btnTrigger ? btnTrigger :
          <ButtonCustom label={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>} color="success" icon="add"/>
        }
        btnConfirm={addingUnit ? <LoadingScreen loaderStyle={{width: "1.9rem", height: "1.9rem"}}/> : <FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        btnConfirmDisabled={addingUnit}
        title={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>}
        handleSave={this.handleSave}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputAdornments
              position="end"
              adornmentLabel={[areaFormat()]}
              label={<FormattedMessage id="GENERAL.PHRASE.AREA"/>}
              name="area"
              value={area}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <InputAdornments label={<FormattedMessage id="GENERAL.PHRASE.ENTRANCE"/>} name="entrance" value={entrance} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <InputAdornments label={<FormattedMessage id="GENERAL.PHRASE.FLOOR"/>} name="floor" value={floor} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <InputAdornments type="date" label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>} name="availableFrom" value={availableFrom} onChange={this.handleChangeDate}/>
          </Grid>
          <Grid item>
            <SingleSelect label={<FormattedMessage id="BUILDING.PHRASE.STATUS"/>} inputName="status" options={FILTERS_SELECTS.UNITS_STATUSES} optionsValue="id" optionsLabel="label" defaultSelect={UNIT_STATUS.AVAILABLE} onSelectedValues={this.handleSelectedStatus}/>
          </Grid>
          <Grid item>
            <SingleSelect label={<FormattedMessage id="GENERAL.PHRASE.TYPE"/>} inputName="unitType" options={FILTERS_SELECTS.UNITS_TYPES} optionsValue="id" optionsLabel="label" defaultSelect={UNIT_TYPES.OFFICE} onSelectedValues={this.handleSelectedType} required/>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item><Typography><FormattedMessage id="UNITS.PHRASE.IS_FLEXIBLE"/></Typography></Grid>
              <Grid item>
                <InputSwitcher checked={canBeDivided} value="canBeDivided" label={<FormattedMessage id="GENERAL.PHRASE.YES"/>} onChange={this.handleChangeSwitcher("canBeDivided")}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item><Typography><FormattedMessage id="UNITS.PHRASE.IS_SUBLEASE"/></Typography></Grid>
              <Grid item>
                <InputSwitcher checked={isSublease} value="isSublease" label={<FormattedMessage id="GENERAL.PHRASE.YES"/>} onChange={this.handleChangeSwitcher("isSublease")}/>
              </Grid>
            </Grid>
          </Grid>
          {isSublease &&
          <Grid item>
            <Grid container spacing={2} direction="column" style={{backgroundColor: "rgba(255,255,255,0.05)"}}>
              <Grid item>
                <Typography variant="h4" style={{color: "#ffffff"}}><FormattedMessage id="GENERAL.PHRASE.CONTACT_PERSON"/></Typography>
              </Grid>
              <Grid item>
                <InputAdornments label={<FormattedMessage id="FORM.FIELD.FULLNAME"/>} name="subleaseContactName" value={subleaseContactName} onChange={this.handleChange}/>
              </Grid>
              <Grid item>
                <InputAdornments label={<FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS"/>} name="subleaseContactEmail" value={subleaseContactEmail} onChange={this.handleChange}/>
              </Grid>
              <Grid item>
                <InputAdornments label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER"/>} name="subleaseContactPhone" value={subleaseContactPhone} onChange={this.handleChange}/>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{color: "#ffffff", marginTop: 25}}><FormattedMessage id="BUILDING.PHRASE.LEASE_TERMS"/></Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item><Typography><FormattedMessage id="UNITS.PHRASE.IS_RENT_TO_NEGOTIATE"/></Typography></Grid>
                  <Grid item>
                    <InputSwitcher checked={subleaseRentToNegotiate} value="subleaseRentToNegotiate" label={<FormattedMessage id="GENERAL.PHRASE.YES"/>} onChange={this.handleChangeSwitcher("subleaseRentToNegotiate")}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM"/>} name="subleaseRent" value={subleaseRent} onChange={this.handleChange} disabled={subleaseRentToNegotiate}/>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputAdornments type="date" label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>} name="subleaseAvailableFrom" value={subleaseAvailableFrom} onChange={this.handleChangeDate}/>
                  </Grid>
                  <Grid item xs={6}>
                    <InputAdornments type="date" label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_TO"/>} name="subleaseAvailableTo" value={subleaseAvailableTo} onChange={this.handleChangeDate}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.PARKING_PLACES"/>} name="subleaseParkingPlaces" value={subleaseParkingPlaces} onChange={this.handleChange}/>
              </Grid>
            </Grid>
          </Grid>
          }
        </Grid>
      </ModalCustom>
      <ConfirmationDialog
          open={this.state.confirmationOpen}
          onConfirm={this.handleConfirmHighFloor}
          message={<FormattedMessage id="GENERAL.PHRASE.UNIT_FLOOR_CONFIRMATION" values={{floor: floor}}/>}
      />
      <AlertFormDialog
          open={this.state.alertFormOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_MIN_AREA"/>}
      />
      <AlertFormDialog
          open={this.state.alertFormDateOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_UNIT_AVAILABILITY_DATE"/>}
      />
        <AlertFormDialog
          open={this.state.alertRequiredFields}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_ADD_UNIT_REQUIRED_FIELDS"/>}
      />
      </>
    );
  }
}

const mapStateToProps = ({ user, offices, units }) => ({
  user: user.data,
  office: offices.office,
  addingUnit: units?.addingUnit,
  unitAdded: units?.unitAdded
});
const mapDispatchToProps = {
  addOfficeUnit: (unit, ordering) => addOfficeUnit(unit, ordering)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeAddUnit)
);