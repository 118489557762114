import React from "react";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {updateIndustrialFile} from "app/crud/estates/industrial/industrial.crud";

class IndustrialToggleFileGalleryStatus extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            open: false,
            isGalleryIncluded: props.isGalleryIncluded
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isGalleryIncluded: nextProps.isGalleryIncluded
        });
    }

    handleCloseModal = () => {
        this.child.current.handleClose();
    };
    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    handleSave = () => {
        const {updateIndustrialFile, fileID} = this.props;
        const {isGalleryIncluded} = this.state;
        this.setState({
            isGalleryIncluded: !isGalleryIncluded
        }, () => updateIndustrialFile(fileID, {isGalleryIncluded: this.state.isGalleryIncluded}));
    };

    render() {
        const {fileAssignedBuilding} = this.props;
        const {isGalleryIncluded} = this.state;

        return (
            <ModalTrigger
                icon="collections"
                label={
                    isGalleryIncluded ?
                        <FormattedMessage
                            id={`INDUSTRIAL.PHRASE.${fileAssignedBuilding ? 'FILE_IS_INCLUDED_IN_WAREHOUSE_GALLERY' : 'FILE_IS_INCLUDED_IN_PARK_GALLERY'}`}
                        /> :
                        <FormattedMessage
                            id={`INDUSTRIAL.PHRASE.${fileAssignedBuilding ? 'INCLUDE_FILE_IN_WAREHOUSE_GALLERY' : 'INCLUDE_FILE_IN_PARK_GALLERY'}`}
                        />
                }
                directIcon
                color={isGalleryIncluded ? "success" : "neutral"}
                onClick={this.handleSave}
                tooltipPosition="left"
            />
        );
    }
}

const mapDispatchToProps = {
    updateIndustrialFile: (fileID, data) => updateIndustrialFile(fileID, data)
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(IndustrialToggleFileGalleryStatus)
);