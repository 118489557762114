import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {unfollowOffices} from "app/crud/followers.crud";

class OfficeRemoveAllFromWatchlist extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {userWatchlist, deleteAllWatchlistItems} = this.props;
    deleteAllWatchlistItems({offices: userWatchlist});
    this.handleCloseModal();
  };

  render() {
    return(
      <ModalCustom
        ref={this.child}
        btn={<ButtonCustom label={<FormattedMessage id="GENERAL.PHRASE.REMOVE_ALL"/>} color="danger"/>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.REMOVE_FROM_OBSERVED"/>}
        handleSave={this.handleSave}
      >
        <Typography><FormattedMessage id="BUILDING.PHRASE.REMOVE_ALL_FROM_OBSERVED_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
    userWatchlist: state.user.data.watchList,
});
const mapDispatchToProps = dispatch => ({
  deleteAllWatchlistItems: data => dispatch(unfollowOffices({data}))
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeRemoveAllFromWatchlist)
);