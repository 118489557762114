import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";

const initialState = {
  basic: null,
  basicLoading: false,
  buildings: null,
  buildingsLoading: false,
  leaseTerms: null,
  leaseTermsLoading: false,
  marketplaceStats: null,
  marketplaceStatsLoading: false,
  changes: null,
  changesLoading: false,
  files: null,
  filesLoading: false,
  similarProperties: null,
  similarPropertiesLoading: false,
  owner: null,
  ownerLoading: false,
  leasingContacts: null,
  leasingContactsLoading: false,
  editFormsData: null,
  editFormsDataLoading: false,
  resetting: false
};

export const reducer = persistReducer(
  { storage, key: "industrialPark" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET INDUSTRIAL PARK LOADING STATES
      case 'RESET_INDUSTRIAL_PARK_LOADING_STATES_REQUEST': {
        return {
          ...state,
          basicLoading: false,
          buildingsLoading: false,
          leaseTermsLoading: false,
          marketplaceStatsLoading: false,
          changesLoading: false,
          filesLoading: false,
          similarPropertiesLoading: false,
          ownerLoading: false,
          leasingContactsLoading: false,
          editFormsDataLoading: false
        }
      }
      // BASIC
      case 'FETCH_INDUSTRIAL_PARK_BASIC_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          basic: backgroundLoading ? state.basic : null,
          basicLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_BASIC_SUCCESS': {
        return { ...state, basic: action.payload, basicLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_BASIC_FAILED': {
        return { ...state, basic: null, basicLoading: false }
      }
      // BUILDINGS
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          buildingsLoading: !backgroundLoading,
          buildings: backgroundLoading ? state.buildings : null
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_SUCCESS': {
        return { ...state, buildingsLoading: false, buildings: action.payload.warehouses }
      }
      case 'FETCH_INDUSTRIAL_PARK_BUILDINGS_FAILED': {
        return { ...state, buildingsLoading: false, buildings: null }
      }
      // LEASE TERMS
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          leaseTerms: backgroundLoading ? state.leaseTerms : null,
          leaseTermsLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_SUCCESS': {
        return { ...state, leaseTerms: action.payload, leaseTermsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASE_TERMS_FAILED': {
        return { ...state, leaseTerms: null, leaseTermsLoading: false }
      }
      // MARKETPLACE STATS
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_REQUEST': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_SUCCESS': {
        return { ...state, marketplaceStats: action.payload, marketplaceStatsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_FAILED': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: false }
      }
      // CHANGES
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST': {
        return { ...state, changes: null, changesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_SUCCESS': {
        return { ...state, changes: action.payload?.changes, changesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_CHANGES_FAILED': {
        return { ...state, changes: null, changesLoading: false }
      }
      // FILES
      case 'FETCH_INDUSTRIAL_PARK_FILES_REQUEST': {
        return { ...state, files: null, filesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_FILES_SUCCESS': {
        return { ...state, files: action.payload, filesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_FILES_FAILED': {
        return { ...state, files: null, filesLoading: false }
      }
      // SIMILAR PROPERTIES
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_REQUEST': {
        return { ...state, similarProperties: null, similarPropertiesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_SUCCESS': {
        return { ...state, similarProperties: action.payload, similarPropertiesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_FAILED': {
        return { ...state, similarProperties: null, similarPropertiesLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_PARK_OWNER_REQUEST': {
        return { ...state, owner: null, ownerLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_OWNER_SUCCESS': {
        return { ...state, owner: action.payload, ownerLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_OWNER_FAILED': {
        return { ...state, owner: null, ownerLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_REQUEST': {
        return { ...state, leasingContacts: null, leasingContactsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_SUCCESS': {
        return { ...state, leasingContacts: action.payload, leasingContactsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_FAILED': {
        return { ...state, leasingContacts: null, leasingContactsLoading: false }
      }
      // EDIT FORMS DATA
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST': {
        return { ...state, editFormsData: null, editFormsDataLoading: true }
      }
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_SUCCESS': {
        return { ...state, editFormsData: action.payload, editFormsDataLoading: false }
      }
      case 'FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_FAILED': {
        return { ...state, editFormsData: null, editFormsDataLoading: false }
      }

      // AUTO ACTION
      case 'RESET_INDUSTRIAL_PARK_STATE_REQUEST': {
        return {
          ...state,
          resetting: true
        }
      }
      case 'RESET_INDUSTRIAL_PARK_STATE_SUCCESS': {
        return {
          ...initialState,
          resetting: false
        }
      }
      case 'RESET_INDUSTRIAL_PARK_STATE_FAILED': {
        return {
          ...initialState,
          resetting: false
        }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchIndustrialParkBasic(action) {
  const {parkID} = action.payload;
  try {
    const officeBasic = yield axios.get(`/industrial-parks/${parkID}/basic/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BASIC_SUCCESS", payload: officeBasic.data || [{ error: officeBasic.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BASIC_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkBuildings(action) {
  const {parkID, ordering} = action.payload;
  try {
    const setOrdering = [
      ...ordering ? [`?ordering=${ordering}`] : []
    ].join("");
    const buildings = yield axios.get(`/industrial-parks/${parkID}/basic-buildings/${setOrdering}`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BUILDINGS_SUCCESS", payload: buildings.data || [{ error: buildings.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_BUILDINGS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkLeaseTerms(action) {
  const {parkID} = action.payload;
  try {
    const leaseTerms = yield axios.get(`/industrial-parks/${parkID}/basic-rent/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASE_TERMS_SUCCESS", payload: leaseTerms.data || [{ error: leaseTerms.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASE_TERMS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkMarketplaceStats(action) {
  const {parkID} = action.payload;
  try {
    const stats = yield axios.get(`/industrial-parks/${parkID}/basic-marketplace/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkChanges(action) {
  const {parkID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-parks/${parkID}/basic-changes/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkFiles(action) {
  const {parkID} = action.payload;
  try {
    const files = yield axios.get(`/industrial-parks/${parkID}/basic-files/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_FILES_SUCCESS", payload: files.data || [{ error: files.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkSimilarProperties(action) {
  const {parkID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-parks/${parkID}/basic-similar/?size=6`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkOwner(action) {
  const {parkID} = action.payload;
  try {
    const owner = yield axios.get(`/industrial-parks/${parkID}/basic-owner/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_OWNER_SUCCESS", payload: owner?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_OWNER_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkLeasingContacts(action) {
  const {parkID} = action.payload;
  try {
    const leasingContact = yield axios.get(`/industrial-parks/${parkID}/basic-landlord/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_SUCCESS", payload: leasingContact?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialParkEditFormsData(action) {
  const {parkID} = action.payload;
  try {
    const editFormsData = yield axios.get(`/industrial-parks/${parkID}/basic-form/`);
    yield put({ type: "FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_SUCCESS", payload: editFormsData.data || [{ error: editFormsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
function* resetIndustrialParkState() {
  try {
    yield put({ type: "RESET_INDUSTRIAL_PARK_STATE_SUCCESS" });
  }
  catch(err) {
    yield put({ type: "RESET_INDUSTRIAL_PARK_STATE_FAILED" });
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_INDUSTRIAL_PARK_BASIC_REQUEST', fetchIndustrialParkBasic);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_BUILDINGS_REQUEST', fetchIndustrialParkBuildings);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_LEASE_TERMS_REQUEST', fetchIndustrialParkLeaseTerms);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_MARKETPLACE_STATS_REQUEST', fetchIndustrialParkMarketplaceStats);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_CHANGES_REQUEST', fetchIndustrialParkChanges);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_FILES_REQUEST', fetchIndustrialParkFiles);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_SIMILAR_PROPERTIES_REQUEST', fetchIndustrialParkSimilarProperties);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_OWNER_REQUEST', fetchIndustrialParkOwner);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_LEASING_CONTACTS_REQUEST', fetchIndustrialParkLeasingContacts);
  yield takeLatest('FETCH_INDUSTRIAL_PARK_EDIT_FORMS_DATA_REQUEST', fetchIndustrialParkEditFormsData);
  yield takeLatest('RESET_INDUSTRIAL_PARK_STATE_REQUEST', resetIndustrialParkState);
}
