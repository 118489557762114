import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Menu, MenuItem} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {PDFViewer} from "@react-pdf/renderer";
import OwnerFinancialPdfDocument from "app/pages/offices/OwnerPage/components/OwnerFinancialPdfDocument";
import ButtonCustom from "app/partials/components/ButtonCustom";
import styles from "./index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {fetchOwnerPortfolio} from "../../../../crud/owners.crud";
import {fetchOfficeFinances} from "../../../../crud/estates/offices/offices.crud";
import {
  fetchIndustrialWarehouseFinances,
  fetchIndustrialWarehousePortfolio
} from "app/crud/estates/industrial/industrial.crud";

class OwnerFinancialGeneratePDF extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            anchorEl: null,
            isIndustrialWarehouseView: props.view === 'industrialWarehouse'
        };
        if (props.ownerId) {
            if(this.state.isIndustrialWarehouseView) {
                props.fetchIndustrialWarehousePortfolio(props.ownerId);
            }
            else {
                props.fetchOwnerPortfolio(props.ownerId);
            }
        }
    }

    handleCloseModal = () => {
        this.child.current.handleClose();
    };
    handleOpen = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    render() {
        const {
            directBtn,
            directIcon,
            user,
            inactive,
            officeFinances,
            ownerPortfolio,
            warehouseFinances,
            warehouseOwnersPortfolio,
            directGenerate,
            fetchOfficeFinances,
            fetchIndustrialWarehouseFinances,
            officeId
        } = this.props;
        const {anchorEl, isIndustrialWarehouseView} = this.state;
        const dataFinances = isIndustrialWarehouseView ? warehouseFinances : officeFinances;
        const dataPortfolio = isIndustrialWarehouseView ? warehouseOwnersPortfolio : ownerPortfolio;

        if (inactive) {
            return (
                <ModalTrigger
                    label={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                    directBtn={directBtn}
                    directIcon={directIcon}
                    inactive
                />
            );
        } else if (directGenerate) {
            return (
                <div>
                    {directGenerate && directGenerate.length && directGenerate.map((year, index) => {
                        return (
                            <ModalCustom
                                ref={this.child}
                                btn={
                                    <ModalTrigger
                                        label={year}
                                        color="success"
                                        directBtn
                                        solid
                                        onClick={() => isIndustrialWarehouseView ? fetchIndustrialWarehouseFinances(officeId) : fetchOfficeFinances(officeId)}
                                        style={{fontSize: "150%", lineHeight: "25px"}}
                                    />
                                }
                                title={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                                maxWidth="lg"
                            >
                                {dataFinances ? (
                                    <div>
                                        <LoadingScreen absolute/>
                                        <PDFViewer
                                            style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                                            <OwnerFinancialPdfDocument
                                                user={user}
                                                intl={this.props.intl}
                                                officeFinances={dataFinances && dataFinances[index]}
                                                ownerPortfolio={dataPortfolio}
                                            />
                                        </PDFViewer>
                                    </div>
                                ) : <LoadingScreen/>}
                            </ModalCustom>
                        )
                    })}
                </div>
            );
        }
        return (
            <div>
                <ButtonCustom
                    aria-controls="generatePdf"
                    aria-haspopup="true"
                    label={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                    color="success"
                    onClick={this.handleOpen}
                />
                <Menu
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    id="generatePdf"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    className={styles.customPopoverContainer}
                >
                    {dataFinances && dataFinances.map((item, index) => (
                        <MenuItem key={index} onClick={this.handleClose}>
                            <ModalCustom
                                ref={this.child}
                                btn={
                                    <ModalTrigger
                                        color="success"
                                        label={item.year}
                                    />
                                }
                                title={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                                maxWidth="lg"
                            >
                                {dataFinances ? (
                                    <div>
                                        <LoadingScreen absolute/>
                                        <PDFViewer
                                            style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                                            <OwnerFinancialPdfDocument
                                                user={user}
                                                intl={this.props.intl}
                                                officeFinances={dataFinances && dataFinances[index]}
                                                ownerPortfolio={dataPortfolio}
                                            />
                                        </PDFViewer>
                                    </div>
                                ) : <LoadingScreen/>}
                            </ModalCustom>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = (store) => ({
    user: store.user.data,
    officeFinances: store.offices.officeFinances,
    ownerPortfolio: store.owners.ownerPortfolio,
    warehouseFinances: store.industrial.warehouseFinances,
    warehouseOwnerPortfolio: store.industrial.warehouseOwnerPortfolio
});
const mapDispatchToProps = {
    fetchOwnerPortfolio: (ownerId) => fetchOwnerPortfolio(ownerId),
    fetchOfficeFinances: (officeId) => fetchOfficeFinances(officeId),
    fetchIndustrialWarehousePortfolio: (ownerID) => fetchIndustrialWarehousePortfolio(ownerID),
    fetchIndustrialWarehouseFinances: (warehouseID) => fetchIndustrialWarehouseFinances(warehouseID)
};
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OwnerFinancialGeneratePDF)
);