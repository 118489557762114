import React, {useState} from "react";
import styles from "./index.module.scss";
import ButtonCustom from "../ButtonCustom";
import IconColorMUI from "../IconColorMUI";
import {ReactComponent as IconSend} from "app/media/icons/icon-send.svg";
import {ReactComponent as IconEye} from "app/media/icons/icon-eye.svg";
import {ReactComponent as IconVisit} from "app/media/icons/icon-visit.svg";
import {ROUTES} from "../../../constants";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../LoadingScreen";
import {FormattedMessage, injectIntl} from "react-intl";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import PropertySectionTitle from "../../../pages/common/components/PropertyPage/PropertySectionTitle";

function FinneLeadsPreviewBar({
  currentApplicationView,
  fetchOfficeMarketplaceStats,
  fetchIndustrialParkMarketplaceStats,
  marketplaceStats,
  marketplaceStatsLoading,
  match,
  callReddClickEvent,
  ...props
}) {
  const [initLoaded, setInitLoaded] = useState(false);
  const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
  const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

  // Handle fetch data on enter viewport
  const handleOnViewportFetch = (isVisible) => {
    const {id} = match?.params;
    // Fetch data
    if (isVisible && !marketplaceStatsLoading && !initLoaded && id) {
      setInitLoaded(true);
      // Fetch Offices Stats
      if(isOfficeView && fetchOfficeMarketplaceStats && id) {
        fetchOfficeMarketplaceStats(id);
      }
      // Fetch Industrial Stats
      else if(isIndustrialView && fetchIndustrialParkMarketplaceStats && id) {
        fetchIndustrialParkMarketplaceStats(id);
      }
    }
  };
  const handleLoadingValue = (value) => {
    if (marketplaceStatsLoading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }
  const handleOnRedirect = () => {
    const propertyID = props?.basic?.id;

    if (propertyID && callReddClickEvent) {
      if(isOfficeView) {
        callReddClickEvent(
          "office-building-page-finne-view-more-click",
          "office",
          null,
          propertyID
        );
      }
      else if(isIndustrialView) {
        callReddClickEvent(
          "industrial-park-page-finne-view-more-click",
          "park",
          null,
          propertyID
        );
      }
    }
  }

  return (
    <ReactVisibilitySensor
      onChange={(isVisible) => handleOnViewportFetch(isVisible)}
      scrollCheck
      partialVisibility
    >
      <>
      <PropertySectionTitle title={<span>Statystyki <span className={styles.finneTitle}>finne</span></span>}/>
      <div className={styles.previewBarWrapper}>
        <div className={styles.previewWrapper}>
          <div className={styles.previewDataWrapper}>
            <div className={styles.previewItem}>
              <p className={styles.label}><FormattedMessage id="VOCABULARY.finne Introductions"/></p>
              <p className={styles.value}>{handleLoadingValue(<><span><IconSend/></span><FormattedMessage
                id="VOCABULARY.tenants have sent inquiries"
                values={{count: marketplaceStats?.leadsMarketplaceCount || 0}}/></>)}</p>
            </div>
            <div className={styles.previewItem}>
              <p className={styles.label}><FormattedMessage id="VOCABULARY.Pageviews on finne"/></p>
              <p className={styles.value}>{handleLoadingValue(<><span><IconEye/></span><FormattedMessage
                id="VOCABULARY.people viewed" values={{count: marketplaceStats?.viewsMarketplaceCount || 0}}/></>)}</p>
            </div>
            <div className={styles.previewItem}>
              <p className={styles.label}><FormattedMessage id="VOCABULARY.Online interest"/></p>
              <p className={styles.value}>{handleLoadingValue(<><span><IconVisit/></span><FormattedMessage
                id="VOCABULARY.visiting companies" values={{count: marketplaceStats?.companiesCount || 0}}/></>)}</p>
            </div>
          </div>
          <div>
            <ButtonCustom
              label={<FormattedMessage id="VOCABULARY.Check leads"/>}
              color="finneBrand"
              solid
              redirect={ROUTES.LEADS_TENANT_INTRODUCTIONS}
              onClick={() => handleOnRedirect()}
            />
          </div>
        </div>
        <div className={styles.previewInfoWrapper}>
          <p>
            <IconColorMUI icon="info_outlined" style={{marginRight: "0.5rem", lineHeight: 0}} size="1.2rem"/>
            <span><FormattedMessage id="Data since"/> <strong>01.2023</strong></span>
          </p>
          <p>
            <IconColorMUI icon="info_outlined" style={{marginRight: "0.5rem", lineHeight: 0}} size="1.2rem"/>
            {isOfficeView && <FormattedMessage id="VOCABULARY.Visible only to the landlord of this building"/>}
            {isIndustrialView && <FormattedMessage id="VOCABULARY.Visible only to the landlord of this property"/>}
          </p>
        </div>
      </div>
      </>
    </ReactVisibilitySensor>
  );
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(FinneLeadsPreviewBar)
);