import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchOffersIndustrialAnalytics,
  fetchOffersIndustrialAnalyticsCharts,
  fetchOffersIndustrialOfficeAnalytics
} from "app/crud/estates/industrial/offers-industrial.crud";

import OffersOnlineAnalysisPage from "./OffersOnlineAnalysisPage";

const mapStateToProps = (state) => ({
  offersAnalytics: state.offersIndustrial.offersOnlineAnalytics,
  offersCharts: state.offersIndustrial.offersOnlineCharts,
  offerDetailsAnalytics: state.offersIndustrial.offersOnlineDetailsAnalytics,
  loadingOffersAnalytics: state.offersIndustrial.loadingOffersOnlineAnalytics,
  loadingOffersChars: state.offersIndustrial.loadingOffersOnlineCharts,
  loadingOfferDetailsAnalytics: state.offersIndustrial.loadingOffersOnlineDetailsAnalytics
});
const mapDispatchToProps = {
  fetchOffersAnalytics: () => fetchOffersIndustrialAnalytics(),
  fetchOffersAnalyticsCharts: (year) => fetchOffersIndustrialAnalyticsCharts(year),
  fetchOffersOfficeAnalytics: (offerID) => fetchOffersIndustrialOfficeAnalytics(offerID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffersOnlineAnalysisPage)
);
