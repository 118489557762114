import React from "react";
import styles from "../index.module.scss";
import cn from "classnames";
import PaywallBannerDefault from "../banners/PaywallBannerDefault";
import PaywallDirectContact from "../components/PaywallDirectContact";
import ModalCustom from "../../ModalCustom";
import ButtonCustom from "../../ButtonCustom";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconLock} from "app/media/icons/icon-lock.svg";
import {callReddClickEvent} from "../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";

const PaywallModal = (
  {
    triggerLabel,
    triggerBtn,
    triggerSlim,
    bannerVariant = "default",
    triggerBannerVariant,
    triggerButtonVariant,
    btnStyle,
    withIcon,
    user,
    callReddClickEvent
  }
) => {
  const handleOnOpen = () => {
    const eventParams = {
      click_page: window.location.href,
      trigger_banner_variant: triggerBannerVariant || bannerVariant,
      trigger_button: triggerButtonVariant || (typeof triggerLabel === "string" ? triggerLabel : null)
    };

    if(user?.pk) {
      callReddClickEvent(
        "paywall-banner-initial-click",
        "user",
        null,
        user?.pk,
        {...eventParams},
        true
      );
    }
  }
  return(
    <ModalCustom
      btn={
      triggerBtn ? triggerBtn :
        <ButtonCustom
          label={
          triggerLabel ? triggerLabel :
          <FormattedMessage id="VOCABULARY.Find out more"/>
          }
          iconCustom={
            withIcon && <IconLock width={triggerSlim ? "1.3rem" : "1.6rem"} height={triggerSlim ? "1.3rem" : "1.6rem"}/>
          }
          color="danger"
          solid
          slim={triggerSlim}
          style={btnStyle}
          btnStyle={btnStyle}
        />
      }
      defaultThemeProvider
      bgColor="#ffffff"
      btnCloseAlt
      onOpen={handleOnOpen}
    >
      <div className={cn(styles.paywallContainer, styles.paywallModalContainer)}>
        <div className={styles.paywallWrapper}>
          <PaywallBannerDefault bannerVariant={bannerVariant}/>
          <PaywallDirectContact/>
        </div>
      </div>
    </ModalCustom>
  );
};

const mapStateToProps = store => ({
  user: store?.user?.data
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaywallModal);