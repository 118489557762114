import React from "react";
import {FormattedMessage} from "react-intl";
import {TextField, Typography} from "@material-ui/core";
import ModalCustom from "../../../../partials/components/ModalCustom";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

export default class CAFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      feedbackMessage: '',
      feedbackSent: false
    }
  }
  handleChange = (event) => {
    this.setState({
      feedbackMessage: event.target.value
    });
  };
  handleSend = () => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const {feedbackMessage} = this.state;

    if(feedbackMessage !== '') {
      this.setState({
        feedbackSent: true
      }, () => {
        callReddClickEvent(
          currentApplicationView === APPLICATION_VIEWS.OFFICES ?
            "competition-analysis-offices-feedback-message" :
            "competition-analysis-industrial-feedback-message",
          "user",
          null,
          null,
          {message: feedbackMessage}
        );
      });
    }
  }

  render() {
    const {feedbackMessage, feedbackSent} = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ButtonCustom
            label={<FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SHARE_YOUR_OPINION"/>}
            color="success"
            solid
          />
        }
        defaultThemeProvider
        maxWidth="xs"
        title={<span style={{display: "block", textAlign: "center"}}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.FEEDBACK_TITLE"/></span>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SEND"/>}
        handleSave={this.handleSend}
        btnConfirmDisabled={feedbackSent}
      >
        {feedbackSent ?
          <Typography variant="h4" style={{color: "#ff4626"}}>
            <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.FEEDBACK_SENT_MESSAGE"/>
          </Typography> :
        <>
          <Typography><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.FEEDBACK_DESCRIPTION"/></Typography>
          <TextField
            label={<FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.FEEDBACK_MESSAGE_PLACEHOLDER"/>}
            name="feedbackMessage"
            value={feedbackMessage}
            style={{width: "100%", marginTop: "2rem"}}
            onChange={this.handleChange}
            multiline
            minRows={6}
          />
        </>
        }
      </ModalCustom>
    );
  }
}