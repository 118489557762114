import React from "react";
import {withRouter} from "react-router";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import IsPro from "../../../utils/IsPro";
import store from "../../../store/store";

let prevLocation; // Hold previous route 'pathname'
class AnalyticsPage extends React.Component {
  constructor(props) {
    super(props);
    // Set previous route 'pathname'
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    // Get current page id/pathname
    const pageId = props.match.params.id;
    // Define current active Market Insights page
    this.state = {
      isAnalytics1: pageId === '1',
      isAnalytics2: pageId === '2',
      isAnalytics3: pageId === '3',
      isAnalytics4: pageId === '4',
      isReady: false,
      initLoaded: false
    }
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(["market"]);
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {premiumLoading} = this.props;
    const {isAnalytics1, isAnalytics2, isAnalytics3, isAnalytics4, isReady, initLoaded} = this.state;
    // Initial fetch params
    let params = {
      page: 1,
      ...store.getState().filters,
      orderBy: isAnalytics1 ? 'recent_freed_modules_no' : isAnalytics2 ? 'recent_rent_change' : isAnalytics3 ? 'recent_rented_modules_no' : isAnalytics4 ? 'last_transaction_date' : '',
      order: isAnalytics2 ? '' : '-'
    };
    // Check if Acquisitions data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');

    // Fetch data
    if(isVisible && !premiumLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        if(updateData) {
          // Fetch data based on current active page
          if(isAnalytics1) {
            // Market Insights Latest Releases
            this.props.fetchOfficePremiumListLatestReleases({params});
          } else if(isAnalytics2) {
            // Market Insights Latest Rent Changed
            this.props.fetchOfficePremiumListLatestRentChanged({params});
          } else if(isAnalytics3) {
            // Market Insights Latest Rented
            this.props.fetchOfficePremiumListLatestRented({params});
          } else if(isAnalytics4) {
            // Market Insights Latest Sold Offices
            this.props.fetchOfficePremiumListLatestSoldOffices({params});
          }
        }
      });
    }
    // Tracking event
    if(isVisible && !isReady) {
      this.setState({
        isReady: true
      }, () => {
        isAnalytics1 && this.props.dispatch(callReddClickEvent(
          "market-insights-office-supply-boost-click",
          "market"
        ));
        isAnalytics2 && this.props.dispatch(callReddClickEvent(
          "market-insights-office-rent-reductions-click",
          "market"
        ));
        isAnalytics3 && this.props.dispatch(callReddClickEvent(
          "market-insights-office-commercialized-area-click",
          "market"
        ));
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {isAnalytics1, isAnalytics2, isAnalytics3, isAnalytics4} = this.state;
    const {officesPremium, items, premiumLoading} = this.props;

    return (
      <IsPro paywall>
        <TableProperties
          headRows={
            [
              { id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false },
              ...isAnalytics1 ? [{ id: 'recent_freed_modules_no', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.NO_OF_LATEST_RELEASES', sort: false }] : [],
              ...isAnalytics2 ? [{ id: 'recent_rent_change', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_REDUCTION', sort: true }] : [],
              ...isAnalytics3 ? [{ id: 'recent_rented_modules_no', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.NO_OF_RENTED_UNITS', sort: false }] : [],
              ...isAnalytics4 ? [{ id: 'last_transaction_date', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.LAST_TRANSACTION_DATA', sort: false }] : [],
              { id: 'number_of_units_available', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.AVAILABLE_UNITS', sort: false },
              { id: 'total_available_area', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.AVAILABLE_AREA', sort: false },
              { id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false },
              { id: 'service_charge', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.SERVICE_CHARGE', sort: false }
            ]
          }
          bodyRows={items}
          tableLabel={
            <FormattedMessage
              id={
                isAnalytics1 ? "MENU.THE_LARGEST_SURFACE_RELEASES" :
                  isAnalytics2 ? "MENU.RENT_REDUCTIONS" :
                    isAnalytics3 ? "MENU.MOST_RENTED_BUILDINGS" :
                      isAnalytics4 ? "MENU.RECENTLY_SOLD_BUILDINGS" :
                        "MENU.ANALYTICS"
              }
            />
          }
          dataSource="office"
          dataTarget={
            isAnalytics1 ? TABLE_CELLS.OFFICES_PREMIUM_LATEST_RELEASES :
              isAnalytics2 ? TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENT_CHANGED :
                isAnalytics3 ? TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENTED :
                  isAnalytics4 ? TABLE_CELLS.OFFICES_PREMIUM_LATEST_SOLD_OFFICES : ''
          }
          itemsPerPage={officesPremium.itemsPerPage}
          itemsCount={officesPremium.itemsCount}
          handleFetchData={
            isAnalytics1 ? this.props.fetchOfficePremiumListLatestReleases :
              isAnalytics2 ? this.props.fetchOfficePremiumListLatestRentChanged :
                isAnalytics3 ? this.props.fetchOfficePremiumListLatestRented :
                  isAnalytics4 ? this.props.fetchOfficePremiumListLatestSoldOffices : ''
          }
          loading={premiumLoading}
          filterBarEnable
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    )
  }
}

export default injectIntl(connect()(withRouter(AnalyticsPage)));