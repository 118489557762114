import React from "react";
import {BUILDING_STATUS} from "../../../constants";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";

export default class PropertyStatusPreview extends React.Component {
  render() {
    const {status, textOnly} = this.props;
    let color = "success";
    let tooltip = "GENERAL.PHRASE.EXISTING";

    if (status === BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION) {
      color = "warning";
      tooltip = "GENERAL.PHRASE.UNDER_CONSTRUCTION";
    }
    else if (status === BUILDING_STATUS.STATUS_PLANNED) {
      color = "neutral";
      tooltip = "GENERAL.PHRASE.PLANNED";
    }
    else if (status === BUILDING_STATUS.STATUS_UNDER_REVITALIZATION) {
      color = "gray";
      tooltip = "GENERAL.PHRASE.UNDER_REVITALIZATION";
    }
    else if (status === BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT) {
      color = "default";
      tooltip = "GENERAL.PHRASE.UNDER_REDEVELOPMENT";
    }

    if(textOnly) {
      return <FormattedMessage id={tooltip}/>;
    }
    return (
      <ModalTrigger
        icon="circle"
        label={<FormattedMessage id={tooltip}/>}
        color={color}
        directIcon
        defaultCursor
        iconSizeInherit
        style={{fontSize: "1.1rem"}}
      />
    );
  }
}