import {toAbsoluteUrl} from "_metronic";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Avatar} from "app/partials/components/Avatar";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import styles from "./index.module.scss";
import cn from "classnames";

class PropertyCardPreview extends React.Component {
  renderPreview = () => {
    const {
      title,
      semiTitle,
      semiTitle2,
      thumb,
      margin,
      small,
      themeDark,
      vertical,
      semiTitleSolid,
      width,
      onClick,
      tag,
      tagTheme,
      noOverflow,
      target
    } = this.props;
    const avatar = thumb
      ? thumb
      : toAbsoluteUrl("media/branding/redd-logo-neon-200px.png");
    const semiTitleFinal = [
      ...semiTitle ? [`${semiTitle}`] : [],
      ...semiTitle2 && semiTitle2 !== semiTitle ? [`${semiTitle2}`] : []
    ].join(", ");

    return (
      <Grid
        container
        direction={vertical ? "column" : "row"}
        justifyContent="flex-start"
        alignItems={vertical ? "stretch" : "center"}
        wrap="nowrap"
        onClick={onClick}
      >
        {thumb !== "disabled" && (
          <Grid item xs={vertical && 12}>
            <Avatar
              avatar={avatar}
              margin={margin}
              size={vertical && "100%"}
              width={width}
            />
          </Grid>
        )}
        <Grid
          item
          xs={vertical && 12}
          style={noOverflow ? {
            overflow: "hidden"
          } : undefined}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography
              variant={small ? "h5" : "h4"}
              color="primary"
              style={{fontWeight: "700", ...vertical ? {marginTop: 10, minHeight: 40} : {}}}
              className={noOverflow ? styles.propertyTitleNoOverflow : undefined}
            >
              {title}
            </Typography>
            {target === "industrialWarehouse" ?
            <>
              {semiTitle &&
                <Typography
                  variant="body2"
                  style={themeDark ? {color: "#ffffff"} : undefined}
                  className={cn(
                    semiTitleSolid ? styles.semiTitleSolid : undefined,
                    noOverflow ? styles.propertyTitleNoOverflow : undefined
                  )}
                >
                  {semiTitle}
                </Typography>
              }
              {semiTitle2 &&
              <Typography
                variant="body2"
                style={themeDark ? {color: "#ffffff"} : undefined}
                className={cn(
                  noOverflow ? styles.propertyTitleNoOverflow : undefined
                )}
              >
                {semiTitle2}
              </Typography>
              }
            </>
            : semiTitleFinal && (
              <Typography
                variant="body2"
                style={themeDark ? {color: "#ffffff"} : undefined}
                className={cn(
                  semiTitleSolid ? styles.semiTitleSolid : undefined,
                  noOverflow ? styles.propertyTitleNoOverflow : undefined
                )}
              >
                {semiTitleFinal}
              </Typography>
            )
            }
            {tag && <span className={cn(styles.tag, tagTheme === "brand" ? styles.brand : undefined)}>{tag}</span>}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {propertyID, route, routeNewTab, routeSuffix = '', children} = this.props;

    if (propertyID) {
      return (
        <RouterLink
          to={propertyID ? `${route}/${propertyID}${routeSuffix}` : false}
          target={routeNewTab ? "_blank" : undefined}
          ref="noopener noreferrer"
        >
          {children ? children : this.renderPreview()}
        </RouterLink>
      );
    } else if (children) {
      return children;
    }
    return this.renderPreview();
  }
}

export default injectIntl(
  connect()(withRouter(PropertyCardPreview))
);
