import React from "react";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableCustom from "../../../TableCustom";
import NoData from "../../../NoData/NoData";
import styles from "../../../Offices/OfficeAdminSettings/index.module.scss";
import SelectSearchVirtualization from "../../../SelectSearchVirtualization";
import InputAdornments from "../../../InputAdornments";
import SingleSelect from "../../../SingleSelect";
import {FILTERS_SELECTS} from "../../../FiltersDrawer/constants";
import ButtonCustom from "../../../ButtonCustom";
import {
  addIndustrialWarehouseOwner,
  fetchIndustrialWarehouseManageOwners,
  removeIndustrialWarehouseOwner
} from "../../../../../crud/estates/industrial/industrial.crud";
import ModalCustom from "../../../ModalCustom";
import {ModalTrigger} from "../../../ModalCustom/ModalTrigger";

class WarehouseDeleteOwnership extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
          endDate: new Date()
        }
    }
    handleChange = (event) => {
        this.setState({
          endDate: event.target.value
        });
    };

    handleSave = () => {
        const {endDate} = this.state;
        const {deleteOwnership, ownerID, warehouseID} = this.props;
        const data = [
            {
                id: ownerID,
                endDate
            }
        ];
        deleteOwnership(warehouseID, data);
    };

  render() {
    const {endDate} = this.state;
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="close"
            label={<FormattedMessage id="BUILDING.PHRASE.END_OWNERSHIP"/>}
            color="danger"
            directIcon
          />
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.DELETE"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.DELETE_OWNERSHIP"/>}
        handleSave={this.handleSave}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
              <Typography variant="body1" style={{marginBottom: 15}}><FormattedMessage id="BUILDING.PHRASE.END_DATE_MESSAGE"/></Typography>
            <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.END_DATE"/>} name="endDate" value={endDate} type="date" onChange={this.handleChange}/>
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}
class EditWarehouseOwners extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      company: '',
      name: '',
      shortName: '',
      nip: '',
      krs: '',
      regon: '',
      share: '',
      signedDate: '',
      endDate: '',
      price: '',
      currency: 1,
      expanded: 'current'
    };
    const {fetchIndustrialWarehouseManageOwners, warehouseID} = this.props;
    fetchIndustrialWarehouseManageOwners(warehouseID);
  }

  // Handlers
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };
  handleExpand = panel => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    })
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleSelectedCurrency = (selected) => {
    this.setState({
      currency: selected
    });
  };
  handleSave = () => {
    const {addIndustrialWarehouseOwner, warehouseID} = this.props;
    const {company, share, signedDate, endDate, price, currency, name, nip, krs, regon, shortName} = this.state;
    let data = {};
    if(company) {
      data = {
        companies: [
          {
            id: company,
            share: Number(share),
            signedDate,
            endDate,
            price: Number(price.replace(" ","")),
            currency
          }
        ]
      };
    } else {
      data = {
        companies: [
          {
            id: company,
            share: Number(share),
            signedDate,
            endDate,
            price: Number(price.replace(" ","")),
            currency,
            data: {
              name,
              nip,
              krs,
              regon,
              shortName
            }
          }
        ]
      };
    }
    addIndustrialWarehouseOwner(warehouseID, data);
  };

  render() {
    const {warehouseManageOwnersCurrent, warehouseManageOwnersPrevious, warehouseManageOwnersCompanies, warehouseManageOwnersTotalShare, warehouseManageOwnersLoading, removeIndustrialWarehouseOwner, warehouseID} = this.props;
    const {company, name, shortName, nip, krs, regon, share, signedDate, endDate, price, currency, expanded} = this.state;
    // CURRENT OWNERS TABLE
    const tHead = [
      {
        "label": "ID",
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.COMPANY_ID'/>,
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.SHARE'/>,
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.COMPANY_NAME'/>,
        "number": false
      },
      {
        "label": "",
        "number": true
      }
    ];
    const tBody = warehouseManageOwnersCurrent && warehouseManageOwnersCurrent.map((owner) => {
      const { id, cId, cName, share } = owner;
      return {
        "data": [id, cId, share, cName, <WarehouseDeleteOwnership deleteOwnership={removeIndustrialWarehouseOwner} warehouseID={warehouseID} ownerID={id}/>]
      }
    });
    // PREVIOUS OWNERS TABLE
    const tHeadPrev = [
      {
        "label": "ID",
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.COMPANY_ID'/>,
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.SHARE'/>,
        "number": true
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.COMPANY_NAME'/>,
        "number": false
      },
      {
        "label": <FormattedMessage id='BUILDING.PHRASE.endDate'/>,
        "number": true
      }
    ];
    const tBodyPrev = warehouseManageOwnersPrevious && warehouseManageOwnersPrevious.map((owner) => {
      const { id, cId, cName, share, endDate } = owner;
      return {
        "data": [id, cId, share, cName, endDate]
      }
    });
    const disabledInput = company !== null && company !== '';

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Owner"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel expanded={expanded === 'current'} onChange={this.handleExpand('current')}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h3"><FormattedMessage id="BUILDING.PHRASE.OFFICE_OWNERS" values={{totalShare: warehouseManageOwnersTotalShare}}/></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              warehouseManageOwnersLoading ? <LoadingScreen/> : warehouseManageOwnersCurrent && warehouseManageOwnersCurrent.length ?
                <TableCustom
                    tHead={tHead}
                    tBody={tBody}
                    tdStyle={{backgroundColor: "#3a3a3a"}}
                    stickyHeader
                /> : <NoData/>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
          <ExpansionPanel expanded={expanded === 'previous'} onChange={this.handleExpand('previous')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography variant="h3"><FormattedMessage id="BUILDING.PHRASE.OFFICE_OWNERS_PREVIOUS"/></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
                warehouseManageOwnersLoading ? <LoadingScreen/> : warehouseManageOwnersPrevious && warehouseManageOwnersPrevious.length ?
                  <TableCustom
                      tHead={tHeadPrev}
                      tBody={tBodyPrev}
                      tdStyle={{backgroundColor: "#3a3a3a"}}
                      stickyHeader
                  /> : <NoData/>
              }
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel expanded={expanded === 'addOwnership'} onChange={this.handleExpand('addOwnership')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="addOwnership-content"
              id="addOwnership-header"
            >
              <Typography variant="h3"><FormattedMessage id="BUILDING.PHRASE.ADD_OFFICE_OWNERS"/></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {warehouseManageOwnersTotalShare < 100 ?
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Typography variant="h4" className={styles.subTitleMargin} style={{marginTop: 25}}><FormattedMessage id="BUILDING.PHRASE.ADD_EXISTING_COMPANY"/></Typography>
                  {warehouseManageOwnersLoading ? <LoadingScreen/> :
                  <>
                  <SelectSearchVirtualization
                    name="company"
                    value={company}
                    label={<FormattedMessage id="BUILDING.PHRASE.EXISTING_COMPANY"/>}
                    options={warehouseManageOwnersCompanies}
                    optionLabel="name"
                    optionValue="id"
                    onChange={this.handleAutocompleteChange}
                    marginOff
                  />
                  </>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" className={styles.subTitleMargin}><FormattedMessage id="BUILDING.PHRASE.ADD_NEW_COMPANY"/></Typography>
                  <Grid container spacing={4} direction="column">
                    <Grid item>
                      <InputAdornments disabled={disabledInput} label={<FormattedMessage id="GENERAL.PHRASE.NAME"/>} name="name" value={name} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments disabled={disabledInput} label={<FormattedMessage id="GENERAL.PHRASE.SHORT_NAME"/>} name="shortName" value={shortName} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments disabled={disabledInput} label="NIP" name="nip" value={nip} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments disabled={disabledInput} label="KRS" name="krs" value={krs} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments disabled={disabledInput} label="REGON" name="regon" value={regon} onChange={this.handleChange}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" className={styles.subTitleMargin}><FormattedMessage id="BUILDING.PHRASE.OWNERSHIP_DETAILS"/></Typography>
                  <Grid container spacing={4} direction="column">
                    <Grid item>
                      <SingleSelect
                        label={<FormattedMessage id="BUILDING.PHRASE.CURRENCY"/>}
                        inputName="currency"
                        options={FILTERS_SELECTS.CURRENCY}
                        optionsValue="id"
                        optionsLabel="label"
                        defaultSelect={currency}
                        onSelectedValues={this.handleSelectedCurrency}
                      />
                    </Grid>
                    <Grid item>
                      <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.PRICE"/>} name="price" value={price} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.SHARE"/>} name="share" value={share} onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.SIGNED_DATE"/>} name="signedDate" value={signedDate} defaultDisabled type="date" onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.endDate"/>} name="endDate" value={endDate} defaultDisabled type="date" onChange={this.handleChange}/>
                    </Grid>
                    <Grid item>
                      <ButtonCustom label="Create" color="success" solid onClick={this.handleSave}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h2" className={styles.fullShare}><FormattedMessage id="BUILDING.PHRASE.FULL_SHARE_MESSAGE"/></Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={styles.fullShare}><FormattedMessage id="BUILDING.PHRASE.FULL_SHARE_MESSAGE_SUB"/></Typography>
                </Grid>
              </Grid>
              }
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  warehouseManageOwnersCurrent: state.industrial.warehouseManageOwnersCurrent,
  warehouseManageOwnersPrevious: state.industrial.warehouseManageOwnersPrevious,
  warehouseManageOwnersCompanies: state.industrial.warehouseManageOwnersCompanies,
  warehouseManageOwnersTotalShare: state.industrial.warehouseManageOwnersTotalShare,
  warehouseManageOwnersLoading: state.industrial.warehouseManageOwnersLoading
});
const mapDispatchToProps = {
  fetchIndustrialWarehouseManageOwners: (warehouseID) => fetchIndustrialWarehouseManageOwners(warehouseID),
  addIndustrialWarehouseOwner: (warehouseID, data) => addIndustrialWarehouseOwner(warehouseID, data),
  removeIndustrialWarehouseOwner: (warehouseID, data) => removeIndustrialWarehouseOwner(warehouseID, data)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseOwners)
);