import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import LoadingScreen from "../LoadingScreen";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../content/Portlet";
import TableCustom from "../TableCustom";
import {handleEmptyValue, toDateFormat} from "../../../utils/helpers";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import BIDataPreviewItem from "app/partials/components/MarketplaceLeadsDataPreviewItem";
import NoData from "../NoData/NoData";

class PropertyParticularsDetails extends React.Component {
    constructor(props) {
        super(props);
        const {teamID, propertyID, onFetchData} = props;
        if(teamID && propertyID) {
            onFetchData(teamID, propertyID);
        }
    }
    handleTableBody = () => {
        const {data, dataView} = this.props;
        const sourceData = data && (data?.visitors[dataView].length || Object.keys(data?.visitors[dataView]).length) && data?.visitors[dataView];

        return sourceData ? sourceData.map(row => {
            return {
                "data": [
                    handleEmptyValue(row.email),
                    handleEmptyValue(row.team),
                    toDateFormat(row.date)
                ]
            }
        }) : [];
    };

    render() {
        const {data, dataView, loading, target} = this.props;
        const isTargetIndustrial = target === "industrial";
        const tHead = [
          {
            "label": <FormattedMessage id="OFFERS.TABLE.VISITOR"/>,
            "number": false
          },
          {
            "label": <FormattedMessage id="OFFERS.TABLE.TEAM"/>,
            "number": false
          },
          {
            "label": dataView === "views" ? <FormattedMessage id="OFFERS.TABLE.LAST_VISIT"/> : <FormattedMessage id="OFFERS.TABLE.LAST_INTERACTION"/>,
            "number": true
          }
        ];

        if(loading) {
            return <LoadingScreen/>;
        }
        else if(!data) {
            return <NoData/>
        }
        return(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Portlet fluidHeight={true}>
                    <PortletHeader
                      className={styles.portletFitWidth}
                      title={
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                          <Grid item>
                            <Typography variant="h3" className={styles.portletTitle}>
                              <FormattedMessage id="OFFERS.PHRASE.ANALYSIS" values={{name: data ? data.name : ""}}/>
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <BIDataPreviewItem
                              value={dataView === "views" ? data?.views : data?.clicks}
                              label={dataView === "views" ? (isTargetIndustrial ? "OFFERS.PHRASE.PARK_VIEWS" : "OFFERS.PHRASE.BUILDING_VIEWS") : "OFFERS.PHRASE.TOTAL_CLICKS"}
                              icon={toAbsoluteUrl("media/branding/icons/offer-views.png")}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <PortletBody fit>
                      {data ? <TableCustom tHead={tHead} tBody={this.handleTableBody()} stickyHeader maxHeight={570} theadStyleOrigin/> : <LoadingScreen/>}
                    </PortletBody>
                  </Portlet>
                </Grid>
            </Grid>
        );
    }
}
export default class PropertyParticularsAnalysisDetails extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isOpen: false
    };
  }
  handleCloseModal = () => {
    this.child.current.handleClose()
  };

  render() {
    const {intl, teamID, propertyID, data, dataView, loading, target, onFetchData} = this.props;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            color="success"
            label={<FormattedMessage id="OFFERS.PHRASE.DETAILED_ANALYSIS"/>}
            directBtn
          />
        }
        fullWidth
        maxWidth="lg"
        defaultThemeProvider
      >
        <PropertyParticularsDetails
            intl={intl}
            teamID={teamID}
            propertyID={propertyID}
            data={data}
            dataView={dataView}
            loading={loading}
            target={target}
            onFetchData={onFetchData}
        />
      </ModalCustom>
    );
  }
}