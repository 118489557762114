import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingUnitsCountPreview from "app/partials/components/_DataParts/BuildingUnitsCountPreview";
import {Grid, Typography} from "@material-ui/core";
import ShortlistRemove from "app/partials/components/Shortlists/ShortlistRemove";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import TableDrawer from "app/partials/components/TableDrawer";
import {fetchShortlists, fetchShortlistUnits, fetchShortlistUnitsReset,} from "app/crud/offers.crud";
import {toDateFormat} from "app/utils/helpers";
import ShortlistExpandedCardPreview from "app/partials/components/_DataParts/ShortlistExpandedCardPreview";
import GenerateShortlistPDFMultiSelect
  from "../../../../pages/offices/OffersPage/components/GenerateShortlistPDFMultiSelect";
import ShareOfferOnline from "../../ShareOfferOnline/ShareOfferOnline";
import UpdatedAtPreview from "../../_DataParts/UpdatedAtPreview";
import {callReddClickEvent} from "app/crud/user.crud";
import OfferEdit from "../../Shortlists/OfferEdit";
import TooltipCustom from "../../TooltipCustom";
import IconColorMUI from "../../IconColorMUI";

class TableOffersCells extends React.Component {
  render() {
    const {
      row,
      fetchShortlistUnits,
      shortlistUnits,
      shortlistUnitsLoading,
      fetchShortlistUnitsReset,
      fetchShortlists,
      isTeamOwner,
      callReddClickEvent
    } = this.props;
    const isOwnerColumnAvailable = isTeamOwner;
    return (
      <>
        <TableCell component="th" scope="row">
          {row?.name}
          {row?.forTesting &&
            <TooltipCustom
              title={
              <span style={{display: "inline-block", textAlign: "left"}}>
                <strong>Test offer</strong>: Admin Only
                <br/>- "Logged in as" mandatory
                <br/>- Offers Online preview disabled
              </span>
              }
            >
            <IconColorMUI icon="info_outline" color="danger" size="16px" style={{fontSize: 0, marginLeft: 5}}/>
          </TooltipCustom>
          }
        </TableCell>
        {isOwnerColumnAvailable && (
          <TableCell component="th" scope="row">
            <Typography variant="body1">{row.owner}</Typography>
          </TableCell>
        )}
        <TableCell align="right">
            <TableDrawer
              title={
                <ShortlistExpandedCardPreview
                  name={row.name}
                  units={row.units && row.units.length}
                  buildings={row.totalBuildings}
                  classes={[...new Set(row.buildingClasses)].join(", ")}
                  years={row.buildingYears}
                />
              }
              headRows={[
                {
                  id: "office_name",
                  numeric: false,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.BUILDING",
                  sort: false,
                },
                {
                  id: "area",
                  numeric: true,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.AREA",
                  sort: false,
                },
                {
                  id: "floor",
                  numeric: true,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.FLOOR",
                  sort: false,
                },
                {
                  id: "type",
                  numeric: true,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.TYPE",
                  sort: false,
                },
                {
                  id: "status",
                  numeric: true,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.STATUS",
                  sort: false,
                },
                {
                  id: "available_from",
                  numeric: true,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.AVAILABLE_FROM",
                  sort: false,
                },
                {
                  id: "update_level_cache",
                  numeric: false,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.UPDATED_AT",
                  sort: false,
                },
                {
                  id: "rent_esitmation",
                  numeric: true,
                  disablePadding: false,
                  label: "UNITS.PHRASE.FORECAST_FEES_PREMIUM",
                  sort: false,
                },
                {
                  id: "actions",
                  numeric: true,
                  disablePadding: false,
                  label: "",
                  sort: false,
                },
              ]}
              bodyRows={shortlistUnits}
              bodyRowsReady={shortlistUnitsLoading}
              target={TABLE_CELLS.SHORTLIST_UNITS}
              parentId={row.id}
              parentPreviewData={row}
              onClick={fetchShortlistUnits}
              onClose={fetchShortlistUnitsReset}
              onRefresh={fetchShortlists}
              onOpen={() => callReddClickEvent(
                "offers-units-preview-click",
                "offer",
                null,
                row?.id
              )}
            >
              <BuildingUnitsCountPreview
                units={row.units && row.units.length}
              />
            </TableDrawer>
          </TableCell>
        <TableCell>{toDateFormat(row.createdAt)}</TableCell>
        <TableCell>
          <UpdatedAtPreview level={1} date={row?.lastUpdate} alternative />
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={2} justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <ShareOfferOnline
                offerID={row.id}
                offerName={row.name}
                offerClients={row.emails}
              />
            </Grid>
            <Grid item>
              <GenerateShortlistPDFMultiSelect
                shortlistId={row.id}
                shortlistName={row.name}
                directBtn
                inactive={row.units && !row.units.length}
                disabled={row.units && !row.units.length}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={0} wrap="nowrap">
                <Grid item>
                  <OfferEdit
                    offerID={row?.id}
                    offerName={row?.name}
                    directIcon
                  />
                </Grid>
                <Grid item>
                  <ShortlistRemove shortlistId={row.id} directIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shortlistUnits: state.shortlists.shortlistUnits,
  shortlistUnitsLoading: state.shortlists.shortlistUnitsLoading,
  isTeamOwner: state.user.data.isTeamOwner
});
const mapDispatchToProps = {
  fetchShortlists: ({ params }) => fetchShortlists(params),
  fetchShortlistUnits: (shortlistID) => fetchShortlistUnits(shortlistID),
  fetchShortlistUnitsReset: fetchShortlistUnitsReset,
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TableOffersCells)
);
