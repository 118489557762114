import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyParkPage from "./PropertyParkPage";
import {
  fetchIndustrialParkBasic,
  fetchIndustrialParkBuildings,
  fetchIndustrialParkChanges,
  fetchIndustrialParkLeaseTerms,
  fetchIndustrialParkLeasingContacts,
  fetchIndustrialParkMarketplaceStats,
  fetchIndustrialParkOwner,
  fetchIndustrialParkSimilarProperties,
  resetIndustrialParkState,
} from "app/crud/estates/industrial/industrialPark.crud";
import {fetchIndustrialParkPhotos} from "../../../crud/estates/industrial/industrial.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  basic: store.industrialPark.basic,
  basicLoading: store.industrialPark.basicLoading,
  buildings: store.industrialPark.buildings,
  buildingsLoading: store.industrialPark.buildingsLoading,
  leaseTerms: store.industrialPark.leaseTerms,
  leaseTermsLoading: store.industrialPark.leaseTermsLoading,
  marketplaceStats: store.industrialPark.marketplaceStats,
  marketplaceStatsLoading: store.industrialPark.marketplaceStatsLoading,
  changes: store.industrialPark.changes,
  changesLoading: store.industrialPark.changesLoading,
  similarProperties: store.industrialPark.similarProperties,
  similarPropertiesLoading: store.industrialPark.similarPropertiesLoading,
  owner: store.industrialPark.owner,
  ownerLoading: store.industrialPark.ownerLoading,
  leasingContacts: store.industrialPark.leasingContacts,
  leasingContactsLoading: store.industrialPark.leasingContactsLoading,
  resetting: store.industrialPark.resetting,
  photos: store.industrial.parkPhotos,
  photosLoading: store.industrial.parkPhotosLoading,
  isStaff: store.user.data.isStaff,
  userWatchlist: store.user.data.watchList,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  fetchIndustrialParkBasic: (parkID) => fetchIndustrialParkBasic(parkID),
  fetchIndustrialParkBuildings: (parkID, ordering) => fetchIndustrialParkBuildings(parkID, ordering),
  fetchIndustrialParkLeaseTerms: (parkID) => fetchIndustrialParkLeaseTerms(parkID),
  fetchIndustrialParkMarketplaceStats: (parkID) => fetchIndustrialParkMarketplaceStats(parkID),
  fetchIndustrialParkChanges: (parkID) => fetchIndustrialParkChanges(parkID),
  fetchIndustrialParkSimilarProperties: (parkID) => fetchIndustrialParkSimilarProperties(parkID),
  fetchIndustrialParkOwner: (parkID) => fetchIndustrialParkOwner(parkID),
  fetchIndustrialParkLeasingContacts: (parkID) => fetchIndustrialParkLeasingContacts(parkID),
  fetchIndustrialParkPhotos: (parkID) => fetchIndustrialParkPhotos(parkID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
  resetIndustrialParkState: resetIndustrialParkState
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParkPage)
);
