import React from "react";
import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {toAbsoluteUrl} from "_metronic";
import {sortArrayNum, toDateFormat} from "app/utils/helpers";
import {BUILDING_STATUS} from "app/constants";
import FONTS_ALLOWED from "app/constants/FONTS_ALLOWED";

class RenderAreaValue extends React.Component {
  render() {
    const {children, styles} = this.props;
    return(
      <View style={styles}>
        {children}
        <Text style={{fontSize: 9, marginLeft: 3, position: "relative", top: 0}}> m&sup2;</Text>
      </View>
    );
  }
}

const numberFormat = (number, fixedMin = 0, fixedMax = 2) => {
  if(isNaN(number)) {
    return number;
  }
  return number.toLocaleString('en-US', {minimumFractionDigits: fixedMin, maximumFractionDigits: fixedMax}).replace(',', ' ');
};
class Footer extends React.Component {
  render() {
    const {states, paddingRight, paddingRightDouble} = this.props;
    const styles = StyleSheet.create({
      footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      },
      paddingRight: {
        paddingRight: 35
      },
      paddingRightDouble: {
        paddingRight: 70
      },
      footerText: {
        fontSize: 8,
        fontWeight: 300
      },
      pageNumber: {
        fontSize: 8,
        color: states.textColor,
      }
    });
    return(
      <View style={[styles.footer, paddingRight && styles.paddingRight, paddingRightDouble && styles.paddingRightDouble]} fixed>
        <Text style={styles.footerText}>All rights reserved. Presentation generated from REDD Platform.</Text>
        <View style={{flexGrow: 1, height: 1, marginHorizontal: 10, backgroundColor: colorSecondary}}/>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (`${pageNumber}`)}/>
      </View>
    );
  }
}
class FrontPage extends React.Component {
  render() {
    const {pdfStyles, user, shortlistName, renderTranslation, states, intl} = this.props;
    let today = new Date();
    const yyyy = today.getFullYear();
    const month = today.toLocaleString(intl.locale, { month: 'long' });
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);
    today = monthCapitalized + " " + yyyy;
    const styles = StyleSheet.create({
      frontPage: {
        paddingLeft: 0
      },
      firstPage: {
        position: "relative",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
      },
      companyLogoBox: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: 150,
        height: 52
      },
      companyLogo: {
        objectFit: "contain"
      },
      pdfTitleContainer: {
        position: "relative",
        width: "80%",
        height: 180,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingHorizontal: 35,
        paddingTop: 50,
        paddingBottom: 35,
        backgroundColor: states.frontBoxBgColor
      },
      pdfTitleContent: {
        flex: 1,
        flexDirection: "column",
      },
      pdfTitleCompany: {
        flex: 1,
        flexDirection: "column",
        paddingTop: 7
      },
      titleBackground: {
        width: "100%"
      },
      titleText: {
        fontSize: 35,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      },
      textThin: {
        fontSize: 32,
        fontWeight: 300
      },
      titleCompany: {
        fontSize: 22,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1,
        marginBottom: 10
      },
      titleDate: {
        fontSize: 17,
        fontWeight: 300,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      },
      marginBottom: {
        marginBottom: 15
      }
    });
    const teamName = user.companyName;
    return(
      <Page style={[pdfStyles.body, styles.frontPage]} orientation="landscape" wrap>
        <View style={styles.firstPage}>
          <View style={styles.companyLogoBox}>
            {user.companyLogoThumb &&
            <Image
              style={styles.companyLogo}
              src={user.companyLogoThumb}
            />
            }
          </View>
          <View style={styles.pdfTitleContainer}>
            <View style={styles.pdfTitleContent}>
              <Text style={[styles.titleText, styles.textThin, styles.marginBottom]}>{renderTranslation("PDF.PHRASE.OFFER")}</Text>
              <Text style={[styles.titleText, styles.textThin]}>{teamName}</Text>
            </View>
            <View style={styles.pdfTitleCompany}>
              <Text style={[styles.titleCompany]}>{renderTranslation("GENERAL.PHRASE.FOR")}</Text>
              <Text style={[styles.titleCompany]}>{shortlistName}</Text>
              <Text style={[styles.titleDate]}>{today}</Text>
            </View>
          </View>
        </View>
        <Footer states paddingRight/>
      </Page>
    );
  }
}
class BuildingsPage extends React.Component {
  render() {
    const {pdfStyles, data, user, states, renderTranslation} = this.props;
    const styles = StyleSheet.create({
      buildingTitleContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
      },
      buildingTitle: {
        fontSize: 40,
        fontWeight: 600,
      },
      buildingsRowContainer: {
        justifyContent: "flex-start"
      },
      colBuilding: {
        display: "flex",
        direction: "column",
        width: "20%"
      },
      rowImageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 80,
        width: "100%",
        borderTop: 2,
        borderBottom: 2,
        borderLeft: 1,
        borderRight: 1,
        borderColor: "#ffffff"
      },
      rowImage: {
        height: "100%",
        width: "100%"
      },
      rowLogo: {
        maxHeight: 50,
        objectFit: "contain"
      },
      rowLabel: {
        fontSize: 10,
        paddingVertical: 3,
        paddingHorizontal: 5,
        borderBottom: 1,
        borderRight: 1,
        borderColor: states.grayBoxesBgColor,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-end",
        width: "100%",
        height: 22,
      },
      rowLabelCaption: {
        backgroundColor: states.grayBoxesBgColor,
        color: states.grayBoxesTextColor,
        borderColor: "#ffffff"
      },
      rowLabelDoubleHeight: {
        height: 37
      },
      buildingLinkContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: 5,
        backgroundColor: states.grayBoxesBgColor
      },
      buildingLink: {
        display: "block",
        textDecoration: "none",
        fontSize: 11,
        textAlign: "center",
        color: states.grayBoxesTextColor
      }
    });
    const buildingStatus = (status) => {
      if(status === BUILDING_STATUS.STATUS_EXISTS) {
        return renderTranslation("GENERAL.PHRASE.EXISTING");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION) {
        return renderTranslation("GENERAL.PHRASE.UNDER_CONSTRUCTION");
      } else if(status === BUILDING_STATUS.STATUS_PLANNED) {
        return renderTranslation("GENERAL.PHRASE.PLANNED");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_REVITALIZATION) {
        return renderTranslation("GENERAL.PHRASE.UNDER_REVITALIZATION");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT) {
        return renderTranslation("GENERAL.PHRASE.UNDER_REDEVELOPMENT");
      }
      return false;
    };
    const buildingRent = (rentFromEur, rentToEur) => {
      const from = rentFromEur ? rentFromEur : null;
      const to = rentToEur ? rentToEur : null;
      if(from && to) {
        return `${from} € - ${to} €`;
      } else if(from && !to) {
        return `${from} €`;
      } else if(!from && to) {
        return `${to} €`;
      } else {
        return "";
      }
    };
    const buildingCertificates = (building) => {
      const renderCerts = building.certificates ? building.certificates.filter(cert => cert).join(", ") : null;
      return renderCerts ? renderCerts : "";
    };
    const buildingParkingFactor = (factorNumber) => {
      if(factorNumber) {
        return `1/${factorNumber}`;
      }
      return "";
    };
    const buildingParkingRent = (groundRent, undergroundRent) => {
      if(groundRent && undergroundRent) {
        return `${groundRent} € / ${undergroundRent} €`;
      } else if(groundRent && !undergroundRent) {
        return `${groundRent} €`;
      } else if(!groundRent && undergroundRent) {
        return `${undergroundRent} €`;
      } else {
        return '';
      }
    };

    return(
      <Page style={pdfStyles.body} size="A4" orientation="landscape" wrap>
        <View style={[pdfStyles.row, styles.buildingsRowContainer]}>
          {data && data.map((building, index) =>
          <>
          {index % 4 === 0 && (
          <View key={Math.random()+index} style={styles.colBuilding} wrap={false}>
            <View style={styles.rowImageContainer}>
              {user.companyLogoThumb &&
              <Image
                style={styles.rowLogo}
                src={user.companyLogoThumb}
              />
              }
            </View>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_NAME")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.ADDRESS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.NEIGHBOURHOOD")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.CITY")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_STATUS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.TOTAL_AREA")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.COMPLETION_YEAR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_CLASS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.CERTIFICATE")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.PARKING_SLOT_GROUND_UNDERGROUND", "\n")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.TOTAL_AVAILABLE_AREA")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.OFFICE_HEADLINE_RENT")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.RETAIL_HEADLINE_RENT")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.SERVICE_CHARGE")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.COMMON_AREA_FACTOR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.PARKING_AREA_FACTOR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption, {borderColor: states.grayBoxesBgColor}]}>{renderTranslation("PDF.PHRASE.LANDLORD.MIN_RENTAL_YEARS")}</Text>
          </View>
          )}
          <View key={Math.random()+index} style={styles.colBuilding} wrap>
            <View style={styles.rowImageContainer}>
              {building.mainPhoto &&
              <Image
                style={styles.rowImage}
                src={building.mainPhoto}
              />
              }
            </View>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{building.name}</Text>
            <Text style={styles.rowLabel}>{building.street}</Text>
            <Text style={styles.rowLabel}>{building.district}</Text>
            <Text style={styles.rowLabel}>{building.market}</Text>
            <Text style={styles.rowLabel}>{buildingStatus(building.status)}</Text>
            <RenderAreaValue styles={styles.rowLabel}>
              <Text style={{fontSize: 10}}>{numberFormat(building.totalAreaNet)}</Text>
            </RenderAreaValue>
            <Text style={styles.rowLabel}>{building.buildingYear}</Text>
            <Text style={styles.rowLabel}>{building.buildingClass}</Text>
            <Text style={styles.rowLabel}>{buildingCertificates(building)}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight]}>{buildingParkingRent(building.rentParkingGroundEur, building.rentParkingUndergroundEur)}</Text>
            <RenderAreaValue styles={styles.rowLabel}>
              <Text style={{fontSize: 10}}>{numberFormat(building.availableArea)}</Text>
            </RenderAreaValue>
            <Text style={styles.rowLabel}>{buildingRent(building.rentFromEur, building.rentToEur)}</Text>
            <Text style={styles.rowLabel}>{buildingRent(building.retailRentFromEur, building.retailRentToEur)}</Text>
            <Text style={styles.rowLabel}>{building.serviceChargesPln} PLN</Text>
            <Text style={[styles.rowLabel]}>{building.commonAreaFactor}%</Text>
            <Text style={[styles.rowLabel]}>{buildingParkingFactor(building.parkingFactorNumber)}</Text>
            <Text style={[styles.rowLabel]}>{building.minRentalYears}</Text>
          </View>
          </>
          )}
        </View>
        <Footer states/>
      </Page>
    );
  }
}
class BuildingsUnitsListPage extends React.Component {
  render() {
    const {pdfStyles, data, renderTranslation} = this.props;
    const styles = StyleSheet.create({
      buildingTitleContainer: {
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "#d9d9d9",
        paddingTop: 5,
        marginBottom: 5,
        alignItems: "flex-end"
      },
      buildingTitle: {
        fontSize: 13,
        fontWeight: 600,
      },
      buildingLocation: {
        fontSize: 11,
        fontWeight: 400
      },
      unitsContainer: {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginBottom: 20
      },
      tableThead: {
        width: "100%",
        alignItems: "center"
      },
      tableBodyRow: {
        width: "100%"
      },
      thValue: {
        fontSize: 10,
        fontWeight: 400,
        textAlign: "right",
        borderRight: 1,
        borderRightColor: "#ffffff"
      },
      tdValue: {
        fontSize: 10,
        fontWeight: 600,
        paddingHorizontal: 5,
        paddingVertical: 5,
        textAlign: "right"
      },
      col0: {
        width: "3%"
      },
      col1: {
        width: "20%"
      },
      col2: {
        width: "8%"
      },
      col3: {
        width: "12%"
      },
      col4: {
        width: "17%"
      },
      col5: {
        width: "20%"
      },
      col6: {
        width: "20%"
      },
      leaseRow: {
        width: "100%",
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "#d9d9d9",
        paddingTop: 4,
        paddingBottom: 7,
        alignItems: "center"
      },
      leaseLabel: {
        width: "47%",
        fontSize: 9,
        lineHeight: 1
      },
      leaseValue: {
        width: "49%",
        fontSize: 9,
        fontWeight: 600,
        lineHeight: 1,
        textAlign: "right"
      },
      subleaseLabel: {
        position: "absolute",
        top: -1,
        right: 10,
        fontSize: 6,
        color: "#FF4626"
      }
    });
    const buildingRent = (rentFromEur, rentToEur) => {
      const from = rentFromEur ? rentFromEur : null;
      const to = rentToEur ? rentToEur : null;
      if(from && to) {
        return `${from} € - ${to} €`;
      } else if(from && !to) {
        return `${from} €`;
      } else if(!from && to) {
        return `${to} €`;
      } else {
        return "";
      }
    };

    return(
      <Page style={pdfStyles.body} size="A4" orientation="landscape" wrap>
        {data && data.map((building, index) => {
          const {
            rentFromEur,
            rentToEur,
            serviceChargesPln
          } = building;
          const renderUnits = (units) => {
          if(units) {
            return sortArrayNum(units, "floor").map((unit, index) => {
              const {availableFrom, type} = unit;
              const currentDate = new Date().getTime();
              const availableDate = new Date(availableFrom).getTime();
              const date = currentDate >= availableDate ? renderTranslation("GENERAL.PHRASE.IMMEDIATELY") : toDateFormat(availableFrom);
              const unitType = type === 1 ? "office" : type === 2 ? "retail" : "office/retail";

              return(
                <View key={Math.random()+index} style={[pdfStyles.row, styles.tableBodyRow]}>
                  <Text style={[styles.tdValue, styles.col0]}>{index+1}</Text>
                  <Text style={[styles.tdValue, styles.col1]}>{numberFormat(unit.area)} m&sup2;</Text>
                  <Text style={[styles.tdValue, styles.col2]}>{unit.floor}</Text>
                  {unit.isSublease ? (
                  <View style={[styles.tdValue, styles.col3]}>
                  <Text>{unitType}</Text>
                  <Text style={styles.subleaseLabel}>{renderTranslation("UNITS.PHRASE.SUBLEASE")}</Text>
                  </View>
                  ) : (
                  <Text style={[styles.tdValue, styles.col3]}>{unitType}</Text>
                  )}
                  <Text style={[styles.tdValue, styles.col4]}>{date}</Text>
                  <Text style={[styles.tdValue, styles.col5]}>{buildingRent(rentFromEur, rentToEur)}</Text>
                  <Text style={[styles.tdValue, styles.col6]}>{serviceChargesPln ? `PLN ${numberFormat(Number(serviceChargesPln),2)}` : ""}</Text>
                </View>
              );
            })
          }
          };
          return(
            <View key={Math.random()+index} wrap={false}>
              <View style={[pdfStyles.row, styles.buildingTitleContainer]}>
                <Text style={styles.buildingTitle}>{building.name}</Text>
                <Text style={styles.buildingLocation}>{building.market} | {building.district} | {building.street}</Text>
              </View>
              <View style={[pdfStyles.column, styles.unitsContainer]}>
                <View>
                  <View style={[pdfStyles.row, styles.tableThead]}>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col0]}>#</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col1]}>{renderTranslation("PDF.PHRASE.AREA")}</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col2]}>{renderTranslation("PDF.PHRASE.FLOOR")}</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col3]}>{renderTranslation("PDF.PHRASE.TYPE")}</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col4]}>{renderTranslation("PDF.PHRASE.AVAILABLE_FROM")}</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col5]}>{renderTranslation("PDF.PHRASE.LANDLORD.OFFICE_HEADLINE_RENT")}</Text>
                    <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col6]}>{renderTranslation("PDF.PHRASE.SERVICE_CHARGE", "")}</Text>
                  </View>
                  {renderUnits(building.units)}
                </View>
              </View>
            </View>
          );
        }
        )}
        <Footer states/>
      </Page>
    );
  }
}
class ContactPage extends React.Component {
  render() {
    const {pdfStyles, user} = this.props;
    const userAvatar = user.avatar ? user.avatar : toAbsoluteUrl("media/office-placeholder.jpg");
    const styles = StyleSheet.create({
      contactContainer: {
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      },
      personName: {
        fontSize: 16,
        fontWeight: 400
      },
      personContact: {
        fontSize: 14,
        fontWeight: 300
      },
      personPosition: {
        fontSize: 12,
        fontWeight: 400
      },
      personCompany: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 10
      }
    });
    return(
        <Page style={pdfStyles.body} size="A4" orientation="landscape" wrap>
          <View style={[pdfStyles.column, styles.contactContainer]}>
            <View style={{width: "30%", marginBottom: 10}}>
              <Image
                style={styles.buildingImage}
                src={userAvatar}
              />
            </View>
            <Text style={styles.personName}>{`${user.firstName} ${user.lastName}`}</Text>
            <Text style={styles.personPosition}>{user.position}</Text>
            <Text style={styles.personCompany}>{user.companyName}</Text>
            <Text style={styles.personContact}>{user.email}</Text>
            <Text style={styles.personContact}>{user.phoneNumber}</Text>
          </View>
          <Footer states/>
        </Page>
    );
  }
}

const colorPrimary = "#5D6472";
const colorSecondary = "#d9d9d9";
const pdfFontFamily = "Poppins";

export default class PdfDocumentHorizontal extends React.Component {
  constructor(props) {
    super(props);
    const {user} = props;
    this.state = {
      frontBoxBgColor: user.pdfFrontPageBoxBg || colorPrimary,
      frontBoxTextColor: user.pdfFrontPageBoxText || "#ffffff",
      grayBoxesBgColor: user.pdfGreyBoxesBg || colorSecondary,
      grayBoxesTextColor: user.pdfGreyBoxesText || colorPrimary,
      h1Color: user.pdfH1Text || colorPrimary,
      h2Color: user.pdfH2Text || colorPrimary,
      textColor: user.pdfPText || colorPrimary,
      textFontFamily: user.pdfFontFamily || pdfFontFamily
    }
  }
  render() {
    const {intl, data, user, shortlistName} = this.props;
    const {textFontFamily} = this.state;
    // Register fonts
    FONTS_ALLOWED.map(font => Font.register(font.code));

    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: textFontFamily,
        color: this.state.textColor,
        position: "relative"
      },
      subtitle: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 5,
        color: this.state.h2Color
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap"
      },
      column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      label: {
        fontSize: 10,
        fontWeight: 300,
        textTransform: "uppercase"
      },
      labelWithBg: {
        display: "block",
        width: "100%",
        fontSize: 12,
        fontWeight: 300,
        backgroundColor: this.state.grayBoxesBgColor,
        color: this.state.grayBoxesTextColor,
        padding: 3
      },
      labelValue: {
        fontSize: 13,
        fontWeight: 400
      },
    });
    const handleStringTranslation = (id, br) => {
      return intl.formatMessage({id: id}, {br})
    };

    return(
      <Document>
        <FrontPage states={this.state} shortlistName={shortlistName} pdfStyles={styles} user={user} intl={intl} renderTranslation={handleStringTranslation}/>
        <BuildingsPage states={this.state} data={data} user={user} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        <BuildingsUnitsListPage states={this.state} data={data} user={user} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        <ContactPage states={this.state} pdfStyles={styles} user={user}/>
      </Document>
    );
  }
}