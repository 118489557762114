import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import {ROUTES} from "../../../../constants";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {areaFormat, handleEmptyValue, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import {FormattedMessage} from "react-intl";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import BadgeList from "../../../../partials/components/BadgeList";
import {toAbsoluteUrl} from "../../../../../_metronic";
import {Tooltip} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import IconColorMUI from "../../../../partials/components/IconColorMUI";

export default class CACompareTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDifferences: true,
      period: '6M',
      confetti: false,
      winningValues: 0,
      expandedAmenities: false,
      amenitiesLimit: 5
    }
  }
  componentDidMount() {
    const ownedProperty = this.props?.data?.[0];
    const isNewVacancyWin = this.handleMinMaxValueMarkup({key: "newVacancyLastSixMonths"}, ownedProperty, "min");
    const isUptakeWin = this.handleMinMaxValueMarkup({key: "leaseUptakeLastSixMonths"}, ownedProperty, "min");
    const isTimeToLeaseWin = this.handleMinMaxValueMarkup({key: "waitingForChangeAvgLastSixMonths"}, ownedProperty, "min");
    const winningValues = [
      ...isNewVacancyWin ? ['true'] : [],
      ...isUptakeWin ? ['true'] : [],
      ...isTimeToLeaseWin ? ['true'] : []
    ];
    this.setState({
      winningValues: winningValues?.length
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const isOfficeView = this.props.currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const winningNumber = isOfficeView ? 3 : 2;
    const isWinning = this.state.winningValues >= winningNumber;
    if(prevState?.winningValues !== this.state.winningValues && prevState?.confetti !== (this.state.winningValues >= isWinning)) {
      setTimeout(() => {
        this.setState({
          confetti: isWinning
        })
      }, 500);
    }
  }

  // Helpers
  getRows = () => {
    const {currentApplicationView} = this.props;
    const rowsOffices = [
      {
        sectionID: "dynamicAnalytics",
        section: "Dynamic Analytics",
        dataRows: [
          {
            label: "Time to lease",
            key: "waitingForChangeAvgLastSixMonths",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.TIME_TO_LEASE_DESCRIPTION"/>
          },
          {
            label: "6M Take-Up",
            key: "leaseUptakeLastSixMonths",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_LEASE_UPTAKE_DESCRIPTION"/>
          },
          {
            label: "6M New Vacancy",
            key: "newVacancyLastSixMonths",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_NEW_VACANCY_DESCRIPTION"/>
          },
          {
            label: "Last Transaction",
            key: "dealDone",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.DEAL_DONE_STAMP_DESCRIPTION"/>
          }
        ]
      },
      {
        sectionID: "occupancyMetrics",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.OCCUPANCY_METRICS"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.VACANCY_RATE"/>,
            key: "vacancyRate"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AVAILABLE_SPACE"/>,
            key: "totalAreaAvailable",
            key3M: "totalAreaAvailableThreeMonthsAgo",
            key6M: "totalAreaAvailableSixMonthsAgo",
            key1Y: "totalAreaAvailableOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.PLANNED_SPACE_VACANCY"/>,
            key: "plannedSpaceVacancy",
            key3M: "plannedSpaceVacancyThreeMonthsAgo",
            key6M: "plannedSpaceVacancySixMonthsAgo",
            key1Y: "plannedSpaceVacancyOneYearAgo",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.PLANNED_SPACE_VACANCY_DESCRIPTION"/>
          }
        ]
      },
      {
        sectionID: "units",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AVAILABLE_UNITS"/>
      },
      {
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LEASE_TERMS"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.RENT_RATE"/>,
            key: "rent",
            key3M: "rentFromThreeMonthsAgo",
            key6M: "rentFromSixMonthsAgo",
            key1Y: "rentFromOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SERVICE_CHARGE"/>,
            key: "serviceChargesPln",
            key3M: "serviceChargeThreeMonthsAgo",
            key6M: "serviceChargeSixMonthsAgo",
            key1Y: "serviceChargeOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LEASE_PERIOD"/>,
            key: "minRentalYears"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.ADD-ON_FACTOR"/>,
            key: "commonAreaFactor"
          }
        ]
      },
      {
        sectionID: "amenities",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AMENITIES"/>,
        dataRows: [
          {
            label: "",
            key: "amenities"
          }
        ]
      },
      {
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.BUILDING"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPLETION_YEAR"/>,
            key: "commissioningYear",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPLETION_YEAR_DESCRIPTION"/>
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.CERTIFICATES"/>,
            key: "certificates"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.OWNER"/>,
            key: "owner"
          }
        ]
      },
    ];
    const rowsIndustrial = [
      {
        sectionID: "dynamicAnalytics",
        section: "Dynamic Analytics",
        dataRows: [
          {
            label: "6M Take-Up",
            key: "leaseUptakeLastSixMonths",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_LEASE_UPTAKE_DESCRIPTION"/>
          },
          {
            label: "6M New Vacancy",
            key: "newVacancyLastSixMonths",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_NEW_VACANCY_DESCRIPTION"/>
          },
          {
            label: "Last Transaction",
            key: "dealDone",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.DEAL_DONE_STAMP_DESCRIPTION"/>
          }
        ]
      },
      {
        sectionID: "occupancyMetrics",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.OCCUPANCY_METRICS"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.VACANCY_RATE"/>,
            key: "vacancyRate",
            key3M: "vacancyRateThreeMonthsAgo",
            key6M: "vacancyRateSixMonthsAgo",
            key1Y: "vacancyRateOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AVAILABLE_SPACE"/>,
            key: "totalAreaAvailable",
            key3M: "totalAreaAvailableThreeMonthsAgo",
            key6M: "totalAreaAvailableSixMonthsAgo",
            key1Y: "totalAreaAvailableOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.PLANNED_SPACE_VACANCY"/>,
            key: "plannedSpaceVacancy",
            key3M: "plannedSpaceVacancyThreeMonthsAgo",
            key6M: "plannedSpaceVacancySixMonthsAgo",
            key1Y: "plannedSpaceVacancyOneYearAgo",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.PLANNED_SPACE_VACANCY_DESCRIPTION"/>
          }
        ]
      },
      {
        sectionID: "units",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AVAILABLE_UNITS"/>
      },
      {
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LEASE_TERMS"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.RENT_RATE"/>,
            key: "rentWarehouseFrom",
            key3M: "rentFromThreeMonthsAgo",
            key6M: "rentFromSixMonthsAgo",
            key1Y: "rentFromOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SERVICE_CHARGE"/>,
            key: "serviceWarehouseFrom",
            key3M: "serviceChargeThreeMonthsAgo",
            key6M: "serviceChargeSixMonthsAgo",
            key1Y: "serviceChargeOneYearAgo"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LEASE_PERIOD"/>,
            key: "parkMinRentalYears"
          }
        ]
      },
      {
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.TECHNICAL_DETAILS"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.STORAGE_HEIGHT"/>,
            key: "storageHeight"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COLUMNS_GRID"/>,
            key: "columnGridMax"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.FLOOR_LOADING_CAPACITY"/>,
            key: "flooringCarrier"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LOADING_DOCKS"/>,
            key: "docks"
          }
        ]
      },
      {
        sectionID: "amenities",
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.AMENITIES"/>,
        dataRows: [
          {
            label: "",
            key: "amenities"
          }
        ]
      },
      {
        section: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.BUILDING"/>,
        dataRows: [
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPLETION_YEAR"/>,
            key: "commissioningYears",
            tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPLETION_YEAR_DESCRIPTION"/>
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.CERTIFICATES"/>,
            key: "certificates"
          },
          {
            label: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.OWNER"/>,
            key: "owner"
          }
        ]
      },
    ];

    if(currentApplicationView === APPLICATION_VIEWS.OFFICES) {
      return rowsOffices;
    }
    else if(currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
      return rowsIndustrial;
    }
  }
  getCertificates = (data) => {
    const certifications = [];
    if (data.dgnbCertification) {
      certifications.push("DGNB");
    }
    if (data.hqeCertification) {
      certifications.push("HQE");
    }
    if (data.wellCertification) {
      certifications.push("WELL");
    }
    if (data.breeamCertification) {
      certifications.push("BREEAM");
    }
    if (data.leedCertification) {
      certifications.push("LEED");
    }
    return certifications && certifications.length ? certifications.join(",") : "-";
  };
  getMinMaxValue = (target, type) => {
    const {data} = this.props;
    // COMMON //
    // Vacancy rate set
    const vacancyRateSet = data?.filter(item => item?.vacancyRate !== null)?.map((item) => item?.vacancyRate);
    const vacancyRateMin = Array.isArray(vacancyRateSet) && vacancyRateSet?.length > 0 ? Number(Math.min(...vacancyRateSet)) : null;
    const vacancyRateMax = Array.isArray(vacancyRateSet) && vacancyRateSet?.length > 0 ? Number(Math.max(...vacancyRateSet)) : null;
    // Total Area Available set
    const totalAreaAvailableSet = data?.filter(item => item?.totalAreaAvailable !== null)?.map((item) => item?.totalAreaAvailable);
    const totalAreaAvailableMin = Array.isArray(totalAreaAvailableSet) && totalAreaAvailableSet?.length > 0 ? Number(Math.min(...totalAreaAvailableSet)) : null;
    const totalAreaAvailableMax = Array.isArray(totalAreaAvailableSet) && totalAreaAvailableSet?.length > 0 ? Number(Math.max(...totalAreaAvailableSet)) : null;
    // Planned Space Vacancy set
    const plannedSpaceVacancySet = data?.filter(item => item?.plannedSpaceVacancy !== null)?.map((item) => item?.plannedSpaceVacancy);
    const plannedSpaceVacancyMin = Array.isArray(plannedSpaceVacancySet) && plannedSpaceVacancySet?.length > 0 ? Number(Math.min(...plannedSpaceVacancySet)) : null;
    const plannedSpaceVacancyMax = Array.isArray(plannedSpaceVacancySet) && plannedSpaceVacancySet?.length > 0 ? Number(Math.max(...plannedSpaceVacancySet)) : null;
    // 6M Take-Up
    const leaseUptakeSet = data?.filter(item => item?.leaseUptakeLastSixMonths !== null)?.map((item) => item?.leaseUptakeLastSixMonths);
    const leaseUptakeMin = Array.isArray(leaseUptakeSet) && leaseUptakeSet?.length > 0 ? Number(Math.min(...leaseUptakeSet)) : null;
    const leaseUptakeMax = Array.isArray(leaseUptakeSet) && leaseUptakeSet?.length > 0 ? Number(Math.max(...leaseUptakeSet)) : null;
    // 6M New Vacancy
    const newVacancySet = data?.filter(item => item?.newVacancyLastSixMonths !== null)?.map((item) => item?.newVacancyLastSixMonths);
    const newVacancyMin = Array.isArray(newVacancySet) && newVacancySet?.length > 0 ? Number(Math.min(...newVacancySet)) : null;
    const newVacancyMax = Array.isArray(newVacancySet) && newVacancySet?.length > 0 ? Number(Math.max(...newVacancySet)) : null;

    // OFFICES ONLY //
    // Time to lease set
    const timeToLeaseSet = data?.filter(item => item?.waitingForChangeAvgLastSixMonths !== null)?.map((item) => item?.waitingForChangeAvgLastSixMonths);
    const timeToLeaseMin = Array.isArray(timeToLeaseSet) && timeToLeaseSet?.length > 0 ? Number(Math.min(...timeToLeaseSet)) : null;
    const timeToLeaseMax = Array.isArray(timeToLeaseSet) && timeToLeaseSet?.length > 0 ? Number(Math.max(...timeToLeaseSet)) : null;
    // Rent from set
    const rentFromSet = data?.filter(item => item?.rentFromEur !== null)?.map((item) => item?.rentFromEur);
    const rentFromMin = Array.isArray(rentFromSet) && rentFromSet?.length > 0 ? Number(Number(Math.min(...rentFromSet)).toFixed(2)) : null;
    const rentFromMax = Array.isArray(rentFromSet) && rentFromSet?.length > 0 ? Number(Number(Math.max(...rentFromSet)).toFixed(2)) : null;
    // Service charge set
    const serviceChargeSet = data?.filter(item => item?.serviceChargesPln !== null)?.map((item) => item?.serviceChargesPln);
    const serviceChargeMin = Array.isArray(serviceChargeSet) && serviceChargeSet?.length > 0 ? Number(Number(Math.min(...serviceChargeSet)).toFixed(2)) : null;
    const serviceChargeMax = Array.isArray(serviceChargeSet) && serviceChargeSet?.length > 0 ? Number(Number(Math.max(...serviceChargeSet)).toFixed(2)) : null;

    // INDUSTRIAL ONLY //
    // Parking factor set
    const storageHeightSet = data?.filter(item => item?.storageHeight !== null)?.map((item) => item?.storageHeight);
    const storageHeightMin = Array.isArray(storageHeightSet) && storageHeightSet?.length > 0 ? Number(Math.min(...storageHeightSet)) : null;
    const storageHeightMax = Array.isArray(storageHeightSet) && storageHeightSet?.length > 0 ? Number(Math.max(...storageHeightSet)) : null;
    // Parking ground rent set
    const flooringCarrierSet = data?.filter(item => item?.flooringCarrier !== null)?.map((item) => item?.flooringCarrier);
    const flooringCarrierMin = Array.isArray(flooringCarrierSet) && flooringCarrierSet?.length > 0 ? Number(Math.min(...flooringCarrierSet)) : null;
    const flooringCarrierMax = Array.isArray(flooringCarrierSet) && flooringCarrierSet?.length > 0 ? Number(Math.max(...flooringCarrierSet)) : null;
    // Parking underground rent set
    const docksSet = data?.filter(item => item?.docks !== null)?.map((item) => item?.docks);
    const docksMin = Array.isArray(docksSet) && docksSet?.length > 0 ? Number(Math.min(...docksSet)) : null;
    const docksMax = Array.isArray(docksSet) && docksSet?.length > 0 ? Number(Math.max(...docksSet)) : null;
    // Rent from set
    const rentWarehouseFromSet = data?.filter(item => item?.rentWarehouseFrom !== null)?.map((item) => item?.rentWarehouseFrom);
    const rentWarehouseFromMin = Array.isArray(rentWarehouseFromSet) && rentWarehouseFromSet?.length > 0 ? Number(Number(Math.min(...rentWarehouseFromSet)).toFixed(2)) : null;
    const rentWarehouseFromMax = Array.isArray(rentWarehouseFromSet) && rentWarehouseFromSet?.length > 0 ? Number(Number(Math.max(...rentWarehouseFromSet)).toFixed(2)) : null;
    // Service charge set
    const serviceWarehouseFromSet = data?.filter(item => item?.serviceWarehouseFrom !== null)?.map((item) => item?.serviceWarehouseFrom);
    const serviceWarehouseFromMin = Array.isArray(serviceWarehouseFromSet) && serviceWarehouseFromSet?.length > 0 ? Number(Number(Math.min(...serviceWarehouseFromSet)).toFixed(2)) : null;
    const serviceWarehouseFromMax = Array.isArray(serviceWarehouseFromSet) && serviceWarehouseFromSet?.length > 0 ? Number(Number(Math.max(...serviceWarehouseFromSet)).toFixed(2)) : null;

    // Common
    if(target === "vacancyRate") {
      if(type === "min") {
        return vacancyRateMin;
      }
      else if(type === "max") {
        return vacancyRateMax;
      }
    }
    else if(target === "totalAreaAvailable") {
      if(type === "min") {
        return totalAreaAvailableMin;
      }
      else if(type === "max") {
        return totalAreaAvailableMax;
      }
    }
    else if(target === "plannedSpaceVacancy") {
      if(type === "min") {
        return plannedSpaceVacancyMin;
      }
      else if(type === "max") {
        return plannedSpaceVacancyMax;
      }
    }
    else if(target === "leaseUptakeLastSixMonths") {
      if(type === "min") {
        return leaseUptakeMin;
      }
      else if(type === "max") {
        return leaseUptakeMax;
      }
    }
    else if(target === "newVacancyLastSixMonths") {
      if(type === "min") {
        return newVacancyMin;
      }
      else if(type === "max") {
        return newVacancyMax;
      }
    }
    // Offices
    else if(target === "waitingForChangeAvgLastSixMonths") {
      if(type === "min") {
        return timeToLeaseMin;
      }
      else if(type === "max") {
        return timeToLeaseMax;
      }
    }
    else if(target === "rent") {
      if(type === "min") {
        return rentFromMin;
      }
      else if(type === "max") {
        return rentFromMax;
      }
    }
    else if(target === "serviceChargesPln") {
      if(type === "min") {
        return serviceChargeMin;
      }
      else if(type === "max") {
        return serviceChargeMax;
      }
    }
    // Industrial
    else if(target === "storageHeight") {
      if(type === "min") {
        return storageHeightMin;
      }
      else if(type === "max") {
        return storageHeightMax;
      }
    }
    else if(target === "flooringCarrier") {
      if(type === "min") {
        return flooringCarrierMin;
      }
      else if(type === "max") {
        return flooringCarrierMax;
      }
    }
    else if(target === "docks") {
      if(type === "min") {
        return docksMin;
      }
      else if(type === "max") {
        return docksMax;
      }
    }
    else if(target === "rentWarehouseFrom") {
      if(type === "min") {
        return rentWarehouseFromMin;
      }
      else if(type === "max") {
        return rentWarehouseFromMax;
      }
    }
    else if(target === "serviceWarehouseFrom") {
      if(type === "min") {
        return serviceWarehouseFromMin;
      }
      else if(type === "max") {
        return serviceWarehouseFromMax;
      }
    }
    else {
      return null;
    }
  }
  getPercentageValue = (baseValue, compareWithValue, asNumber) => {
    if(!isNaN(baseValue) && !isNaN(compareWithValue)) {
      const percentageValue = Number(Number(compareWithValue)*100/Number(baseValue)-100).toFixed(2);
      const finalValue = isNaN(percentageValue) ? '-' : (percentageValue === "Infinity" ? "-" : percentageValue);

      if(asNumber && !isNaN(percentageValue)) {
        return Number(percentageValue);
      }
      return toNumberFormat(finalValue, '%');
    }
    return null;
  }
  // Handlers
  handleChangeSwitcher = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };
  handleExpandAllAmenities = () => {
    const {callReddClickEvent, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    this.setState({
      expandedAmenities: !this.state.expandedAmenities,
    }, () => {
      this.setState({
        amenitiesLimit: this.state.expandedAmenities ? 40 : 5
      });
      callReddClickEvent(
        isOfficeView ?
          "competition-analysis-offices-amenities-toggle-click" :
          "competition-analysis-industrial-amenities-toggle-click",
        "user",
        null,
        null,
        {
          expanded: this.state.expandedAmenities
        }
      );
    });
  }
  handleMinMaxValueMarkup = (dataRow, property, expectedValue) => {
    // COMMON
    const isVacancyRateMin = dataRow?.key === "vacancyRate" && this.getMinMaxValue("vacancyRate", "min") === Number(property?.vacancyRate);
    const isVacancyRateMax = dataRow?.key === "vacancyRate" && this.getMinMaxValue("vacancyRate", "max") === Number(property?.vacancyRate);
    const isTotalAreaAvailableMin = dataRow?.key === "totalAreaAvailable" && this.getMinMaxValue("totalAreaAvailable", "min") === Number(property?.totalAreaAvailable);
    const isTotalAreaAvailableMax = dataRow?.key === "totalAreaAvailable" && this.getMinMaxValue("totalAreaAvailable", "max") === Number(property?.totalAreaAvailable);
    const isPlannedSpaceVacancyMin = dataRow?.key === "plannedSpaceVacancy" && this.getMinMaxValue("plannedSpaceVacancy", "min") === Number(property?.plannedSpaceVacancy);
    const isPlannedSpaceVacancyMax = dataRow?.key === "plannedSpaceVacancy" && this.getMinMaxValue("plannedSpaceVacancy", "max") === Number(property?.plannedSpaceVacancy);
    const isLeaseUptakeMin = dataRow?.key === "leaseUptakeLastSixMonths" && this.getMinMaxValue("leaseUptakeLastSixMonths", "min") === Number(property?.leaseUptakeLastSixMonths);
    const isLeaseUptakeMax = dataRow?.key === "leaseUptakeLastSixMonths" && this.getMinMaxValue("leaseUptakeLastSixMonths", "max") === Number(property?.leaseUptakeLastSixMonths);
    const isNewVacancyMin = dataRow?.key === "newVacancyLastSixMonths" && this.getMinMaxValue("newVacancyLastSixMonths", "min") === Number(property?.newVacancyLastSixMonths);
    const isNewVacancyMax = dataRow?.key === "newVacancyLastSixMonths" && this.getMinMaxValue("newVacancyLastSixMonths", "max") === Number(property?.newVacancyLastSixMonths);
    // OFFICES
    const isTimeToLeaseMin = dataRow?.key === "waitingForChangeAvgLastSixMonths" && this.getMinMaxValue("waitingForChangeAvgLastSixMonths", "min") === Number(property?.waitingForChangeAvgLastSixMonths);
    const isTimeToLeaseMax = dataRow?.key === "waitingForChangeAvgLastSixMonths" && this.getMinMaxValue("waitingForChangeAvgLastSixMonths", "max") === Number(property?.waitingForChangeAvgLastSixMonths);
    const isRentMin = dataRow?.key === "rent" && this.getMinMaxValue("rent", "min") === Number(property?.rentFromEur);
    const isRentMax = dataRow?.key === "rent" && this.getMinMaxValue("rent", "max") === Number(property?.rentFromEur);
    const isServiceMin = dataRow?.key === "serviceChargesPln" && this.getMinMaxValue("serviceChargesPln", "min") === Number(property?.serviceChargesPln);
    const isServiceMax = dataRow?.key === "serviceChargesPln" && this.getMinMaxValue("serviceChargesPln", "max") === Number(property?.serviceChargesPln);
    // INDUSTRIAL
    const isStorageHeightMin = dataRow?.key === "storageHeight" && this.getMinMaxValue("storageHeight", "min") === Number(property?.storageHeight);
    const isStorageHeightMax = dataRow?.key === "storageHeight" && this.getMinMaxValue("storageHeight", "max") === Number(property?.storageHeight);
    const isFlooringCarrierMin = dataRow?.key === "flooringCarrier" && this.getMinMaxValue("flooringCarrier", "min") === Number(property?.flooringCarrier);
    const isFlooringCarrierMax = dataRow?.key === "flooringCarrier" && this.getMinMaxValue("flooringCarrier", "max") === Number(property?.flooringCarrier);
    const isDocksMin = dataRow?.key === "docks" && this.getMinMaxValue("docks", "min") === Number(property?.docks);
    const isDocksMax = dataRow?.key === "docks" && this.getMinMaxValue("docks", "max") === Number(property?.docks);
    const isRentWarehouseMin = dataRow?.key === "rentWarehouseFrom" && this.getMinMaxValue("rentWarehouseFrom", "min") === Number(property?.rentWarehouseFrom);
    const isRentWarehouseMax = dataRow?.key === "rentWarehouseFrom" && this.getMinMaxValue("rentWarehouseFrom", "max") === Number(property?.rentWarehouseFrom);
    const isServiceWarehouseMin = dataRow?.key === "serviceWarehouseFrom" && this.getMinMaxValue("serviceWarehouseFrom", "min") === Number(property?.serviceWarehouseFrom);
    const isServiceWarehouseMax = dataRow?.key === "serviceWarehouseFrom" && this.getMinMaxValue("serviceWarehouseFrom", "max") === Number(property?.serviceWarehouseFrom);

    // Summary
    const isMinValue =
      isVacancyRateMin ||
      isTotalAreaAvailableMin ||
      isPlannedSpaceVacancyMin ||
      isLeaseUptakeMax ||
      isNewVacancyMin ||
      isRentMin ||
      isServiceMin ||
      isTimeToLeaseMin ||
      isRentWarehouseMin ||
      isServiceWarehouseMin ||
      isDocksMax ||
      isFlooringCarrierMax ||
      isStorageHeightMax;

    const isMaxValue =
      isVacancyRateMax ||
      isTotalAreaAvailableMax ||
      isPlannedSpaceVacancyMax ||
      isLeaseUptakeMin ||
      isNewVacancyMax ||
      isRentMax ||
      isServiceMax ||
      isTimeToLeaseMax ||
      isRentWarehouseMax ||
      isServiceWarehouseMax ||
      isDocksMin ||
      isFlooringCarrierMin ||
      isStorageHeightMin;

    if(expectedValue === "min") {
      return isMinValue;
    }
    else if(expectedValue === "max") {
      return isMaxValue;
    }
    else {
      return false;
    }
  }
  handlePercentageMarkup = (dataRow, property) => {
    const {period} = this.state;
    const percentageValue = this.getPercentageValue(property?.[dataRow?.key], property?.[dataRow?.[`key${period}`]], true);
    if(percentageValue > 0) {
      return "+";
    }
    else if(percentageValue < 0) {
      return "-";
    }
    else {
      return false;
    }
  }
  handlePropertyRedirect = (propertyID, clickSource) => {
    const {callReddClickEvent, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    if(clickSource === "thead") {
      callReddClickEvent(
        isOfficeView ?
          "competition-analysis-offices-thead-property-click" :
            "competition-analysis-industrial-thead-property-click",
        isOfficeView ? "office" : "park",
        null,
        propertyID
      );
    }
    else if(clickSource === "tbody") {
      callReddClickEvent(
        isOfficeView ?
          "competition-analysis-offices-tbody-units-click" :
            "competition-analysis-industrial-tbody-units-click",
        isOfficeView ? "office" : "park",
        null,
        propertyID
      );
    }
  }
  handleOwnerRedirect = (ownerID) => {
    const {callReddClickEvent} = this.props;

    callReddClickEvent(
        "competition-analysis-offices-owner-click",
        "company",
        null,
        ownerID
      );
  }
  handlePeriodChange = (newPeriod) => {
    const {callReddClickEvent, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const params = {
      period: newPeriod
    };

    this.setState({
      period: newPeriod
    }, () => {
      callReddClickEvent(
        isOfficeView ?
          "competition-analysis-offices-date-period-change-click" :
            "competition-analysis-industrial-date-period-change-click",
        "user",
        null,
        null,
        params
      );
    });
  }

  render() {
    const {data, currentApplicationView, isUserPremium} = this.props;
    const {showDifferences, period, confetti, expandedAmenities, amenitiesLimit} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    return (
      <div className={styles.tableOuterWrapper}>
        {confetti &&
        <div className={styles.confettiWrapper}>
          <ConfettiExplosion
            force={1}
            duration={3000}
            particleCount={400}
            width={2000}
          />
        </div>
        }
        <div className={styles.tableInnerWrapper}>
          <table>
            <thead>
              <tr>
                <th className={cn(styles.fix, styles.thHeader)}>
                  <h3><FormattedMessage id={isOfficeView ? "COMPETITION_ANALYSIS.PHRASE.SELECTED_BUILDINGS" : "COMPETITION_ANALYSIS.PHRASE.SELECTED_PARKS"}/></h3>
                </th>
                {data?.map((property, index) => {
                  const address = [
                    ...property?.addressCity ? [property?.addressCity] : [],
                    ...property?.addressStreet ? [property?.addressStreet] : []
                  ].join(", ");
                  return (
                    <th key={index}>
                      <PropertyCardPreview
                        propertyID={(isUserPremium || property?.isEditable) && property?.id}
                        marketID={isOfficeView ? property?.marketId : null}
                        title={property?.name}
                        semiTitle={address}
                        thumb={property?.mainPhotoThumbnail}
                        route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                        tag={property?.isEditable && index === 0 &&
                          <FormattedMessage
                            id={
                            isOfficeView ? "COMPETITION_ANALYSIS.PHRASE.YOUR_BUILDING" :
                              "COMPETITION_ANALYSIS.PHRASE.YOUR_PARK"
                            }
                          />
                        }
                        onClick={() => this.handlePropertyRedirect(property?.id, "thead")}
                        routeNewTab
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
            {this.getRows()?.map((row, index) => {
              const isUnitsRow = row?.sectionID === "units";
              const isReddRow = row?.sectionID === "dynamicAnalytics";
              const isAmenitiesRow = row?.sectionID === "amenities";
              const isOccupancyMetricsRow = row?.sectionID === "occupancyMetrics";

              return (
                <React.Fragment key={index}>
                  <tr className={cn(
                    styles.sectionRow,
                    isUnitsRow ? styles.unitsSectionRow : undefined,
                    isReddRow ? styles.reddSectionRow : undefined
                  )}>
                    <td colSpan={(isUnitsRow || isReddRow) ? 1 : data?.length + 1}>
                      <span className={cn(
                        isReddRow ? styles.reddSectionRowWrapper : undefined,
                        (isAmenitiesRow || isOccupancyMetricsRow) ? styles.toolbarSectionRowWrapper : undefined
                      )}>
                        {row?.section}
                        {isReddRow && <img src={toAbsoluteUrl("media/branding/redd-logo-white-200px.png")} alt="REDD"/>}
                        {isAmenitiesRow &&
                        <button
                          className={styles.btnExpandAll}
                          onClick={() => this.handleExpandAllAmenities()}
                        >
                          {expandedAmenities ? <FormattedMessage id="GENERAL.PHRASE.COLLAPSE"/> : <FormattedMessage id="GENERAL.PHRASE.EXPAND"/>}
                        </button>
                        }
                        {isOccupancyMetricsRow &&
                        <div className={styles.periodSelectorWrapper}>
                          <Tooltip
                            title={
                            <span style={{fontSize: "1.2rem"}}>
                              <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.Percentage change over time"/>
                            </span>
                            }
                            placement="left"
                          >
                            <span style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                              <IconColorMUI
                                icon="info_outline"
                                color="danger"
                                size="16px"
                                style={{lineHeight: 0, marginRight: 5}}
                              />
                            </span>
                          </Tooltip>
                          <button
                            className={cn(
                            period === "3M" ? styles.active : undefined
                            )}
                            onClick={() => this.handlePeriodChange("3M")}
                          >3M</button>
                          <button
                            className={cn(
                            period === "6M" ? styles.active : undefined
                            )}
                            onClick={() => this.handlePeriodChange("6M")}
                          >6M</button>
                          <button
                            className={cn(
                            period === "1Y" ? styles.active : undefined
                            )}
                            onClick={() => this.handlePeriodChange("1Y")}
                          >1Y</button>
                        </div>
                        }
                      </span>
                    </td>
                    {(isUnitsRow || isReddRow) && data?.map((property, index) => {
                      return (
                        <td key={index}>
                          {isUnitsRow ?
                            <PropertyCardPreview
                              propertyID={(isUserPremium || property?.isEditable) && property?.id}
                              marketID={isOfficeView ? property?.marketId : null}
                              route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                              routeNewTab
                              routeSuffix={isOfficeView ? '?tab=tab_2' : ''}
                            >
                              <span className={styles.btnUnitsRedirect} onClick={() => this.handlePropertyRedirect(property?.id, "tbody")}>
                                <FormattedMessage
                                  id="COMPETITION_ANALYSIS.PHRASE.VIEW_UNITS"
                                  values={{count: property?.units?.length}}
                                />
                              </span>
                            </PropertyCardPreview>
                            : ""
                          }
                        </td>
                      );
                    })}
                  </tr>
                  {row?.dataRows && row?.dataRows.length > 0 ? row?.dataRows.map((dataRow, index) => {
                      return (
                        <tr key={index} className={cn(
                          isReddRow ? styles.reddSectionChild : undefined,
                          isReddRow && index === (row?.dataRows.length - 1) ? styles.reddSectionChildLast : undefined
                        )}>
                          <td>
                            <span className={cn(dataRow?.tooltip ? styles.rowLabelTooltip : undefined)}>
                              {dataRow?.tooltip ?
                              <Tooltip
                                title={
                                <span style={{fontSize: "1.2rem"}}>{dataRow?.tooltip}</span>
                                }
                                placement="right"
                              >
                                <span style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                  {dataRow?.label}
                                  <IconColorMUI
                                    icon="info_outline"
                                    size="16px"
                                    style={{lineHeight: 0, marginLeft: 5}}
                                  />
                                </span>
                              </Tooltip> : dataRow?.label
                              }
                            </span>
                          </td>
                          {data?.map((property, index) => {
                            const rentRange = isOfficeView ? [
                              ...property?.rentFromEur ? [property?.rentFromEur] : [],
                              ...property?.rentToEur ? [property?.rentToEur] : []
                            ] : [
                              ...property?.rentWarehouseFrom ? [property?.rentWarehouseFrom] : []
                            ];
                            const commissioningYears = [
                              ...property?.commissioningYearMin ? [property?.commissioningYearMin] : [],
                              ...(property?.commissioningYearMax && property?.commissioningYearMax !== property?.commissioningYearMin) ? [
                                property?.commissioningYearMax
                              ] : []
                            ].join(" - ");
                            const isOwnerRow = dataRow?.key === "owner";

                            if(isOwnerRow && property?.ownerId) {
                              return(
                                <td key={index}>
                                  <RouterLink
                                    to={property?.ownerId ? `${ROUTES.OWNER_PAGE}/${property?.ownerId}` : false}
                                    target="_blank"
                                    ref="noopener noreferrer"
                                    className={styles.btnOwnerRedirect}
                                    onClick={() => this.handleOwnerRedirect(property?.ownerId)}
                                  >
                                    {property?.[dataRow?.key]}
                                  </RouterLink>
                                </td>
                              );
                            }
                            return (
                              <td
                                key={index}
                                className={cn(
                                  index === 0 ? (this.handleMinMaxValueMarkup(dataRow, property, "min") && showDifferences ? styles.minValue :
                                    this.handleMinMaxValueMarkup(dataRow, property, "max") && showDifferences ? styles.maxValue :
                                      undefined) : undefined
                                )}
                                style={dataRow?.key === "amenities" ? {verticalAlign: "top", paddingTop: "1.6rem", paddingBottom: "1.6rem"} : undefined}
                              >
                                <span className={styles.cellValueWrapper}>
                                  <span className={styles.cellValue}>
                                  {
                                  dataRow?.key === "rent" ? toNumberFormat(rentRange.join(" - "), 'range', '', '€') :
                                    dataRow?.key === "serviceChargesPln" ? toNumberFormat(property?.[dataRow?.key], 'currency', 'PLN') :
                                      dataRow?.key === "commonAreaFactor" ? toNumberFormat(property?.[dataRow?.key], '%') :
                                        dataRow?.key === "totalAreaAvailable" ? toNumberFormat(property?.[dataRow?.key], 'area') :
                                          dataRow?.key === "certificates" ? this.getCertificates(property) :
                                              dataRow?.key === "amenities" ? <BadgeList badges={property?.[dataRow?.key]} simple collapsedLimit={amenitiesLimit}/> :
                                                dataRow?.key === "vacancyRate" ? toNumberFormat(property?.[dataRow?.key], '%') :
                                                  dataRow?.key === "plannedSpaceVacancy" ? toNumberFormat(property?.[dataRow?.key], 'area') :
                                                    dataRow?.key === "waitingForChangeAvgLastSixMonths" && handleEmptyValue(property?.[dataRow?.key], true) ? <FormattedMessage id="BUILDING.PHRASE.REDD_INDEX_DAYS" values={{days: Number(property?.[dataRow?.key]).toFixed(0)}}/> :
                                                      dataRow?.key === "leaseUptakeLastSixMonths" ? toNumberFormat(property?.[dataRow?.key], 'area', null, null, <span style={{fontWeight: 400, opacity: 0.7}}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span>) :
                                                        dataRow?.key === "newVacancyLastSixMonths" ? toNumberFormat(property?.[dataRow?.key], 'area', null, null, <span style={{fontWeight: 400, opacity: 0.7}}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span>) :
                                                          dataRow?.key === "dealDone" ? toNumberFormat(property?.["lastTransaction"]?.area, 'area', null, null, <span style={{fontWeight: 400, opacity: 0.7}}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span>) :
                                                            dataRow?.key === "storageHeight" ? toNumberFormat(property?.[dataRow?.key], 'm') :
                                                              dataRow?.key === "flooringCarrier" ? toNumberFormat(property?.[dataRow?.key], <span>kg/{areaFormat()}</span>) :
                                                                dataRow?.key === "rentWarehouseFrom" ? toNumberFormat(property?.[dataRow?.key], 'currency') :
                                                                  dataRow?.key === "serviceWarehouseFrom" ? toNumberFormat(property?.[dataRow?.key], 'currency', 'PLN') :
                                                                    dataRow?.key === "industrialUnitMinArea" ? toNumberFormat(property?.[dataRow?.key], 'area') :
                                                                      dataRow?.key === "commissioningYears" ? toNumberFormat(commissioningYears, 'range', '', '') :
                                                            handleEmptyValue(property?.[dataRow?.key], true) ? property?.[dataRow?.key] :
                                                              (
                                                                dataRow?.key === "waitingForChangeAvgLastSixMonths" ||
                                                                dataRow?.key === "leaseUptakeLastSixMonths" ||
                                                                dataRow?.key === "newVacancyLastSixMonths" ||
                                                                dataRow?.key === "dealDone"
                                                              ) ? <span style={{fontWeight: 400, opacity: 0.7}}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span> : "-"
                                  }
                                  </span>
                                  <span className={cn(
                                    styles.cellPercentageValue,
                                    dataRow?.key !== "rent" &&
                                    dataRow?.key !== "serviceChargesPln" &&
                                    dataRow?.key !== "rentWarehouseFrom" &&
                                    dataRow?.key !== "serviceWarehouseFrom" ? (
                                    this.handlePercentageMarkup(dataRow, property) === "-" ?
                                      styles.percentagePositive :
                                      this.handlePercentageMarkup(dataRow, property) === "+" ?
                                        styles.percentageNegative : undefined
                                    ) : undefined
                                  )}>
                                  {
                                  dataRow?.key === "rent" ? this.getPercentageValue(property?.rentFromEur, property?.[dataRow?.[`key${period}`]]) :
                                    dataRow?.key === "dealDone" && handleEmptyValue(property?.["lastTransaction"]?.createdAt, true) ? toDateFormat(property?.["lastTransaction"]?.createdAt) :
                                    this.getPercentageValue(property?.[dataRow?.key], property?.[dataRow?.[`key${period}`]])
                                  }
                                  </span>
                                </span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }) : null}
                </React.Fragment>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}