import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {updateIndustrialFile} from "app/crud/estates/industrial/industrial.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import SingleSelect from "app/partials/components/SingleSelect";
import {Grid, Typography} from '@material-ui/core';
import {FILTERS_SELECTS} from "../../../../partials/components/FiltersDrawer/constants";
import InputSwitcher from "../../../../partials/components/InputSwitcher";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";

class IndustrialUpdateFile extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        const {data} = this.props;
        this.state = {
            name: data?.name || '',
            nameEn: data?.nameEn || '',
            fileCategory: data?.fileCategory || '',
            isGalleryIncluded: data?.isGalleryIncluded || false
        };
    }

    handleCloseModal = () => {
        this.child.current && this.child.current.handleClose();
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleChangeSelect = (selected, target) => {
        this.setState({
            [target]: selected
        });
    };
    handleChangeSwitcher = name => event => {
        this.setState({
            [name]: event.target.checked
        });
    };
    handleUpdateFile = () => {
        const {updateIndustrialFile, fileID, dataSource} = this.props;
        const data = this.state;

        updateIndustrialFile && fileID && updateIndustrialFile(fileID, data, dataSource);
        this.handleCloseModal();
    }

    render() {
        const {loading} = this.props;
        const {name, nameEn, fileCategory, isGalleryIncluded} = this.state;
        if (loading) {
            return <LoadingScreen/>;
        }

        return (
            <ModalCustom
                ref={this.child}
                btn={
                    <ModalTrigger
                        icon="edit"
                        label={<FormattedMessage id="GENERAL.PHRASE.UPDATE_FILE_DETAILS"/>}
                        directIcon
                    />
                }
                title={<FormattedMessage id="GENERAL.PHRASE.UPDATE_FILE_DETAILS"/>}
                handleSave={this.handleUpdateFile}
                btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
            >
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <InputIcon
                            label={<FormattedMessage id="GENERAL.PHRASE.FILE_NAME_PL"/>}
                            name="name"
                            value={name}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <InputIcon
                            label={<FormattedMessage id="GENERAL.PHRASE.FILE_NAME_EN"/>}
                            name="nameEn"
                            value={nameEn}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <SingleSelect
                            label={<FormattedMessage id="GENERAL.PHRASE.FILE_CATEGORY"/>}
                            inputName="fileCategory"
                            options={FILTERS_SELECTS.INDUSTRIAL_FILES_CATEGORIES}
                            optionsValue="id"
                            optionsLabel="label"
                            defaultSelect={fileCategory}
                            onSelectedValues={this.handleChangeSelect}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item><Typography><FormattedMessage
                                id="INDUSTRIAL.PHRASE.DISPLAY_IN_GALLERY"/></Typography></Grid>
                            <Grid item>
                                <InputSwitcher checked={isGalleryIncluded} value="isGalleryIncluded"
                                               label={<FormattedMessage id="GENERAL.PHRASE.YES"/>}
                                               onChange={this.handleChangeSwitcher("isGalleryIncluded")}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ModalCustom>
        );
    }
}

const mapStateToProps = store => ({
    loading: store.industrial.uploadParkFileLoading
});
const mapDispatchToProps = {
    updateIndustrialFile: (fileID, data, dataSource) => updateIndustrialFile(fileID, data, dataSource)
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IndustrialUpdateFile)
);