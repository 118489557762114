import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {downloadIndustrialParkFiles} from "app/crud/estates/industrial/industrial.crud";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {downloadFileBase64} from "../../../../utils/helpers";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";

class IndustrialDownloadFiles extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.downloadData && prevProps.downloadData !== this.props.downloadData) {
            downloadFileBase64('test', this.props.downloadData);
        }
    }

    handleDownloadFiles = () => {
        const {parkID, selectedFiles, parentData, intl, directDownload, downloadIndustrialParkFiles} = this.props;
        if(parkID && selectedFiles && selectedFiles.length) {
            const fileName = directDownload ? `${parentData.parentName}-${parentData.fileName}` : `${parentData.parentName}-${intl.formatMessage({id: 'files'})}`;
            downloadIndustrialParkFiles(parkID, selectedFiles, fileName);
        }
    }
    render() {
        const {downloading, label, btnMultiselect} = this.props;

        if(downloading) {
            return <LoadingScreen/>;
        }
        else if(btnMultiselect) {
            return(
              <ModalTrigger
                icon="download"
                label={<FormattedMessage id="GENERAL.PHRASE.DOWNLOAD_SELECTED_FILES"/>}
                color="danger"
                normalCase
                styleDefault={{
                  color: "#ff4626",
                  paddingTop: "0",
                  paddingBottom: "0"
                }}
                onClick={() => this.handleDownloadFiles()}
              />
            );
        }
        return(
            <ModalTrigger
                label={label}
                icon="download"
                color="danger"
                directIcon
                onClick={() => this.handleDownloadFiles()}
            />
        );
    }
}

const mapStateToProps = store => ({
    downloading: store.industrial.parkFilesDownloading,
    downloadData: store.industrial.parkDownloadFilesData
});
const mapDispatchToProps = {
    downloadIndustrialParkFiles: (parkID, data, fileName) => downloadIndustrialParkFiles(parkID, data, fileName)
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(IndustrialDownloadFiles)
);