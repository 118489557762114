import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Checkbox,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography
} from "@material-ui/core";
import TableOfficeCells from "app/partials/components/TableProperties/TableCells/TableOfficeCells";
import TableOfficeUnitsCells from "app/partials/components/TableProperties/TableCells/TableOfficeUnitsCells";
import TableUnitsCells from "app/partials/components/TableProperties/TableCells/TableUnitsCells";
import {ROUTES, UNIT_STATUS} from "app/constants";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import TableShortlistsCells from "app/partials/components/TableProperties/TableCells/TableOffersCells";
import TableShortlistUnitsCells from "app/partials/components/TableProperties/TableCells/TableOffersOfficeUnitsCells";
import TableActionsLandlord from "app/partials/components/TableProperties/TableActions/TableActionsLandlord";
import TableTeamCells from "app/partials/components/TableProperties/TableCells/TableTeamCells";
import TableSubscribersCells from "app/partials/components/TableProperties/TableCells/TableSubscribersCells";
import {FormattedMessage} from "react-intl";
import store from "app/store/store";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {filterByModules} from "app/crud/estates/filters.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import TableOfficesPremiumCells from "app/partials/components/TableProperties/TableCells/TableOfficesPremiumCells";
import styles from "./index.module.scss";
import NoData from "app/partials/components/NoData/NoData";
import TableOwnersCells from "app/partials/components/TableProperties/TableCells/TableOwnersCells";
import TableOwnerPortfolioCells from "app/partials/components/TableProperties/TableCells/TableOwnerPortfolioCells";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";
import GenerateShortlistPDFMultiSelect from "app/pages/offices/OffersPage/components/GenerateShortlistPDFMultiSelect";
import TableAcquisitionCells from "./TableCells/TableAcquisitionCells";
import TableAcquisitionOfficeCells from "./TableCells/TableAcquisitionOfficeCells";
import TableAcquisitionOwnerCells from "./TableCells/TableAcquisitionOwnerCells";
import TableShortlistsLandlordCells from "./TableCells/TableOffersLandlordCells";
import TableShortlistsLandlordUnitsCells from "./TableCells/TableOffersLandlordOfficeUnitsCells";
import {updateUserMarket} from "../../../crud/user.crud";
import ShortlistAddNew from "app/partials/components/Shortlists/ShortlistAddNew";
import {fetchOfficeList} from "app/crud/estates/offices/offices.crud";
import {fetchUnitsList} from "app/crud/estates/offices/units.crud";
import TablePaginationCustom from "app/partials/components/TableProperties/TablePaginationCustom";
import UnitAddToExistingShortlist from "app/partials/components/Units/UnitAddToExistingShortlist";
import UnitAddToNewShortlist from "app/partials/components/Units/UnitAddToNewShortlist";
import ParksPageTableRow from "../../../pages/industrial/ParksPage/components/ParksPageTableRow";
import WarehousesPageTableRow from "../../../pages/industrial/WarehousesPage/components/WarehousesPageTableRow";
import WarehousesPageWarehouseUnitsTableRow
  from "../../../pages/industrial/WarehousesPage/components/WarehousesPageWarehouseUnitsTableRow";
import ParksPageParkWarehousesTableRow
  from "../../../pages/industrial/ParksPage/components/ParksPageParkWarehousesTableRow";
import TableActionsIndustrial from "./TableActions/TableActionsIndustrial";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import IndustrialDownloadFiles from "../../../pages/industrial/components/IndustrialFiles/IndustrialDownloadFiles";
import TableActionsLandlordOfficeUnits from "./TableActions/TableActionsLandlord/TableActionsLandlordOfficeUnits";
import IndustrialUnitRefreshAll from "../../../pages/industrial/components/ActionsUnits/IndustrialUnitRefreshAll";
import IndustrialUnitRefreshSelected
  from "../../../pages/industrial/components/ActionsUnits/IndustrialUnitRefreshSelected";
import {isFiltersActive} from "app/partials/components/FiltersManageBar/constants";
import ParkAddToNewShortlist from "./TableActions/TableActionsIndustrial/components/ParkAddToNewShortlist";
import ParkAddToExistingShortlist from "./TableActions/TableActionsIndustrial/components/ParkAddToExistingShortlist";
import TableShortlistsCellsIndustrial from "./TableCells/TableOffersIndustrialCells";
import TableShortlistParksCell from "./TableCells/TableOffersParksCell";
import ShortlistAddNewIndustrial from "../Shortlists/ShortlistAddNewIndustrial";
import TableLeadsManagementCells from "./TableCells/TableLeadsManagementCells";
import LeadsManagementAddProperty
  from "../../../pages/common/LeadsManagementPage/components/LeadsManagementAddProperty";
import OfficeAddUnit from "../Offices/OfficeAddUnit";
import LeadsManagementAddLead from "../../../pages/common/LeadsManagementPage/components/LeadsManagementAddLead";
import ButtonCustom from "../ButtonCustom";
import IconColorMUI from "../IconColorMUI";
import ReactVisibilitySensor from "react-visibility-sensor";
import TooltipCustom from "../TooltipCustom";
import IsAdmin from "../../../utils/IsAdmin";
import IsPro from "../../../utils/IsPro";
import PaywallBannerRowTable from "../PayWall/banners/PaywallBannerRowTable";
import IsAccountType from "../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";
import {fetchIndustrialParks, fetchIndustrialWarehouses} from "../../../crud/estates/industrial/industrial.crud";
import InputSwitcher from "../InputSwitcher";
import TableAdminManageAccountRequests from "./TableCells/TableAdminManageAccountRequests";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headRows,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const renderLabelTooltip = (label, tooltip) => {
    if (tooltip && label) {
      return (
        <TooltipCustom
          placement="bottom"
          title={<FormattedMessage id={tooltip}/>}
        >
          <span style={{display: "flex", alignItems: "center"}}>
            <FormattedMessage id={label}/>
            <IconColorMUI icon="info_outline" color="danger" size="16px" style={{fontSize: 0, marginLeft: 5}}/>
          </span>
        </TooltipCustom>
      );
    }
    return label ? <FormattedMessage id={label}/> : "";
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => {
          const premiumCell =
            row.id === "recent_freed_modules_no" ||
            row.id === "recent_rent_change" ||
            row.id === "recent_rented_modules_no" ||
            row.id === "last_transaction_date";
          if (row.id === "checkable") {
            return (
              <TableCell key={row.id} padding="checkbox">
                {rowCount !== 0 && (
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": (
                        <FormattedMessage id="TABLE.ACTIONS.SELECT_ALL"/>
                      ),
                    }}
                  />
                )}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === row.id ? order : false}
                className={premiumCell ? styles.premiumCellHead : ""}
              >
                {row.sort ? (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    style={{flexDirection: 'row'}}
                    onClick={createSortHandler(row.id)}
                  >
                    <Typography
                      className={clsx(
                        premiumCell ? styles.premiumCellHeadText : "",
                        styles.headCell
                      )}
                    >
                      {renderLabelTooltip(row.label, row.infoTooltip)}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography
                    className={clsx(
                      premiumCell ? styles.premiumCellHeadText : "",
                      styles.headCell
                    )}
                  >
                    {renderLabelTooltip(row.label, row.infoTooltip)}
                  </Typography>
                )}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: "#2d2d2d",
        backgroundColor: "#ffffff",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center"
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [onlyPromoted, setOnlyPromoted] = useState("");
  const {
    rowData,
    numSelected,
    tableLabel,
    titleAdvanced,
    dataTarget,
    selected,
    parentId,
    parentData,
    parentPreviewData,
    onShowUnavailableUnits,
    onShowOwnedBuildings,
    onShowPromoted,
    itemsCount,
    totalItems,
    totalAvailableItems,
    isShowUnavailable,
    isShowOwned,
    user,
    teamActiveSubsCount,
    dataSource,
    officeUnitsUnavailable,
    unitsInfo,
    onSetSelected
  } = props;
  const totalUnavailable = totalItems - totalAvailableItems;
  const allSelected = isShowUnavailable
    ? totalUnavailable
    : totalAvailableItems;

  if (!tableLabel) {
    return false;
  }
  // Handler Only Promoted
  const handleOnlyPromoted = event => {
    const isChecked = event?.target?.checked;
    const value = isChecked ? "onlyPromoted" : "";
    setOnlyPromoted(value);
    onShowPromoted(isChecked);
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <div className={classes.actions}>
            {dataTarget === TABLE_CELLS.UNITS && (
              <div className={styles.multiselectOptions}>
                <UnitAddToExistingShortlist
                  units={selected}
                  inactive={numSelected > 30}
                  btn
                  btnMultiselect
                  solid
                  onComplete={onSetSelected}
                />
                <UnitAddToNewShortlist
                  units={selected}
                  inactive={numSelected > 30}
                  btn
                  btnMultiselect
                  solid
                  onComplete={onSetSelected}
                />
              </div>
            )}
            {dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS && (
              <div className={styles.multiselectOptions}>
                <ParkAddToNewShortlist
                  rowData={rowData}
                  parks={selected}
                  btn
                  btnMultiselect
                  solid
                  inactive={numSelected > 30}
                />
                <ParkAddToExistingShortlist
                  rowData={rowData}
                  parks={selected}
                  btn
                  btnMultiselect
                  solid
                  inactive={numSelected > 30}
                />
                <IsAdmin>
                  <LeadsManagementAddProperty propertyID={selected} btnMode/>
                </IsAdmin>
              </div>
            )}
            <IsAdmin>
              {(
                dataTarget === TABLE_CELLS.OFFICES ||
                  dataTarget === TABLE_CELLS.UNITS ||
                  dataTarget === TABLE_CELLS.OFFICE_UNITS ||
                  dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS
              ) &&
              <div className={styles.multiselectOptions}>
                <LeadsManagementAddProperty
                  propertyID={selected}
                  propertyData={rowData}
                  isUnitView={dataTarget === TABLE_CELLS.UNITS}
                  isOfficeUnitView={dataTarget === TABLE_CELLS.OFFICE_UNITS}
                  isIndustrialBuildingsView={dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS}
                  btnMode
                />
              </div>
              }
            </IsAdmin>
            {dataTarget === TABLE_CELLS.OFFICE_UNITS &&
              !isShowUnavailable && (
                <TableActionsLandlordOfficeUnits
                  parentId={parentId}
                  units={selected}
                  totalItems={totalItems}
                  totalAvailableItems={totalAvailableItems}
                  isShowUnavailable={isShowUnavailable}
                />
            )}
            {dataTarget === TABLE_CELLS.INDUSTRIAL_FILES && (
              <IndustrialDownloadFiles
                parkID={parentId}
                parentData={{
                  parentName: parentData?.propertyName,
                }}
                selectedFiles={selected}
                btnMultiselect
              />
            )}
            {dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS && parentData?.isEditable && (
              <Grid container spacing={2}>
                {selected.length === allSelected && (
                  <Grid item>
                    <IndustrialUnitRefreshAll
                      warehouseID={parentId}
                      directBtn
                      solid
                    />
                  </Grid>
                )}
                {selected.length !== allSelected && selected.length > 0 && (
                  <Grid item>
                    <IndustrialUnitRefreshSelected
                      warehouseID={parentId}
                      units={selected}
                      directBtn
                      solid
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Typography
              color="inherit"
              variant="subtitle1"
              style={{marginLeft: 15}}
            >
              <FormattedMessage
                id="selected"
                values={{count: numSelected, selected: numSelected}}
              />
            </Typography>
          </div>
        ) : titleAdvanced ? (
          tableLabel
        ) : (
          <Typography variant="h3" id="tableTitle">
            {isShowOwned ?
              (
                dataSource === "units" ? <FormattedMessage id="GENERAL.PHRASE.UNITS_IN_YOUR_BUILDINGS"/> : <FormattedMessage id="GENERAL.PHRASE.YOUR_PROPERTIES"/>
              ) : tableLabel
            }
            {itemsCount && itemsCount > 0 ? (
              <FormattedMessage
                id="GENERAL.PHRASE.TOTAL_RESULTS"
                values={{
                  results: (
                    <span className={styles.resultsValue}>{itemsCount}</span>
                  ),
                  prefix: " - ",
                }}
              />
            ) : (
              ""
            )}
          </Typography>
        )}
        {dataTarget === TABLE_CELLS.SHORTLISTS &&
          <span style={{paddingLeft: "2rem"}}><ShortlistAddNew/></span>
        }
        {dataTarget === TABLE_CELLS.INDUSTRIAL_SHORTLIST &&
          <span style={{paddingLeft: "2rem"}}><ShortlistAddNewIndustrial/></span>
        }
      </div>
      <div className={classes.spacer}/>
      <div className={classes.title}>
        {numSelected <= 0 ? (
          <>
            {
              (
                dataTarget === TABLE_CELLS.OFFICES ||
                dataTarget === TABLE_CELLS.UNITS ||
                dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS
              ) &&
              <IsAdmin markup="font">
                <InputSwitcher
                  checked={onlyPromoted === "onlyPromoted"}
                  value="onlyPromoted"
                  label={<FormattedMessage id="GENERAL.PHRASE.ONLY_PROMOTED" />}
                  onChange={(e) => handleOnlyPromoted(e)}
                  style={{paddingRight: "1rem"}}
                />
              </IsAdmin>
            }
            <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
              <TableActionsLandlord
                dataTarget={dataTarget}
                parentId={parentId}
                onShowUnavailableUnits={onShowUnavailableUnits}
                onShowOwnedBuildings={onShowOwnedBuildings}
                parentData={parentData}
                user={user}
                teamActiveSubsCount={teamActiveSubsCount}
                officeUnitsUnavailable={officeUnitsUnavailable}
                total={totalItems}
              />
            </IsAccountType>
            {dataTarget === TABLE_CELLS.SHORTLISTS &&
              <ButtonCustom
                label={<FormattedMessage id="OFFERS.PHRASE.STATISTICS" />}
                redirect={ROUTES.OFFERS_ONLINE_ANALYSIS}
                color="danger"
                solid
                icon="bar_chart"
              />
            }
            {dataTarget === TABLE_CELLS.SHORTLIST_UNITS && (
              <GenerateShortlistPDFMultiSelect
                shortlistId={parentId}
                shortlistName={parentPreviewData.name}
                directBtn
              />
            )}
            {/* INDUSTRIAL TABLE ACTIONS */}
            {dataTarget === TABLE_CELLS.INDUSTRIAL_SHORTLIST &&
              <ButtonCustom
                label={<FormattedMessage id="OFFERS.PHRASE.STATISTICS" />}
                redirect={ROUTES.INDUSTRIAL_OFFERS_ONLINE_ANALYSIS}
                color="danger"
                solid
                icon="bar_chart"
              />
            }
            <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
              {(dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS ||
                dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS ||
                dataTarget === TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES ||
                dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS ||
                dataTarget === TABLE_CELLS.INDUSTRIAL_FILES) && (
                <TableActionsIndustrial
                  dataSource={dataSource}
                  dataTarget={dataTarget}
                  isShowUnavailable={isShowUnavailable}
                  onShowUnavailableUnits={onShowUnavailableUnits}
                  onShowOwnedBuildings={onShowOwnedBuildings}
                  parentData={parentData}
                  parentId={parentId}
                  unitsInfo={unitsInfo}
                />
              )}
            </IsAccountType>
            <IsAdmin>
              {dataTarget === TABLE_CELLS.LEADS_MANAGEMENT && <LeadsManagementAddLead/>}
            </IsAdmin>
          </>
        ) : (
          dataTarget === TABLE_CELLS.UNITS && (
            <Typography variant="body1" style={{color: "#ff4626"}}>
              <FormattedMessage id="You can add up to 30 units to a single offer."/>
            </Typography>
          )
        )}
      </div>
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  filterBarEnable: {
    height: "calc(100% - 5rem)",
    marginTop: "5rem",
  },
  container: {
    flexGrow: 3,
  },
  containerFilters: {
    flexGrow: 3,
  },
  containerDrawer: {
    maxHeight: "31.4rem",
  },
  table: {
    minWidth: "100%",
  },
  isDeleted: {
    backgroundColor: "rgba(192,27,51,0.3)",
  },
  tableLoading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
  tableLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-30px",
    marginLeft: "-30px",
  },
  boxShadowDisabled: {
    boxShadow: "none !important",
  },
}));

function TableProperties(props) {
  const {
    boxShadowDisabled,
    userMarket,
    headRows,
    bodyRows,
    itemsPage = 1,
    tableLabel,
    titleAdvanced,
    itemsPerPage,
    itemsCount,
    totalItems,
    handleFetchData,
    dataTarget,
    parentId,
    parentData,
    parentPreviewData,
    loading,
    maxHeight = "auto",
    minHeight = "auto",
    dataSource,
    setLatestActiveTablePage,
    latestPage,
    updateUserMarket,
    fetchOfficeList,
    fetchUnitsList,
    fetchIndustrialParks,
    fetchIndustrialWarehouses,
    user,
    currentApplicationView,
    filterBarEnable,
    officeUnitsUnavailable,
    onEnterViewport
  } = props;
  const classes = useStyles();
  const activeSubsResult = user && user.team && user.team.activeSubs;
  const initialOrderBy =
    dataTarget === TABLE_CELLS.UNITS
      ? "updated_at"
      : (
        dataTarget === TABLE_CELLS.OFFICES ||
        dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS ||
        dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS
      )
        ? "updated_at_by_user"
        : dataTarget === TABLE_CELLS.OFFICE_UNITS
          ? "floor"
          : dataTarget === TABLE_CELLS.COWORKS
            ? "available_desks"
            : dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RELEASES
              ? "recent_freed_modules_no"
              : dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENT_CHANGED
                ? "recent_rent_change"
                : dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENTED
                  ? "recent_rented_modules_no"
                  : dataTarget === TABLE_CELLS.OFFICES_PREMIUM_LATEST_SOLD_OFFICES
                    ? "last_transaction_date"
                    : dataTarget === TABLE_CELLS.ACQUISITIONS
                      ? "transaction_date"
                      : dataTarget === TABLE_CELLS.OWNERS
                        ? "ownerdetail__portfolio"
                        : "";
  const initialRowsPerPage = 20;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(initialOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [showUnavailable, setShowUnavailable] = React.useState(false);
  const [showOwned, setShowOwnedBuildings] = React.useState(false);
  const [showPromoted, setShowPromoted] = React.useState(false);
  const [page, setPage] = React.useState(latestPage || itemsPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [teamActiveSubsCount, setTeamActiveSubsCount] = React.useState(
    activeSubsResult || 0
  );
  const [dense] = React.useState(false);
  const isDrawerTable =
    dataTarget === TABLE_CELLS.OFFICE_UNITS ||
    dataTarget === TABLE_CELLS.COWORK_DESKS;
  const totalPages = Math.ceil(itemsCount / rowsPerPage);

  // Did Update
  const mounted = useRef();
  const tableScrolled = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (store.getState().filters.pageReset) {
        setPage(1);
        setOrderBy(initialOrderBy);
        setOrder("desc");
        setRowsPerPage(initialRowsPerPage);
        const initParams = {
          pageReset: false,
          order: "desc",
          orderBy: initialOrderBy,
          size: initialRowsPerPage,
        };
        props.filterByModules(initParams);
      } else {
        setPage(latestPage);
      }
    }
  }, [setPage, props, latestPage, initialOrderBy]);
  useEffect(() => {
    const isShowOwnedOn = store.getState().filters?.isEditable === "true";
    const isShowPromotedOn = store.getState().filters?.onlyPromoted === "true";
    setShowOwnedBuildings(isShowOwnedOn);
    setShowPromoted(isShowPromotedOn);
  }, [setShowOwnedBuildings, setShowPromoted]);

  // HANDLE Table Pagination and Sorting
  function handleChangePage(event, newPage) {
    setPage(newPage);
    const params = {
      page: newPage,
      ...store.getState().filters,
    };
    handleFetchData({params});
    // setSelected([]);
    setLatestActiveTablePage(newPage);
    tableScrolled.current.scrollTop = 0;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    const orderVal = isDesc ? "" : "-";
    const orderByVal = (property === "updated_at" || property === "updated_at_by_user") ? "" : property;
    const paramsOrder = {order: orderVal, orderBy: orderByVal};
    props.filterByModules(paramsOrder);
    const params = {
      ...props.filters,
      order: orderVal,
      orderBy: orderByVal,
    };
    handleFetchData({params});
    setPage(1);
  }

  // HANDLE Table Row Selects
  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds =
        bodyRows &&
        Array.isArray(bodyRows) &&
        bodyRows
          .filter((row) => {
            if (
              (dataTarget === TABLE_CELLS.OFFICE_UNITS && officeUnitsUnavailable) ||
              (dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS)
            ) {
              if (showUnavailable) {
                return row.status === UNIT_STATUS.UNAVAILABLE;
              }
              return row.status < UNIT_STATUS.UNAVAILABLE;
            }
            return true;
          })
          .map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    }
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  // HANDLE Show Unavailable Units
  function handleShowUnavailableUnits(showUnavailable) {
    setShowUnavailable(showUnavailable);
  }

  // HANDLE Show Only Owned Buildings
  function handleShowOnlyOwnedBuildings(showOwned) {
    setShowOwnedBuildings(showOwned);
    let params = {
      ...store.getState().filters,
      isEditable: showOwned ? "true" : "",
    };
    props.filterByModules(params);
    if (dataTarget === TABLE_CELLS.OFFICES) {
      fetchOfficeList({params});
    }
    else if (dataTarget === TABLE_CELLS.UNITS) {
      fetchUnitsList({params});
    }
    else if (dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS) {
      fetchIndustrialParks({params});
    }
    else if (dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS) {
      fetchIndustrialWarehouses({params});
    }
  }
  // HANDLE Show Only Promoted (Admin)
  function handleShowOnlyPromoted(showPromoted) {
    setShowPromoted(showPromoted);
    let params = {
      ...store.getState().filters,
      onlyPromoted: showPromoted ? "onlyPromoted" : "",
    };
    props.filterByModules(params);
    if (dataTarget === TABLE_CELLS.OFFICES) {
      fetchOfficeList({params});
    }
    else if (dataTarget === TABLE_CELLS.UNITS) {
      fetchUnitsList({params});
    }
    else if (dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS) {
      fetchIndustrialParks({params});
    }
    else if (dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS) {
      fetchIndustrialWarehouses({params});
    }
  }

  // HANDLE Change Items Per Page
  function handleChangeItemsPerPage(event) {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    props.filterByModules({size: newRowsPerPage});
    const params = {
      ...props.filters,
      size: newRowsPerPage,
    };
    handleFetchData({params});
    setPage(1);
  }

  // HANDLE Update User Team Active Subscriptions Count
  function handleUpdateUserTeamActiveSubscriptions() {
    setTeamActiveSubsCount(teamActiveSubsCount + 1);
  }

  const isSelected = (unitId) => selected.indexOf(unitId) !== -1;
  const emptyRows =
    bodyRows &&
    Array.isArray(bodyRows) &&
    bodyRows.filter((row) => {
      if (officeUnitsUnavailable && dataTarget === TABLE_CELLS.OFFICE_UNITS) {
        if (showUnavailable) {
          return row.status === UNIT_STATUS.UNAVAILABLE;
        }
        return row.status < UNIT_STATUS.UNAVAILABLE;
      }
      return true;
    }).length;

  return (
    <Paper
      className={clsx(
        classes.root,
        boxShadowDisabled && classes.boxShadowDisabled,
        filterBarEnable && isFiltersActive() && classes.filterBarEnable
      )}
    >
      <ReactVisibilitySensor
          onChange={(isVisible) => onEnterViewport && onEnterViewport(isVisible)}
          scrollCheck
          partialVisibility
      >
        <EnhancedTableToolbar
          rowData={bodyRows}
          selected={selected}
          numSelected={selected.length}
          totalItems={bodyRows && Array.isArray(bodyRows) && bodyRows.length}
          itemsCount={itemsCount}
          unitsInfo={
            bodyRows &&
            Array.isArray(bodyRows) &&
            bodyRows.filter((row) => row.status < UNIT_STATUS.UNAVAILABLE)
          }
          totalAvailableItems={
            bodyRows &&
            Array.isArray(bodyRows) &&
            bodyRows.filter((row) => row.status < UNIT_STATUS.UNAVAILABLE).length
          }
          tableLabel={tableLabel}
          titleAdvanced={titleAdvanced}
          dataTarget={dataTarget}
          dataSource={dataSource}
          parentId={parentId}
          parentData={parentData}
          parentPreviewData={parentPreviewData}
          onShowUnavailableUnits={handleShowUnavailableUnits}
          isShowUnavailable={showUnavailable}
          onShowOwnedBuildings={handleShowOnlyOwnedBuildings}
          isShowOwned={showOwned}
          onShowPromoted={handleShowOnlyPromoted}
          isShowPromoted={showPromoted}
          user={props.user}
          teamActiveSubsCount={teamActiveSubsCount}
          officeUnitsUnavailable={officeUnitsUnavailable}
          onSetSelected={setSelected}
        />
      </ReactVisibilitySensor>
      {loading ? (
        <div className={classes.tableLoading}>
          <div className={classes.tableLoader}>
            <LoadingScreen/>
          </div>
        </div>
      ) : emptyRows === 0 ? (
        dataTarget === TABLE_CELLS.OFFICE_UNITS &&
        parentData?.isEditable ? (
          <>
            <NoData
              message={<FormattedMessage id="GENERAL.PHRASE.NO_RESULTS_FOUND"/>}
              extraMessage={
                <>
                  <FormattedMessage id="To add a new unit, you must first uncheck - "/>
                  <strong><FormattedMessage id="Lack of available area."/></strong>
                </>
              }
              limitedHeight
              action={
              <OfficeAddUnit
                officeId={parentId}
                officeStatus={parentData?.status}
                lackOfAvailableArea={parentData?.lackOfAvailableArea}
                tooltipDisabled
              />
              }
            />
          </>
          ) :
        <NoData
          message={<FormattedMessage id="GENERAL.PHRASE.NO_RESULTS_FOUND"/>}
          limitedHeight
        />
      ) : (
        <>
          <TableContainer
            ref={tableScrolled}
            className={
              isDrawerTable
                ? classes.containerDrawer
                : document.getElementById("kt_subheader")
                  ? classes.containerFilters
                  : classes.container
            }
            style={{maxHeight: maxHeight, minHeight: minHeight}}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={
                  bodyRows &&
                  Array.isArray(bodyRows) &&
                  bodyRows.filter((row) => {
                    if (
                      officeUnitsUnavailable &&
                      dataTarget === TABLE_CELLS.OFFICE_UNITS
                    ) {
                      if (showUnavailable) {
                        return row.status === UNIT_STATUS.UNAVAILABLE;
                      }
                      return row.status < UNIT_STATUS.UNAVAILABLE;
                    }
                    return true;
                  }).length
                }
                headRows={headRows}
                totalItems={totalItems}
                itemsCount={itemsCount}
                dataTarget={dataTarget}
              />
              <TableBody>
                {bodyRows &&
                  Array.isArray(bodyRows) &&
                  bodyRows
                    .filter((row) => {
                      // INDUSTRIAL
                      if (
                        currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL
                      ) {
                        if (
                          dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS
                        ) {
                          if (showUnavailable) {
                            return row.status === UNIT_STATUS.UNAVAILABLE;
                          }
                          return row.status < UNIT_STATUS.UNAVAILABLE;
                        }
                      } else {
                        if (officeUnitsUnavailable) {
                          if (dataTarget === TABLE_CELLS.OFFICE_UNITS) {
                            if (showUnavailable) {
                              return row.status === UNIT_STATUS.UNAVAILABLE;
                            }
                            return row.status < UNIT_STATUS.UNAVAILABLE;
                          }
                        }
                        return true;
                      }
                      return true;
                    })
                    .map((row, index) => {
                      const isItemSelected = isSelected(row?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const officeDataSource =
                        dataTarget === TABLE_CELLS.OFFICE_UNITS
                          ? parentData
                          : row?.office;
                      const isEditable = officeDataSource?.isEditable;
                      const isPropertyList = dataTarget === TABLE_CELLS.OFFICES ||
                        dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS ||
                        dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS;

                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            className={row.isDeleted ? classes.isDeleted : ""}
                          >
                            {(
                                dataTarget === TABLE_CELLS.UNITS ||
                                (dataTarget === TABLE_CELLS.OFFICES && IsAdmin()) ||
                                (dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS && IsPro({})) ||
                                (dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS && IsAdmin()) ||
                                (dataTarget === TABLE_CELLS.OFFICE_UNITS && isEditable) ||
                                dataTarget === TABLE_CELLS.INDUSTRIAL_FILES ||
                                (dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS && parentData?.isEditable)
                              ) &&
                            <TableCell padding="checkbox">
                              <div
                                onClick={(event) => {
                                  if(selected?.length >= 30 && !isItemSelected) {
                                    return true;
                                  }
                                  else {
                                    handleClick(event, row.id)
                                  }
                                }
                                }
                                style={selected?.length >= 30 && !isItemSelected ? {
                                  opacity: 0,
                                  pointerEvents: "none"
                                } : undefined}
                              >
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </div>
                            </TableCell>
                            }
                            {dataTarget === TABLE_CELLS.OFFICES && (
                              <TableOfficeCells
                                officeID={row.id}
                                row={row}
                                dataTarget={dataTarget}
                                parentData={parentData}
                              />
                            )}
                            {(dataTarget ===
                              TABLE_CELLS.OFFICES_PREMIUM_LATEST_RELEASES ||
                              dataTarget ===
                              TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENT_CHANGED ||
                              dataTarget ===
                              TABLE_CELLS.OFFICES_PREMIUM_LATEST_RENTED ||
                              dataTarget ===
                              TABLE_CELLS.OFFICES_PREMIUM_LATEST_SOLD_OFFICES) && (
                              <TableOfficesPremiumCells
                                officeID={row.id}
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.OFFICE_UNITS && (
                              <TableOfficeUnitsCells
                                row={row}
                                dataTarget={dataTarget}
                                parentData={parentData}
                                isOfficeView={dataSource === "office"}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.UNITS && (
                              <TableUnitsCells
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.SHORTLISTS && (
                              <TableShortlistsCells
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.INDUSTRIAL_SHORTLIST && (
                              <TableShortlistsCellsIndustrial
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.SHORTLIST_UNITS && (
                              <TableShortlistUnitsCells
                                row={row}
                                dataTarget={dataTarget}
                                shortlistId={parentId}
                              />
                            )}
                            {dataTarget ===
                              TABLE_CELLS.INDUSTRIAL_SHORTLIST_PARKS && (
                                <TableShortlistParksCell
                                  row={row}
                                  dataTarget={dataTarget}
                                  shortlistId={parentId}
                                />
                              )}
                            {dataTarget === TABLE_CELLS.SHORTLIST_LANDLORD && (
                              <TableShortlistsLandlordCells
                                row={row}
                                dataTarget={dataTarget}
                                shortlistId={parentId}
                              />
                            )}
                            {dataTarget ===
                              TABLE_CELLS.INDUSTRIAL_SHORTLIST_LANDLORD && (
                                <TableShortlistsLandlordCells
                                  row={row}
                                  dataTarget={dataTarget}
                                  shortlistId={parentId}
                                />
                              )}
                            {dataTarget ===
                              TABLE_CELLS.SHORTLIST_LANDLORD_UNITS && (
                                <TableShortlistsLandlordUnitsCells
                                  row={row}
                                  dataTarget={dataTarget}
                                  shortlistId={parentId}
                                />
                              )}
                            {dataTarget === TABLE_CELLS.TEAM && (
                              <TableTeamCells
                                row={row}
                                dataTarget={dataTarget}
                                onActiveSubsChanged={
                                  handleUpdateUserTeamActiveSubscriptions
                                }
                              />
                            )}
                            {dataTarget === TABLE_CELLS.SUBSCRIBERS && (
                              <TableSubscribersCells
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.OWNERS && (
                              <TableOwnersCells
                                row={row}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.OWNER_PORTFOLIO && (
                              <TableOwnerPortfolioCells
                                row={row}
                                parentId={parentId}
                                userMarket={userMarket}
                                updateUserMarket={updateUserMarket}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.ACQUISITIONS && (
                              <TableAcquisitionCells
                                row={row}
                                parentId={parentId}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.ACQUISITIONS_OFFICE && (
                              <TableAcquisitionOfficeCells
                                row={row}
                                parentId={parentId}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.ACQUISITIONS_OWNER && (
                              <TableAcquisitionOwnerCells
                                row={row}
                                parentId={parentId}
                                dataTarget={dataTarget}
                              />
                            )}
                            {dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS && (
                              <ParksPageTableRow row={row}/>
                            )}
                            {dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS && (
                              <WarehousesPageTableRow row={row}/>
                            )}
                            {dataTarget ===
                              TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS && (
                                <WarehousesPageWarehouseUnitsTableRow
                                  row={row}
                                  parentData={parentData}
                                  parentId={parentId}
                                  unitsInfo={
                                    bodyRows &&
                                    Array.isArray(bodyRows) &&
                                    bodyRows.filter(
                                      (row) => row.status < UNIT_STATUS.UNAVAILABLE
                                    )
                                  }
                                />
                              )}
                            {dataTarget ===
                              TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES && (
                                <ParksPageParkWarehousesTableRow
                                  row={row}
                                  parentData={parentData}
                                  parentId={parentId}
                                />
                              )}
                            {dataTarget === TABLE_CELLS.LEADS_MANAGEMENT &&
                              <TableLeadsManagementCells
                                row={row}
                              />
                            }
                            {dataTarget === TABLE_CELLS.ADMIN_MANAGE_ACCOUNT_REQUESTS &&
                              <TableAdminManageAccountRequests
                                row={row}
                              />
                            }
                          </TableRow>
                          {
                            isPropertyList &&
                            IsPro({reverseCheck: true}) &&
                            (index === 3 || (index === (emptyRows-1)-4)) &&
                            <TableRow>
                              <TableCell colSpan={100}>
                                <PaywallBannerRowTable/>
                              </TableCell>
                            </TableRow>
                          }
                        </React.Fragment>
                      );
                    })
                }
                {emptyRows < 5 && emptyRows >= 1 && (
                  <TableRow style={{height: 48.5 * (5 - emptyRows)}}>
                    <TableCell colSpan={7} style={{borderBottom: 0}}/>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rowsPerPage && totalPages > 1 && (
            <div className={styles.tableFooter}>
              <div className={styles.rowsPerPageWrapper}>
                <Typography variant="body2">
                  <FormattedMessage id="GENERAL.PHRASE.Results per page"/>:
                </Typography>
                <Select
                  id="rowsPerPage"
                  defaultValue={itemsPerPage}
                  value={rowsPerPage}
                  onChange={handleChangeItemsPerPage}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </div>
              <TablePaginationCustom
                totalPages={totalPages}
                currentPage={page <= 0 ? 1 : page}
                onChangePage={handleChangePage}
              />
              <div/>
            </div>
          )}
        </>
      )}
    </Paper>
  );
}

const mapStateToProps = (store) => ({
  filters: store.filters,
  latestPage: store.tablePage.latestPage,
  currentMarket: store.user.data.marketName,
  userMarket: store.user.data.market,
  user: store.user.data,
  currentApplicationView: store.user.data.currentApplicationView,
  officeUnitsUnavailable: store.units.officeUnitsUnavailable,
  warehouseFiles: store.industrial.parkFiles,
});
const mapDispatchToProps = {
  filterByModules: (params) => filterByModules(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  updateUserMarket: (market, redirect) => updateUserMarket(market, redirect),
  fetchOfficeList: ({params}) => fetchOfficeList(params),
  fetchUnitsList: ({params}) => fetchUnitsList(params),
  fetchIndustrialParks: ({params}) => fetchIndustrialParks(params),
  fetchIndustrialWarehouses: ({params}) => fetchIndustrialWarehouses(params)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableProperties)
);
