import React from "react";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../content/Portlet";
import {FormattedMessage} from "react-intl";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import styles from "./index.module.scss";
import clsx from "classnames";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

class PortletAccordion extends React.Component {
  constructor(props) {
    super(props);
    const {isCollapsed} = props;

    this.state = {
      collapsed: isCollapsed || false
    }
  }
  // Handlers
  handleCollapsed = () => {
    const {trackingCode, callReddClickEvent} = this.props;

    this.setState({
      collapsed: !this.state.collapsed
    }, () => {
      if(trackingCode && callReddClickEvent) {
        callReddClickEvent(
          trackingCode,
          "user",
          null,
          null,
          {expanded: !this.state.collapsed}
        );
      }
    });
  };

  render() {
    const {title, children, toolbar, bodyFit, fluidHeight, disableAccordion, footer} = this.props;
    const {collapsed} = this.state;

    return(
      <Portlet style={{marginBottom: 0, minHeight: "100%"}} fluidHeight={fluidHeight}>
        <PortletHeader
          title={title}
          toolbar={
          !disableAccordion &&
          <div className={clsx(styles.toolbarActionsWrapper)}>
            <ModalTrigger
              icon={collapsed ? "expand_more" : "expand_less"}
              label={collapsed ? <FormattedMessage id="GENERAL.PHRASE.SHOW"/> : <FormattedMessage id="GENERAL.PHRASE.HIDE"/>}
              directIcon
              color="default"
              style={{fontSize: "30px"}}
              iconSizeInherit
              iconWithoutBg
              onClick={() => this.handleCollapsed()}
            />
          </div>
        }
          titleToolbar={!collapsed && toolbar}
        />
        <PortletBody
          className={clsx(styles.portletCollapse, collapsed && styles.collapsed)}
          fit={bodyFit}
        >
          {children}
        </PortletBody>
        {footer &&
        <PortletFooter className={styles.portletFooterWrapper}>
          {footer}
        </PortletFooter>
        }
      </Portlet>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default connect(null, mapDispatchToProps)(PortletAccordion);