import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {deleteShortlistIndustrial} from "app/crud/estates/industrial/offers-industrial.crud";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";

class ShortlistRemoveIndustrial extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const { deleteShortlistIndustrial, shortlistId } = this.props;
    deleteShortlistIndustrial({ shortlistId });
    this.handleCloseModal();
  };

  render() {
    const { directBtn, directIcon } = this.props;
    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="delete"
            label={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST" />}
            directBtn={directBtn}
            directIcon={directIcon}
            color="gray"
          />
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST" />}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE" />}
      >
        <Typography>
          <FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_MESSAGE" />{" "}
          <br />
          <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE" />
        </Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  deleteShortlistIndustrial: (shortlistId) => deleteShortlistIndustrial(shortlistId),
};

export default injectIntl(connect(null, mapDispatchToProps)(ShortlistRemoveIndustrial));
