import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {ROUTES} from "../../constants";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "app/partials/components/LoadingScreen";

// COMMON ROUTES
import * as CommonDashboard from "../common/DashboardPage";
import * as CommonTeam from "../common/TeamPage";
import * as CommonUserProfile from "../common/UserProfile";
import * as CommonFollowers from "../common/SubscribersPage";
import * as CommonLeadsManagement from "../common/LeadsManagementPage";
import * as CommonCompetitionAnalysis from "../common/CompetitionAnalysisPage";
import * as CommonSymmco from "../common/SymmcoPage";
import * as CommonAdminAddUser from "../common/AdminAddUserPage";
import * as CommonLatestTransactions from "../common/LatestTransactionsPage";
import * as CommonSupplyBoost from "../common/SupplyBoostPage";
import * as CommonAdminManageAccountRequests from "../common/AdminManageUserRequestsPage";
// ESTATE ROUTES
import * as OfficeProperty from "../offices/PropertyPage";
import * as EstatesBuildings from "../offices/BuildingsPage";
import * as EstatesUnits from "../offices/UnitsPage";
import * as EstatesOwners from "../offices/OwnersPage";
import * as EstatesOwner from "../offices/OwnerPage";
import * as EstatesAnalytics from "../offices/AnalyticsPage";
import * as EstatesOffers from "../offices/OffersPage";
import * as EstatesOffersAnalysis from "../offices/ShortlistsLandlordPage";
import * as EstatesAcquisitions from "../offices/AcquisitionsPage";
import * as EstatesOfficeFollowers from "../offices/WatchlistPage";
import * as LeadsTenantIntroductionsPage from "../common/LeadsTenantIntroductionsPage";
import * as LeadsPotentialTenantsPage from "../common/LeadsPotentialTenantsPage";
import * as EstatesPropertyParticularsAnalysis from "../offices/PropertyParticularsAnalysisPage";
import * as EstatesOffersOnlineAnalytics from "../offices/OffersOnlineAnalysisPage";
// INDUSTRIAL ROUTES
import * as IndustrialParks from "../industrial/ParksPage";
import * as IndustrialPropertyPark from "../industrial/PropertyParkPage";
import * as IndustrialWarehouses from "../industrial/WarehousesPage";
import * as IndustrialPropertyWarehouse from "../industrial/PropertyWarehousePage";
import * as IndustrialPropertyParticularAnalysis from "../industrial/PropertyParticularsAnalysisPage";
import {ShortlistsPageContainer} from "../industrial/OffersPage";
import {IndustrialOffersOnlineAnalysisPage} from "../industrial/OffersOnlineAnalysisPage";

class InitialPage extends React.Component {
  render() {
    const {loading} = this.props;

    if (!loading) {
      return (
        <Switch>
          {/* COMMON ROUTES */}
          <Redirect
            exact
            from="/"
            to={ROUTES.DASHBOARD_PAGE}
            component={CommonDashboard.DashboardPageContainer}
          />
          <Route
            path={ROUTES.DASHBOARD_PAGE}
            component={CommonDashboard.DashboardPageContainer}
          />
          <Route
            path={`${ROUTES.USER_PROFILE}/:tab`}
            component={CommonUserProfile.UserProfilePageContainer}
          />
          <Route
            path={ROUTES.TEAM_PAGE}
            component={CommonTeam.TeamPageContainer}
          />
          <Route
            path={ROUTES.SUBSCRIBERS_PAGE}
            component={CommonFollowers.SubscribersPageContainer}
          />
          <Route
            path={ROUTES.LEADS_MANAGEMENT}
            component={CommonLeadsManagement.LeadsManagementPageContainer}
          />
          <Route
            path={ROUTES.COMPETITION_ANALYSIS_PAGE}
            component={CommonCompetitionAnalysis.CompetitionAnalysisContainer}
          />
          <Route
            path={ROUTES.SYMMCO}
            component={CommonSymmco.SymmcoPageContainer}
          />
          <Route
            path={ROUTES.ADMIN_ADD_USER}
            component={CommonAdminAddUser.AdminAddUserPageContainer}
          />
          <Route
            path={ROUTES.ADMIN_MANAGE_ACCOUNT_REQUESTS}
            component={CommonAdminManageAccountRequests.AdminManageAccountRequestsPageContainer}
          />
          <Route
            path={ROUTES.LATEST_TRANSACTIONS}
            component={CommonLatestTransactions.LatestTransactionsPageContainer}
          />
          <Route
            path={ROUTES.SUPPLY_BOOST}
            component={CommonSupplyBoost.SupplyBoostPageContainer}
          />
          {/* OFFICES ROUTES */}
          <Route
            path={`${ROUTES.BUILDING_V2_PAGE}/:id`}
            component={OfficeProperty.PropertyPageContainer}
          />
          <Route
            path={ROUTES.MODULES_LIST_PAGE}
            component={EstatesUnits.UnitsPageContainer}
          />
          <Route
            path={ROUTES.BUILDINGS_LIST_PAGE}
            component={EstatesBuildings.BuildingsPageContainer}
          />
          <Route
            path={`${ROUTES.BUILDING_PAGE}/:id`}
            component={OfficeProperty.PropertyPageContainer}
          />
          <Route
            path={ROUTES.OWNERS_LIST_PAGE}
            component={EstatesOwners.OwnersPageContainer}
          />
          <Route
            path={`${ROUTES.OWNER_PAGE}/:id`}
            component={EstatesOwner.OwnerPageContainer}
          />
          <Route
            path={ROUTES.USER_WATCHLIST}
            component={EstatesOfficeFollowers.UserWatchlistPageContainer}
          />
          <Route
            path={`${ROUTES.ANALYTICS_PAGE}/:id`}
            component={EstatesAnalytics.AnalyticsPageContainer}
          />
          <Route
            path={ROUTES.SHORTLISTS_PAGE}
            component={EstatesOffers.ShortlistsPageContainer}
          />
          <Route
            path={ROUTES.SHORTLISTS_LANDLORD_PAGE}
            component={EstatesOffersAnalysis.ShortlistsLandlordPageContainer}
          />
          <Route
            path={ROUTES.INDUSTRIAL_SHORTLISTS_LANDLORD_PAGE}
            component={EstatesOffersAnalysis.ShortlistsLandlordPageContainerIndustrial}
          />
          <Route
            path={ROUTES.ACQUISITIONS_PAGE}
            component={EstatesAcquisitions.AcquisitionsPageContainer}
          />
          <Route
            path={ROUTES.LEADS_TENANT_INTRODUCTIONS}
            component={LeadsTenantIntroductionsPage.LeadsTenantIntroductionsPageContainer}
          />
          <Route
            path={ROUTES.LEADS_POTENTIAL_TENANTS}
            component={LeadsPotentialTenantsPage.LeadsPotentialTenantsPageContainer}
          />
          <Route
            path={ROUTES.PROPERTY_PARTICULARS_ANALYSIS}
            component={
              EstatesPropertyParticularsAnalysis.PropertyParticularsAnalysisPageContainer
            }
          />
          <Route
            path={ROUTES.OFFERS_ONLINE_ANALYSIS}
            component={
              EstatesOffersOnlineAnalytics.OffersOnlineAnalysisPageContainer
            }
          />
          {/* INDUSTRIAL ROUTES */}
          <Route
            path={ROUTES.INDUSTRIAL_PARKS}
            component={IndustrialParks.ParksPageContainer}
          />
          <Route
            path={ROUTES.INDUSTRIAL_BUILDINGS}
            component={IndustrialWarehouses.WarehousesPageContainer}
          />
          <Route
            path={`${ROUTES.INDUSTRIAL_PARK}/:id`}
            component={IndustrialPropertyPark.PropertyParkPageContainer}
          />
          <Route
            path={`${ROUTES.INDUSTRIAL_BUILDING}/:id`}
            component={IndustrialPropertyWarehouse.PropertyWarehousePageContainer}
          />
          <Route
            path={ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ANALYSIS}
            component={
              IndustrialPropertyParticularAnalysis.PropertyParticularsAnalysisPageContainer
            }
          />
          <Route
            path={ROUTES.INDUSTRIAL_SHORTLISTS_PAGE}
            component={ShortlistsPageContainer}
          />
          <Route
            path={ROUTES.INDUSTRIAL_OFFERS_ONLINE_ANALYSIS}
            component={IndustrialOffersOnlineAnalysisPage}
          />
          {/* ERRORS ROUTES */}
          <Redirect to="/error/error-v1"/>
        </Switch>
      );
    } else {
      return <LoadingScreen/>;
    }
  }
}

const mapStateToProps = ({user}) => ({
  user: user.data,
  loading: user.loading,
});

const withRedux = connect(mapStateToProps, null);

export default withRedux(InitialPage);
