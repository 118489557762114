import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import quickActionsStyles from "app/partials/components/PartialComponents.module.scss";
import Icon from "@material-ui/core/Icon/Icon";
import ModalCustom from "app/partials/components/ModalCustom";
import {addParkToNewShortlistIndustrial} from "app/crud/estates/industrial/offers-industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputIcon from "app/partials/components/InputIcon";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {Checkbox, FormControlLabel} from "@material-ui/core";

class ParkAddToNewShortlist extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    open: false,
    shortlistName: "",
    forTesting: false
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value,
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleSave = () => {
    const { parkId, addParkToNewShortlistIndustrial } = this.props;
    const { shortlistName, forTesting } = this.state;
    const parks = this.props.parks || [parkId];

    if (shortlistName !== "") {
      addParkToNewShortlistIndustrial(shortlistName, parks, forTesting);
      this.handleCloseModal();
    }

    this.setState({shortlistName: ""})
  };

  render() {
    const { btn, btnMultiselect, solid, inactive, rowData, parks, user } = this.props;
    const { shortlistName, forTesting } = this.state;
    const isLoggedInAs = user?.loggedInAs;

    const withoutAvailableArea = parks && parks
      .map(
        (id) =>
          rowData.find(
            (el) =>
              el.id === id 
          )
      ).filter(item => 
        item !== undefined && 
        !item.totalAreaOfficeAvailable &&
        !item.totalAreaWarehouseAvailable)


    return (
      <ModalCustom
        ref={this.child}
        btn={
          btn ? (
            <ModalTrigger
              color={btnMultiselect ? "danger" : "success"}
              label={
                <FormattedMessage id="UNITS.PHRASE.ADD_TO_NEW_SHORTLIST" />
              }
              directBtn
              solid={solid}
              inactive={inactive}
            />
          ) : (
            <span className={clsx("kt-nav__link", quickActionsStyles.actionButton)}>
              <span className={quickActionsStyles.actionIcon}>
                <Icon>work_outline</Icon>
              </span>
              <span className="kt-nav__link-text">
                <FormattedMessage id="UNITS.PHRASE.ADD_TO_NEW_SHORTLIST" />
              </span>
            </span>
          )
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
        title={
          <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_PARK_TO_NEW_SHORTLIST" />
        }
        handleSave={this.handleSave}
        inactive={inactive}
      >
        <InputIcon
          icon={<Icon>work_outline</Icon>}
          id="new_shortlist_name"
          label={<FormattedMessage id="SHORTLIST.PHRASE.NEW_SHORTLIST_NAME" />}
          name="new_shortlist_name"
          value={shortlistName}
          onChange={this.handleChange}
        />
        {isLoggedInAs &&
          <FormControlLabel
            control={
              <Checkbox
                checked={forTesting}
                id="forTesting"
                value="forTesting"
                color="primary"
                onChange={this.handleCheckboxChange}
              />
            }
            label={<FormattedMessage id="SHORTLIST.PHRASE.FOR_TESTING"/>}
            style={{marginTop: "0.5rem"}}
          />
        }
        {withoutAvailableArea?.length ?
        <div className={styles.warningWrapper}>
            <span className={styles.warningLabel}><FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_AREA_WARNING" /></span>
            <div className={styles.warningValuesWrapper}>{
                withoutAvailableArea.map((item, index) => {
                    return <span key={index} className={styles.warningValue}>{`${index+1}. ${item?.name}`}</span>
                })
            }</div>
        </div> : null
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.data
})
const mapDispatchToProps = {
  addParkToNewShortlistIndustrial: (name, parks, forTesting) => addParkToNewShortlistIndustrial(name, parks, forTesting),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ParkAddToNewShortlist)
);
