import React from "react";
import PropertyHeaderTitle from "app/pages/common/components/PropertyPage/PropertyHeaderTitle";
import {injectIntl} from "react-intl";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import PropertyHeaderMain from "app/pages/common/components/PropertyPage/PropertyHeaderMain";
import PropertyHeaderNav from "app/pages/common/components/PropertyPage/PropertyHeaderNav";
import LoadingScreen from "app/partials/components/LoadingScreen";
import _ from "lodash";
import SimpleMap from "app/partials/components/SimpleMap";
import EditModal from "app/partials/components/EditModal";
import ReactVisibilitySensor from "react-visibility-sensor";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import cn from "classnames";
import {BUILDING_STATUS, ROUTES} from "../../../../../constants";
import LeadsManagementAddProperty from "../../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import {Tooltip} from "@material-ui/core";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import {returnOfficeBuildingStatus} from "../../../../../utils/helpers";
import IsAdmin from "../../../../../utils/IsAdmin";

class PropertyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      initOwner: false,
      initContacts: false,
      initPhotos: false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      fetchIndustrialWarehouseOwner,
      fetchIndustrialWarehouseLeasingContacts,
      fetchIndustrialParkPhotos,
      ownerLoading,
      leasingContactsLoading,
      photosLoading,
      match,
      basic
    } = this.props;
    const {initLoaded, initOwner, initContacts, initPhotos} = this.state;
    const {id} = match?.params;

    if(initLoaded && !initOwner && !ownerLoading) {
      this.setState({
        initOwner: true
      }, () => fetchIndustrialWarehouseOwner(id));
    }
    if(initLoaded && initOwner && !initContacts && !leasingContactsLoading) {
      this.setState({
        initContacts: true
      }, () => fetchIndustrialWarehouseLeasingContacts(id));
    }
    if(initLoaded && initOwner && initContacts && !initPhotos && !photosLoading && fetchIndustrialParkPhotos && basic?.parkId) {
      this.setState({
        initPhotos: true
      }, () => fetchIndustrialParkPhotos(basic?.parkId));
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialWarehouseBasic,
      basic,
      basicLoading,
      resetting,
      match,
      callReddClickEvent
    } = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !basicLoading && _.isEmpty(basic) && id && !resetting) {
      if(id && callReddClickEvent) {
        callReddClickEvent(
          "industrial-warehouse-detail",
          "warehouse",
          null,
          id
        );
      }
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialWarehouseBasic(id));
    }
  }

  render() {
    const {
      basic,
      basicLoading,
      onScrollToSection,
      owner,
      leasingContacts,
      photos,
      userWatchlist,
      isStaff,
      view
    } = this.props;
    const propertyLocation = [
      ...basic?.addressCity ? [basic?.addressCity] : [],
      ...basic?.addressStreet ? [basic?.addressStreet] : []
    ].join(", ");
    const isParkEditable = basic?.isEditable;
    const parkName = basic?.parkName ? basic?.parkName : "";
    const buildingName = basic?.name ? basic?.name : "";
    const sameName = parkName === buildingName;
    const fullName = sameName ? buildingName : <>{parkName} - <span style={{color: "#ff4626"}}>{buildingName}</span></>;
    const propertyStatus = returnOfficeBuildingStatus(basic?.status);

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <div className={cn(
          styles.propertyHeaderContainer,
          styles.industrialHeight
        )}>
          {
            !basicLoading && basic ?
              <>
              <PropertyHeaderTitle
                title={fullName}
                location={propertyLocation}
                titleActions={
                <>
                  {basic?.status !== BUILDING_STATUS.STATUS_EXISTS && propertyStatus &&
                  <Tooltip
                    title={<span style={{fontSize: "1.2rem"}}>{propertyStatus}</span>}
                    placement="top"
                  >
                    <span className={styles.propertyStatus}>
                      <IconColorMUI icon="schedule" size="20px"/>
                    </span>
                  </Tooltip>
                  }
                  {(basic?.isEditable || basic?.ipiEditable) &&
                  <EditModal
                    target="warehouse"
                    parentData={basic}
                    isPropertyEditable={isParkEditable}
                    {...this.props}
                  />
                  }
                </>
                }
                locationActions={
                <SimpleMap
                  lat={basic?.geoLatitude}
                  lng={basic?.geoLongitude}
                  propertyID={basic?.id}
                  view="industrialWarehouse"
                />
                }
                toolbar={
                <>
                  <IsAdmin>
                    <LeadsManagementAddProperty propertyID={basic?.parkId} btnListMode tooltip="Admin Only"/>
                  </IsAdmin>
                </>
                }
                goBackName={basic?.parkName}
                goBackRoute={`${ROUTES.INDUSTRIAL_PARK}/${basic?.parkId}`}
              />
              <PropertyHeaderMain
                data={basic}
                owner={owner}
                contacts={leasingContacts}
                images={photos}
                userWatchlist={userWatchlist}
                isStaff={isStaff}
                view={view}
                isEditable={basic?.isEditable}
              />
              <PropertyHeaderNav
                propertyID={basic?.id}
                activeItem={0}
                view={view}
                onScrollTo={onScrollToSection}
              />
              </> :
              <LoadingScreen absolute/>
          }
        </div>
      </ReactVisibilitySensor>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeader)
);