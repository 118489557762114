import React from "react";
import ModalCustom from "../../../../partials/components/ModalCustom";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";
import {removeLeadProperties} from "../../../../crud/leadsManagement.crud";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

class LeadsManagementRemoveProperty extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleProceed = () => {
    const {removeLeadProperties, leadID, propertyID, currentApplicationView} = this.props;

    if (leadID && propertyID && removeLeadProperties) {
      const data = {
        ...currentApplicationView === APPLICATION_VIEWS.OFFICES ? {"offices": [propertyID]} : {},
        ...currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? {"parks": [propertyID]} : {}
      };
      removeLeadProperties(leadID, data);
    }
    this.handleCloseModal();
  };

  render() {
    const {leadName, propertyName, removing} = this.props;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            color="danger"
            icon="delete_outline"
            directIcon
          />
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
        btnConfirmDisabled={removing}
        title={<FormattedMessage id="LM.PHRASE.REMOVE_PROPERTY_FROM_LEAD"/>}
        maxWidth="sm"
        handleSave={this.handleProceed}
      >
        <Typography>
          <FormattedMessage
            id="LM.PHRASE.REMOVE_PROPERTY_FROM_LEAD_CONFIRMATION_MESSAGE"
            values={{
              property: <strong>{propertyName}</strong>,
              lead: <strong>{leadName}</strong>,
              br: <br/>
            }}
          />
        </Typography>
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({leadsManagement, user}) => ({
  removing: leadsManagement?.removing,
  currentApplicationView: user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  removeLeadProperties: (leadID, data) => removeLeadProperties(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementRemoveProperty);