import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import styles from "../../../Offices/OfficeAdminSettings/index.module.scss";

class EditWarehouseContacts extends React.Component {
  render() {
    const baseUrl = `${process.env.REACT_APP_API_HOST}admin`;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Lease and contact"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" style={{marginTop: "2rem", color: "#ff4626", fontStyle: "italic"}}>Osoby kontaktowe należy wybrać z poziomu Django.</Typography>
          <Typography variant="body1" style={{marginTop: "2rem", fontStyle: "italic"}}>Pomocne linki:</Typography>
          <Typography variant="h4" style={{marginTop: "1rem"}}>
            <a
              href={`${baseUrl}/contacts/contactperson`}
              target="_blank"
              rel="noreferrer noopener"
              style={{fontSize: "inherit"}}
               className={styles.remember}
            >1. Dodaj nowy kontakt</a>
          </Typography>
          <Typography variant="h4">
            <a
              href={`${baseUrl}/contacts/contactrelation `}
              target="_blank"
              rel="noreferrer noopener"
              style={{fontSize: "inherit"}}
              className={styles.remember}
            >2. Przypisz istniejący kontakt do nieruchomości</a>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
export default injectIntl(
  connect(null, null)(EditWarehouseContacts)
);