import React from "react";
import {Button, Icon} from "@material-ui/core";
import styles from "./index.module.scss";
import clsx from "clsx";
import ButtonTooltip from "../ButtonTooltip";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import {withRouter} from "react-router-dom";
import _ from "lodash";

class ButtonCustom extends React.Component {
  handleRenderBtn = () => {
    const {
      label,
      color,
      solid,
      darken,
      icon,
      iconPrefix,
      iconCustom,
      onClick,
      redirect,
      download,
      inactive,
      disabled,
      type,
      normalCase,
      noPadding,
      iconOnly,
      iconReverse,
      redirectTarget,
      fullWidth,
      widthFit,
      style,
      btnStyle,
      btnStyleForced,
      classNames,
      trackingData,
      callReddClickEvent,
      slim,
      iconNoMargin,
      ...props
    } = this.props;

    const handleOnClick = (e) => {
      if(trackingData) {
        callReddClickEvent(trackingData?.clickType, trackingData?.contentType, trackingData?.appLabel, trackingData?.objectId, trackingData?.params);
      }
       if(onClick) {
         onClick(e);
       }
      if(redirect) {
        if(redirectTarget === "_blank") {
          window.open(redirect, redirectTarget);
        }
        else {
          props.history.push(redirect);
        }
      }
    }

    return (
      <span
        className={clsx(
          styles.btn,
          color ? styles[color] : styles.default,
          solid && styles.solid,
          darken && styles.darken,
          (icon || iconCustom) && styles.iconMargin,
          iconPrefix ? styles.iconPrefix : undefined,
          inactive && styles.inactive,
          disabled && styles.disabled,
          fullWidth && styles.fullWidth,
          widthFit && styles.widthFit,
          btnStyleForced && styles.inlineStyles,
          classNames
        )}
        style={style}
      >
        <Button
          variant="contained"
          color="inherit"
          type={type ? type : "button"}
          style={btnStyle}
          className={clsx(
            iconOnly && styles.iconOnly,
            noPadding && styles.noPadding,
            fullWidth && styles.fullWidthInner,
            styles.buttonCustom,
            normalCase ? styles.normalCase : undefined,
            slim ? styles.slim : undefined,
            iconNoMargin ? styles.noMargin : undefined
          )}
          download={download}
          onClick={handleOnClick}
        >
          <>
            {iconPrefix && (_.isString(iconPrefix) ? <Icon>{iconPrefix}</Icon> : <span className={styles.iconPrefixWrapper}>{iconPrefix}</span>)}
            {label && label}
            {(icon || iconCustom) &&
              (iconReverse ? (
                <span className={iconReverse && styles.iconReverse}>
                  <Icon>{icon}</Icon>
                </span>
              ) : (
                <>{iconCustom ? iconCustom : <Icon>{icon}</Icon>}</>
              ))}
          </>
        </Button>
      </span>
    );
  };

  render() {
    const { tooltip, tooltipPosition, color } = this.props;

    if (tooltip) {
      return (
        <ButtonTooltip
          tPlacement={tooltipPosition}
          tOverlay={tooltip}
          color={color}
        >
          {this.handleRenderBtn()}
        </ButtonTooltip>
      );
    }
    return this.handleRenderBtn();
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default connect(null, mapDispatchToProps)(withRouter(ButtonCustom));