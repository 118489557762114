import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {updateOffer} from "app/crud/offers.crud";
import {updateOfferIndustrial} from "../../../crud/estates/industrial/offers-industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Icon from "@material-ui/core/Icon/Icon";
import InputIcon from "app/partials/components/InputIcon";
import {callReddClickEvent} from "app/crud/user.crud";
import {withRouter} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

class OfferEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlistName: props?.offerName,
      initialOpen: false
    };
    this.child = React.createRef();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const{currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    if(isOfficeView) {
      if(prevProps.offerUpdating && !this.props.offerUpdating) {
        this.handleCloseModal();
      }
    }
    else {
      if(prevProps.offerIndustrialUpdating && !this.props.offerIndustrialUpdating) {
        this.handleCloseModal();
      }
    }
  }

  handleCloseModal = () => {
    if(this.state.initialOpen) {
      this.setState({
        initialOpen: false
      });
    }
    else {
      this.child.current.handleClose();
    }
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value
    })
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleSave = () => {
    const {updateOffer, updateOfferIndustrial, offerID, currentApplicationView} = this.props;
    const {shortlistName} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const updater = isOfficeView ? updateOffer : updateOfferIndustrial;

    if(shortlistName !== "" && offerID) {
      updater(
        offerID,
        {
          name: shortlistName
        }
      );
    }
  };

  render() {
    const {callReddClickEvent, offerUpdating, offerIndustrialUpdating, currentApplicationView} = this.props;
    const {shortlistName, initialOpen} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const loading = isOfficeView ? offerUpdating : offerIndustrialUpdating;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="edit"
            label={<FormattedMessage id="SHORTLIST.PHRASE.EDIT_OFFER"/>}
            directIcon
            color="neutral"
          />
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.EDIT_OFFER"/>}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
        externalModalState={initialOpen}
        saving={loading}
        onOpen={() => callReddClickEvent("offer-edit-button", "offer")}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <InputIcon
                icon={<Icon>work_outline</Icon>}
                id="shortlist_name"
                label={<FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_NAME"/>}
                name="shortlist_name"
                value={shortlistName}
                onChange={this.handleChange}
                minWidth="20rem"
            />
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user?.data,
  offerUpdating: store.shortlists.offerUpdating,
  offerIndustrialUpdating: store.offersIndustrial.offerUpdating,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  updateOffer: (offerID, data) => updateOffer(offerID, data),
  updateOfferIndustrial: (offerID, data) => updateOfferIndustrial(offerID, data),
  callReddClickEvent: (clickType, contentType) => callReddClickEvent(clickType, contentType)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(OfferEdit))
);