import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyUnits from "./components/content/PropertyUnits";
import PropertyLeaseTerms from "./components/content/PropertyLeaseTerms";
import PropertyAmenities from "./components/content/PropertyAmenities";
import PropertyMetrics from "./components/content/PropertyMetrics";
import PropertyChangesTracker from "./components/content/PropertyChangesTracker";
import PropertyFeedback from "app/pages/common/components/PropertyPage/PropertyFeedback";
import PropertySimilarProperties from "app/pages/common/components/PropertyPage/PropertySimilarProperties";
import PropertyHeader from "./components/PropertyHeader";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import FinneLeadsPreviewBar from "../../../partials/components/FinneLeadsPreviewBar";
import PrintModeDetect from "../../../partials/components/PrintModeDetect";

export default class PropertyPage extends React.Component {
  constructor(props) {
    super(props);
    this.refAnalytics = React.createRef();
    this.refUnits = React.createRef();
    this.refLeaseTerms = React.createRef();
    this.refAmenities = React.createRef();
    this.refMetrics = React.createRef();
    this.refSimilar = React.createRef();

    this.props.resetOfficeBuildingState && this.props.resetOfficeBuildingState();
  }

  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
  }
  // Handlers
  handleScrollToSection = (ref) => {
    const overflowParent = document.getElementById("root");
    const targetPosition = this[ref]?.current?.getBoundingClientRect().top;
    const offsetPosition = targetPosition + window.scrollY - 100;

    // Handle Scroll
    overflowParent.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet>
            <PortletBody>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <PropertyHeader
                    parentRef={this.refAnalytics}
                    onScrollToSection={this.handleScrollToSection}
                    {...this.props}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PropertyUnits parentRef={this.refUnits} {...this.props}/>
                </Grid>
                <Grid item xs={12}>
                  <PropertyLeaseTerms parentRef={this.refLeaseTerms} {...this.props}/>
                </Grid>
                <PrintModeDetect hide>
                {this.props.basic?.isEditable &&
                <Grid item xs={12}>
                  <FinneLeadsPreviewBar {...this.props}/>
                </Grid>
                }
                </PrintModeDetect>
                <Grid item xs={12}>
                  <PropertyAmenities parentRef={this.refAmenities} {...this.props}/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <PropertyMetrics parentRef={this.refMetrics} {...this.props}/>
                      <PrintModeDetect hide>
                        <PropertyFeedback {...this.props}/>
                      </PrintModeDetect>
                    </Grid>
                    <Grid item xs={8}>
                      <PropertyChangesTracker {...this.props}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <PrintModeDetect hide>
          <Grid item xs={12}>
            <Portlet>
              <PortletBody>
                <PropertySimilarProperties parentRef={this.refSimilar} {...this.props}/>
              </PortletBody>
            </Portlet>
          </Grid>
        </PrintModeDetect>
      </Grid>
    );
  }
}