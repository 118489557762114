import React from "react";
import {injectIntl} from "react-intl";
import {
  BicyclingLayer,
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  LoadScript,
  Marker,
  TrafficLayer,
  TransitLayer
} from "@react-google-maps/api";
import connect from "react-redux/es/connect/connect";
import {toAbsoluteUrl} from "_metronic";
import LoadingScreen from "../LoadingScreen";
import GOOGLE_MAPS_OPTIONS from "../../../constants/GOOGLE_MAPS_OPTIONS";

class LoadScriptOnlyIfNeeded extends LoadScript {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')

    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error('google api is already presented');
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded && this._isMounted) {
      this.setState({loaded: !this.state.loaded});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

class GoogleMaps extends React.Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      latDrag: null,
      lngDrag: null,
      defaultLat: 0,
      defaultLng: 0,
      poiLatStatic: Number(props.lat),
      poiLngStatic: Number(props.lng),
      directionServiceResponse: null
    };
  }

  onMapLoad = () => {
    const {markerByAddress, user: {marketName, marketLat, marketLng}} = this.props;
    const address = markerByAddress || marketName;
    const google = window.google;
    if (marketLat && marketLng) {
      if (this._isMounted) {
        this.setState({
          defaultLat: marketLat,
          defaultLng: marketLng,
        });
      }
    } else {
      if (google && google.maps && typeof google.maps.Geocoder === "function") {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': `${address}, Polska`}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            if (this._isMounted) {
              this.setState({
                defaultLat: lat,
                defaultLng: lng
              });
            }
          } else {
            console.log(status);
          }
        });
      } else {
        console.log('no google');
      }
    }
  };
  directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === 'OK') {
        const distance = response.routes[0].legs[0].distance.text;
        const time = response.routes[0].legs[0].duration.text;
        this.setState({
          directionServiceResponse: response
        }, () => this.props.handleDirectionValues(distance, time));
      } else {
        console.log('response: ', response)
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      lat,
      lng,
      zoom,
      height = '100%',
      minHeight = 'auto',
      draggable,
      onDragEnd,
      disableUI,
      uiOnlyFS,
      disableScrollWheel,
      traffic,
      transit,
      bicycle,
      directionOptions,
      poisMap,
      poiMarker,
      markerIcon
    } = this.props;
    const {latDrag, lngDrag, defaultLat, defaultLng, poiLatStatic, poiLngStatic} = this.state;
    const markerIconActive = markerIcon ? markerIcon : toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const directionsRendererOptions = {
      directions: this.state.directionServiceResponse,
      suppressMarkers: true
    };
    const mapOptionsOnlyFullscreen = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true
    };

    return (
      <LoadScriptOnlyIfNeeded
        id="redd-gm"
        googleMapsApiKey={GOOGLE_MAPS_OPTIONS.API_KEY}
        libraries={GOOGLE_MAPS_OPTIONS.LIBRARIES}
        loadingElement={<LoadingScreen/>}
        onLoad={() => this.onMapLoad()}
      >
        {window.google && window.google.maps ?
          <GoogleMap
            zoom={zoom || 14}
            center={{lat: Number(lat) || defaultLat, lng: Number(lng) || defaultLng}}
            options={{
              styles: GOOGLE_MAPS_OPTIONS.STYLES,
              disableDefaultUI: disableUI,
              scrollwheel: !disableScrollWheel,
              ...uiOnlyFS ? mapOptionsOnlyFullscreen : {}
            }}
            mapContainerStyle={{width: '100%', height: height, minHeight: minHeight}}
            {...this.props}
          >
            {poisMap && (
              <Marker
                position={{lat: poiLatStatic || defaultLng, lng: poiLngStatic || defaultLng}}
                icon={toAbsoluteUrl("media/icons/pois/success/marker-poi-warehouse.png")}
              />
            )}
            <Marker
              position={{lat: latDrag || Number(lat) || defaultLat, lng: lngDrag || Number(lng) || defaultLng}}
              draggable={draggable}
              onDragEnd={onDragEnd}
              icon={poiMarker ? poiMarker : markerIconActive}
            />
            {traffic && <TrafficLayer/>}
            {transit && <TransitLayer/>}
            {bicycle && <BicyclingLayer/>}
            {directionOptions && <DirectionsService options={directionOptions} callback={this.directionsCallback}/>}
            {directionOptions && this.state.directionServiceResponse &&
              <DirectionsRenderer options={directionsRendererOptions}/>}
            }
          </GoogleMap> : <LoadingScreen/>
        }
      </LoadScriptOnlyIfNeeded>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user.data
});

export default injectIntl(connect(mapStateToProps)(GoogleMaps));