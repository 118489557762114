import React from "react";
import TableMUI from "../../../../partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {handleTableCellAlign, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import {FormattedMessage, injectIntl} from "react-intl";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../utils/IsPro";
import BlurText from "../../../../partials/components/BlurText";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import UnitTypePreview from "../../../../partials/components/_DataParts/UnitTypePreview";
import IsApplicationView from "../../../../utils/IsApplicationView";

class SupplyBoostBody extends React.Component {
  // Handlers
  handleTrackPropertyRedirect = (propertyID) => {
    const {callReddClickEvent} = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    if(propertyID) {
      callReddClickEvent && callReddClickEvent(
        isOfficeView ? 'supply-boost-offices-property-click' : 'supply-boost-industrial-property-click',
        isOfficeView ? 'office' : 'park',
        null,
        propertyID
      );
    }
  }

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      loading,
      intl,
      onFetch
    } = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const tHead = [
        {id: 'building_name', numeric: false, disablePadding: false, label: isOfficeView ? 'FORM.FIELD.BUILDING' : 'FORM.FIELD.PARK', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'FORM.FIELD.AREA', sort: false},
        {id: 'type', numeric: false, disablePadding: false, label: 'VOCABULARY.Unit type', sort: false},
        ...isOfficeView ? [{id: 'floor', numeric: true, disablePadding: false, label: 'VOCABULARY.Floor', sort: false}] : [],
        {id: 'rent', numeric: true, disablePadding: false, label: 'VOCABULARY.Asking rent', sort: false},
        {id: 'created_at', numeric: true, disablePadding: false, label: 'VOCABULARY.Date', sort: "inactive"},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];

    return(
      <TableMUI
        tHead={tHead}
        itemsPerPage={itemsPerPage}
        totalItems={itemsCount}
        loading={loading}
        initialOrderBy="date"
        initialRowsPerPage={20}
        containerStyle={{maxHeight: "100%"}}
        handleFetchDataCustom={onFetch}
      >
        {items && Array.isArray(items) && items.map((item, index) => {
          return (
            <React.Fragment
              key={index}
            >
              <TableRow
                hover
                tabIndex={-1}
              >
                <TableCell align={handleTableCellAlign(tHead, 0)}>
                  <PropertyCardPreview
                    propertyID={item?.assetId}
                    route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                    routeNewTab
                  >
                    <BlurText
                      isBlurred={IsPro({reverseCheck: true})}
                      customBlurValue="Lorem Ipsum - Nice Try"
                    >
                      <span
                        style={{fontSize: "1.6rem"}}
                        onClick={() => this.handleTrackPropertyRedirect(item?.assetId)}
                      >{item?.assetName}</span>
                    </BlurText>
                  </PropertyCardPreview>
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 1)}>
                  <HighlightLabel color="warning">
                    {toNumberFormat(item?.area, 'area')}
                  </HighlightLabel>
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 2)}>
                  <UnitTypePreview
                    unitType={item?.assetType}
                    industrialType={!isOfficeView}
                    simple
                  />
                </TableCell>
                {isOfficeView &&
                <TableCell align={handleTableCellAlign(tHead, 3)}>
                  {item?.assetFloor}
                </TableCell>
                }
                <TableCell align={handleTableCellAlign(tHead, isOfficeView ? 4 : 3)}>
                  {toNumberFormat(item?.assetRent, 'currency')}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, isOfficeView ? 5 : 4)}>
                  {toDateFormat(item?.date)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, isOfficeView ? 6 : 5)}>
                  <IsPro
                    paywall
                    paywallComponent={
                    <PaywallModal
                      triggerLabel={<FormattedMessage id="VOCABULARY.Find out more"/>}
                      triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Find out more"})}
                      withIcon
                    />
                    }
                  >
                    <ButtonCustom
                      label={<FormattedMessage id="VOCABULARY.Find out more" />}
                      redirect={`${isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}/${item?.assetId}/`}
                      redirectTarget="_blank"
                      color="danger"
                      solid
                    />
                  </IsPro>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableMUI>
    );
  }
}

export default injectIntl(SupplyBoostBody);