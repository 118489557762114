import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

// Display custom markers and it's descriptions
class MapLegend extends React.Component {
  render() {
    const pinGreen = toAbsoluteUrl("media/icons/markers/pin-green.png");
    const pinOrange = toAbsoluteUrl("media/icons/markers/pin-orange.png");
    const pinRed = toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const pinBlue = toAbsoluteUrl("media/icons/markers/pin-blue.png");

    return(
      <Grid container spacing={4}>
        <Grid item>
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinGreen} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.AVAILABLE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinOrange} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.AVAILABLE_IN_FUTURE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinRed} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.UNAVAILABLE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinBlue} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.PLANNED"/></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(connect()(MapLegend));