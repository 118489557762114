import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {deleteIndustrialFile} from "app/crud/estates/industrial/industrial.crud";

class IndustrialRemoveFile extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {deleteIndustrialFile, fileID, parkID = null, warehouseID = null} = this.props;
    deleteIndustrialFile(fileID, parkID, warehouseID);
    this.handleCloseModal();
  };

  render() {
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="delete"
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.DELETE_FILE"/>}
            directIcon
            color="gray"
          />
        }
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.DELETE_FILE"/>}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.DELETE"/>}
      >
        <Typography><FormattedMessage id="INDUSTRIAL.PHRASE.DELETE_FILE_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  deleteIndustrialFile: (fileID, parkID, warehouseID) => deleteIndustrialFile(fileID, parkID, warehouseID)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(IndustrialRemoveFile)
);