import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Menu, MenuItem, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import ButtonCustom from "app/partials/components/ButtonCustom";
import styles from "./index.module.scss";
import clsx from "clsx";
import {sendOfficeFinanceXLS} from "app/crud/estates/offices/offices.crud";

class OwnerFinancialGenerateXLS extends React.Component {
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };
  handleSendXls = (pk) => {
    const {officeID, sendOfficeFinanceXLS} = this.props;
    sendOfficeFinanceXLS(officeID, {"pk": pk});
  };

  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      anchorEl: null
    }
  }

  render() {
    const {directBtn, directIcon, inactive, officeFinances} = this.props;
    const {anchorEl} = this.state;
    if(inactive) {
      return(
        <ModalTrigger
          label={<FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS"/>}
          directBtn={directBtn}
          directIcon={directIcon}
          inactive
        />
      );
    }
    return (
      <div>
        <ButtonCustom
          aria-controls="generateXls"
          aria-haspopup="true"
          label={<FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS"/>}
          color="warning"
          onClick={this.handleOpen}
          style={{marginLeft: 15}}
        />
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="generateXls"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={clsx(styles.customPopoverContainer, styles.xlsContainer)}
        >
          {officeFinances && officeFinances.map((item, index) => (
          <MenuItem key={index} onClick={this.handleClose}>
            <ModalCustom
              ref={this.child}
              btn={
                <ModalTrigger
                  color="warning"
                  label={item.year}
                  onClick={() => this.handleSendXls(item.pk)}
                />
              }
              title={<FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS" />}
              btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
              maxWidth="lg"
            >
              <Typography><FormattedMessage id="OWNERS.PHRASE.FINANCIAL_STATEMENT_XLS_HAS_BEEN_SENT"/></Typography>
            </ModalCustom>
          </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user.data,
  officeFinances: store.offices.officeFinances,
  ownerPortfolio: store.owners.ownerPortfolio,
  ownerPortfolioLoading: store.owners.ownerPortfolioLoading
});
const mapDispatchToProps = {
  sendOfficeFinanceXLS: (officeID, pk) => sendOfficeFinanceXLS(officeID, pk)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnerFinancialGenerateXLS)
);