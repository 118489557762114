import React from "react";
import {SingleBuilding} from "./SingleBuilding";
import PropertyCardPreview from "../../_DataParts/PropertyCardPreview";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import {ROUTES} from "../../../../constants";

export default class BuildingList extends React.Component {
  render() {
    const {properties, lead, removing, mode, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const propertyRoute = isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK;

    if(properties?.length > 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} style={{maxHeight: "40rem"}}>
            {properties?.map((elem) => (
            <SingleBuilding
              key={elem?.id}
              lead={lead}
              property={elem}
              removing={removing}
              mode={mode}
            >
              <PropertyCardPreview
                title={elem?.name}
                semiTitle={elem?.addressStreet}
                thumb={elem?.mainPhotoThumbnail}
                route={propertyRoute}
                propertyID={elem?.id}
                themeDark
                routeNewTab
              />
            </SingleBuilding>
          ))}
          </Grid>
        </Grid>
      );
    }
    return <Typography variant="h3" style={{textAlign: "center"}}><FormattedMessage id="LM.PHRASE.ADD_PROPERTIES"/></Typography>;
  }
}