import React from "react";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import format from "date-fns/format";
import IsAdmin from "../../../../../utils/IsAdmin";

class EditOfficeBasics extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const {
      buildingAvailableFrom,
      availableFromAdditionalTime,
      name,
      totalAreaNetto,
      totalAreaBrutto,
      buildingClass,
      commissioningYear,
      commissioningQuarter,
      status,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      covid_19,
      isSubleaseNotPermitted
    } = props.data;
    this.state = {
      name,
      totalAreaNetto: totalAreaNetto || '',
      totalAreaBrutto: totalAreaBrutto || '',
      buildingClass,
      commissioningYear: commissioningYear || '',
      commissioningQuarter,
      status: status,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      buildingAvailableFrom,
      availableFromAdditionalTime: availableFromAdditionalTime || '',
      covid_19,
      isSubleaseNotPermitted
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.officeUpdating !== this.props.officeUpdating) {
      this.props.onRequestSaving(this.props.officeUpdating);
    }
  }

  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleChangeSelect = (selected, target) => {
    this.setState({
      [target]: selected
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleSave = () => {
    const {data: {id}, updateOffice} = this.props;
    const {
      buildingAvailableFrom,
      availableFromAdditionalTime,
      name,
      totalAreaNetto,
      totalAreaBrutto,
      buildingClass,
      commissioningYear,
      commissioningQuarter,
      status,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      covid_19,
      isSubleaseNotPermitted
    } = this.state;
    this.initialState = this.state;
    const data = {
      name,
      "totalAreaNetto": totalAreaNetto || 0,
      "totalAreaBrutto": totalAreaBrutto || 0,
      buildingClass,
      "commissioningYear": commissioningYear || 0,
      commissioningQuarter: commissioningQuarter !== '' ? commissioningQuarter : null,
      status,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      buildingAvailableFrom: buildingAvailableFrom ? format(new Date(buildingAvailableFrom), "yyyy-MM-dd") : null,
      availableFromAdditionalTime: availableFromAdditionalTime !== '' ? availableFromAdditionalTime : null,
      covid_19,
      isSubleaseNotPermitted
    };
    updateOffice(id, data, "basic");
  };

  render() {
    const {officeUpdating} = this.props;
    const {
      buildingAvailableFrom,
      availableFromAdditionalTime,
      name,
      totalAreaNetto,
      totalAreaBrutto,
      buildingClass,
      commissioningYear,
      commissioningQuarter,
      status,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      covid_19,
      isSubleaseNotPermitted
    } = this.state;
    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Basic data"/></Typography>
        </Grid>
        <Grid item xs={4}>
          <InputAdornments
            label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_NAME"/>}
            name="name"
            value={name}
            onChange={this.handleChange}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelect
            label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_CLASS"/>}
            inputName="buildingClass"
            options={FILTERS_SELECTS.BUILDINGS_CLASSES}
            defaultSelect={buildingClass}
            onSelectedValues={this.handleChangeSelect}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelect
            label={<FormattedMessage id="BUILDING.PHRASE.STATUS"/>}
            inputName="status"
            options={FILTERS_SELECTS.BUILDINGS_STATUSES}
            optionsValue="id"
            optionsLabel="label"
            defaultSelect={status}
            onSelectedValues={this.handleChangeSelect}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            type="date"
            label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>}
            name="buildingAvailableFrom"
            value={buildingAvailableFrom}
            onChange={this.handleChangeDate}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM_ADDITIONAL_TIME"/>}
            name="availableFromAdditionalTime"
            value={availableFromAdditionalTime}
            onChange={this.handleChange}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputAdornments
                label={<FormattedMessage id="BUILDING.PHRASE.COMMISSIONING_YEAR"/>}
                name="commissioningYear"
                value={commissioningYear}
                onChange={this.handleChange}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={6}>
              <SingleSelect
                label={<FormattedMessage id="BUILDING.PHRASE.COMMISSIONING_QUARTER"/>}
                inputName="commissioningQuarter"
                options={FILTERS_SELECTS.BUILDINGS_COMMISIONING_QUARTERS}
                optionsValue="id"
                optionsLabel="label"
                defaultSelect={commissioningQuarter}
                onSelectedValues={this.handleChangeSelect}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="BUILDING.PHRASE.TOTAL_AREA_NETTO"/>}
            name="totalAreaNetto"
            value={totalAreaNetto}
            onChange={this.handleChange}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="BUILDING.PHRASE.TOTAL_AREA_BRUTTO"/>}
            name="totalAreaBrutto"
            value={totalAreaBrutto}
            onChange={this.handleChange}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{marginTop: 15}}><FormattedMessage id="BUILDING.PHRASE.CERTIFICATE"/></Typography>
          <FormControlLabel
            control={
              <Checkbox checked={dgnbCertification} id="dgnbCertification" onChange={this.handleCheckboxChange} value="dgnbCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[0]["label"]}
            disabled={officeUpdating}
          />
          <FormControlLabel
            control={
              <Checkbox checked={hqeCertification} id="hqeCertification" onChange={this.handleCheckboxChange} value="hqeCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[1]["label"]}
            disabled={officeUpdating}
          />
          <FormControlLabel
            control={
              <Checkbox checked={wellCertification} id="wellCertification" onChange={this.handleCheckboxChange} value="wellCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[2]["label"]}
            disabled={officeUpdating}
          />
          <FormControlLabel
            control={
              <Checkbox checked={breeamCertification} id="breeamCertification" onChange={this.handleCheckboxChange} value="breeamCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[3]["label"]}
            disabled={officeUpdating}
          />
          <FormControlLabel
            control={
              <Checkbox checked={leedCertification} id="leedCertification" onChange={this.handleCheckboxChange} value="leedCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[4]["label"]}
            disabled={officeUpdating}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography><FormattedMessage id="BUILDING.PHRASE.SECURITY"/></Typography>
          <FormControlLabel
            control={
              <Checkbox checked={covid_19} id="covid_19" onChange={this.handleCheckboxChange} value="covid_19" color="primary"/>
            }
            label={<FormattedMessage id="BUILDING.PHRASE.SAFE_OFFICE_SPACE_COVID_19"/>}
            disabled={officeUpdating}
          />
        </Grid>
        <IsAdmin>
          <Grid item xs={12}>
            <IsAdmin markup="fontLight">
              <Typography><FormattedMessage id="VOCABULARY.Sublease visibility"/></Typography>
            </IsAdmin>
            <FormControlLabel
              control={
                <Checkbox checked={isSubleaseNotPermitted} id="isSubleaseNotPermitted" onChange={this.handleCheckboxChange} value="isSubleaseNotPermitted" color="primary"/>
              }
              label={<FormattedMessage id="VOCABULARY.Disable sublease visibility"/>}
              disabled={officeUpdating}
            />
          </Grid>
        </IsAdmin>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  officeUpdating: store.offices.officeUpdating
});
const mapDispatchToProps = {
  updateOffice: (officeId, data, backgroundRefresh) => updateOffice(officeId, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeBasics)
);