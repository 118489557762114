import React, {useState} from "react";
import styles from "./index.module.scss";
import {callReddClickEvent} from "app/crud/user.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ReactComponent as IconEye} from "app/media/icons/icon-eye.svg";

function PhoneNumberShow({phone, propertyID, callReddClickEvent, isIndustrialParkView, isIndustrialWarehousView}) {
  const [visible, setVisible] = useState(false);
  const isPrefix = phone.startsWith("+48");
  const phoneWithoutPrefix = phone?.replace("+48", "").replaceAll(" ", "");
  const phoneTeaserBase = phoneWithoutPrefix?.slice(0,3);
  const phoneTeaser = [
    isPrefix ? ["+48"] : [],
    phoneTeaserBase
  ].join(" ");

  const handleShowNumber = () => {
    setVisible(true);
    if(callReddClickEvent && propertyID) {
      if(isIndustrialParkView) {
        callReddClickEvent(
          "industrial-park-page-show-phone-number-click",
          "park",
          null,
          propertyID
        );
      }
      else if(isIndustrialWarehousView) {
        callReddClickEvent(
          "industrial-warehouse-page-show-phone-number-click",
          "warehouse",
          null,
          propertyID
        );
      }
      else {
        callReddClickEvent(
          "office-building-page-show-phone-number-click",
          "office",
          null,
          propertyID
        );
      }
    }
  }

  return(
    <span className={styles.phoneWrapper}>
      {visible ? phone : phoneTeaser}
      {!visible &&
        <button
          className={styles.btnShow}
          onClick={() => handleShowNumber()}
        ><FormattedMessage id="VOCABULARY.Show"/><IconEye/></button>
      }
    </span>
  );
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PhoneNumberShow)
);