export const fetchFollowerOffices = () => ({
  type: 'FETCH_FOLLOWER_OFFICES_REQUEST'
});
export const followOffice = (officeId, data) => ({
  type: 'FOLLOW_OFFICE_REQUEST',
  payload: {
    officeId,
    data
  },
});
export const unfollowOffice = (officeId) => ({
  type: 'UNFOLLOW_OFFICE_REQUEST',
  payload: officeId,
});
export const unfollowOffices = (data) => ({
  type: 'UNFOLLOW_OFFICES_BULK_REQUEST',
  payload: {
    data
  },
});