import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import EditModal from "app/partials/components/EditModal";
import INDUSTRIAL_BUILDING_TYPE from "../../../../../constants/INDUSTRIAL_BUILDING_TYPE";
import PropertyStatusPreview from "../../../../../partials/components/_DataParts/PropertyStatusPreview";

export default class PropertyMetrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialWarehouseMetrics, metrics, metricsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !metricsLoading && _.isEmpty(metrics) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialWarehouseMetrics(id));
    }
  }
  handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  render() {
    const {parentRef, metrics, metricsLoading, basic} = this.props;
    const isOfficeEditable = basic?.isEditable;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.About building"/>}
          titleActions={
          basic?.isEditable &&
          <EditModal
            target="warehouse"
            parentData={basic}
            isPropertyEditable={isOfficeEditable}
          />
          }
        />
        <div className={styles.metricsWrapper}>
          <table className={styles.simpleTable}>
            <tbody>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Type"/></td>
                <td>
                  {this.handleValueLoading(
                      metricsLoading,
                      metrics?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.name}/> :
                      metrics?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.name}/> :
                      metrics?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.name}/> :
                      metrics?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.name}/> :
                      metrics?.buildingType === INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.id ? <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.name}/> : "-"
                  )}
                </td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Status"/></td>
                <td>{this.handleValueLoading(metricsLoading, <PropertyStatusPreview status={metrics?.status} textOnly/>)}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Completion year"/></td>
                <td>{this.handleValueLoading(metricsLoading, handleEmptyValue(metrics?.commissioningYear))}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Total area"/></td>
                <td>{this.handleValueLoading(metricsLoading, toNumberFormat(metrics?.totalArea, 'area'))}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Total warehouse area"/></td>
                <td>{this.handleValueLoading(metricsLoading, toNumberFormat(metrics?.totalWarehouseAreaInBuilding, 'area'))}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Total office area"/></td>
                <td>{this.handleValueLoading(metricsLoading, toNumberFormat(metrics?.totalOfficeAreaInBuilding, 'area'))}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </>
      </ReactVisibilitySensor>
    );
  }
}