import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import {toNumberFormat, toTimeFromDate} from "../../../../utils/helpers";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../../../_metronic";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import {FormattedMessage, injectIntl} from "react-intl";
import {FILTERS_SELECTS} from "../../../../partials/components/FiltersDrawer/constants";
import SliderCustom from "../../../../partials/components/SliderCustom";
import ModalCustom from "../../../../partials/components/ModalCustom";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import TooltipCustom from "../../../../partials/components/TooltipCustom";
import PropertyHeaderContacts from "./PropertyHeaderContacts";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import IPI_PLAN_SOURCE from "../../../../constants/IPI_PLAN_SOURCE";
import IndustrialPreviewIPI from "../../../industrial/components/IndustrialPreviewIPI";
import cn from "classnames";
import PrintModeDetect from "../../../../partials/components/PrintModeDetect";
import ProgressBox from "../../../../partials/components/ProgressBox";
import SlickSlider from "../../../../partials/components/SlickSlider";
import SlickNavigation from "../../../../partials/components/SlickSlider/components/SlickNavigation";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";
import PaywallBannerRowSimple from "../../../../partials/components/PayWall/banners/PaywallBannerRowSimple";

function PropertyHeaderMain(
  {
    data,
    owner,
    contacts,
    images,
    currentApplicationView,
    view,
    isEditable
  }
) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
  const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
  const modalIpiTitle = [
      ...data?.parkName ? [data?.parkName] : [],
      ...data?.name ? [data?.name] : []
  ];
  const isIndustrialWarehouseView = view === "industrialWarehouse";
  const isIndustrialParkView = isIndustrialView && !isIndustrialWarehouseView;
  const propertyImage = data?.mainPhotoThumbnail600x400 ? data?.mainPhotoThumbnail600x400 :
    data?.mainPhotoThumbnail600x400Park ? data?.mainPhotoThumbnail600x400Park :
      images?.length > 0 ? images?.[0]?.image :
      isOfficeView ? toAbsoluteUrl("media/office-card-placeholder.jpg") :
        isIndustrialView && toAbsoluteUrl("media/industrial-placeholder-outside.jpg");

  // Helpers
  const renderCertificate = () => {
    const certificatesList = data?.certificates;
    const isDGNB = data?.dgnbCertification || certificatesList?.includes("DGNB");
    const isHQE = data?.hqeCertification || certificatesList?.includes("HQE");
    const isWELL = data?.wellCertification || certificatesList?.includes("WELL");
    const isBREEAM = data?.breeamCertification || certificatesList?.includes("BREEAM");
    const isLEED = data?.leedCertification || certificatesList?.includes("LEED");
    const cert =
      isDGNB ? 'cert-dgnb.svg' :
        isHQE ? 'cert-hqe.svg' :
          isWELL ? 'cert-well.svg' :
            isBREEAM ? 'cert-breeam.svg' :
              isLEED ? 'cert-leed.svg' : null;
    const certs = [
      ...isDGNB ? ['cert-dgnb.svg'] : [],
      ...isHQE ? ['cert-hqe.svg'] : [],
      ...isWELL ? ['cert-well.svg'] : [],
      ...isBREEAM ? ['cert-breeam.svg'] : [],
      ...isLEED ? ['cert-leed.svg'] : []
    ];

    if(certs?.length > 1) {
      return(
        <SliderCustom
          images={certs?.map(cert => toAbsoluteUrl(`media/logos/certificates/${cert}`))}
          interval={3000}
          controls={false}
          options={{
            indicators: false
          }}
        />
      );
    }
    else if(certs?.length === 1) {
      return <img src={toAbsoluteUrl(`media/logos/certificates/${cert}`)} alt="certificate"/>;
    }
    return null;
  }
  const renderCertificateTooltip = () => {
    const certificatesList = data?.certificates;
    const isDGNB = data?.dgnbCertification || certificatesList?.includes("DGNB");
    const isHQE = data?.hqeCertification || certificatesList?.includes("HQE");
    const isWELL = data?.wellCertification || certificatesList?.includes("WELL");
    const isBREEAM = data?.breeamCertification || certificatesList?.includes("BREEAM");
    const isLEED = data?.leedCertification || certificatesList?.includes("LEED");
    const activeCerts = FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD?.filter(item =>
      (item?.id === "dgnbCertification" && isDGNB) ||
        (item?.id === "hqeCertification" && isHQE) ||
          (item?.id === "wellCertification" && isWELL) ||
            (item?.id === "breeamCertification" && isBREEAM) ||
              (item?.id === "leedCertification" && isLEED)
    );
    const activeCertsLabelsArray = activeCerts?.map(item => item?.label);

    return <span><FormattedMessage id="BUILDING.PHRASE.CERTIFICATE"/>: {activeCertsLabelsArray?.join(", ") || "-"}</span>;
  }
  const isCertificateActive = () => {
    const certificatesList = data?.certificates;
    const isDGNB = data?.dgnbCertification || certificatesList?.includes("DGNB");
    const isHQE = data?.hqeCertification || certificatesList?.includes("HQE");
    const isWELL = data?.wellCertification || certificatesList?.includes("WELL");
    const isBREEAM = data?.breeamCertification || certificatesList?.includes("BREEAM");
    const isLEED = data?.leedCertification || certificatesList?.includes("LEED");

    return isDGNB || isHQE || isWELL || isBREEAM || isLEED;
  }
  const isIpiViewPermitted = data?.parkIpiPlanImage || data?.warehouseIpiPlanImage;
  const isIpiEditPermitted = data?.ipiEditable;
  const isIPIPermitted = isIpiViewPermitted || isIpiEditPermitted;
  const blurEnable = !IsPro({}) && !isEditable;

  return(
    <div className={cn(
      styles.propertyHeaderMainWrapper,
      isIndustrialWarehouseView ? styles.shadowDisabled : undefined
    )}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div className={styles.propertyHeaderGalleryWrapper}>
                {isIndustrialView && isIndustrialWarehouseView && isIPIPermitted ?
                  <div className={styles.ipiPreviewWrapper}>
                    <IndustrialPreviewIPI
                        title={modalIpiTitle.join(" | ")}
                        planSource={IPI_PLAN_SOURCE.WAREHOUSE}
                        planPark={data?.parkIpiPlanImage}
                        planBuilding={data?.warehouseIpiPlanImage}
                        parkID={data?.parkId}
                        buildingID={data?.id}
                        isEditable={data?.ipiEditable}
                    />
                  </div> :
                  <div className={styles.galleryWrapper}>
                    <PrintModeDetect
                      placeholder={<img src={propertyImage} alt={`${data?.name}`}/>}
                    >
                    {images && images?.length > 1 ?
                      <ModalCustom
                        btn={
                        <SlickSlider
                          slidesToShow={1}
                          newSlideRequest={currentSlide}
                        >
                          {images?.map((item, index) => {
                            return(
                              <img src={item?.image} alt={`${item?.name}`} key={index}/>
                            );
                          })}
                        </SlickSlider>
                        }
                        title=""
                        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                        btnFlex
                        fullScreen
                        inactive={!images || (images && images.length === 0)}
                      >
                        <SliderCustom
                          images={images?.map(item => item?.image)}
                          interval={4000}
                        />
                      </ModalCustom> : <img src={propertyImage} alt={`${data?.name}`}/>
                    }
                    </PrintModeDetect>
                    {images && images?.length > 1 &&
                    <SlickNavigation
                      slides={images}
                      currentSlide={currentSlide}
                      onSetCurrentSlide={setCurrentSlide}
                      style={{
                        position: "absolute",
                        bottom: "1.2rem",
                        right: "1.2rem",
                        zIndex: 2
                      }}
                    />
                    }
                  </div>
                }
                {isIndustrialView && isIndustrialParkView && isIPIPermitted &&
                  <div className={styles.ipiPreviewBtnWrapper}>
                    <IndustrialPreviewIPI
                        title={data?.parkName}
                        planSource={IPI_PLAN_SOURCE.PARK}
                        planPark={data?.ipiPlanImage}
                        parkID={data?.parkId}
                        isEditable={data?.ipiEditable}
                        modalTrigger={
                          <TooltipCustom title={<FormattedMessage id="INDUSTRIAL.PHRASE.TABS.INTERACTIVE_PLAN"/>} placement="left">
                            <IconColorMUI icon="texture" size="2rem" color="white"/>
                          </TooltipCustom>
                        }
                    />
                  </div>
                }
                <PrintModeDetect hide>
                {isCertificateActive() &&
                <div className={styles.certificateWrapper}>
                  <TooltipCustom title={renderCertificateTooltip()} placement="right">
                    <span>
                      {renderCertificate()}
                    </span>
                  </TooltipCustom>
                </div>
                }
                </PrintModeDetect>
              </div>
              <p className={styles.propertyLastUpdate}><FormattedMessage id="VOCABULARY.Data update"/> <span className={styles.time}>{toTimeFromDate(data?.updatedAtByUser)}</span></p>
            </Grid>
            <Grid item xs={6}>
              <table className={styles.propertyPreviewTable}>
                <tbody>
                  {(isOfficeView || isIndustrialWarehouseView) &&
                  <tr>
                    <td>
                      <p><FormattedMessage id="VOCABULARY.Available space"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span>{toNumberFormat(data?.totalAreaAvailable, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  }
                  {isIndustrialParkView &&
                  <tr>
                    <td>
                      <p><FormattedMessage id="VOCABULARY.Area offered"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span>{toNumberFormat(data?.totalAreaWarehouseAvailable, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  }
                  {isOfficeView &&
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.incl. subleases"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAreaIsSubleaseAvailable, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  }
                  {isIndustrialParkView &&
                  <>
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.Existing"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAvailableWarehouseAreaOfExistingWarehouses, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.Under construction"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAvailableWarehouseAreaOfUnderConstructionWarehouses, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.Planned"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAvailableWarehouseAreaOfPlannedWarehouses, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  </>
                  }
                  {isIndustrialWarehouseView &&
                  <>
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.incl. industrial"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAreaWarehouseAvailable, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.incl. office"/></p>
                    </td>
                    <td>
                      <BlurText
                        type="area"
                        isBlurred={blurEnable}
                      >
                        <span className={styles.subRowValue}>{toNumberFormat(data?.totalAreaOfficeAvailable, 'area')}</span>
                      </BlurText>
                    </td>
                  </tr>
                  </>
                  }
                  {(isOfficeView || isIndustrialWarehouseView) &&
                  <>
                  <tr>
                    <td>
                      <p><FormattedMessage id="VOCABULARY.Occupancy rate"/></p>
                    </td>
                    <td>
                      <ProgressBox
                        percent={data?.occupancyRateV2}
                        styleBar={{maxWidth: "50%"}}
                        styleValue={{fontWeight: 700}}
                        isBlurred={blurEnable}
                      />
                    </td>
                  </tr>
                  {isOfficeView &&
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.for  office"/></p>
                    </td>
                    <td>
                      <ProgressBox
                        percent={data?.occupancyRateV2Office}
                        styleBar={{maxWidth: "50%"}}
                        isBlurred={blurEnable}
                      />
                    </td>
                  </tr>
                  }
                  {isIndustrialView &&
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.industrial"/></p>
                    </td>
                    <td>
                      <ProgressBox
                        percent={data?.occupancyRateV2Warehouse}
                        styleBar={{maxWidth: "50%"}}
                        isBlurred={blurEnable}
                      />
                    </td>
                  </tr>
                  }
                  {isOfficeView &&
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.for  retail"/></p>
                    </td>
                    <td>
                      <ProgressBox
                        percent={data?.occupancyRateV2Retail}
                        styleBar={{maxWidth: "50%"}}
                        isBlurred={blurEnable}
                      />
                    </td>
                  </tr>
                  }
                  {isIndustrialView &&
                  <tr>
                    <td>
                      <p className={styles.subRowLabel}><FormattedMessage id="VOCABULARY.office"/></p>
                    </td>
                    <td>
                      <ProgressBox
                        percent={data?.occupancyRateV2Office}
                        styleBar={{maxWidth: "50%"}}
                        isBlurred={blurEnable}
                      />
                    </td>
                  </tr>
                  }
                  </>
                  }
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <IsPro reverseCheck overwriteRule={isEditable}>
            <PaywallBannerRowSimple style={{marginBottom: "1.6rem"}}/>
          </IsPro>
          {(!_.isEmpty(owner) || !_.isEmpty(contacts)) &&
          <PropertyHeaderContacts
            propertyID={data?.id}
            owner={owner}
            contacts={contacts}
            isIndustrialParkView={isIndustrialParkView}
            isIndustrialWarehouseView={isIndustrialWarehouseView}
            isEditable={isEditable}
          />
          }
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = store => ({
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyHeaderMain)
);