import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";

const initialState = {
  basic: null,
  basicLoading: false,
  units: null,
  unitsLoading: false,
  leaseTerms: null,
  leaseTermsLoading: false,
  marketplaceStats: null,
  marketplaceStatsLoading: false,
  amenities: null,
  amenitiesLoading: false,
  metrics: null,
  metricsLoading: false,
  changes: null,
  changesLoading: false,
  similarProperties: null,
  similarPropertiesLoading: false,
  owner: null,
  ownerLoading: false,
  leasingContacts: null,
  leasingContactsLoading: false,
  editFormsData: null,
  editFormsDataLoading: false,
  resetting: false
};

export const reducer = persistReducer(
  { storage, key: "officeBuilding" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICE BUILDING LOADING STATES
      case 'RESET_OFFICE_BUILDING_LOADING_STATES_REQUEST': {
        return {
          ...state,
          basicLoading: false,
          unitsLoading: false,
          leaseTermsLoading: false,
          marketplaceStatsLoading: false,
          amenitiesLoading: false,
          metricsLoading: false,
          changesLoading: false,
          similarPropertiesLoading: false,
          ownerLoading: false,
          leasingContactsLoading: false,
          editFormsDataLoading: false
        }
      }
      // BASIC
      case 'FETCH_OFFICE_BASIC_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          basic: backgroundLoading ? state.basic : null,
          basicLoading: !backgroundLoading
        }
      }
      case 'FETCH_OFFICE_BASIC_SUCCESS': {
        return { ...state, basic: action.payload, basicLoading: false }
      }
      case 'FETCH_OFFICE_BASIC_FAILED': {
        return { ...state, basic: null, basicLoading: false }
      }
      // UNITS
      case 'FETCH_OFFICE_BUILDING_UNITS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          unitsLoading: !backgroundLoading,
          units: backgroundLoading ? state.units : null
        }
      }
      case 'FETCH_OFFICE_BUILDING_UNITS_SUCCESS': {
        return { ...state, unitsLoading: false, units: [...action.payload.units] }
      }
      case 'FETCH_OFFICE_BUILDING_UNITS_FAILED': {
        return { ...state, unitsLoading: false, units: null }
      }
      // LEASE TERMS
      case 'FETCH_OFFICE_LEASE_TERMS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          leaseTerms: backgroundLoading ? state.leaseTerms : null,
          leaseTermsLoading: !backgroundLoading
        }
      }
      case 'FETCH_OFFICE_LEASE_TERMS_SUCCESS': {
        return { ...state, leaseTerms: action.payload, leaseTermsLoading: false }
      }
      case 'FETCH_OFFICE_LEASE_TERMS_FAILED': {
        return { ...state, leaseTerms: null, leaseTermsLoading: false }
      }
      // MARKETPLACE STATS
      case 'FETCH_OFFICE_MARKETPLACE_STATS_REQUEST': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: true }
      }
      case 'FETCH_OFFICE_MARKETPLACE_STATS_SUCCESS': {
        return { ...state, marketplaceStats: action.payload, marketplaceStatsLoading: false }
      }
      case 'FETCH_OFFICE_MARKETPLACE_STATS_FAILED': {
        return { ...state, marketplaceStats: null, marketplaceStatsLoading: false }
      }
      // AMENITIES
      case 'FETCH_OFFICE_BUILDING_AMENITIES_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          amenities: backgroundLoading ? state.amenities : null,
          amenitiesLoading: !backgroundLoading
        }
      }
      case 'FETCH_OFFICE_BUILDING_AMENITIES_SUCCESS': {
        return { ...state, amenities: action.payload?.amenities, amenitiesLoading: false }
      }
      case 'FETCH_OFFICE_BUILDING_AMENITIES_FAILED': {
        return { ...state, amenities: null, amenitiesLoading: false }
      }
      // METRICS
      case 'FETCH_OFFICE_METRICS_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          metrics: backgroundLoading ? state.metrics : null,
          metricsLoading: !backgroundLoading
        }
      }
      case 'FETCH_OFFICE_METRICS_SUCCESS': {
        return { ...state, metrics: action.payload, metricsLoading: false }
      }
      case 'FETCH_OFFICE_METRICS_FAILED': {
        return { ...state, metrics: null, metricsLoading: false }
      }
      // CHANGES
      case 'FETCH_OFFICE_CHANGES_REQUEST': {
        return { ...state, changes: null, changesLoading: true }
      }
      case 'FETCH_OFFICE_CHANGES_SUCCESS': {
        return { ...state, changes: action.payload?.changes, changesLoading: false }
      }
      case 'FETCH_OFFICE_CHANGES_FAILED': {
        return { ...state, changes: null, changesLoading: false }
      }
      // SIMILAR PROPERTIES
      case 'FETCH_OFFICE_SIMILAR_PROPERTIES_REQUEST': {
        return { ...state, similarProperties: null, similarPropertiesLoading: true }
      }
      case 'FETCH_OFFICE_SIMILAR_PROPERTIES_SUCCESS': {
        return { ...state, similarProperties: action.payload, similarPropertiesLoading: false }
      }
      case 'FETCH_OFFICE_SIMILAR_PROPERTIES_FAILED': {
        return { ...state, similarProperties: null, similarPropertiesLoading: false }
      }
      // OWNER
      case 'FETCH_OFFICE_OWNER_REQUEST': {
        return { ...state, owner: null, ownerLoading: true }
      }
      case 'FETCH_OFFICE_OWNER_SUCCESS': {
        return { ...state, owner: action.payload, ownerLoading: false }
      }
      case 'FETCH_OFFICE_OWNER_FAILED': {
        return { ...state, owner: null, ownerLoading: false }
      }
      // OWNER
      case 'FETCH_OFFICE_LEASING_CONTACTS_REQUEST': {
        return { ...state, leasingContacts: null, leasingContactsLoading: true }
      }
      case 'FETCH_OFFICE_LEASING_CONTACTS_SUCCESS': {
        return { ...state, leasingContacts: action.payload, leasingContactsLoading: false }
      }
      case 'FETCH_OFFICE_LEASING_CONTACTS_FAILED': {
        return { ...state, leasingContacts: null, leasingContactsLoading: false }
      }
      // EDIT FORMS DATA
      case 'FETCH_OFFICE_EDIT_FORMS_DATA_REQUEST': {
        return { ...state, editFormsData: null, editFormsDataLoading: true }
      }
      case 'FETCH_OFFICE_EDIT_FORMS_DATA_SUCCESS': {
        return { ...state, editFormsData: action.payload, editFormsDataLoading: false }
      }
      case 'FETCH_OFFICE_EDIT_FORMS_DATA_FAILED': {
        return { ...state, editFormsData: null, editFormsDataLoading: false }
      }

      // AUTO ACTION
      case 'RESET_OFFICE_BUILDING_STATE_REQUEST': {
        return {
          ...state,
          resetting: true
        }
      }
      case 'RESET_OFFICE_BUILDING_STATE_SUCCESS': {
        return {
          ...initialState
        }
      }
      case 'RESET_OFFICE_BUILDING_STATE_FAILED': {
        return {
          ...initialState
        }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOfficeBasic(action) {
  const {officeID} = action.payload;
  try {
    const officeBasic = yield axios.get(`/offices-v2/${officeID}/basic/`);
    yield put({ type: "FETCH_OFFICE_BASIC_SUCCESS", payload: officeBasic.data || [{ error: officeBasic.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_BASIC_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeUnits(action) {
  const {officeID, ordering} = action.payload;
  try {
    const setOrdering = [
      ...ordering ? [`?ordering=${ordering}`] : []
    ].join("");
    const units = yield axios.get(`/offices-v2/${officeID}/basic-units/${setOrdering}`);
    yield put({ type: "FETCH_OFFICE_BUILDING_UNITS_SUCCESS", payload: units.data || [{ error: units.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_BUILDING_UNITS_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeLeaseTerms(action) {
  const {officeID} = action.payload;
  try {
    const leaseTerms = yield axios.get(`/offices-v2/${officeID}/basic-rent/`);
    yield put({ type: "FETCH_OFFICE_LEASE_TERMS_SUCCESS", payload: leaseTerms.data || [{ error: leaseTerms.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_LEASE_TERMS_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeMarketplaceStats(action) {
  const {officeID} = action.payload;
  try {
    const stats = yield axios.get(`/offices-v2/${officeID}/basic-marketplace/`);
    yield put({ type: "FETCH_OFFICE_MARKETPLACE_STATS_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_MARKETPLACE_STATS_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeAmenities(action) {
  const {officeID} = action.payload;
  try {
    const amenities = yield axios.get(`/offices-v2/${officeID}/basic-amenities/`);
    yield put({ type: "FETCH_OFFICE_BUILDING_AMENITIES_SUCCESS", payload: amenities.data || [{ error: amenities.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_BUILDING_AMENITIES_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeMetrics(action) {
  const {officeID} = action.payload;
  try {
    const metrics = yield axios.get(`/offices-v2/${officeID}/basic-metrics/`);
    yield put({ type: "FETCH_OFFICE_METRICS_SUCCESS", payload: metrics.data || [{ error: metrics.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_METRICS_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeChanges(action) {
  const {officeID} = action.payload;
  try {
    const changes = yield axios.get(`/offices-v2/${officeID}/basic-changes/`);
    yield put({ type: "FETCH_OFFICE_CHANGES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeSimilarProperties(action) {
  const {officeID} = action.payload;
  try {
    const changes = yield axios.get(`/offices-v2/${officeID}/basic-similar/?size=6`);
    yield put({ type: "FETCH_OFFICE_SIMILAR_PROPERTIES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_SIMILAR_PROPERTIES_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeOwner(action) {
  const {officeID} = action.payload;
  try {
    const owner = yield axios.get(`/offices-v2/${officeID}/basic-owner/`);
    yield put({ type: "FETCH_OFFICE_OWNER_SUCCESS", payload: owner?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_OWNER_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeLeasingContacts(action) {
  const {officeID} = action.payload;
  try {
    const leasingContact = yield axios.get(`/offices-v2/${officeID}/basic-landlord/`);
    yield put({ type: "FETCH_OFFICE_LEASING_CONTACTS_SUCCESS", payload: leasingContact?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_LEASING_CONTACTS_FAILED" });
    console.log(err);
  }
}
function* fetchOfficeEditFormsData(action) {
  const {officeID} = action.payload;
  try {
    const editFormsData = yield axios.get(`/offices-v2/${officeID}/basic-form/`);
    yield put({ type: "FETCH_OFFICE_EDIT_FORMS_DATA_SUCCESS", payload: editFormsData.data || [{ error: editFormsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
function* resetOfficeBuildingState() {
  try {
    yield put({ type: "RESET_OFFICE_BUILDING_STATE_SUCCESS" });
  }
  catch(err) {
    yield put({ type: "RESET_OFFICE_BUILDING_STATE_FAILED" });
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_OFFICE_BASIC_REQUEST', fetchOfficeBasic);
  yield takeLatest('FETCH_OFFICE_BUILDING_UNITS_REQUEST', fetchOfficeUnits);
  yield takeLatest('FETCH_OFFICE_LEASE_TERMS_REQUEST', fetchOfficeLeaseTerms);
  yield takeLatest('FETCH_OFFICE_MARKETPLACE_STATS_REQUEST', fetchOfficeMarketplaceStats);
  yield takeLatest('FETCH_OFFICE_BUILDING_AMENITIES_REQUEST', fetchOfficeAmenities);
  yield takeLatest('FETCH_OFFICE_METRICS_REQUEST', fetchOfficeMetrics);
  yield takeLatest('FETCH_OFFICE_CHANGES_REQUEST', fetchOfficeChanges);
  yield takeLatest('FETCH_OFFICE_SIMILAR_PROPERTIES_REQUEST', fetchOfficeSimilarProperties);
  yield takeLatest('FETCH_OFFICE_OWNER_REQUEST', fetchOfficeOwner);
  yield takeLatest('FETCH_OFFICE_LEASING_CONTACTS_REQUEST', fetchOfficeLeasingContacts);
  yield takeLatest('FETCH_OFFICE_EDIT_FORMS_DATA_REQUEST', fetchOfficeEditFormsData);
  yield takeLatest('RESET_OFFICE_BUILDING_STATE_REQUEST', resetOfficeBuildingState);
}
