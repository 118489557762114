import React from "react";
import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {toAbsoluteUrl} from "_metronic";
import {sortArrayNum, toDateFormat} from "app/utils/helpers";
import FONTS_ALLOWED from "app/constants/FONTS_ALLOWED";
import {BUILDING_TYPES} from "../../../../constants";

class RenderAreaValue extends React.Component {
  render() {
    const {children, pdfStyles} = this.props;
    return(
      <View style={[pdfStyles.row, {justifyContent: "flex-start", alignItems: "flex-end"}]}>
        {children}
        <Text style={{fontSize: 10, marginLeft: 3, position: "relative", top: -2}}> m&sup2;</Text>
      </View>
    );
  }
}

const numberFormat = (number, fixedMin = 0, fixedMax = 2) => {
  if(isNaN(number)) {
    return number;
  }
  return number.toLocaleString('en-US', {minimumFractionDigits: fixedMin, maximumFractionDigits: fixedMax}).replace(',', ' ');
};
class Footer extends React.Component {
  render() {
    const styles = StyleSheet.create({
      footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      },
      footerText: {
        fontSize: 8,
        fontWeight: 300
      },
      pageNumber: {
        fontSize: 8,
        color: this.props.states.textColor,
      }
    });
    return(
      <View style={styles.footer} fixed>
        <Text style={styles.footerText}>All rights reserved. Presentation generated from REDD Platform.</Text>
        <View style={{flexGrow: 1, height: 1, marginHorizontal: 10, backgroundColor: colorSecondary}}/>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (`${pageNumber}`)}/>
      </View>
    );
  }
}
class FrontPage extends React.Component {
  render() {
    const {pdfStyles, user, renderTranslation, intl, states, shortlistName} = this.props;
    const teamName = user.companyName;
    let today = new Date();
    const yyyy = today.getFullYear();
    const month = today.toLocaleString(intl.locale, { month: 'long' });
    const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);
    today = monthCapitalized + " " + yyyy;
    const styles = StyleSheet.create({
      frontPage: {
        paddingLeft: 0
      },
      firstPage: {
        position: "relative",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
      },
      companyLogoBox: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: 150,
        height: 52
      },
      companyLogo: {
        objectFit: "contain"
      },
      pdfTitleContainer: {
        position: "relative",
        width: "80%",
        height: 180,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingHorizontal: 35,
        paddingTop: 50,
        paddingBottom: 35,
        backgroundColor: states.frontBoxBgColor
      },
      pdfTitleContent: {
        flex: 1,
        flexDirection: "column",
      },
      pdfTitleCompany: {
        flex: 1,
        flexDirection: "column",
        paddingTop: 7
      },
      titleBackground: {
        width: "100%"
      },
      titleText: {
        fontSize: 30,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      },
      textThin: {
        fontSize: 27,
        fontWeight: 300
      },
      titleCompany: {
        fontSize: 22,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1,
        marginBottom: 10
      },
      titleDate: {
        fontSize: 17,
        fontWeight: 300,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      },
      marginBottom: {
        marginBottom: 15
      }
    });

    return(
      <Page style={[pdfStyles.body, styles.frontPage]} size="A4" wrap>
        <View style={styles.firstPage}>
          <View style={styles.companyLogoBox}>
            {user.companyLogoThumb &&
            <Image
              style={styles.companyLogo}
              src={user.companyLogoThumb}
            />
            }
          </View>
          <View style={styles.pdfTitleContainer}>
            <View style={styles.pdfTitleContent}>
              <Text style={[styles.titleText, styles.textThin, styles.marginBottom]}>{renderTranslation("PDF.PHRASE.OFFER")}</Text>
              <Text style={[styles.titleText, styles.textThin]}>{teamName}</Text>
            </View>
            <View style={styles.pdfTitleCompany}>
              <Text style={[styles.titleCompany]}>{renderTranslation("GENERAL.PHRASE.FOR")}</Text>
              <Text style={[styles.titleCompany]}>{shortlistName}</Text>
              <Text style={[styles.titleDate]}>{today}</Text>
            </View>
          </View>
        </View>
        <Footer states/>
      </Page>
    );
  }
}
class BuildingPages extends React.Component {
  render() {
    if(this.props.data) {
      const {pdfStyles, data, renderTranslation, states} = this.props;
      return data.map((building, index) => {
        const {
          name,
          mainPhoto,
          market,
          district,
          street,
          type,
          status,
          totalAreaNet,
          buildingClass,
          buildingYear,
          certificates,
          availableArea,
          rentFromEur,
          retailRentFromEur,
          serviceChargesPln,
          rentParkingGroundEur,
          rentParkingUndergroundEur,
          commonAreaFactor,
          parkingFactorNumber,
          minRentalYears,
          units,
          geoLatitude,
          geoLongitude,
          parkingGroundNotExist,
          parkingGroundToNegotiate,
          parkingUndergroundNotExist,
          parkingUndergroundToNegotiate,
          minRentalYearsToNegotiate,
          minRentalYearsIndefinite
        } = building;
        const buildingType =
          type === BUILDING_TYPES.OFFICE_BUILDING.id ? renderTranslation(BUILDING_TYPES.OFFICE_BUILDING.name) :
            type === BUILDING_TYPES.APARTMENT_HOUSE.id ? renderTranslation(BUILDING_TYPES.APARTMENT_HOUSE.name) :
              type === BUILDING_TYPES.WAREHOUSE.id ? renderTranslation(BUILDING_TYPES.WAREHOUSE.name) :
                type === BUILDING_TYPES.SHOPPING_CENTER.id ? renderTranslation(BUILDING_TYPES.SHOPPING_CENTER.name) :
                  type === BUILDING_TYPES.HQ.id ? renderTranslation(BUILDING_TYPES.HQ.name) :
                    type === BUILDING_TYPES.TENEMENT_HOUSE.id ? renderTranslation(BUILDING_TYPES.TENEMENT_HOUSE.name) : renderTranslation(BUILDING_TYPES.OTHER.name);
        const buildingStatus = status === 0 ? renderTranslation("PDF.PHRASE.EXISTING") : status === 1 ? renderTranslation("PDF.PHRASE.UNDER_CONSTRUCTION") : status === 2 ? renderTranslation("PDF.PHRASE.PLANNED") : status === 3 ? renderTranslation("PDF.PHRASE.UNDER_REVITALIZATION") : status === 4 ? renderTranslation("PDF.PHRASE.UNDER_REDEVELOPMENT") : "---";
        const buildingCerts = certificates.join(", ");
        const buildingPhoto = mainPhoto ? mainPhoto : toAbsoluteUrl("media/office-placeholder.jpg");
        const buildingStaticMap = `${geoLatitude},${geoLongitude}`;
        const buildingMapMarkerLetter = name[0];
        const buildingParkingGround = parkingGroundNotExist ? renderTranslation("BUILDING.PHRASE.NOT_EXIST") : parkingGroundToNegotiate ? renderTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : rentParkingGroundEur ? `€ ${rentParkingGroundEur}` : null;
        const buildingParkingUnderground = parkingUndergroundNotExist ? renderTranslation("BUILDING.PHRASE.NOT_EXIST") : parkingUndergroundToNegotiate ? renderTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : rentParkingUndergroundEur ? `€ ${rentParkingUndergroundEur}` : null;
        const buildingMinRentalYears = minRentalYearsToNegotiate ? renderTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : minRentalYearsIndefinite ? renderTranslation("BUILDING.PHRASE.INDEFINITE") : minRentalYears ? minRentalYears : null;

        const styles = StyleSheet.create({
          buildingTitle: {
            fontSize: 22,
            fontWeight: 600,
            textTransform: "uppercase",
            color: states.h1Color
          },
          buildingDetails: {
            width: "35%",
            paddingTop: 10,
            borderTopWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            marginRight: 10
          },
          buildingDetailsPreview: {
            width: "100%",
            marginVertical: 15,
            paddingVertical: 10,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
          },
          buildingImageBox: {
            width: "60%",
            height: 297,
            overflow: "hidden"
          },
          buildingImage: {
            width: "100%",
            height: "auto"
          },
          city: {
            fontSize: 20,
            fontWeight: 600
          },
          district: {
            fontSize: 18,
            fontWeight: 300
          },
          street: {
            fontSize: 13,
            fontWeight: 300,
            marginBottom: 10
          },

          unitsContainer: {
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: 30
          },
          tableThead: {
            width: "100%"
          },
          tableBodyRow: {
            width: "100%"
          },
          thValue: {
            fontSize: 9,
            textAlign: "right",
            textTransform: "uppercase"
          },
          tdValue: {
            fontSize: 11,
            textAlign: "right",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingHorizontal: 5,
            paddingVertical: 1,
            position: "relative"
          },
          col0: {
            width: "5%"
          },
          col1: {
            width: "25%"
          },
          col2: {
            width: "15%"
          },
          col3: {
            width: "25%"
          },
          col4: {
            width: "30%"
          },

          leaseTermsContainer: {
            width: "48%"
          },
          buildingMapStatic: {
            width: "50%",
            maxHeight: 220,
            overflow: "hidden"
          },
          buildingMapStaticImage: {
            width: "100%",
            height: "auto"
          },
          leaseRow: {
            width: "100%",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingTop: 4,
            paddingBottom: 7,
            alignItems: "center"
          },
          leaseLabel: {
            width: "47%",
            fontSize: 9,
            lineHeight: 1
          },
          leaseValue: {
            width: "49%",
            fontSize: 9,
            fontWeight: 600,
            lineHeight: 1,
            textAlign: "right"
          },
          subleaseLabel: {
            position: "absolute",
            top: -1,
            right: 10,
            fontSize: 6,
            color: "#FF4626"
          }
        });
        const renderUnits = (units) => {
          if(units) {
            return sortArrayNum(units, "floor").map((unit, index) => {
              const {availableFrom, type} = unit;
              const currentDate = new Date().getTime();
              const availableDate = new Date(availableFrom).getTime();
              const date = currentDate >= availableDate ? renderTranslation("GENERAL.PHRASE.IMMEDIATELY") : toDateFormat(availableFrom);
              const unitType = type === 1 ? "office" : type === 2 ? "retail" : "o/r";

              return(
                <View key={Math.random()+index} style={[pdfStyles.row, styles.tableBodyRow]}>
                  <Text style={[styles.tdValue, styles.col0]}>{index+1}</Text>
                  <Text style={[styles.tdValue, styles.col1]}>{numberFormat(unit.area)} m&sup2;</Text>
                  <Text style={[styles.tdValue, styles.col2]}>{unit.floor}</Text>
                  {unit.isSublease ? (
                  <View style={[styles.tdValue, styles.col3]}>
                  <Text>{unitType}</Text>
                  <Text style={styles.subleaseLabel}>{renderTranslation("UNITS.PHRASE.SUBLEASE")}</Text>
                  </View>
                  ) : (
                  <Text style={[styles.tdValue, styles.col3]}>{unitType}</Text>
                  )}
                  <Text style={[styles.tdValue, styles.col4]}>{date}</Text>
                </View>
              );
            })
          }
        };
        const renderLeaseTerm = (label, value) => {
          return(
            <View style={[pdfStyles.row, styles.leaseRow]}>
              <Text style={[pdfStyles.label, styles.leaseLabel]}>{label}</Text>
              <Text style={[pdfStyles.label, styles.leaseValue]}>{value}</Text>
            </View>
          );
        };

        return(
          <Page key={index} style={pdfStyles.body} size="A4" wrap>
            <Text style={styles.buildingTitle}>{name}</Text>
            <View style={pdfStyles.row}>
              <View style={[pdfStyles.column, styles.buildingDetails]}>
                <Text style={styles.city}>{market}</Text>
                <Text style={styles.district}>{district}</Text>
                <Text style={styles.street}>{street}</Text>
                <View style={pdfStyles.row}>
                  <View style={{width: "45%", marginRight: 10}}>
                    <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.BUILDING_TYPE")}</Text>
                    <Text style={[pdfStyles.labelWithBg, {fontSize: 10}]}>{buildingType}</Text>
                  </View>
                  <View style={{width: "45%", marginRight: 10}}>
                    <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.BUILDING_STATUS")}</Text>
                    <Text style={[pdfStyles.labelWithBg, {fontSize: 10}]}>{buildingStatus}</Text>
                  </View>
                </View>
                <View style={styles.buildingDetailsPreview}>
                  <View style={pdfStyles.row}>
                    <View style={{width: "50%", marginRight: 10}}>
                      <RenderAreaValue pdfStyles={pdfStyles}>
                        <Text style={pdfStyles.labelValue}>{numberFormat(totalAreaNet)}</Text>
                      </RenderAreaValue>
                      <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.TOTAL_AREA")}</Text>
                    </View>
                    <View style={{width: "50%", marginRight: 10}}>
                      <Text style={pdfStyles.labelValue}>{buildingClass}</Text>
                      <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.BUILDING_CLASS")}</Text>
                    </View>
                  </View>
                  <View style={[pdfStyles.row, {marginVertical: 10}]}>
                    <View style={{width: "50%", marginRight: 10}}>
                      <Text style={pdfStyles.labelValue}>{buildingYear}</Text>
                      <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.COMPLETION_YEAR")}</Text>
                    </View>
                    {certificates && certificates.length ?
                    <View style={{width: "50%", marginRight: 10}}>
                      <Text style={pdfStyles.labelValue}>{buildingCerts}</Text>
                      <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.CERTIFICATE")}</Text>
                    </View> : null
                    }
                  </View>
                  <View style={pdfStyles.row}>
                    <View style={{width: "100%", marginRight: 10}}>
                      <RenderAreaValue pdfStyles={pdfStyles}>
                        <Text style={pdfStyles.labelValue}>{numberFormat(availableArea)}</Text>
                      </RenderAreaValue>
                      <Text style={pdfStyles.label}>{renderTranslation("PDF.PHRASE.TOTAL_AVAILABLE_AREA")}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.buildingImageBox}>
                <Image
                  style={styles.buildingImage}
                  src={buildingPhoto}
                />
              </View>
            </View>
            <View style={[pdfStyles.column, styles.unitsContainer]}>
              <Text style={pdfStyles.subtitle}>{renderTranslation("PDF.PHRASE.AVAILABLE_UNITS")}</Text>
              <View style={[pdfStyles.row, styles.tableThead]}>
                <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col0]}>#</Text>
                <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col1]}>{renderTranslation("PDF.PHRASE.AREA")}</Text>
                <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col2]}>{renderTranslation("PDF.PHRASE.FLOOR")}</Text>
                <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col3]}>{renderTranslation("PDF.PHRASE.TYPE")}</Text>
                <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col4]}>{renderTranslation("PDF.PHRASE.AVAILABLE_FROM")}</Text>
              </View>
              {renderUnits(units)}
            </View>
            <View style={pdfStyles.row}>
              <View style={[pdfStyles.column, styles.leaseTermsContainer]}>
                <Text style={pdfStyles.subtitle}>{renderTranslation("PDF.PHRASE.LEASE_TERMS")}</Text>
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.LANDLORD.OFFICE_HEADLINE_RENT"), rentFromEur ? `€ ${rentFromEur}` : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.LANDLORD.RETAIL_HEADLINE_RENT"), retailRentFromEur ? `€ ${retailRentFromEur}` : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.SERVICE_CHARGE"), serviceChargesPln ? `PLN ${serviceChargesPln}` : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.GROUND_PARKING_RENT", "\n"), buildingParkingGround ? buildingParkingGround : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.UNDERGROUND_PARKING_RENT", "\n"), buildingParkingUnderground ? buildingParkingUnderground : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.COMMON_AREA_FACTOR", "\n"), commonAreaFactor ? `${commonAreaFactor}%` : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.PARKING_AREA_FACTOR", "\n"), parkingFactorNumber ? `1/${parkingFactorNumber}` : '')}
                {renderLeaseTerm(renderTranslation("PDF.PHRASE.MIN_RENTAL_PERIOD", "\n"), buildingMinRentalYears ? buildingMinRentalYears : '')}
              </View>
              <View style={styles.buildingMapStatic}>
                <Image
                  style={styles.buildingMapStaticImage}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${buildingStaticMap}&zoom=17&scale=1&size=439x370&maptype=roadmap&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xe73939%7Clabel:${buildingMapMarkerLetter}%7C${buildingStaticMap}&key=AIzaSyCsW871Avr_mDFw5aTw3XS7Cir7mK1Rw54`}
                />
              </View>
            </View>
            <Footer states/>
          </Page>
        );
      });
    }
    return false;
  }
}
class BuildingsListPage extends React.Component {
  render() {
    const {pdfStyles, data, renderTranslation} = this.props;
    const styles = StyleSheet.create({
      buildingTitleContainer: {
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "#d9d9d9",
        paddingTop: 5,
        marginBottom: 5,
        alignItems: "flex-end"
      },
      buildingTitle: {
        fontSize: 13,
        fontWeight: 600,
      },
      buildingLocation: {
        fontSize: 11,
        fontWeight: 400
      },
      buildingSummaryTable: {
        marginBottom: 25
      },
      tableThead: {
        width: "100%",
        alignItems: "center"
      },
      tableBodyRow: {
        width: "100%"
      },
      thValue: {
        fontSize: 7,
        fontWeight: 400,
        textTransform: "uppercase"
      },
      tdValue: {
        fontSize: 9,
        fontWeight: 600,
        paddingHorizontal: 5,
        paddingVertical: 5
      },
      col1: {
        width: "19%"
      },
      col2: {
        width: "15%"
      },
      col3: {
        width: "18%"
      },
      col4: {
        width: "18%"
      },
      col5: {
        width: "15%"
      },
      col6: {
        width: "15%"
      },
    });

    return(
      <Page style={pdfStyles.body} size="A4" wrap>
        {data && data.map((building, index) =>
        <View key={Math.random()+index}>
        <View style={[pdfStyles.row, styles.buildingTitleContainer]}>
          <Text style={styles.buildingTitle}>{building.name}</Text>
          <Text style={styles.buildingLocation}>{building.market} | {building.district} | {building.street}</Text>
        </View>
        <View style={[pdfStyles.row, styles.buildingSummaryTable]}>
          <View style={{width: "10%"}}>
            <Image
              style={styles.buildingImage}
              src={building.mainPhoto ? building.mainPhoto : toAbsoluteUrl("media/office-placeholder.jpg")}
            />
          </View>
          <View style={{width: "90%"}}>
            <View style={[pdfStyles.row, styles.tableThead]}>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col1]}>{renderTranslation("PDF.PHRASE.RENT_FROM", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col2]}>{renderTranslation("PDF.PHRASE.SERVICE_CHARGE", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col3]}>{renderTranslation("PDF.PHRASE.GROUND_PARKING_RENT_SHORT", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col4]}>{renderTranslation("PDF.PHRASE.UNDERGROUND_PARKING_RENT_SHORT", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col6]}>{renderTranslation("PDF.PHRASE.COMMON_AREA_FACTOR_SHORT", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col5]}>{renderTranslation("PDF.PHRASE.MIN_RENTAL_YEARS_SHORT", "\n")}</Text>
            </View>
            <View style={[pdfStyles.row, styles.tableBodyRow]}>
              <Text style={[styles.tdValue, styles.col1]}>{building.rentFromEur ? `€ ${building.rentFromEur}` : ''}</Text>
              <Text style={[styles.tdValue, styles.col2]}>{building.serviceChargesPln ? `PLN ${building.serviceChargesPln}` : ''}</Text>
              <Text style={[styles.tdValue, styles.col3]}>{building.rentParkingGroundEur ? `€ ${building.rentParkingGroundEur}` : ''}</Text>
              <Text style={[styles.tdValue, styles.col4]}>{building.rentParkingUndergroundEur ? `€ ${building.rentParkingUndergroundEur}` : ''}</Text>
              <Text style={[styles.tdValue, styles.col6]}>{building.commonAreaFactor ? `${building.commonAreaFactor}%` : ''}</Text>
              <Text style={[styles.tdValue, styles.col5]}>{building.minRentalYears ? building.minRentalYears : ''}</Text>
            </View>
          </View>
        </View>
        </View>
        )}
        <Footer states/>
      </Page>
    );
  }
}
class ContactPage extends React.Component {
  render() {
    const {pdfStyles, user} = this.props;
    const userAvatar = user.avatar ? user.avatar : toAbsoluteUrl("media/office-placeholder.jpg");
    const styles = StyleSheet.create({
      contactContainer: {
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      },
      personName: {
        fontSize: 16,
        fontWeight: 400
      },
      personContact: {
        fontSize: 14,
        fontWeight: 300
      },
      personPosition: {
        fontSize: 12,
        fontWeight: 400
      },
      personCompany: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 10
      }
    });
    return(
        <Page style={pdfStyles.body} size="A4" wrap>
          <View style={[pdfStyles.column, styles.contactContainer]}>
            <View style={{width: "30%", marginBottom: 10}}>
              <Image
                style={styles.buildingImage}
                src={userAvatar}
              />
            </View>
            <Text style={styles.personName}>{`${user.firstName} ${user.lastName}`}</Text>
            <Text style={styles.personPosition}>{user.position}</Text>
            <Text style={styles.personCompany}>{user.companyName}</Text>
            <Text style={styles.personContact}>{user.email}</Text>
            <Text style={styles.personContact}>{user.phoneNumber}</Text>
          </View>
          <Footer states/>
        </Page>
    );
  }
}

const colorPrimary = "#5D6472";
const colorSecondary = "#d9d9d9";
const pdfFontFamily = "Poppins";

export default class PdfDocument extends React.Component {
  constructor(props) {
    super(props);
    const {user} = props;
    this.state = {
      frontBoxBgColor: user.pdfFrontPageBoxBg || colorPrimary,
      frontBoxTextColor: user.pdfFrontPageBoxText || "#ffffff",
      grayBoxesBgColor: user.pdfGreyBoxesBg || colorSecondary,
      grayBoxesTextColor: user.pdfGreyBoxesText || colorPrimary,
      h1Color: user.pdfH1Text || colorPrimary,
      h2Color: user.pdfH2Text || colorPrimary,
      textColor: user.pdfPText || colorPrimary,
      textFontFamily: user.pdfFontFamily || pdfFontFamily
    }
  }
  render() {
    const {intl, shortlistName, data, user} = this.props;
    const {textFontFamily} = this.state;
    // Register fonts
    FONTS_ALLOWED.map(font => Font.register(font.code));

    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: textFontFamily,
        color: this.state.textColor
      },
      subtitle: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 5,
        color: this.state.h2Color
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      label: {
        fontSize: 10,
        fontWeight: 300,
        textTransform: "uppercase"
      },
      labelWithBg: {
        display: "block",
        width: "100%",
        fontSize: 12,
        fontWeight: 300,
        backgroundColor: this.state.grayBoxesBgColor,
        color: this.state.grayBoxesTextColor,
        padding: 3
      },
      labelValue: {
        fontSize: 13,
        fontWeight: 400
      },
    });
    const handleStringTranslation = (id, br) => {
      return intl.formatMessage({id: id}, {br})
    };
    return(
      <Document>
        <FrontPage states={this.state} pdfStyles={styles} shortlistName={shortlistName} user={user} intl={intl} renderTranslation={handleStringTranslation}/>
        <BuildingPages states={this.state} data={data} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        <BuildingsListPage states={this.state} data={data} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        <ContactPage states={this.state} pdfStyles={styles} user={user}/>
      </Document>
    );
  }
}