import React from "react";
import clsx from "clsx";
import styles from "app/partials/components/PartialComponents.module.scss";
import modalStyles from "./index.module.scss";
import Icon from "@material-ui/core/Icon/Icon";
import {IconButton} from "@material-ui/core";
import ButtonTooltip from "app/partials/components/ButtonTooltip";
import ButtonCustom from "app/partials/components/ButtonCustom";
import TooltipCustom from "../TooltipCustom";

export class ModalTrigger extends React.Component {
  render() {
    const {
      icon,
      iconPrefix,
      label,
      color,
      directIcon,
      directBtn,
      onClick,
      solid,
      darken,
      fullWidth,
      widthFit,
      inactive,
      style,
      styleParent,
      iconReverse,
      iconSizeInherit,
      iconWithoutBg,
      tooltip,
      tooltipPosition = "top",
      directBtnTooltip,
      defaultCursor,
      normalCase,
      styleDefault
    } = this.props;
    if(directIcon && !label) {
      return(
        <IconButton
          color="inherit"
          style={style}
          className={iconWithoutBg ? modalStyles.plainIcon : undefined}
          onClick={onClick}
        >
          <Icon fontSize={iconSizeInherit ? "inherit" : "medium"}>
            {icon}
          </Icon>
        </IconButton>
      );
    }
    else if (directIcon) {
      return (
        <ButtonTooltip
          tPlacement={tooltipPosition}
          tOverlay={label}
          color={color}
          onClick={onClick}
          inactive={inactive}
          defaultCursor={defaultCursor}
        >
          <IconButton color="inherit" style={style} className={iconWithoutBg ? modalStyles.plainIcon : undefined}>
            <Icon fontSize={iconSizeInherit ? "inherit" : "medium"}>
              {icon}
            </Icon>
          </IconButton>
        </ButtonTooltip>
      );
    }
    else if (directBtn) {
      if (directBtnTooltip) {
        return (
          <ButtonTooltip
            tPlacement={tooltipPosition}
            tOverlay={directBtnTooltip}
            color={color}
            onClick={onClick}
            inactive={inactive}
            defaultCursor={defaultCursor}
          >
            <ButtonCustom
              label={label}
              icon={icon}
              color={color}
              solid={solid}
              fullWidth={fullWidth}
              onClick={onClick}
              inactive={inactive}
              style={styleParent}
              btnStyle={style}
              iconReverse={iconReverse}
              widthFit={widthFit}
            />
          </ButtonTooltip>
        );
      }
      else {
        return (
          <ButtonCustom
            label={label}
            icon={icon}
            iconPrefix={iconPrefix}
            color={color}
            solid={solid}
            darken={darken}
            fullWidth={fullWidth}
            onClick={onClick}
            inactive={inactive}
            style={styleParent}
            btnStyle={style}
            iconReverse={iconReverse}
            widthFit={widthFit}
            normalCase={normalCase}
          />
        );
      }
    }
    else if(tooltip) {
      return(
        <TooltipCustom title={tooltip} placement={tooltipPosition}>
          <span
            className={clsx("kt-nav__link", styles.actionButton)}
            onClick={onClick}
            style={{ color: color, ...styleDefault }}
          >
            {icon && (
              <span
                className={clsx(
                  styles.actionIcon,
                  iconReverse && styles.iconReverse
                )}
              >
                <Icon>{icon}</Icon>
              </span>
            )}
            <span className="kt-nav__link-text">{label}</span>
          </span>
        </TooltipCustom>
      );
    }
    return (
      <span
        className={clsx("kt-nav__link", styles.actionButton)}
        onClick={onClick}
        style={{ color: color, ...styleDefault }}
      >
        {icon && (
          <span
            className={clsx(
              styles.actionIcon,
              iconReverse && styles.iconReverse
            )}
          >
            <Icon>{icon}</Icon>
          </span>
        )}
        <span className="kt-nav__link-text">{label}</span>
      </span>
    );
  }
}
