import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import ReactVisibilitySensor from "react-visibility-sensor";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import CompaniesView from "./components/CompaniesView";
import IsAccountType from "../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";
import styles from "../components/LeadsPages/index.module.scss";
import IconColorMUI from "../../../partials/components/IconColorMUI";
import cn from "classnames";
import LoadingScreen from "../../../partials/components/LoadingScreen";

export default class LeadsPotentialTenantsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      companiesLoading,
      fetchMarketplaceLeadsCompanies,
      currentApplicationView,
      callReddClickEvent
    } = this.props;
    const {isReady} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const target = isOfficeView ? "offices" : "industrial";

    // Fetch Data
    if(isVisible && !companiesLoading && fetchMarketplaceLeadsCompanies) {
      const params = {
        page: 1
      };
      fetchMarketplaceLeadsCompanies(target, {params});
    }
    // Tracking event
    if(isVisible && !isReady) {
      this.setState({
        isReady: true
      }, () => {
        if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
          callReddClickEvent(
            "leads-potential-tenants-office-page-enter",
            "user"
          );
        }
        else if(currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
          callReddClickEvent(
            "leads-potential-tenants-industrial-page-enter",
            "user"
          );
        }
      });
    }
  }
  handleDownloadCsv = () => {
    const {potentialTenantsXlsLoading, currentApplicationView, fetchMarketplaceLeadsCompaniesXls, callReddClickEvent, user} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const teamName = user?.team?.name;

    if(!potentialTenantsXlsLoading && fetchMarketplaceLeadsCompaniesXls) {
      callReddClickEvent(
        isOfficeView ? 'finne-leads-offices-lead-companies-download-csv-click' : 'finne-leads-industrial-lead-companies-download-csv-click',
        'lead',
        'marketplace'
      );
      fetchMarketplaceLeadsCompaniesXls(isOfficeView ? 'offices' : 'industrial', teamName);
    }
  }

  render() {
    const {potentialTenantsXlsLoading} = this.props;

    return(
      <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
        <Portlet fluidHeight>
          <PortletHeader
            title={<Typography variant="h3"><FormattedMessage id="MENU.LEADS_POTENTIAL_TENANTS"/></Typography>}
            style={{borderBottom: 0}}
            toolbar={
              <button
                className={cn(
                  styles.btnDownload,
                  potentialTenantsXlsLoading ? styles.inactive : undefined
                )}
                onClick={() => this.handleDownloadCsv()}
              >
                {potentialTenantsXlsLoading ? <LoadingScreen loaderStyle={{width: "16px", height: "16px", marginRight: "5px"}}/> :
                <IconColorMUI
                  icon="download"
                  size="18px"
                  style={{paddingRight: "5px", lineHeight: "0"}}
                />
                }
                <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
              </button>
            }
            titleToolbarBottom={
            <div className={styles.titleDescriptionWrapper}>
              <IconColorMUI
                icon="info_outlined"
                size="1.8rem"
                style={{lineHeight: "0"}}
              />
              <Typography variant="body2">
                <FormattedMessage id="VOCABULARY.Companies viewing your property listings on finne without filling contact forms"/>
              </Typography>
            </div>
            }
          />
          <PortletBody style={{paddingTop: 0}}>
            <ReactVisibilitySensor
              onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
              scrollCheck
              partialVisibility
            >
              <CompaniesView {...this.props}/>
            </ReactVisibilitySensor>
          </PortletBody>
        </Portlet>
      </IsAccountType>
    );
  }
}
