import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import ModalCustom from "../ModalCustom";
import GoogleMaps from "../GoogleMaps/GoogleMaps";
import styles from "./index.module.scss";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

class SimpleMap extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  // Handlers
  handleOnMapOpen = () => {
    const {callReddClickEvent, propertyID, currentApplicationView, view} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const isIndustrialWarehouseView = isIndustrialView && view === "industrialWarehouse";

    if(propertyID && callReddClickEvent) {
      if(isOfficeView) {
        callReddClickEvent(
          "office-building-page-show-on-map-click",
          "office",
          null,
          propertyID
        );
      }
      else if(isIndustrialView) {
        if(isIndustrialWarehouseView) {
          callReddClickEvent(
            "industrial-warehouse-page-show-on-map-click",
            "warehouse",
            null,
            propertyID
          );
        }
        else {
          callReddClickEvent(
            "industrial-park-page-show-on-map-click",
            "park",
            null,
            propertyID
          );
        }
      }
    }
  }

  render() {
    const {lat, lng} = this.props;

    if(lat && lng) {
      return(
        <ModalCustom
          ref={this.child}
          btn={
            <button className={styles.btnShowOnMap}><FormattedMessage id="VOCABULARY.Show on map"/></button>
          }
          fullScreen
          onOpen={() => this.handleOnMapOpen()}
        >
          <GoogleMaps lat={lat} lng={lng} zoom={14} disableScrollWheel minHeight="337px"/>
        </ModalCustom>
      );
    }
    return null;
  }
}

const mapStateToProps = store => ({
  currentApplicationView: store.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SimpleMap)
);