import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {ReactComponent as IconThumbUp} from "app/media/icons/thumb_up.svg";
import {ReactComponent as IconThumbDown} from "app/media/icons/thumb_down.svg";
import {getRandomNumber, handleEmptyValue, toNumberFormat} from "../../../../utils/helpers";
import cn from "classnames";
import TooltipCustom from "../../../../partials/components/TooltipCustom";
import PrintModeDetect from "../../../../partials/components/PrintModeDetect";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";

export default function PropertyHeaderDynamicAnalytics({data, callReddClickEvent}) {
  const [activeVote, setActiveVote] = useState('');
  const dynamicData = [
    {
      label: "Time to lease",
      key: "waitingForChangeAvgLastSixMonths",
      tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.TIME_TO_LEASE_DESCRIPTION"/>
    },
    {
      label: "6M Take-Up",
      key: "leaseUptakeLastSixMonths",
      tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_LEASE_UPTAKE_DESCRIPTION"/>
    },
    {
      label: "6M New Vacancy",
      key: "newVacancyLastSixMonths",
      tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.6M_NEW_VACANCY_DESCRIPTION"/>
    },
    {
      label: "Last Transaction",
      key: "lastTransaction",
      tooltip: <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.LAST_TRANSACTION_DESCRIPTION"/>
    }
  ];
  const renderDataBox = (item) => {
    const itemValue = item?.key === "lastTransaction" ?
      handleEmptyValue(data?.[item?.key]?.area, true) :
      handleEmptyValue(data?.[item?.key], true);
    const isAreaValue = item?.key === "lastTransaction" ||
      item?.key === "newVacancyLastSixMonths" ||
      item?.key === "leaseUptakeLastSixMonths";

    return (
      <div className={cn(
        styles.propertyDynamicAnalyticsDataWrapper,
        !itemValue ? styles.emptyValue : undefined
      )}>
        <TooltipCustom
          title={item?.tooltip}
          placement="right"
        >
          <label>{item?.label} <IconColorMUI icon="info_outlined" style={{paddingLeft: "3px", lineHeight: 0}}/></label>
        </TooltipCustom>
        <p className={styles.dataValue}>
          <BlurText
            type={isAreaValue ? "area" : "custom"}
            customBlurValue={isAreaValue ? undefined :
            <FormattedMessage
              id="BUILDING.PHRASE.REDD_INDEX_DAYS"
              values={{days: getRandomNumber(0, 200)}}
            />
            }
            isBlurred={!IsPro({})}
            blurStrength="11px"
          >
            {
              isAreaValue ?
                toNumberFormat(
                  itemValue,
                  'area',
                  null,
                  null,
                  <span><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span>
                ) :
                itemValue ?
                  <FormattedMessage
                    id="BUILDING.PHRASE.REDD_INDEX_DAYS"
                    values={{days: Number(itemValue).toFixed(0)}}
                  /> :
                  <span><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.NO_TRANSACTIONS"/></span>
            }
          </BlurText>
        </p>
      </div>
    );
  }
  const handleSendOpinion = (vote) => {
    const propertyID = data?.id;
    setActiveVote(vote);

    if (propertyID && callReddClickEvent) {
      callReddClickEvent(
        "office-building-page-dynamic-analytics-useful",
        "office",
        null,
        propertyID,
        {
          useful: vote
        },
        true
      );
    }
  }

  return (
    <Grid container spacing={2}>
      {dynamicData?.map((item, index) => {
        return (
          <Grid key={index} item xs={3}>
            {renderDataBox(item)}
          </Grid>
        );
      })}
      <IsPro>
        <PrintModeDetect hide>
        <Grid item xs={12}>
          <div className={styles.dynamicDataEvaluateWrapper}>
            <p><FormattedMessage id="VOCABULARY.Is this information useful?"/></p>
            <button
              className={cn(
                styles.btnVoteYes,
                activeVote === "Yes" ? styles.active : undefined
              )}
              onClick={() => handleSendOpinion('Yes')}
            ><IconThumbUp/></button>
            <button
              className={cn(
                styles.btnVoteNo,
                activeVote === "No" ? styles.active : undefined
              )}
              onClick={() => handleSendOpinion('No')}
            ><IconThumbDown/></button>
          </div>
        </Grid>
      </PrintModeDetect>
      </IsPro>
    </Grid>
  );
}