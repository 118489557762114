import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../../../partials/content/Portlet";
import clsx from "clsx";
import statsStyles from "../../BuildingsPage/components/index.module.scss";
import TableCustom from "../../../../partials/components/TableCustom";
import {handleEmptyValue, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import CopyLinkToClipboard from "../../../../partials/components/CopyLinkToClipboard";
import ROUTES from "../../../../constants/ROUTES";
import styles from "../../../common/OffersOnlineAnalysisPage/components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import BIDataPreviewItem from "app/partials/components/MarketplaceLeadsDataPreviewItem";

class OfferOnlineDetails extends React.Component {
    constructor(props) {
        super(props);
        const {offerID, onFetchData} = props;
        this.state = {
            viewOffices: true
        };
        if(offerID) {
            onFetchData(offerID);
        }
    }
    handleToggleViews = () => {
        this.setState({
            viewOffices: !this.state.viewOffices
        })
    };
    handleTableBody = view => {
        const {data, intl} = this.props;
        const {viewOffices} = this.state;
        const sourceData = data && (data[view].length || Object.keys(data[view]).length) && data[view];
        return sourceData ? sourceData.map(row => {
            return {
                "data": [
                    viewOffices ? handleEmptyValue(row.name) : handleEmptyValue(row.email),
                    viewOffices ? <CopyLinkToClipboard route={ROUTES.OFFERS_ONLINE} routeParams={[data.id, row.id]} label="GENERAL.PHRASE.COPY_TO_CLIPBOARD"/> : "",
                    viewOffices ? toNumberFormat(row.views) : handleEmptyValue(row.buildingName),
                    viewOffices ? toNumberFormat(row.contacts) : handleEmptyValue(intl.formatMessage({id: `OFFERS.PHRASE.ACTION.${row.action}`})),
                    viewOffices ? toDateFormat(row.lastVisit) : toDateFormat(row.date, true)
                ]
            }
        }) : [];
    };

    render() {
        const {data, loading} = this.props;
        const {viewOffices} = this.state;
        const dataSource = viewOffices ? "offices" : "visitors";
        const tHead = [
          {
            "label": viewOffices ? <FormattedMessage id="BUILDING.PHRASE.BUILDING_NAME"/> : <FormattedMessage id="OFFERS.TABLE.VISITOR"/>,
            "number": false
          },
          {
            "label": "",
            "number": true
          },
          {
            "label": viewOffices ? <FormattedMessage id="OFFERS.TABLE.VIEWS"/> : <FormattedMessage id="OFFERS.TABLE.OFFICE_NAME"/>,
            "number": viewOffices
          },
          {
            "label": viewOffices ? <FormattedMessage id="OFFERS.TABLE.CONTACT_REVEALS"/> : <FormattedMessage id="OFFERS.TABLE.ACTION"/>,
            "number": viewOffices
          },
          {
            "label": viewOffices ? <FormattedMessage id="OFFERS.TABLE.LAST_VISIT"/> : <FormattedMessage id="OFFERS.TABLE.LAST_INTERACTION"/>,
            "number": true
          }
        ];

        if(loading || !data) {
            return <LoadingScreen/>;
        }
        return(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Portlet fluidHeight={true}>
                    <PortletHeader
                      className={styles.portletFitWidth}
                      title={
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                          <Grid item>
                            <Typography variant="h3" className={styles.portletTitle}>
                              <FormattedMessage id="OFFERS.PHRASE.OFFER_NAME_ANALYSIS" values={{name: data ? data.name : ""}}/>
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <BIDataPreviewItem
                              value={data.views}
                              label="OFFERS.PHRASE.OFFER_VIEWS"
                              icon={toAbsoluteUrl("media/branding/icons/offer-views.png")}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <BIDataPreviewItem
                              value={data.details}
                              label="OFFERS.PHRASE.OFFER_BUILDINGS_VIEWS"
                              icon={toAbsoluteUrl("media/branding/icons/offer-buildings-views.png")}
                            />
                          </Grid>
                        </Grid>
                      }
                      toolbar={
                          <div style={{display: "flex"}}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <button
                                    className={clsx(statsStyles.customBtn, viewOffices && statsStyles.active, statsStyles.light)}
                                    onClick={this.handleToggleViews}
                                >
                                    <Typography><span><FormattedMessage id="OFFERS.PHRASE.BUILDINGS"/></span></Typography>
                                </button>
                            </Grid>
                            <Grid item>
                                <button
                                    className={clsx(statsStyles.customBtn, !viewOffices && statsStyles.active, statsStyles.light)}
                                    onClick={this.handleToggleViews}
                                >
                                    <Typography><span><FormattedMessage id="OFFERS.PHRASE.USERS"/></span></Typography>
                                </button>
                            </Grid>
                          </Grid>
                          </div>
                      }
                    />
                    <PortletBody fit>
                      {data ? <TableCustom tHead={tHead} tBody={this.handleTableBody(dataSource)} stickyHeader maxHeight={570} theadStyleOrigin/> : <LoadingScreen/>}
                    </PortletBody>
                  </Portlet>
                </Grid>
            </Grid>
        );
    }
}
export default class OffersOnlineAnalysisDetails extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isOpen: false
    };
  }
  handleCloseModal = () => {
    this.child.current.handleClose()
  };

  render() {
    const {intl, offerID, data, loading, onFetchData} = this.props;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            color="success"
            label={<FormattedMessage id="OFFERS.PHRASE.DETAILED_ANALYSIS"/>}
            directBtn
          />
        }
        fullWidth
        maxWidth="lg"
        defaultThemeProvider
      >
        <OfferOnlineDetails
            intl={intl}
            offerID={offerID}
            data={data}
            loading={loading}
            onFetchData={onFetchData}
        />
      </ModalCustom>
    );
  }
}