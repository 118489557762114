import {
  fetchMoreShortlistsLandlord,
  fetchShortlistsLandlord,
  fetchShortlistUnitsLandlord,
  fetchShortlistUnitsLandlordReset
} from "app/crud/offers.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ShortlistsLandlordPage from "./ShortlistsLandlordPage";
import {filterReset} from "../../../crud/estates/filters.crud";

const mapStateToProps = (state) => ({
  shortlists: state.shortlists,
  landlordItems: state.shortlists.landlordItems,
  landlordLoading: state.shortlists.landlordLoading,
  landlordItemsCount: state.shortlists.landlordItemsCount,
  landlordItemsPerPage: state.shortlists.landlordItemsPerPage,
  landlordShortlistUnits: state.shortlists.landlordShortlistUnits,
  landlordShortlistUnitsLoading: state.shortlists.landlordShortlistUnitsLoading,
  loadingMore: state.shortlists.loadingMore
});
const mapDispatchToProps = {
  fetchShortlistsLandlord: ({params}) => fetchShortlistsLandlord(params),
  fetchMoreShortlistsLandlord: ({params}) => fetchMoreShortlistsLandlord(params),
  fetchShortlistUnitsLandlord: (params) => fetchShortlistUnitsLandlord(params),
  fetchShortlistUnitsLandlordReset: fetchShortlistUnitsLandlordReset,
  filterReset: () => filterReset()
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShortlistsLandlordPage)
);
