import React from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import loadingGif from "app/media/redd-loader.gif";

class LoadingScreen extends React.Component {
  render() {
    const {narrow, absolute, brand, brandColor = "#4a9dfd", loaderStyle} = this.props;
    return (
      <span className={clsx(styles.loadingContainer, narrow && styles.narrow, absolute && styles.absolutePos)}>
        {brand ?
          <span className={styles.brandLoader} style={{"--color": brandColor}}><span/><span/><span/></span>
        :
        <img src={loadingGif} alt="REDD" style={loaderStyle} />
        }
      </span>
    );
  }
}

export default LoadingScreen;
