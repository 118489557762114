import React from "react";
import {Icon, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {unfollowOffice} from "app/crud/followers.crud";
import HighlightLabel from "app/partials/components/HighlightLabel";
import clsx from "clsx";
import styles from "app/partials/components/PartialComponents.module.scss";
import TooltipCustom from "../TooltipCustom";

class OfficeRemoveFromWatchlist extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {officeId, removeFromWatchlist, instant} = this.props;
    removeFromWatchlist(officeId);
    if(!instant) {
      this.handleCloseModal();
    }
  };

  render() {
    const {btnClassic, btnText, btnIcon, instant} = this.props;

    if(instant) {
      if(btnClassic) {
        return(
          <ModalTrigger
            icon="favorite"
            label={<FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/>}
            color="danger"
            directIcon
            onClick={() => this.handleSave()}
          />
        );
      }
      else if(btnText) {
        return(
          <span
            className={clsx("kt-nav__link", styles.actionButton)}
            onClick={() => this.handleSave()}
          >
            <span className={styles.actionIcon}>
              <Icon>
                favorite
              </Icon>
            </span>
            <span className="kt-nav__link-text"><FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/></span>
          </span>
        );
      }
      else if(btnIcon) {
        return(
          <TooltipCustom
            title={<FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/>}
            placement="left"
          >
            <span
              style={{display: "block"}}
              onClick={() => this.handleSave()}
            >{btnIcon}</span>
          </TooltipCustom>
        );
      }
      return(
        <HighlightLabel
          color="danger"
          hover
          style={{marginLeft: 15, textDecoration: "line-through"}}
          onClick={() => this.handleSave()}
        ><FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/></HighlightLabel>
      );
    }
    return(
      <ModalCustom
        ref={this.child}
        btn=
        {
          btnClassic ?
            <ModalTrigger
              icon="favorite"
              label={<FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/>}
              color="danger"
              directIcon
            /> : btnText ?
            <span className={clsx("kt-nav__link", styles.actionButton)}>
              <span className={styles.actionIcon}>
                <Icon>
                  favorite
                </Icon>
              </span>
              <span className="kt-nav__link-text"><FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/></span>
            </span>
              : btnIcon ?
                <TooltipCustom
                  title={<FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/>}
                  placement="left"
                >
                  <span style={{display: "block"}}>{btnIcon}</span>
                </TooltipCustom>
            : <HighlightLabel color="danger" hover style={{marginLeft: 15, textDecoration: "line-through"}}><FormattedMessage id="GENERAL.PHRASE.UNFOLLOW"/></HighlightLabel>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.REMOVE_FROM_OBSERVED"/>}
        handleSave={this.handleSave}
      >
        <Typography><FormattedMessage id="BUILDING.PHRASE.REMOVE_FROM_OBSERVED_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  userEmail: state.user.data.email
});
const mapDispatchToProps = {
  removeFromWatchlist: (officeId) => unfollowOffice(officeId)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeRemoveFromWatchlist)
);