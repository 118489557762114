import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";

export default function PropertySubscription({subscription}) {
  if(subscription) {
    const subName = subscription && subscription.charAt(0) + subscription.slice(1).toLowerCase();

    return(
      <span className={cn(
        styles.propertySubscriptionWrapper,
        styles[`sub_${subscription}`]
      )}>{subName}</span>
    );
  }
  return null;
}