import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import commonStyles from "app/partials/components/PartialComponents.module.scss";
import {Checkbox, FormControl, FormControlLabel, Icon, MenuItem, Select} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputIcon from "app/partials/components/InputIcon";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {ReactComponent as IconAdd} from "app/media/icons/add-offer.svg";
import {callReddClickEvent} from "app/crud/user.crud";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {
  addParkToExistingShortlist,
  addParkToNewShortlistIndustrial,
  fetchOffersIndustrialAll
} from "../../../crud/estates/industrial/offers-industrial.crud";
import IsPro from "../../../utils/IsPro";
import PaywallModal from "../../../partials/components/PayWall/components/PaywallModal";

class ParkAddToOffer extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      shortlistName: "",
      selected: "empty",
      tab: 0,
      forTesting: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.offerUpdated &&
      this.props.offerUpdated &&
      this.props.onComplete
    ) {
      this.props.onComplete([]);
    }
  }

  // Handlers
  handleFetchOffers = () => {
    const {fetchOffersIndustrialAll, callReddClickEvent, parkId} = this.props;
    fetchOffersIndustrialAll && fetchOffersIndustrialAll();

    if(parkId && callReddClickEvent) {
      callReddClickEvent(
        "industrial-park-page-add-to-offer-click",
        "park",
        null,
        parkId
      );
    }
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleSelect = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value,
    });
  };
  handleChangeTab = newTab => {
    this.setState({
      tab: newTab
    }, () => {
      if(this.state.tab === 1) {
        this.handleFetchOffers();
      }
    });
  }
  handleSave = () => {
    const { parkId, addParkToNewShortlistIndustrial, addParkToExistingShortlist } = this.props;
    const { shortlistName, selected, tab, forTesting } = this.state;
    const parks = this.props.parks || [parkId];

    // Add to New Offer
    if(tab === 0) {
      if (shortlistName !== "") {
        addParkToNewShortlistIndustrial(shortlistName, parks, forTesting);
        this.handleCloseModal();
      }
    }
    // Add to Existing Offer
    else if(tab === 1) {
      if (selected !== "" && selected !== "empty") {
        addParkToExistingShortlist(selected, parks);
        this.handleCloseModal();
      }
    }
  };

  render() {
    const {
      inactive,
      loadingOffersAll,
      offersAll,
      parkId,
      parks,
      rowData,
      btnTrigger,
      user
    } = this.props;
    const { shortlistName, tab, open, selected, forTesting } = this.state;
    const isLoggedInAs = user?.loggedInAs;
    const withoutAvailableArea = parks && parks
      .map(
        (id) =>
          rowData.find(
            (el) =>
              el.id === id 
          )
      ).filter(item => 
        item !== undefined && 
        !item.totalAreaOfficeAvailable &&
        !item.totalAreaWarehouseAvailable)
    
    return (
      <IsPro
        paywall
        paywallComponent={
        <PaywallModal
          triggerBtn={
            btnTrigger ? btnTrigger :
            <ModalTrigger
              color="brandEmpty"
              label={<FormattedMessage id="VOCABULARY.Add to offer" />}
              iconPrefix={<IconAdd/>}
              directBtn
              style={{fontSize: "1.4rem"}}
            />
          }
          triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.Add to offer"})}
        />
        }
      >
        <ModalCustom
          ref={this.child}
          btn={
          btnTrigger ? btnTrigger :
          <ModalTrigger
            color="brandEmpty"
            label={<FormattedMessage id="VOCABULARY.Add to offer" />}
            iconPrefix={<IconAdd/>}
            directBtn
            style={{fontSize: "1.4rem"}}
          />
          }
          btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
          title={<FormattedMessage id="VOCABULARY.Add to offer" />}
          handleSave={this.handleSave}
          inactive={inactive}
          onOpen={() => this.handleFetchOffers()}
        >
          <div className={commonStyles.tabsWrapper}>
            <button
              className={clsx(
                commonStyles.btnTab,
                tab === 0 ? commonStyles.active : undefined
              )}
              onClick={() => this.handleChangeTab(0)}
            >
              <FormattedMessage id="VOCABULARY.Add new offer"/>
            </button>
            <button
              className={clsx(
                commonStyles.btnTab,
                tab === 1 ? commonStyles.active : undefined
              )}
              onClick={() => this.handleChangeTab(1)}
            >
              <FormattedMessage id="VOCABULARY.Add to existing offer"/>
            </button>
          </div>
          <div className={commonStyles.tabsContentWrapper}>
            {tab === 0 &&
              <div>
                <InputIcon
                  icon={<Icon>work_outline</Icon>}
                  id="new_shortlist_name"
                  label={<FormattedMessage id="SHORTLIST.PHRASE.NEW_SHORTLIST_NAME" />}
                  name="new_shortlist_name"
                  value={shortlistName}
                  width="100%"
                  onChange={this.handleChange}
                />
                {isLoggedInAs &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forTesting}
                      id="forTesting"
                      value="forTesting"
                      color="primary"
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={<FormattedMessage id="SHORTLIST.PHRASE.FOR_TESTING"/>}
                  style={{marginTop: "0.5rem"}}
                />
                }
              </div>
            }
            {tab === 1 && (
              loadingOffersAll ? (
                <LoadingScreen />
              ) : (
                <FormControl style={{width: "100%"}}>
                  <Select
                    open={open}
                    onClose={this.handleToggleSelect}
                    onOpen={this.handleToggleSelect}
                    value={selected}
                    defaultValue="empty"
                    onChange={this.handleSelect}
                    inputProps={{
                      name: "existing_shortlist",
                      id: "existing_shortlist_select",
                    }}
                  >
                    <MenuItem value="empty">
                      <em>
                        <FormattedMessage id="SHORTLIST.PHRASE.SELECT_SHORTLIST" />
                      </em>
                    </MenuItem>
                    {offersAll &&
                      offersAll?.map((offer, index) => {
                        const parks = this.props.parks || parkId;
                        const disableOffer = parks && Array.isArray(parks) ? parks?.filter(park => offer?.parks?.includes(park)).length > 0 : offer?.parks && offer?.parks?.includes(parkId);

                        return (
                          <MenuItem
                            key={index}
                            value={offer?.id}
                            disabled={disableOffer}
                          >
                            {offer?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )
            )}
          </div>
          {withoutAvailableArea?.length ?
          <div className={styles.warningWrapper}>
              <span className={styles.warningLabel}><FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_AREA_WARNING" /></span>
              <div className={styles.warningValuesWrapper}>{
                  withoutAvailableArea.map((item, index) => {
                      return <span key={index} className={styles.warningValue}>{`${index+1}. ${item?.name}`}</span>
                  })
              }</div>
          </div> : null
          }
        </ModalCustom>
      </IsPro>

    );
  }
}

const mapStateToProps = (state) => ({
  offerUpdated: state.offersIndustrial.offerUpdated,
  shortlists: state.offersIndustrial.items,
  offersAll: state.offersIndustrial.offersAll,
  loadingOffersAll: state.offersIndustrial.loadingOffersAll,
  user: state.user.data
});
const mapDispatchToProps = {
  fetchOffersIndustrialAll: () => fetchOffersIndustrialAll(),
  addParkToNewShortlistIndustrial: (name, parks, forTesting) => addParkToNewShortlistIndustrial(name, parks, forTesting),
  addParkToExistingShortlist: (id, parks) => addParkToExistingShortlist(id, parks),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ParkAddToOffer)
);
