import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {fetchShortlists, fetchShortlistUnits} from "app/crud/offers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";

class ShortlistRemoveUnits extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    open: false,
    selected: ""
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    this.setState({
      selected: event.target.value
    })
  };
  removeUnitsFromShortlist = (units, shortlistId) => {
    if (!shortlistId) return;

    axios
      .post(`/offers/${shortlistId}/remove_units/`, {
        units,
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.props.fetchShortlistUnits(this.props.shortlistId);
          this.props.fetchShortlists && this.props.fetchShortlists({page: 1});
        }
      });
  };
  handleSave = () => {
    const {units, shortlistId} = this.props;
    this.removeUnitsFromShortlist(units, shortlistId);
    this.handleCloseModal();
  };

  render() {
    const {directBtn, directIcon} = this.props;
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="delete"
            label={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_UNIT"/>}
            directBtn={directBtn}
            directIcon={directIcon}
            color="danger"
          />
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_UNIT"/>}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
      >
        <Typography><FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_UNIT_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  fetchShortlists: fetchShortlists,
  fetchShortlistUnits: (shortlistID) => fetchShortlistUnits(shortlistID),
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(ShortlistRemoveUnits)
);