import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {handleGoogleAnalyticsTracking} from "../../../utils/helpers";
import ButtonTooltip from "../ButtonTooltip";
import LoadingScreen from "../LoadingScreen";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

class PropertyParticularsAnalysisCTAShareUpdated extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  handleProceed = () => {
    const { onSendPP, teamID } = this.props;
    if (teamID && onSendPP) {
      handleGoogleAnalyticsTracking(
        "Button",
        "Click",
        "Send Updated Property Particulars - PP Page"
      );
      onSendPP(teamID);
      this.child.current.handleClose();
    }
  };

  render() {
    const { ppUpdate, sharingUpdated, currentApplicationView } = this.props;
    const lastUpdate = ppUpdate?.lastUpdate;
    const nextUse = ppUpdate?.nextUse;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          (lastUpdate && nextUse) || sharingUpdated ? (
            <ButtonTooltip
              tPlacement="bottom"
              tOverlay={
                sharingUpdated ? (
                  <LoadingScreen />
                ) : (
                  <FormattedMessage
                    id="PROPERTY_PARTICULARS.PHRASE.SEND_UPDATED_PP_DISABLED"
                    values={{
                      lastUpdate: <strong>{lastUpdate}</strong>,
                      nextUse: <strong>{nextUse}</strong>,
                      br: <br />,
                    }}
                  />
                )
              }
            >
              <span>
                <ButtonCustom
                  label={
                    <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SEND_PP" />
                  }
                  color="danger"
                  inactive
                />
              </span>
            </ButtonTooltip>
          ) : (
            <ButtonCustom
              label={
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SEND_PP" />
              }
              color="danger"
            />
          )
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.YES_PROCEED" />}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.NO_THANK_YOU" />}
        title={
          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SEND_UPDATED_PP" />
        }
        inactive={(lastUpdate && nextUse) || sharingUpdated}
        handleSave={this.handleProceed}
        onOpen={() => {
          isOfficeView && this.props.dispatch(callReddClickEvent(
            "property-particulars-send-pp-button",
            "team"
          ));
          isIndustrialView && this.props.dispatch(callReddClickEvent(
            "property-particulars-industrial-send-pp-button",
            "team"
          ));
        }}
      >
        <Typography>
          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SEND_UPDATED_PP_MESSAGE" />
          <br />
          <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE" />
        </Typography>
      </ModalCustom>
    );
  }
}

const mapStateToProps = state => ({
    currentApplicationView: state?.user?.data?.currentApplicationView
});
export default injectIntl(connect(mapStateToProps)(PropertyParticularsAnalysisCTAShareUpdated));