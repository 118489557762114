import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import {ROUTES} from "../../../../constants";
import {withRouter} from "react-router-dom";

function PropertyHeaderNav({activeItem, onScrollTo, propertyID, currentApplicationView, callReddClickEvent, match, view}) {
  const path = match?.path;
  const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
  const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
  const isIndustrialParkView = path.includes(ROUTES.INDUSTRIAL_PARK);
  const isIndustrialWarehouseView = path.includes(ROUTES.INDUSTRIAL_BUILDING);
  const navItemsOffices = [
      {
        label: <FormattedMessage id="VOCABULARY.Dynamic Analytics"/>,
        ref: "refAnalytics"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Available units"/>,
        ref: "refUnits"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
        ref: "refLeaseTerms"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Amenities"/>,
        ref: "refAmenities"
      },
      {
        label: <FormattedMessage id="VOCABULARY.About building"/>,
        ref: "refMetrics"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Comparable properties"/>,
        ref: "refSimilar"
      }
    ];
  const navItemsIndustrialPark = [
      {
        label: <FormattedMessage id="VOCABULARY.Buildings"/>,
        ref: "refBuildings"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
        ref: "refLeaseTerms"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Park changes"/>,
        ref: "refChanges"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Files"/>,
        ref: "refFiles"
      }
    ];
  const navItemsIndustrialWarehouse = [
      {
        label: <FormattedMessage id="VOCABULARY.Available units"/>,
        ref: "refUnits"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
        ref: "refLeaseTerms"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Technical spec"/>,
        ref: "refTechnicalDetails"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Amenities"/>,
        ref: "refAmenities"
      },
      {
        label: <FormattedMessage id="VOCABULARY.About building"/>,
        ref: "refMetrics"
      },
      {
        label: <FormattedMessage id="VOCABULARY.Files"/>,
        ref: "refFiles"
      }
    ];
  const navItems = isOfficeView ? navItemsOffices :
    isIndustrialView ? (
      isIndustrialParkView ? navItemsIndustrialPark :
        isIndustrialWarehouseView ? navItemsIndustrialWarehouse : []
    ) : [];
  // Handlers
  const handleNavItemClick = (item) => {
    if(propertyID && callReddClickEvent) {
      const params = {
        tab: item?.ref || ""
      };

      if(isOfficeView) {
        callReddClickEvent(
          "office-building-page-tab-nav-click",
          "office",
          null,
          propertyID,
          params
        );
      }
      else if(isIndustrialView) {
        if(view === "industrialWarehouse") {
          callReddClickEvent(
            "industrial-warehouse-page-tab-nav-click",
            "warehouse",
            null,
            propertyID,
            params
          );
        }
        else {
          callReddClickEvent(
            "industrial-park-page-tab-nav-click",
            "park",
            null,
            propertyID,
            params
          );
        }
      }
    }
    onScrollTo(item?.ref);
  }

  return(
    <div className={styles.propertyNavigationWrapper}>
      {navItems?.map((item, index) => {
        return(
          <span
            key={index}
            className={cn(
              styles.navItem,
              activeItem === index ? styles.active : undefined
            )}
            onClick={() => handleNavItemClick(item)}
          >
            {item?.label}
          </span>
        );
      })}
    </div>
  );
}

const mapStateToProps = store => ({
  currentApplicationView: store.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PropertyHeaderNav))
);