import React from "react";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import ButtonCustom from "../../partials/components/ButtonCustom";

export function ForceLogoutPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
          style={{
            backgroundColor: "#2d2d2d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{textAlign: "center"}}>
            <img src={toAbsoluteUrl("media/branding/redd-logo-neon.png")} style={{height: 60, marginBottom: 100}} alt="REDD Logo" />
            <h1 style={{fontSize: 30, color: "#ffffff"}}><FormattedMessage id="The application has been updated"/></h1>
            <p style={{fontSize: 20, color: "#ffffff", marginTop: 25, marginBottom: 25}}><FormattedMessage id="You have been logged out to make sure you are running the latest version of the application." /></p>
            <ButtonCustom label={<FormattedMessage id="FORM.BUTTON.SIGN_IN" />} color="danger" solid redirect="/"/>
          </div>
        </div>
      </div>
    </>
  );
}
