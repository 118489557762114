import {Grid} from "@material-ui/core";
import React from "react";
import {toNumberFormat} from "app/utils/helpers";
import {FormattedMessage} from "react-intl";
import IconLabel from "app/partials/components/IconLabel";
import Typography from "@material-ui/core/Typography/Typography";

export default class ShortlistExpandedCardPreview extends React.Component {
  render() {
    const {name, units, buildings, classes, years} = this.props;
    return (
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <Typography variant="h3" id="tableTitle">
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <IconLabel
            icon="apps"
            size={32}
            label={<FormattedMessage id="SHORTLIST.PHRASE.UNITS"/>}
            value={toNumberFormat(units)}
          />
        </Grid>
        <Grid item>
          <IconLabel
            icon="flip_to_back"
            label={<FormattedMessage id="SHORTLIST.PHRASE.BUILDINGS"/>}
            value={toNumberFormat(buildings)}
          />
        </Grid>
        <Grid item>
          <IconLabel
            icon="apartment"
            label={<FormattedMessage id="SHORTLIST.PHRASE.BUILDING_CLASSES"/>}
            value={classes}
          />
        </Grid>
        <Grid item>
          <IconLabel
            icon="local_convenience_store"
            label={<FormattedMessage id="SHORTLIST.PHRASE.COMPLETION_YEARS"/>}
            value={years}
          />
        </Grid>
      </Grid>
    );
  }
}