import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import {FormattedMessage, injectIntl} from "react-intl";
import statsStyles from "../../../pages/offices/BuildingsPage/components/index.module.scss";
import clsx from "clsx";
import TableCustom from "../TableCustom";
import {getBaseUrl, toNumberFormat} from "../../../utils/helpers";
import LoadingScreen from "app/partials/components/LoadingScreen";
import styles from "./index.module.scss";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import ROUTES from "app/constants/ROUTES";
import PropertyParticularsAnalysisDetails from "./PropertyParticularsAnalysisDetails";
import connect from "react-redux/es/connect/connect";
import {fetchPropertyParticularsAnalyticsDetails} from "../../../crud/propertyParticulars/offices.crud";
import {fetchIndustrialPropertyParticularsAnalyticsDetails} from "app/crud/propertyParticulars/industrial.crud";
import PropertyCardPreview from "../_DataParts/PropertyCardPreview";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import ReactVisibilitySensor from "react-visibility-sensor";
import NoData from "../NoData/NoData";

class PPLinkCopyToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  handleCopyPPLink = () => {
    const {teamID, officeID, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const baseUrl = getBaseUrl(window.location);
    const parentUrl = isOfficeView ? ROUTES.PROPERTY_PARTICULARS_ONLINE : ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ONLINE;
    const ppLink = `${baseUrl}${parentUrl}/${teamID}/${officeID}/`;

    this.setState({
      copied: true
    }, () => {
      setTimeout(() => {
        this.setState({
          copied: false
        });
      }, 5000);
      return navigator.clipboard.writeText(ppLink);
    });
  };

  render() {
    const {copied} = this.state;
    return (
      <ModalTrigger
        icon="content_copy"
        label={<FormattedMessage
          id={copied ? "PROPERTY_PARTICULARS.PHRASE.COPIED_TO_CLIPBOARD" : "PROPERTY_PARTICULARS.PHRASE.COPY_PP_OFFICE_LINK"}/>}
        directIcon
        color={copied ? "success" : "neutral"}
        onClick={() => this.handleCopyPPLink()}
      />
    );
  }
}

class PropertyParticularsAnalysisPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      views: true,
      initLoaded: false
    };
  }

  handleToggleViews = () => {
    this.setState({
      views: !this.state.views
    })
  };
  handleRenderValueDiff = (value) => {
    if (value) {
      const valueNumber = Number(value);
      const isRise = valueNumber > 0;
      const icon = isRise ? <Icon fontSize="inherit">trending_up</Icon> :
        <Icon fontSize="inherit">trending_down</Icon>;
      return <span className={isRise ? styles.success : styles.danger}>{icon} {toNumberFormat(value, '%')}</span>;
    }
    return "-";
  };
  handleTableBody = view => {
    const {
      target,
      intl,
      data,
      teamID,
      ppAnalyticsDetails,
      ppAnalyticsDetailsLoading,
      ppIndustrialAnalyticsDetails,
      ppIndustrialAnalyticsDetailsLoading,
      fetchPropertyParticularsAnalyticsDetails,
      fetchIndustrialPropertyParticularsAnalyticsDetails,
      currentApplicationView
    } = this.props;
    const isTargetIndustrial = target === "industrial";
    const onFetchAnalyticsDetails = isTargetIndustrial ? fetchIndustrialPropertyParticularsAnalyticsDetails : fetchPropertyParticularsAnalyticsDetails;
    const ppAnalyticsDetailsData = isTargetIndustrial ? ppIndustrialAnalyticsDetails : ppAnalyticsDetails;
    const ppAnalyticsDetailsDataLoading = isTargetIndustrial ? ppIndustrialAnalyticsDetailsLoading : ppAnalyticsDetailsLoading;
    const sourceData = data && (data[view].length || Object.keys(data[view]).length) && data[view];
    return sourceData ? sourceData.map(row => {
      return {
        "data": [
          <PropertyCardPreview
            title={row.name}
            semiTitle={row.addressCity}
            semiTitle2={row.addressStreet}
            thumb={row.mainPhotoThumbnail}
            propertyID={row.id}
            marketID={isTargetIndustrial ? null : row.marketId}
            route={isTargetIndustrial ? ROUTES.INDUSTRIAL_PARK : ROUTES.BUILDING_PAGE}
          />,
          <PPLinkCopyToClipboard
            officeID={row.id}
            teamID={teamID}
            currentApplicationView={currentApplicationView}
          />,
          toNumberFormat(row.all),
          toNumberFormat(row.prev),
          toNumberFormat(row.val),
          this.handleRenderValueDiff(row.valPrevPerc),
          <PropertyParticularsAnalysisDetails
            intl={intl}
            teamID={teamID}
            propertyID={row.id}
            data={ppAnalyticsDetailsData}
            dataView={view === "contacts" ? "clicks" : view}
            loading={ppAnalyticsDetailsDataLoading}
            target={target}
            onFetchData={onFetchAnalyticsDetails}
          />
        ]
      }
    }) : [];
  };
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {onFetchData, loading, teamID} = this.props;
    const {initLoaded} = this.state;

    if (isVisible && !loading && !initLoaded && teamID) {
      this.setState({
        initLoaded: true
      }, () => onFetchData(teamID));
    }
  };

  render() {
    const {data, loading, target} = this.props;
    const {views} = this.state;
    const dataSource = views ? "views" : "contacts";
    const tHead = [
      {
        "label": <FormattedMessage id="PROPERTY_PARTICULARS.TABLE.BUILDING"/>,
        "number": false
      },
      {
        "label": "",
        "number": true
      },
      {
        "label": <FormattedMessage
          id={dataSource === "views" ? "PROPERTY_PARTICULARS.TABLE.TOTAL_VIEWS" : "PROPERTY_PARTICULARS.TABLE.TOTAL_INTERACTIONS"}/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="PROPERTY_PARTICULARS.TABLE.LAST_MONTH"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="PROPERTY_PARTICULARS.TABLE.CURRENT_MONTH"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="PROPERTY_PARTICULARS.TABLE.DIFFERENCE"/>,
        "number": true
      },
      {
        "label": "",
        "number": true
      }
    ];
    const isTargetIndustrial = target === "industrial";

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={
                <ReactVisibilitySensor
                  onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                  scrollCheck
                  partialVisibility
                >
                  <Typography variant="h3">
                    {isTargetIndustrial ?
                      <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.WAREHOUSES_PORTFOLIO_TITLE"/> :
                      <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BUILDINGS_PORTFOLIO_TITLE"/>}
                  </Typography>
                </ReactVisibilitySensor>
              }
              toolbar={
                <div style={{display: "flex"}}>
                  <Grid container spacing={1} alignItems="center" style={{flexWrap: "nowrap"}}>
                    <Grid item>
                      <button
                        className={clsx(statsStyles.customBtn, views && statsStyles.active, statsStyles.light)}
                        onClick={this.handleToggleViews}
                      >
                        <Typography><span><FormattedMessage
                          id="PROPERTY_PARTICULARS.PHRASE.VIEWS"/></span></Typography>
                      </button>
                    </Grid>
                    <Grid item>
                      <button
                        className={clsx(statsStyles.customBtn, !views && statsStyles.active, statsStyles.light)}
                        onClick={this.handleToggleViews}
                      >
                        <Typography><span><FormattedMessage
                          id="PROPERTY_PARTICULARS.PHRASE.CLICKS"/></span></Typography>
                      </button>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <PortletBody fit>
              {loading ? <LoadingScreen/> :
                data ?
                  <TableCustom
                    tHead={tHead}
                    tBody={this.handleTableBody(dataSource)}
                    stickyHeader
                    maxHeight={570}
                    theadStyleOrigin
                  /> : <NoData/>
              }
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  ppAnalyticsDetails: store.ppOnlineOffices.ppAnalyticsDetails,
  ppAnalyticsDetailsLoading: store.ppOnlineOffices.ppAnalyticsDetailsLoading,
  ppIndustrialAnalyticsDetails: store.ppOnlineIndustrial.ppAnalyticsDetails,
  ppIndustrialAnalyticsDetailsLoading: store.ppOnlineIndustrial.ppAnalyticsDetailsLoading,
  currentApplicationView: store?.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchPropertyParticularsAnalyticsDetails: (teamID, officeID) => fetchPropertyParticularsAnalyticsDetails(teamID, officeID),
  fetchIndustrialPropertyParticularsAnalyticsDetails: (teamID, officeID) => fetchIndustrialPropertyParticularsAnalyticsDetails(teamID, officeID)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticularsAnalysisPortfolio)
)