// FETCH ACTIONS
export const fetchOfficeList = (params) => ({
  type: 'FETCH_OFFICE_LIST_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficeListReset = (initOrderBy, isEditable) => ({
  type: 'FETCH_OFFICE_RESET_REQUEST',
  payload: {
    initOrderBy,
    isEditable
  }
});
export const fetchOffice = (officeID) => ({
  type: 'FETCH_OFFICE_REQUEST',
  payload: officeID,
});
export const fetchOfficeFinances = (officeID) => ({
  type: 'FETCH_OFFICE_FINANCES_REQUEST',
  payload: officeID,
});
export const fetchOfficePhotos = (officeID) => ({
  type: 'FETCH_OFFICE_PHOTOS_REQUEST',
  payload: officeID,
});
export const fetchOfficeLeasingTeams = (officeID) => ({
  type: 'FETCH_OFFICE_LEASING_TEAMS_REQUEST',
  payload: officeID,
});
export const fetchOfficeOwners = (officeID) => ({
  type: 'FETCH_OFFICE_OWNERS_REQUEST',
  payload: officeID,
});
export const fetchOfficeAmenities = (officeID) => ({
  type: 'FETCH_OFFICE_AMENITIES_REQUEST',
  payload: officeID,
});
export const fetchOfficesLatestLeads = (params) => ({
  type: 'FETCH_OFFICES_LATEST_LEADS_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficesVisitingCompanies = (params) => ({
  type: 'FETCH_OFFICES_VISITING_COMPANIES_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficesMinified = (params) => ({
  type: 'FETCH_OFFICES_MINIFIED_REQUEST',
  payload: {
    params
  },
});

export const fetchOfficeRCBuilding = (params) => ({
  type: 'FETCH_OFFICE_RC_BUILDING_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficeRCUnits = (params) => ({
  type: 'FETCH_OFFICE_RC_UNITS_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficeRCContact = (params) => ({
  type: 'FETCH_OFFICE_RC_CONTACT_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficeRCAll = (params) => ({
  type: 'FETCH_OFFICE_RC_ALL_REQUEST',
  payload: {
    params
  },
});

export const getOfficesViewType = () => ({
  type: 'GET_VIEW_TYPE_REQUEST',
});
export const fetchOfficeClusters = (params) => ({
  type: 'FETCH_OFFICE_CLUSTERS_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficeClustersReset = () => ({
  type: 'FETCH_OFFICE_CLUSTERS_RESET_REQUEST',
});

export const fetchOfficesDashboardTransactions = () => ({
  type: 'FETCH_OFFICES_DASHBOARD_TRANSACTIONS_REQUEST',
});
export const fetchOfficesDashboardSupplyBoost = () => ({
  type: 'FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_REQUEST',
});
export const fetchOfficesTransactions = (params) => ({
  type: 'FETCH_OFFICES_TRANSACTIONS_REQUEST',
  payload: {
    params
  }
});
export const fetchOfficesSupplyBoost = (params) => ({
  type: 'FETCH_OFFICES_SUPPLY_BOOST_REQUEST',
  payload: {
    params
  }
});

// FETCH OFFICES - COMPETITORS ANALYSIS
export const fetchOfficesSimpleList = (params, owned) => ({
  type: 'FETCH_OFFICES_SIMPLE_LIST_REQUEST',
  payload: {
    params,
    owned
  },
});

// USER ACTIONS
export const addOffice = (office) => ({
  type: 'ADD_OFFICE_REQUEST',
  payload: office,
});
export const refreshSelectedOffice = (officeID) => ({
  type: 'REFRESH_SELECTED_OFFICE_REQUEST',
  payload: officeID,
});
export const deleteOffice = (officeID) => ({
  type: 'DELETE_OFFICE_REQUEST',
  payload: officeID,
});
export const updateOffice = (officeID, data, backgroundRefresh) => ({
  type: 'UPDATE_OFFICE_REQUEST',
  payload: {officeID, data, backgroundRefresh},
});
export const addOfficePhoto = (officeID, file) => ({
  type: 'ADD_OFFICE_PHOTO_REQUEST',
  payload: {officeID, file},
});
export const deleteOfficePhoto = (photoId) => ({
  type: 'DELETE_OFFICE_PHOTO_REQUEST',
  payload: photoId,
});
export const sendOfficeFinanceXLS = (officeID, pk) => ({
  type: 'SEND_OFFICE_FINANCE_XLS_REQUEST',
  payload: {officeID, pk},
});
export const updateOfficeLeasingTeam = (officeID, data) => ({
  type: 'UPDATE_OFFICE_LEASING_TEAM_REQUEST',
  payload: {officeID, data},
});
export const addOfficeOwners = (officeID, data) => ({
  type: 'ADD_OFFICE_OWNERS_REQUEST',
  payload: {officeID, data},
});
export const deleteOfficeOwners = (officeID, data) => ({
  type: 'DELETE_OFFICE_OWNERS_REQUEST',
  payload: {officeID, data},
});
export const updateOfficeAmenities = (officeID, data, backgroundRefresh) => ({
  type: 'UPDATE_OFFICE_AMENITIES_REQUEST',
  payload: {officeID, data, backgroundRefresh},
});
export const compareSelectedOffices = (data, inBackground) => ({
  type: 'COMPARE_SELECTED_OFFICES_REQUEST',
  payload: {data, inBackground},
});
export const compareSelectedOfficesReset = () => ({
  type: 'COMPARE_SELECTED_OFFICES_RESET_REQUEST'
});

// RESET LOADING STATES
export const resetOfficeLoadingStates = () => ({
  type: 'RESET_OFFICE_LOADING_STATES_REQUEST'
});