import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyBuildings from "./components/content/PropertyBuildings";
import PropertyLeaseTerms from "./components/content/PropertyLeaseTerms";
import PropertyChangesTracker from "./components/content/PropertyChangesTracker";
import PropertyFeedback from "../../common/components/PropertyPage/PropertyFeedback";
import PropertySimilarProperties from "../../common/components/PropertyPage/PropertySimilarProperties";
import PropertyHeader from "./components/content/PropertyHeader";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import FinneLeadsPreviewBar from "../../../partials/components/FinneLeadsPreviewBar";
import IndustrialFiles from "../components/IndustrialFiles/IndustrialFiles";

export default class PropertyParkPage extends React.Component {
  constructor(props) {
    super(props);
    this.refBuildings = React.createRef();
    this.refLeaseTerms = React.createRef();
    this.refChanges = React.createRef();
    this.refFiles = React.createRef();

    this.props.resetIndustrialParkState && this.props.resetIndustrialParkState();
  }

  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
  }
  // Handlers
  handleScrollToSection = (ref) => {
    const overflowParent = document.getElementById("root");
    const targetPosition = this[ref]?.current?.getBoundingClientRect().top;
    const offsetPosition = targetPosition + window.scrollY - 100;

    // Handle Scroll
    overflowParent.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet>
            <PortletBody>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <PropertyHeader
                    view="industrialPark"
                    onScrollToSection={this.handleScrollToSection}
                    {...this.props}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PropertyBuildings
                    parentRef={this.refBuildings}
                    {...this.props}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%"
                        }}
                      >
                        <PropertyLeaseTerms parentRef={this.refLeaseTerms} {...this.props}/>
                        <PropertyFeedback {...this.props}/>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <PropertyChangesTracker parentRef={this.refChanges} {...this.props}/>
                    </Grid>
                  </Grid>
                </Grid>
                {this.props.basic?.isEditable &&
                <Grid item xs={12}>
                  <FinneLeadsPreviewBar {...this.props}/>
                </Grid>
                }
                <Grid item xs={12}>
                  <IndustrialFiles
                    parentRef={this.refFiles}
                    view="industrialPark"
                    {...this.props}
                  />
                </Grid>
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <Grid item xs={12}>
          <Portlet>
            <PortletBody>
                <PropertySimilarProperties {...this.props}/>
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}