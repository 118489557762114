import React from 'react';
import {TextField, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import InputSwitcher from "../InputSwitcher";

class OfficeCommercializationSuspended extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      commercializationSuspended: props.commercializationSuspended,
      commercializationSuspendedDate: props.commercializationSuspendedDate
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      commercializationSuspended: nextProps.commercializationSuspended,
      commercializationSuspendedDate: nextProps.commercializationSuspendedDate
    });
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSave = () => {
    const {updateOffice, officeId} = this.props;
    const {commercializationSuspendedDate, commercializationSuspended} = this.state;
    this.setState({
      commercializationSuspended: !commercializationSuspended
    }, () => updateOffice(officeId, {commercializationSuspendedDate: commercializationSuspendedDate || null, commercializationSuspended: this.state.commercializationSuspended}));
    if(!this.state.commercializationSuspended) {
      this.handleCloseModal();
    }
  };

  render() {
    const {directBtn, directIcon, tooltipPosition, modeSwitcher} = this.props;
    const {commercializationSuspendedDate, commercializationSuspended} = this.state;
    if(modeSwitcher) {
      if(!commercializationSuspended) {
        return(
          <ModalCustom
            ref={this.child}
            btn={
              <InputSwitcher
                checked={commercializationSuspended}
                value={commercializationSuspended}
                label={<FormattedMessage id="BUILDING.PHRASE.SUSPEND_COMMERCIALIZATION"/>}
              />
            }
            btnConfirm={<FormattedMessage id="GENERAL.PHRASE.CONFIRM"/>}
            title={<FormattedMessage id="BUILDING.PHRASE.SUSPEND_COMMERCIALIZATION"/>}
            handleSave={this.handleSave}
          >
            <Typography><FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_MESSAGE"/></Typography>
            <TextField
              onChange={this.handleChange}
              value={commercializationSuspendedDate}
              id="commercializationSuspendedDate"
              label={<FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_TO"/>}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </ModalCustom>
        );
      }
      return(
        <InputSwitcher
          checked={commercializationSuspended}
          value={commercializationSuspended}
          label={<FormattedMessage id="BUILDING.PHRASE.SUSPEND_COMMERCIALIZATION"/>}
          onChange={this.handleSave}
        />
      );
    }
    else {
      if(!commercializationSuspended) {
        return(
          <ModalCustom
            ref={this.child}
            btn={
              <ModalTrigger
                icon="play_circle_outline"
                label={<FormattedMessage id="BUILDING.PHRASE.SUSPEND_COMMERCIALIZATION"/>}
                directBtn={directBtn}
                directIcon={directIcon}
                tooltipPosition={tooltipPosition}
                color="success"
              />
            }
            btnConfirm={<FormattedMessage id="GENERAL.PHRASE.CONFIRM"/>}
            title={<FormattedMessage id="BUILDING.PHRASE.SUSPEND_COMMERCIALIZATION"/>}
            handleSave={this.handleSave}
          >
            <Typography><FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_MESSAGE"/></Typography>
            <TextField
              onChange={this.handleChange}
              value={commercializationSuspendedDate}
              id="commercializationSuspendedDate"
              label={<FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_TO"/>}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </ModalCustom>
        );
      }
      return (
        <ModalTrigger
          icon="pause_circle_outline"
          label={<FormattedMessage id="BUILDING.PHRASE.RESUME_COMMERCIALIZATION_SUSPENDED_UNTIL" values={{date: commercializationSuspendedDate, br: <br/>}}/>}
          directBtn={directBtn}
          directIcon={directIcon}
          color="danger"
          tooltipPosition={tooltipPosition}
          onClick={this.handleSave}
        />
      );
    }
  }
}

const mapDispatchToProps = {
  updateOffice: (officeId, data) => updateOffice(officeId, data)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(OfficeCommercializationSuspended)
);