import React from "react";
import {Grid, TextField, Typography} from "@material-ui/core";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "../../../ButtonCustom";
import LoadingScreen from "../../../LoadingScreen";

class EditOfficeCommercializationSuspended extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      commercializationSuspended: props?.data?.commercializationSuspended,
      commercializationSuspendedDate: props?.data?.commercializationSuspendedDate
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      commercializationSuspended: nextProps?.data?.commercializationSuspended,
      commercializationSuspendedDate: nextProps?.data?.commercializationSuspendedDate
    });
  }
  // Handlers
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSave = () => {
    const {updateOffice, data} = this.props;
    const {commercializationSuspendedDate, commercializationSuspended} = this.state;
    const officeId = data?.id;

    if(officeId) {
      this.setState({
        commercializationSuspended: !commercializationSuspended
      }, () =>
        updateOffice(
          officeId,
          {
            commercializationSuspendedDate: commercializationSuspendedDate || null,
            commercializationSuspended: this.state.commercializationSuspended
          },
          "basic"
        )
      );
    }
  };

  render() {
    const {officeUpdating} = this.props;
    const {commercializationSuspendedDate, commercializationSuspended} = this.state;

    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography><FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_MESSAGE"/></Typography>
          <TextField
            onChange={this.handleChange}
            value={commercializationSuspendedDate}
            id="commercializationSuspendedDate"
            label={<FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED_TO"/>}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            fullWidth
            disabled={commercializationSuspended}
          />
        </Grid>
        <Grid item>
          {officeUpdating ? <LoadingScreen/> :
          <ButtonCustom
            label={
            commercializationSuspended ?
              <FormattedMessage id="VOCABULARY.Resume commercialization"/> :
              <FormattedMessage id="VOCABULARY.Suspend commercialization"/>
            }
            color="danger"
            solid
            onClick={() => this.handleSave()}
          />
          }
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  officeUpdating: store.offices.officeUpdating
});
const mapDispatchToProps = {
  updateOffice: (officeId, data, backgroundRefresh) => updateOffice(officeId, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeCommercializationSuspended)
);