import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import clsx from "clsx";
import quickActionsStyles from "app/partials/components/PartialComponents.module.scss";
import styles from "./index.module.scss";
import Icon from "@material-ui/core/Icon/Icon";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {
  addParkToExistingShortlist,
  fetchOffersIndustrialAll,
} from "app/crud/estates/industrial/offers-industrial.crud";

class ParkAddToExistingShortlist extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      selected: "",
    };
    props.fetchOffersIndustrialAll();
  }

  handleFetchOffers = () => {
    this.props.fetchOffersIndustrialAll();
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleSave = () => {
    const { parkId, addParkToExistingShortlist } = this.props;
    const { selected } = this.state;
    const parks = this.props.parks || [parkId];
    if (selected !== "") {
      addParkToExistingShortlist(selected, parks);
      this.handleCloseModal();
    }
  };

  render() {
    const {
      offersAll,
      loadingOffersAll,
      parkId,
      btn,
      btnMultiselect,
      solid,
      inactive,
      rowData,
      parks
    } = this.props;
    const { open, selected } = this.state;

    const withoutAvailableArea = parks && parks
      .map(
        (id) =>
          rowData.find(
            (el) =>
              el.id === id 
          )
      ).filter(item => 
        item !== undefined && 
        !item.totalAreaOfficeAvailable &&
        !item.totalAreaWarehouseAvailable)

    return (
      <ModalCustom
        ref={this.child}
        btn={
          btn ? (
            <ModalTrigger
              color={btnMultiselect ? "danger" : "success"}
              label={
                <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_TO_EXISTING_SHORTLIST" />
              }
              directBtn
              solid={solid}
              inactive={inactive}
            />
          ) : (
            <span className={clsx("kt-nav__link", quickActionsStyles.actionButton)}>
              <span className={quickActionsStyles.actionIcon}>
                <Icon>work</Icon>
              </span>
              <span className="kt-nav__link-text">
                <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_TO_EXISTING_SHORTLIST" />
              </span>
            </span>
          )
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
        title={
          <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_PARK_TO_EXISTING_SHORTLIST" />
        }
        handleSave={this.handleSave}
        fetch={this.handleFetchOffers}
        inactive={inactive}
      >
        {loadingOffersAll ? (
          <LoadingScreen />
        ) : (
          <FormControl>
            <InputLabel htmlFor="existing_shortlist_select">
              <FormattedMessage id="SHORTLIST.PHRASE.SELECT_SHORTLIST" />
            </InputLabel>
            <Select
              open={open}
              onClose={this.handleToggleSelect}
              onOpen={this.handleToggleSelect}
              value={selected}
              onChange={this.handleChange}
              inputProps={{
                name: "existing_shortlist",
                id: "existing_shortlist_select",
              }}
            >
              <MenuItem value="">
                <em>
                  <FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_NAME" />
                </em>
              </MenuItem>
              {offersAll &&
                offersAll.map((offer, index) => {
                  const parks = this.props.parks || parkId;
                  const disableOffer = parks && Array.isArray(parks) ? parks?.filter(park => offer?.parks?.includes(park)).length > 0 : offer?.parks && offer?.parks?.includes(parkId);
                  return (
                    <MenuItem
                      key={index}
                      value={offer.id}
                      disabled={disableOffer}
                    >
                      {offer.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        {withoutAvailableArea?.length ?
        <div className={styles.warningWrapper}>
            <span className={styles.warningLabel}><FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_AREA_WARNING" /></span>
            <div className={styles.warningValuesWrapper}>{
                withoutAvailableArea.map((item, index) => {
                    return <span key={index} className={styles.warningValue}>{`${index+1}. ${item?.name}`}</span>
                })
            }</div>
        </div> : null
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  offersAll: state.offersIndustrial.offersAll,
  loadingOffersAll: state.offersIndustrial.loadingOffersAll,
});
const mapDispatchToProps = {
  fetchOffersIndustrialAll: () => fetchOffersIndustrialAll(),
    addParkToExistingShortlist: (id, parks) =>
    addParkToExistingShortlist(id, parks),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ParkAddToExistingShortlist)
);
