import React from "react";
import {Grid} from "@material-ui/core";
import OffersOnlineAnalysisPortfolio
  from "../../common/OffersOnlineAnalysisPage/components/OffersOnlineAnalysisPortfolio";
import OffersOnlineAnalysisCharts from "../../common/OffersOnlineAnalysisPage/components/OffersOnlineAnalysisCharts";
import OffersOnlineAnalysisDetails from "./components/OffersOnlineAnalysisDetails";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {callReddClickEvent} from "app/crud/user.crud";
import ButtonCustom from "../../../partials/components/ButtonCustom";
import {ROUTES} from "../../../constants";
import IconColorMUI from "../../../partials/components/IconColorMUI";
import IsPro from "../../../utils/IsPro";

class OffersOnlineAnalysisPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        }
    }
    componentDidMount() {
        if(!this.state.isReady) {
            this.setState({
                isReady: true
            }, () => {
                this.props.dispatch(callReddClickEvent(
                  "offers-online-analysis-click",
                  "offer"
                ));
            });
        }
    }

    render() {
        const {
            intl,
            offersAnalytics,
            offersCharts,
            offerDetailsAnalytics,
            loadingOffersAnalytics,
            loadingOffersChars,
            loadingOfferDetailsAnalytics,
            fetchOffersAnalytics,
            fetchOffersAnalyticsCharts,
            fetchOffersOfficeAnalytics
        } = this.props;

        return(
          <IsPro paywall>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1} justifyContent="flex-start" alignItems="center" style={{flexWrap: "nowrap"}}>
                    <Grid item>
                      <ButtonCustom
                        label={
                          <>
                            <IconColorMUI icon="arrow_back_ios" style={{paddingRight: "0.5rem"}}/>
                            <FormattedMessage id="OFFERS.PHRASE.BACK_TO_OFFERS" />
                          </>
                        }
                        redirect={ROUTES.SHORTLISTS_PAGE}
                        color="danger"
                        solid
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                    <OffersOnlineAnalysisPortfolio
                        intl={intl}
                        data={offersAnalytics}
                        loading={loadingOffersAnalytics}
                        onFetchData={fetchOffersAnalytics}
                        dataDetails={offerDetailsAnalytics}
                        loadingDetails={loadingOfferDetailsAnalytics}
                        onFetchDataDetails={fetchOffersOfficeAnalytics}
                        DetailsComponent={OffersOnlineAnalysisDetails}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OffersOnlineAnalysisCharts
                        intl={intl}
                        data={offersCharts}
                        loading={loadingOffersChars}
                        onFetchData={fetchOffersAnalyticsCharts}
                    />
                </Grid>
            </Grid>
          </IsPro>
        );
    }
}

export default injectIntl(
  connect()(OffersOnlineAnalysisPage)
);