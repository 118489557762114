import React from "react";
import {getBaseUrl} from "../../../utils/helpers";
import {ModalTrigger} from "../ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";

export default class CopyLinkToClipboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          copied: false
        };
    }
    handleCopyLink = () => {
        const {route, routeParams, redirect} = this.props;
        const baseUrl = getBaseUrl(window.location);
        const params = routeParams && routeParams.length ? routeParams.join("/") : '';
        const copyLink = `${baseUrl}${route}/${params}/`;

        if(redirect) {
            window.open(copyLink, "_blank");
        }
        else {
            this.setState({
                copied: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        copied: false
                    });
                }, 5000);
               return navigator.clipboard.writeText(copyLink);
            });
        }
    };
    render() {
        const {route, label} = this.props;
        const {copied} = this.state;
        if(route) {
            return(
                <ModalTrigger
                  icon="content_copy"
                  label={<FormattedMessage id={copied ? "PROPERTY_PARTICULARS.PHRASE.COPIED_TO_CLIPBOARD" : label}/>}
                  directIcon
                  color={copied ? "success" : "neutral"}
                  onClick={() => this.handleCopyLink()}
                />
            );
        }
        return false;
    }
}