import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {onlyNumbers} from "app/utils/helpers";
import InputCheckbox from "../../../InputCheckbox";
import CURRENCIES from "../../../../../constants/CURRENCIES";
import _ from "lodash";

class EditOfficeLeaseTerms extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const {
      rentFrom,
      rentTo,
      retailRentPrice,
      retailRentPriceTo,
      rentToNegotiate,
      minRentalYears,
      minRentalYearsToNegotiate,
      minRentalYearsIndefinite,
      serviceCharge,
      parkingGroundRent,
      parkingGroundNotExist,
      parkingGroundToNegotiate,
      parkingUndergroundRent,
      parkingUndergroundNotExist,
      parkingUndergroundToNegotiate,
      parkingFactorArea,
      parkingFactorNumber,
      commonAreaFactor,
      rentHolidays,
      fitoutPrice,
      rentCurrency,
      serviceChargesCurrency,
      retailRentPriceCurrency,
      parkingGroundRentCurrency,
      parkingUndergroundRentCurrency,
      fitoutCurrency
    } = props.data;
    this.state = {
      rentFrom: rentFrom || '',
      rentTo: rentTo || '',
      retailRentPrice: retailRentPrice || '',
      retailRentPriceTo: retailRentPriceTo || '',
      rentToNegotiate,
      minRentalYears: minRentalYears || '',
      minRentalYearsToNegotiate,
      minRentalYearsIndefinite,
      serviceCharge: serviceCharge || '',
      parkingGroundRent: parkingGroundRent || '',
      parkingGroundNotExist,
      parkingGroundToNegotiate,
      parkingUndergroundRent: parkingUndergroundRent || '',
      parkingUndergroundNotExist,
      parkingUndergroundToNegotiate,
      parkingFactorArea: parkingFactorArea || '',
      parkingFactorNumber: parkingFactorNumber || '1',
      commonAreaFactor: commonAreaFactor || '',
      rentHolidays: rentHolidays || '',
      fitoutPrice: fitoutPrice || '',
      rentCurrency: rentCurrency || '',
      serviceChargesCurrency: serviceChargesCurrency || '',
      retailRentPriceCurrency: retailRentPriceCurrency || '',
      parkingGroundRentCurrency: parkingGroundRentCurrency || '',
      parkingUndergroundRentCurrency: parkingUndergroundRentCurrency || '',
      fitoutCurrency: fitoutCurrency || ''
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.officeUpdating !== this.props.officeUpdating) {
      this.props.onRequestSaving(this.props.officeUpdating);
    }
  }

  handleSelectedRentHolidays = (selected) => {
    this.setState({
      rentHolidays: selected
    });
  };
  handleSelect = (selected, target) => {
    this.setState({
      [target]: selected
    });
  }
  handleChange = validation => event => {
    const onlyNums = onlyNumbers(event.target.value);
    this.setState({
      [event.target.name]: validation === 'number' ? onlyNums : event.target.value
    });
  };
  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };
  handleSave = () => {
    const {data: {id}, updateOffice} = this.props;
    const {
      rentFrom,
      rentTo,
      retailRentPrice,
      retailRentPriceTo,
      rentToNegotiate,
      minRentalYears,
      minRentalYearsToNegotiate,
      minRentalYearsIndefinite,
      serviceCharge,
      parkingGroundRent,
      parkingGroundNotExist,
      parkingGroundToNegotiate,
      parkingUndergroundRent,
      parkingUndergroundNotExist,
      parkingUndergroundToNegotiate,
      parkingFactorArea,
      parkingFactorNumber,
      commonAreaFactor,
      rentHolidays,
      fitoutPrice,
      rentCurrency,
      serviceChargesCurrency,
      retailRentPriceCurrency,
      parkingGroundRentCurrency,
      parkingUndergroundRentCurrency,
      fitoutCurrency
    } = this.state;
    this.initialState = this.state;
    const data = {
      rentFrom: rentFrom || null,
      rentTo: rentTo || null,
      retailRentPrice: retailRentPrice || null,
      retailRentPriceTo: retailRentPriceTo || null,
      rentToNegotiate,
      minRentalYears: minRentalYears || null,
      minRentalYearsToNegotiate,
      minRentalYearsIndefinite,
      serviceCharge: serviceCharge || null,
      parkingGroundRent: parkingGroundRent || null,
      parkingGroundNotExist,
      parkingGroundToNegotiate,
      parkingUndergroundRent: parkingUndergroundRent || null,
      parkingUndergroundNotExist,
      parkingUndergroundToNegotiate,
      parkingFactorArea: parkingFactorArea || null,
      parkingFactorNumber: parkingFactorNumber || '1',
      commonAreaFactor: commonAreaFactor || null,
      rentHolidays: rentHolidays || null,
      fitoutPrice: fitoutPrice || null,
      ..._.isNumber(rentCurrency) ? {rentCurrency} : {},
      ..._.isNumber(serviceChargesCurrency) ? {serviceChargesCurrency} : {},
      ..._.isNumber(retailRentPriceCurrency) ? retailRentPriceCurrency : {},
      ..._.isNumber(parkingGroundRentCurrency) ? parkingGroundRentCurrency : {},
      ..._.isNumber(parkingUndergroundRentCurrency) ? parkingUndergroundRentCurrency : {},
      ..._.isNumber(fitoutCurrency) ? fitoutCurrency : {}
    };
    updateOffice(id, data, "leaseTerms");
  };

  render() {
    const {officeUpdating} = this.props;
    const {
      rentFrom,
      rentTo,
      retailRentPrice,
      retailRentPriceTo,
      rentToNegotiate,
      minRentalYears,
      minRentalYearsToNegotiate,
      minRentalYearsIndefinite,
      serviceCharge,
      parkingGroundRent,
      parkingGroundNotExist,
      parkingGroundToNegotiate,
      parkingUndergroundRent,
      parkingUndergroundNotExist,
      parkingUndergroundToNegotiate,
      parkingFactorArea,
      commonAreaFactor,
      rentHolidays,
      fitoutPrice,
      rentCurrency,
      serviceChargesCurrency,
      retailRentPriceCurrency,
      parkingGroundRentCurrency,
      parkingUndergroundRentCurrency,
      fitoutCurrency
    } = this.state;

    let rentHolidaysArray = [];
    for (let i = 1; i <= 24; i++) {
        rentHolidaysArray.push(i);
    }

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Lease terms"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <Typography variant="h4"><FormattedMessage id="BUILDING.PHRASE.RENT_DETAILS"/></Typography>
            </Grid>
            <Grid item xs={12}>
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/>}
                name="rentToNegotiate"
                value={rentToNegotiate}
                onCheckboxChange={this.handleCheckboxChange}
                disabled={officeUpdating}
              />
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM"/>}
                    name="rentFrom"
                    value={rentFrom}
                    disabled={rentToNegotiate || officeUpdating}
                    onChange={this.handleChange('number')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.RENT_TO"/>}
                    name="rentTo"
                    value={rentTo}
                    disabled={rentToNegotiate || officeUpdating}
                    onChange={this.handleChange('number')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SingleSelect
                    label={<FormattedMessage id="BUILDING.PHRASE.RENT_CURRENCY" values={{breakingLine: ''}}/>}
                    inputName="rentCurrency"
                    defaultSelect={rentCurrency}
                    options={CURRENCIES}
                    optionsValue="value"
                    optionsLabel="label"
                    onSelectedValues={this.handleSelect}
                    required
                    disabled={rentToNegotiate || officeUpdating}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.RETAIL_RENT_FROM"/>}
                    name="retailRentPrice"
                    value={retailRentPrice}
                    onChange={this.handleChange('number')}
                    disabled={officeUpdating}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.RETAIL_RENT_TO"/>}
                    name="retailRentPriceTo"
                    value={retailRentPriceTo}
                    onChange={this.handleChange('number')}
                    disabled={officeUpdating}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SingleSelect
                    label={<FormattedMessage id="BUILDING.PHRASE.RETAIL_RENT_CURRENCY" values={{breakingLine: ''}}/>}
                    inputName="retailRentPriceCurrency"
                    defaultSelect={retailRentPriceCurrency}
                    options={CURRENCIES}
                    optionsValue="value"
                    optionsLabel="label"
                    onSelectedValues={this.handleSelect}
                    required
                    disabled={officeUpdating}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{marginTop: 25}}><FormattedMessage id="BUILDING.PHRASE.MINIMAL_RENT_PERIOD"/></Typography>
            </Grid>
            <Grid item xs={4}>
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/>}
                name="minRentalYearsToNegotiate"
                value={minRentalYearsToNegotiate}
                disabled={minRentalYearsIndefinite ||officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.FOR_AN_INDEFINITE_PERIOD"/>}
                name="minRentalYearsIndefinite"
                value={minRentalYearsIndefinite}
                disabled={minRentalYearsToNegotiate || officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <InputAdornments
                label={<FormattedMessage id="BUILDING.PHRASE.MINIMAL_RENTAL_YEARS"/>}
                name="minRentalYears"
                value={minRentalYears}
                disabled={minRentalYearsToNegotiate || minRentalYearsIndefinite || officeUpdating}
                onChange={this.handleChange('number')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{
          backgroundColor: "#282828",
          borderRadius: "4px",
          paddingTop: "0.5rem",
          paddingBottom: "1.5rem",
          marginTop: "2.5rem"
        }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h4"><FormattedMessage id="BUILDING.PHRASE.PARKING_DETAILS"/></Typography>
              <Typography style={{marginTop: "1.5rem"}}><FormattedMessage id="BUILDING.PHRASE.GROUND_PARKING"/></Typography>
            </Grid>
            <Grid item>
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.NOT_EXIST"/>}
                name="parkingGroundNotExist"
                value={parkingGroundNotExist}
                disabled={parkingGroundToNegotiate || officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/>}
                name="parkingGroundToNegotiate"
                value={parkingGroundToNegotiate}
                disabled={parkingGroundNotExist || officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.CHARGES_FOR_GROUND_PARKING"/>}
                    name="parkingGroundRent"
                    value={parkingGroundRent}
                    disabled={parkingGroundNotExist || parkingGroundToNegotiate || officeUpdating}
                    onChange={this.handleChange('number')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleSelect
                    label={<FormattedMessage id="BUILDING.PHRASE.PARKING_GROUND_CURRENCY" values={{breakingLine: ''}}/>}
                    inputName="parkingUndergroundRentCurrency"
                    defaultSelect={parkingGroundRentCurrency}
                    options={CURRENCIES}
                    optionsValue="value"
                    optionsLabel="label"
                    onSelectedValues={this.handleSelect}
                    required
                    disabled={parkingGroundNotExist || parkingGroundToNegotiate || officeUpdating}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography style={{marginTop: "1.5rem"}}><FormattedMessage id="BUILDING.PHRASE.UNDERGROUND_PARKING"/></Typography>
            </Grid>
            <Grid item>
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.NOT_EXIST"/>}
                name="parkingUndergroundNotExist"
                value={parkingUndergroundNotExist}
                disabled={parkingUndergroundToNegotiate || officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <InputCheckbox
                label={<FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/>}
                name="parkingUndergroundToNegotiate"
                value={parkingUndergroundToNegotiate}
                disabled={parkingUndergroundNotExist || officeUpdating}
                onCheckboxChange={this.handleCheckboxChange}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.CHARGES_FOR_UNDERGROUND_PARKING"/>}
                    name="parkingUndergroundRent"
                    value={parkingUndergroundRent}
                    disabled={parkingUndergroundNotExist || parkingUndergroundToNegotiate || officeUpdating}
                    onChange={this.handleChange('number')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SingleSelect
                    label={<FormattedMessage id="BUILDING.PHRASE.PARKING_UNDERGROUND_CURRENCY" values={{breakingLine: ''}}/>}
                    inputName="parkingUndergroundRentCurrency"
                    defaultSelect={parkingUndergroundRentCurrency}
                    options={CURRENCIES}
                    optionsValue="value"
                    optionsLabel="label"
                    onSelectedValues={this.handleSelect}
                    required
                    disabled={parkingUndergroundNotExist || parkingUndergroundToNegotiate || officeUpdating}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography style={{marginTop: '1.5rem'}}><FormattedMessage id="BUILDING.PHRASE.PARKING_FACTOR"/></Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={2}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end"
                    }}
                  >
                    <InputAdornments
                      label={<FormattedMessage id="BUILDING.PHRASE.FACTOR_NUMBER"/>}
                      name="parkingFactorNumber"
                      value={1}
                      onChange={this.handleChange('number')}
                      disabled
                    />
                    <Typography style={{textAlign: 'center', marginLeft: "1.5rem"}}>/</Typography>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <InputAdornments
                    label={<FormattedMessage id="BUILDING.PHRASE.FACTOR_AREA"/>}
                    name="parkingFactorArea"
                    value={parkingFactorArea}
                    onChange={this.handleChange('number')}
                    disabled={officeUpdating}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" style={{marginTop: "2.5rem"}}><FormattedMessage id="BUILDING.PHRASE.OTHER_DATA"/></Typography>
            </Grid>
            <Grid item xs={4}>
              <InputAdornments
                label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE"/>}
                name="serviceCharge"
                value={serviceCharge}
                onChange={this.handleChange('number')}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleSelect
                label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGES_CURRENCY" values={{breakingLine: ''}}/>}
                inputName="serviceChargesCurrency"
                defaultSelect={serviceChargesCurrency}
                options={CURRENCIES}
                optionsValue="value"
                optionsLabel="label"
                onSelectedValues={this.handleSelect}
                required
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={4}>
              <InputAdornments
                label={<FormattedMessage id="BUILDING.PHRASE.COMMON_AREA_FACTOR"/>}
                name="commonAreaFactor"
                value={commonAreaFactor}
                onChange={this.handleChange('number')}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleSelect
                label={<FormattedMessage id="BUILDING.PHRASE.TRADE_FIXTURES_MONTHS" values={{breakingLine: ''}}/>}
                inputName="rentHolidays"
                defaultSelect={rentHolidays}
                options={rentHolidaysArray}
                onSelectedValues={this.handleSelectedRentHolidays}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={4}>
              <InputAdornments
                label={<FormattedMessage id="BUILDING.PHRASE.FITOUT"/>}
                name="fitoutPrice"
                value={fitoutPrice}
                onChange={this.handleChange('number')}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleSelect
                label={<FormattedMessage id="BUILDING.PHRASE.FITOUT_CURRENCY" values={{breakingLine: ''}}/>}
                inputName="fitoutCurrency"
                defaultSelect={fitoutCurrency}
                options={CURRENCIES}
                optionsValue="value"
                optionsLabel="label"
                onSelectedValues={this.handleSelect}
                required
                disabled={officeUpdating}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  officeUpdating: store.offices.officeUpdating
});
const mapDispatchToProps = {
  updateOffice: (officeId, data, backgroundRefresh) => updateOffice(officeId, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeLeaseTerms)
);