import React from "react";
import {FormattedMessage} from "react-intl";
import {toDateFormat} from "app/utils/helpers";
import {Icon, Tooltip, Typography} from "@material-ui/core";
import {BUILDING_STATUS} from "../../../constants";

export default class UnitAvailabilityPreview extends React.Component {
    render() {
        const {date, isUnavailable, isReserved, availableInMonths, parentStatus, style} = this.props;
        const currentDate = new Date().getTime();
        const availableDate = new Date(date).getTime();
        const isParentStatusPlanned = parentStatus === BUILDING_STATUS.STATUS_PLANNED;
        const isAvailable = availableInMonths ? (
                <Tooltip
                    placement="top"
                    title={<Typography variant="body1"><FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM_ADDITIONAL_TIME"/></Typography>}
                >
                  <span>
                    <FormattedMessage
                        id="BUILDING.PHRASE.MONTHS_VALUE"
                        values={{months: availableInMonths, count: Number(availableInMonths)}}
                    />
                    <Icon fontSize="small" color="error">help_outline</Icon>
                  </span>
                </Tooltip>
            ) :
            isUnavailable ? <FormattedMessage id="GENERAL.PHRASE.UNAVAILABLE"/> :
                isReserved ? <FormattedMessage id="GENERAL.PHRASE.RESERVED"/> :
                    currentDate >= availableDate ? (
                        isParentStatusPlanned ? <FormattedMessage id="GENERAL.PHRASE.ON_REQUEST"/> :
                        <FormattedMessage id="GENERAL.PHRASE.IMMEDIATELY"/>
                      ) :
                        toDateFormat(date);
        return <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ...style}}>{isAvailable}</span>;
    }
}