import React from "react";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import ListView from "app/pages/offices/components/ListView/ListView";
import IsPro from "../../../utils/IsPro";

class OffersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      initLoaded: false
    };
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchShortlists, loading, callReddClickEvent} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        callReddClickEvent(
          "offers-page-visit",
          "offer"
        );
        fetchShortlists({params});
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {shortlists, items, loading, isTeamOwner, user} = this.props;
    const isOwnerColumnAvailable = isTeamOwner;
    const isLoggedInAs = user?.loggedInAs;

    return (
      <IsPro paywall>
        <ListView
          tableLabel={<FormattedMessage id="SHORTLIST.PHRASE.SHORTLISTS"/>}
          tableHeadRows={
            [
              { id: 'shortlist_name', numeric: false, disablePadding: false, label: 'SHORTLIST.PHRASE.NAME', sort: true },
              ...isOwnerColumnAvailable ? [{ id: 'offer_owner', numeric: false, disablePadding: false, label: 'OFFERS.PHRASE.OFFER_OWNER', sort: false }] : [],
              { id: 'shortlist_units', numeric: true, disablePadding: false, label: 'SHORTLIST.PHRASE.NO_OF_UNITS', sort: true },
              { id: 'shortlist_create_date', numeric: false, disablePadding: false, label: 'SHORTLIST.PHRASE.CREATE_DATE', sort: true },
              { id: 'shortlist_last_update', numeric: false, disablePadding: false, label: 'SHORTLIST.PHRASE.LAST_UPDATE', sort: true },
              { id: 'actions', numeric: true, disablePadding: false, label: '', sort: false }
            ]
          }
          tableBodyRows={items?.filter(item => isLoggedInAs ? item : !item?.forTesting)}
          tableDataSource="office"
          tableDataTarget={TABLE_CELLS.SHORTLISTS}
          itemsPerPage={shortlists.itemsPerPage}
          itemsCount={shortlists.itemsCount}
          totalItems={shortlists.totalItems}
          handleFetchData={this.props.fetchShortlists}
          loading={loading}
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    );
  }
}

export default withRouter(OffersPage);