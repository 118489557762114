import React from "react";
import connect from "react-redux/es/connect/connect";
import styles from "../../../../common/external/components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import {TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import clsx from "clsx";
import {Formik} from "formik";
import {authLogin} from "app/crud/authApp.crud";
import {requestOfferOnlineAccess, verifyOfferOnlineAccess} from "app/crud/offers.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import PageMessages from "app/partials/components/PageMessages";

class OffersOnlineAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerID: props.match.params.offer,
      verifyMail: ""
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.hasAccess !== this.props.hasAccess) {
      if(this.props.hasAccess) {
       localStorage.setItem("offerMail", this.state.verifyMail);
       window.location.reload();
      }
      else {
        localStorage.removeItem("offerMail");
      }
    }
  }
  handleRequestAccess = (e) => {
    e.preventDefault();
    if(this.state.offerID && this.state.verifyMail) {
      this.props.requestOfferOnlineAccess(this.state.offerID, this.state.verifyMail);
    }
  };

  render() {
    const {
      intl,
      authLoading,
      verifyingAccess,
      requestingAccess,
      verifyOfferOnlineAccess,
      offerBranding,
      loadingOfferBranding,
      message,
      type,
      isOpen,
      destroyNotification
    } = this.props;
    const {offerID} = this.state;

    if(loadingOfferBranding || !offerBranding) {
      return <LoadingScreen/>;
    }
    return(
      <div
          className={styles.mainAuthContainer}
          style={{
            "--bg-image": `url(${toAbsoluteUrl("media/bg/bg-offers-online.jpg")})`,
          }}
      >
        <div className={styles.authWrapper}>
          <div className={styles.authImage}>
            {offerBranding.offerCompanyLogo ? <img src={offerBranding.offerCompanyLogo} alt={offerBranding.name}/> : <span className={styles.authName}>{offerBranding.name}</span>}
          </div>
          <div className={styles.authContent}>
            <div className={styles.contentHeader}>
              <h3><FormattedMessage id="OFFERS.PHRASE.OFFERS_ONLINE_FOR" values={{name: offerBranding.offerName}}/></h3>
              <div className={styles.langMenu}>
                  <LanguageSelector preventReload/>
              </div>
            </div>
            <div className={styles.contentBody}>
              <p><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.HELLO"/>,</p>
              <p><FormattedMessage id="OFFERS.PHRASE.AUTH_DESCRIPTION"/></p>
              <p><FormattedMessage id="OFFERS.PHRASE.AUTH_FORM_INFO"/></p>
            </div>
            <div className={styles.contentFooter}>
              <Formik
                initialValues={{
                  email: ""
                }}
                validate={values => {
                  const errors = {};
                  // Email address is required
                  if (!values.email) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                  }
                  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD"
                    });
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  // Update given email address
                  this.setState({
                    verifyMail: values.email
                  });
                  // Verify common based on provided email address
                  verifyOfferOnlineAccess(offerID, values.email);
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => (
                  <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={handleSubmit}
                  >
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}
                    <div className="form-group">
                      <TextField
                        type="email"
                        label={intl.formatMessage({
                          id: "FORM.FIELD.EMAIL_ADDRESS"
                        })}
                        margin="normal"
                        className={styles.customInput}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>
                    <Typography variant="body2" className={styles.formNote}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AUTH_CONSENTS_INFO"/></Typography>
                    <div className={styles.contentActions}>
                      <button
                          className={clsx(styles.btnSimpleText, requestingAccess && styles.inactive)}
                          onClick={(e) => this.handleRequestAccess(e)}
                      >
                        <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.REQUEST_ACCESS"/>
                      </button>
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={authLoading}
                        className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit, (authLoading || verifyingAccess) && styles.inactive)}
                      >
                        <FormattedMessage id="OFFERS.PHRASE.BTN_AUTH_SUBMIT" />
                      </button>
                      <img src={toAbsoluteUrl("media/branding/redd-loader.gif")} alt="REDD Loader" className={clsx(styles.btnLoader, requestingAccess && styles.left, (authLoading || verifyingAccess || requestingAccess) && styles.active)}/>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          {/* Error/Notification Component - display when needed */}
          <PageMessages
            message={message}
            variant={type}
            isOpen={isOpen}
            timeout={5000}
            destroyNotification={destroyNotification}
            vertical="bottom"
            style={{
              position: "absolute",
              right: "0",
              bottom: "-6.4rem"
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  hasAccess: store.shortlists.hasAccess,
  verifyingAccess: store.shortlists.verifyingAccess,
  requestingAccess: store.shortlists.requestingAccess,
  offerBranding: store.shortlists.offerBranding,
  loadingOfferBranding: store.shortlists.loadingOfferBranding
});
const mapDispatchToProps = {
  authLogin: (email, password) => authLogin(email, password),
  verifyOfferOnlineAccess: (offerID, email) => verifyOfferOnlineAccess(offerID, email),
  requestOfferOnlineAccess: (offerID, email) => requestOfferOnlineAccess(offerID, email)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffersOnlineAuth)
);
