import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {addIndustrialWarehouseUnit} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Grid, Typography} from '@material-ui/core';
import InputAdornments from "../../../../InputAdornments";
import SingleSelect from "../../../../SingleSelect";
import {FILTERS_SELECTS} from "../../../../FiltersDrawer/constants";
import InputSwitcher from "../../../../InputSwitcher";
import {onlyNumbers} from "../../../../../../utils/helpers";
import format from "date-fns/format";
import AlertFormDialog from "../../../../AlertFormDialog";
import {BUILDING_STATUS} from "../../../../../../constants";
import LoadingScreen from "../../../../LoadingScreen";
import TooltipCustom from "../../../../TooltipCustom";

class IndustrialWarehouseUnitAdd extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
        area: '',
        availableFrom: format(new Date(), 'yyyy-MM-dd'),
        status: null,
        isOffice: false,
        docks: '',
        canBeDivided: false,
        alertFormOpen: false,
        alertFormDateOpen: false,
        alertRequiredFields: false
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {addingUnit, unitAdded} = this.props;

    if(prevProps?.addingUnit && !addingUnit && unitAdded) {
      this.handleCloseModal();
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area";
    this.setState({
      alertFormOpen: false,
      alertFormDateOpen: false,
      alertRequiredFields: false,
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      alertFormDateOpen: false,
      alertRequiredFields: false,
      [event.target.name]: event.target.value
    });
  };
  handleSelectedStatus = (selected) => {
    this.setState({
      alertRequiredFields: false,
      status: selected
    });
  };
  handleChangeSwitcher = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  };

  handleSave = () => {
    const {parentID, addIndustrialWarehouseUnit, ordering} = this.props;
    const {
      area,
      availableFrom,
      status,
      isOffice,
      docks,
      canBeDivided,
    } = this.state;
    const isNotExisting = status !== BUILDING_STATUS.STATUS_EXISTS;
    const unitDate = availableFrom ? new Date(availableFrom) : new Date();
    const todaysDate = new Date();

    this.setState({
      areaError: !area.length
    }, () => {
      if(parentID) {
        if(Number(area) < 5) {
          this.setState({
            alertFormOpen: true
          });
        }
        else if(isNotExisting && unitDate.setHours(0,0,0,0) <= todaysDate.setHours(0,0,0,0)) {
          this.setState({
            alertFormDateOpen: true
          });
        }
        else {
          const data = {
            "warehouse": parentID,
            "area": area || null,
            "availableFrom": availableFrom ? new Date(availableFrom).toISOString() : null,
            "status": status,
            "isOffice": isOffice,
            "docks": docks || null,
            "canBeDivided": canBeDivided
          };
          addIndustrialWarehouseUnit(data, ordering);
        }
        return true;
      }
    });
  };

  render() {
    const {lackOfAvailableArea, warehouseData, btnTrigger, addingUnit} = this.props;
    const {
      area,
      availableFrom,
      status,
      isOffice,
      docks,
      canBeDivided
    } = this.state;

    if(lackOfAvailableArea || warehouseData?.lackOfAvailableArea) {
      return(
        <TooltipCustom title={<FormattedMessage id="BUILDING.PHRASE.LACK_OF_AVAILABLE_AREA_MESSAGE"/>}>
          <span>{btnTrigger ? btnTrigger : <ButtonCustom label={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>} color="warning" icon="add"/>}</span>
        </TooltipCustom>
      )
    }
    return(
      <>
      <ModalCustom
        ref={this.child}
        btn={
        btnTrigger ? btnTrigger :
          <ButtonCustom label={<FormattedMessage id="INDUSTRIAL.PHRASE.ADD_UNIT"/>} color="success" icon="add"/>
        }
        btnConfirm={addingUnit ? <LoadingScreen loaderStyle={{width: "1.9rem", height: "1.9rem"}}/> : <FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.ADD_UNIT"/>}
        handleSave={this.handleSave}
        btnConfirmDisabled={addingUnit}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputAdornments label={<FormattedMessage id="GENERAL.PHRASE.AREA"/>} name="area" value={area} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <InputAdornments label={<FormattedMessage id="INDUSTRIAL.PHRASE.DOCKS"/>} name="docks" value={docks} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <InputAdornments type="date" label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>} name="availableFrom" value={availableFrom} onChange={this.handleChangeDate}/>
          </Grid>
          <Grid item>
            <SingleSelect
              label={<FormattedMessage id="BUILDING.PHRASE.STATUS"/>}
              inputName="status"
              options={FILTERS_SELECTS.UNITS_STATUSES}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={status}
              onSelectedValues={this.handleSelectedStatus}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item><Typography><FormattedMessage id="INDUSTRIAL.PHRASE.IS_OFFICE_UNIT"/></Typography></Grid>
              <Grid item>
                <InputSwitcher checked={isOffice} value="isOffice" label={<FormattedMessage id="GENERAL.PHRASE.YES"/>} onChange={this.handleChangeSwitcher("isOffice")}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item><Typography><FormattedMessage id="UNITS.PHRASE.IS_FLEXIBLE"/></Typography></Grid>
              <Grid item>
                <InputSwitcher checked={canBeDivided} value="canBeDivided" label={<FormattedMessage id="GENERAL.PHRASE.YES"/>} onChange={this.handleChangeSwitcher("canBeDivided")}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalCustom>
      <AlertFormDialog
          open={this.state.alertFormOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_MIN_AREA"/>}
          onClose={() => this.setState({alertFormOpen: false})}
      />
      <AlertFormDialog
          open={this.state.alertFormDateOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_UNIT_AVAILABILITY_DATE"/>}
          onClose={() => this.setState({alertFormDateOpen: false})}
      />
        <AlertFormDialog
          open={this.state.alertRequiredFields}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_ADD_UNIT_REQUIRED_FIELDS"/>}
          onClose={() => this.setState({alertRequiredFields: false})}
      />
      </>
    );
  }
}

const mapStateToProps = ({ user, industrial }) => ({
  user: user.data,
  warehouseUpdateLoading: industrial.warehouseUpdateLoading,
  warehouseData: industrial.warehouse,
  addingUnit: industrial.addingUnit,
  unitAdded: industrial.unitAdded

});
const mapDispatchToProps = {
  addIndustrialWarehouseUnit: (data, ordering) => addIndustrialWarehouseUnit(data, ordering)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialWarehouseUnitAdd)
);