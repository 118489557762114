import {
  fetchOfficePremiumListLatestReleases,
  fetchOfficePremiumListLatestRentChanged,
  fetchOfficePremiumListLatestRented,
  fetchOfficePremiumListLatestSoldOffices
} from "app/crud/estates/offices/officesPremium.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import AnalyticsPage from "./AnalyticsPage";
import {setLatestActiveTablePage} from "../../../crud/tablePage.crud";
import {filterReset} from "../../../crud/estates/filters.crud";

const mapStateToProps = (state) => ({
  user: state.user.data,
  items: state.officesPremium.items,
  officesPremium: state.officesPremium,
  premiumLoaded: state.officesPremium.loaded,
  premiumLoading: state.officesPremium.loading
});
const mapDispatchToProps = {
  fetchOfficePremiumListLatestReleases: ({params}) => fetchOfficePremiumListLatestReleases(params),
  fetchOfficePremiumListLatestRentChanged: ({params}) => fetchOfficePremiumListLatestRentChanged(params),
  fetchOfficePremiumListLatestRented: ({params}) => fetchOfficePremiumListLatestRented(params),
  fetchOfficePremiumListLatestSoldOffices: ({params}) => fetchOfficePremiumListLatestSoldOffices(params),
  filterReset: (filtersToOmit, currentApplicationView, fullReset) => filterReset(filtersToOmit, currentApplicationView, fullReset),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnalyticsPage)
);
