/* eslint-disable no-use-before-define */
import React from 'react';
import {injectIntl} from "react-intl";
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from "lodash";
import styles from "./index.module.scss";
import IsApplicationView from "../../../utils/IsApplicationView";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

function AutocompleteCustom(
  {
    intl,
    name,
    options,
    inputLabel,
    inputValue,
    inputVariant,
    groupedEnable,
    optionLabelKey,
    style,
    onChange,
    ...props
  }
) {
  const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
  const marketsGrouped = options.map((option, index) => {
    const primaryBorder = isIndustrialView ? 5 : 6;
    const isPrimary = index <= primaryBorder;
    return {
      group: isPrimary ? intl.formatMessage({id: "VOCABULARY.Key markets"}) : intl.formatMessage({id: "VOCABULARY.Other markets"}),
      ..._.isObject(option) ? option : {option},
    };
  });
  const optionsFinal = groupedEnable ? marketsGrouped : options;

  return (
    <div className={styles.autocompleteWrapper} style={style}>
      {props?.inputIcon && <span className={styles.iconWrapper}>{props?.inputIcon}</span>}
      <Autocomplete
        id={name}
        options={optionsFinal}
        groupBy={(option) => groupedEnable ? option.group : undefined}
        getOptionLabel={(option) => option?.[optionLabelKey] || ""}
        getOptionSelected={(option, value) => _.isEqual(option, value)}
        renderInput={(params) =>
          <TextField
            {...params}
            label={inputLabel}
            variant={inputVariant || "standard"}
          />
        }
        value={props?.value || {}}
        inputValue={inputValue || ""}
        onChange={onChange}
      />
    </div>
  );
}

export default injectIntl(AutocompleteCustom);