import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import styles from "./index.module.scss";
import cx from "classnames";
import Tab1 from "./components/Tab1";
import ButtonCustom from "../ButtonCustom";
import {getBaseUrl, handleGoogleAnalyticsTracking} from "../../../utils/helpers";
import ROUTES from "../../../constants/ROUTES";
import Tab2 from "./components/Tab2";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

class ShareOfferIndustrialOnline extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: 'tab_1',
      copied: false
    };
  }
  // Function to change/set new Active Tab
  handleChangeActiveTab = (event, newValue) => {
    this.setState({
      activeTab: newValue
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleCopyOfferLink = () => {
    const {offerID} = this.props;
    const baseUrl = getBaseUrl(window.location);
    const offerLink = `${baseUrl}${ROUTES.INDUSTRIAL_OFFERS_ONLINE}/${offerID}/`;

    this.setState({
        copied: true
    }, () => {
      handleGoogleAnalyticsTracking('Button', 'Click', 'Offers Industrial Online - Copy Link');
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 5000);
        return navigator.clipboard.writeText(offerLink);
    });
  };

  render() {
    const {offerID, triggerColor, triggerLabel} = this.props;
    const {activeTab, copied} = this.state;
    // Function to determine if given Tab is a Active Tab
    const isActiveTabBarItem = (tab) => {
      return tab === activeTab;
    };
    // Overwrite some base Tabs Bar Styles
    const tabsBarStyles = {
      marginBottom: 18,
      backgroundColor: "transparent",
      color: "#3a3a3a",
      boxShadow: "none"
    };

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="reply"
            color={triggerColor ? triggerColor : "neutral"}
            label={triggerLabel ? triggerLabel : <FormattedMessage id="GENERAL.PHRASE.SHARE"/>}
            directBtn
            solid
            iconReverse
            onClick={() => handleGoogleAnalyticsTracking('Button', 'Click', 'Share Offers Industrial Online - Offers List Page')}
          />
        }
        defaultThemeProvider
        btnRejectDisabled
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "offers-industrial-share-button",
          "offer",
          null,
          offerID
        ))}
      >
        <div className={styles.tabsWrapper}>
          <div className={styles.tabsBarWrapper}>
            <AppBar position="static" style={tabsBarStyles}>
              <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FF4626"
                    }
                  }}
                  value={activeTab}
                  onChange={this.handleChangeActiveTab}
                  variant="scrollable"
                  scrollButtons="auto"
              >
                <Tab
                    value="tab_1"
                    className={cx(styles.tabBarItem, isActiveTabBarItem("tab_1") && styles.active)}
                    label={<FormattedMessage id="GENERAL.PHRASE.SHARE"/>}
                    wrapped
                />
                <Tab
                    value="tab_2"
                    className={cx(styles.tabBarItem, isActiveTabBarItem("tab_2") && styles.active)}
                    label={<FormattedMessage id="GENERAL.PHRASE.MANAGE_ACCESS"/>}
                    wrapped
                />
              </Tabs>
            </AppBar>
            <ButtonCustom
                label={<FormattedMessage id={copied ? "GENERAL.PHRASE.COPIED_TO_CLIPBOARD" : "GENERAL.PHRASE.COPY_TO_CLIPBOARD"}/>}
                color={copied ? "success" : "danger"}
                solid
                onClick={() => this.handleCopyOfferLink()}
            />
          </div>
          {activeTab === 'tab_1' && <Tab1 {...this.props}/>}
          {activeTab === 'tab_2' && <Tab2 {...this.props}/>}
        </div>
      </ModalCustom>
    );
  }
}

export default injectIntl(
  connect()(ShareOfferIndustrialOnline)
);