import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import reverse from "lodash/reverse";
import slice from "lodash/slice";
import sortBy from "lodash/sortBy";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse, handleGoogleAnalyticsTracking,} from "app/utils/helpers";
import {resetFilters, setFilters} from "../../utils/filters";

const initialState = {
  items: [],
  office: null,
  itemsPerPage: 20,
  itemsCount: null,
  loaded: false,
  loading: false,
  shortlistPdf: null,
  shortlistPdfLoading: false,
  shortlistUnits: null,
  shortlistUnitsLoading: false,
  landlordItems: [],
  landlordItemsCount: null,
  landlordItemsPerPage: 20,
  landlordTotal: null,
  landlordLoading: false,
  landlordShortlistUnits: null,
  landlordShortlistUnitsLoading: false,
  loadingMore: false,
  offerItems: null,
  offerOffice: null,
  offerOffice404: false,
  offerContact: null,
  offerBranding: null,
  offerBranding404: false,
  loadingOfferItems: false,
  loadingOfferOffice: false,
  loadingOfferContact: false,
  loadingOffersBranding: false,
  verifyingAccess: false,
  requestingAccess: false,
  hasAccess: false,
  offersOnlineAnalytics: null,
  offersOnlineCharts: null,
  offersOnlineDetailsAnalytics: null,
  loadingOffersOnlineAnalytics: false,
  loadingOffersOnlineCharts: false,
  loadingOffersOnlineDetailsAnalytics: false,
  offersAll: null,
  loadingOffersAll: false,
  offerPhotos: null,
  loadingPhotos: false,
  shareLoading: false,
  shared: false,
  offerBuildings: null,
  offerBuildingsLoading: false,
  offerBuildingsIndustrial: null,
  offerBuildingsIndustrialLoading: false,
  offersBatchLoading: false,
  offersBatchSuccess: false,
  offersIndustrialBatchLoading: false,
  offersIndustrialBatchSuccess: false,
  offerUpdated: false,
  offerUpdating: false
};
const handleUpdateOfferData = (currentList, updatedItem) => {
  return currentList.map(
    (obj) => [updatedItem].find((o) => o.id === obj.id) || obj
  );
};
export const reducer = persistReducer(
  { storage, key: "shortlists" },
  (state = initialState, action) => {
    const result = action.payload;
    switch (action.type) {
      // RESET OFFICE OFFERS LOADING STATES
      case "RESET_OFFICE_OFFERS_LOADING_STATES_REQUEST": {
        return {
          ...state,
          loading: false,
          shortlistPdfLoading: false,
          shortlistUnitsLoading: false,
          landlordLoading: false,
          landlordShortlistUnitsLoading: false,
          loadingMore: false,
          loadingOfferItems: false,
          loadingOfferOffice: false,
          loadingOfferContact: false,
          loadingOffersBranding: false,
          loadingOffersOnlineAnalytics: false,
          loadingOffersOnlineCharts: false,
          loadingOffersOnlineDetailsAnalytics: false,
          loadingOffersAll: false,
          loadingPhotos: false,
          shareLoading: false,
          offerBuildingsLoading: false,
          offerBuildingsIndustrialLoading: false,
          offersBatchLoading: false,
          offersIndustrialBatchLoading: false
        };
      }
      // OFFER
      case "FETCH_OFFERS_ALL_REQUEST": {
        return { ...state, loadingOffersAll: true };
      }
      case "FETCH_OFFERS_ALL_SUCCESS": {
        return { ...state, offersAll: action.payload, loadingOffersAll: false };
      }
      case "FETCH_SHORTLISTS_REQUEST": {
        return {
          ...state,
          items: [],
          itemsCount: null,
          loading: true
        };
      }
      case "FETCH_SHORTLISTS_SUCCESS": {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return {
          ...state,
          items,
          itemsCount,
          itemsPerPage,
          loading: false,
          loaded: true,
        };
      }
      case "FETCH_SHORTLISTS_FAILED": {
        return {
          ...state,
          items: [],
          itemsCount: null,
          loading: false
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_REQUEST": {
        return { ...state, landlordLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST": {
        return { ...state, landlordLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_SUCCESS": {
        const { items, itemsCount, itemsPerPage, total } = action.payload;
        return {
          ...state,
          landlordItems: items,
          landlordItemsCount: itemsCount,
          landlordItemsPerPage: itemsPerPage,
          landlordTotal: total,
          landlordLoading: false,
        };
      }
      case "FETCH_MORE_SHORTLISTS_LANDLORD_REQUEST": {
        return { ...state, loadingMore: true };
      }
      case "FETCH_MORE_SHORTLISTS_LANDLORD_SUCCESS": {
        return {
          ...state,
          landlordItems: state.landlordItems
            ? [...state.landlordItems, ...result.items]
            : result.items,
          loadingMore: false,
        };
      }
      case "FETCH_MORE_SHORTLISTS_LANDLORD_FAILED": {
        return { ...state, landlordItems: null, loadingMore: false };
      }
      case "FETCH_SHORTLIST_PDF_REQUEST": {
        return { ...state, shortlistPdfLoading: true };
      }
      case "FETCH_SHORTLIST_PDF_SUCCESS": {
        return {
          ...state,
          shortlistPdf: action.payload,
          shortlistPdfLoading: false,
        };
      }
      case "FETCH_SHORTLIST_UNITS_REQUEST": {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: true };
      }
      case "FETCH_SHORTLIST_UNITS_SUCCESS": {
        return {
          ...state,
          shortlistUnits: action.payload,
          shortlistUnitsLoading: false,
        };
      }
      case "FETCH_SHORTLIST_UNITS_RESET_SUCCESS": {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: false };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST": {
        return { ...state, landlordShortlistUnitsLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_SUCCESS": {
        return {
          ...state,
          landlordShortlistUnits: action.payload ? action.payload : [],
          landlordShortlistUnitsLoading: false,
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_SUCCESS": {
        return {
          ...state,
          landlordShortlistUnits: null,
          landlordShortlistUnitsLoading: false,
        };
      }
      case "FETCH_OFFER_BUILDINGS_REQUEST": {
        return { ...state, offerBuildings: null, offerBuildingsLoading: true };
      }
      case "FETCH_OFFER_BUILDINGS_SUCCESS": {
        return {
          ...state,
          offerBuildings: action.payload,
          offerBuildingsLoading: false,
        };
      }
      case "FETCH_OFFER_BUILDINGS_INDUSTRIAL_REQUEST": {
        return {
          ...state,
          offerBuildingsIndustrial: null,
          offerBuildingsIndustrialLoading: true,
        };
      }
      case "FETCH_OFFER_BUILDINGS_INDUSTRIAL_SUCCESS": {
        return {
          ...state,
          offerBuildingsIndustrial: action.payload,
          offerBuildingsIndustrialLoading: false,
        };
      }
      // OFFER ONLINE
      case "FETCH_OFFER_ONLINE_REQUEST": {
        return { ...state, loadingOfferItems: true, offerItems: null };
      }
      case "FETCH_OFFER_ONLINE_SUCCESS": {
        return {
          ...state,
          offerItems: action.payload,
          loadingOfferItems: false,
          offerPhotos: null,
        };
      }
      case "FETCH_OFFER_ONLINE_FAILED": {
        return { ...state, offerItems: null, loadingOfferItems: false };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_REQUEST": {
        return {
          ...state,
          loadingOfferOffice: true,
          offerOffice: null,
          offerContact: null,
          offerOffice404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_SUCCESS": {
        return {
          ...state,
          offerOffice: action.payload,
          loadingOfferOffice: false,
          offerContact: null,
          offerOffice404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_FAILED": {
        return {
          ...state,
          offerOffice: null,
          loadingOfferOffice: false,
          offerContact: null,
          offerOffice404: action.payload >= 400 && action.payload <= 500,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST": {
        return { ...state, loadingOfferContact: true, offerContact: null };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_SUCCESS": {
        return {
          ...state,
          offerContact: action.payload,
          loadingOfferContact: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_FAILED": {
        return { ...state, offerContact: null, loadingOfferContact: false };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_REQUEST": {
        return {
          ...state,
          loadingOfferBranding: true,
          offerBranding: null,
          offerBranding404: false,
          offerItems: null,
        };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_SUCCESS": {
        return {
          ...state,
          offerBranding: action.payload,
          loadingOfferBranding: false,
          offerBranding404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_FAILED": {
        return {
          ...state,
          offerBranding: null,
          loadingOfferBranding: false,
          offerBranding404: action.payload === 404,
        };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST": {
        return { ...state, loadingPhotos: true, offerPhotos: null };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_SUCCESS": {
        return { ...state, loadingPhotos: false, offerPhotos: action.payload };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_FAILED": {
        return { ...state, loadingPhotos: false, offerPhotos: null };
      }
      // OFFER ONLINE ANALYTICS
      case "FETCH_OFFERS_ONLINE_ANALYTICS_REQUEST": {
        return {
          ...state,
          offersOnlineAnalytics: null,
          loadingOffersOnlineAnalytics: true,
        };
      }
      case "FETCH_OFFERS_ONLINE_ANALYTICS_SUCCESS": {
        return {
          ...state,
          offersOnlineAnalytics: action.payload,
          loadingOffersOnlineAnalytics: false,
        };
      }
      case "FETCH_OFFERS_ONLINE_ANALYTICS_FAILED": {
        return {
          ...state,
          offersOnlineAnalytics: null,
          loadingOffersOnlineAnalytics: false,
        };
      }
      case "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_REQUEST": {
        return {
          ...state,
          offersOnlineCharts: null,
          loadingOffersOnlineCharts: true,
        };
      }
      case "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_SUCCESS": {
        return {
          ...state,
          offersOnlineCharts: action.payload,
          loadingOffersOnlineCharts: false,
        };
      }
      case "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_FAILED": {
        return {
          ...state,
          offersOnlineCharts: null,
          loadingOffersOnlineCharts: false,
        };
      }
      case "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_REQUEST": {
        return {
          ...state,
          offersOnlineDetailsAnalytics: null,
          loadingOffersOnlineDetailsAnalytics: true,
        };
      }
      case "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_SUCCESS": {
        return {
          ...state,
          offersOnlineDetailsAnalytics: action.payload,
          loadingOffersOnlineDetailsAnalytics: false,
        };
      }
      case "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_FAILED": {
        return {
          ...state,
          offersOnlineDetailsAnalytics: null,
          loadingOffersOnlineDetailsAnalytics: false,
        };
      }
      // OFFER ACTIONS
      case "ADD_SHORTLIST_REQUEST": {
        return { ...state };
      }
      case "ADD_SHORTLIST_SUCCESS": {
        let record = action.payload;
        record["emails"] = [];
        return {
          ...state,
          items: slice(
            reverse(sortBy([...state.items, record], "id")),
            0,
            state.itemsPerPage
          ),
          itemsCount: state.itemsCount + 1,
        };
      }
      case "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST": {
        return { ...state, offerUpdated: false };
      }
      case "ADD_UNIT_TO_EXISTING_SHORTLIST_SUCCESS": {
        return { ...state, offerUpdated: true };
      }
      case "ADD_UNIT_TO_NEW_SHORTLIST_REQUEST": {
        return { ...state, offerUpdated: false };
      }
      case "ADD_UNIT_TO_NEW_SHORTLIST_SUCCESS": {
        return { ...state, offerUpdated: true };
      }
      case "DELETE_SHORTLIST_REQUEST": {
        return { ...state };
      }
      case "DELETE_SHORTLIST_SUCCESS": {
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.payload),
          itemsCount: state.itemsCount > 0 ? state.itemsCount - 1 : null,
        };
      }
      case "DELETE_MULTIPLE_SHORTLIST_REQUEST": {
        return { ...state };
      }
      case "DELETE_MULTIPLE_SHORTLIST_SUCCESS": {
        return {
          ...state,
          items: state.items.filter(
            (item) => !action.payload.shortLists.includes(item.id)
          ),
        };
      }
      case "UPDATE_OFFER_CLIENTS_SUCCESS": {
        return {
          ...state,
          items: handleUpdateOfferData(state.items, action.payload),
        };
      }
      case "UPDATE_OFFER_REQUEST": {
        return {
          ...state,
          offerUpdating: true
        };
      }
      case "UPDATE_OFFER_SUCCESS": {
        return {
          ...state,
          items: handleUpdateOfferData(state.items, action.payload),
          offerUpdating: false
        };
      }
      case "UPDATE_OFFER_FAILED": {
        return {
          ...state,
          offerUpdating: false
        };
      }
      // OFFER ONLINE ACTIONS
      case "VERIFY_OFFER_ONLINE_ACCESS_REQUEST": {
        return { ...state, verifyingAccess: true, hasAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_SUCCESS": {
        const { hasAccess } = action.payload;
        return { ...state, hasAccess, verifyingAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_FAILED": {
        return { ...state, hasAccess: false, verifyingAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_RESET_REQUEST": {
        return { ...state, hasAccess: false, verifyingAccess: true };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_RESET_SUCCESS": {
        return { ...state, verifyingAccess: false };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_REQUEST": {
        return { ...state, requestingAccess: true };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_SUCCESS": {
        return { ...state, requestingAccess: false };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_FAILED": {
        return { ...state, requestingAccess: false };
      }
      // SHARE OFFER ONLINE
      case "SHARE_OFFER_ONLINE_REQUEST": {
        return { ...state, shareLoading: true, shared: false };
      }
      case "SHARE_OFFER_ONLINE_SUCCESS": {
        handleGoogleAnalyticsTracking(
          "Button",
          "Click",
          "Offers Online - Share"
        );
        return { ...state, shareLoading: false, shared: true };
      }
      case "SHARE_OFFER_ONLINE_FAILED": {
        return { ...state, shareLoading: false, shared: false };
      }
      // OFFERS BATCH - POST
      case "OFFERS_BATCH_POST_REQUEST": {
        return {
          ...state,
          offersBatchLoading: true,
          offersBatchSuccess: false,
        };
      }
      case "OFFERS_BATCH_POST_SUCCESS": {
        return {
          ...state,
          offersBatchLoading: false,
          offersBatchSuccess: true,
        };
      }
      case "OFFERS_BATCH_POST_FAILED": {
        return {
          ...state,
          offersBatchLoading: false,
          offersBatchSuccess: false,
        };
      }
      // OFFERS INDUSTRIAL BATCH - POST
      case "OFFERS_INDUSTRIAL_BATCH_POST_REQUEST": {
        return {
          ...state,
          offersIndustrialBatchLoading: true,
          offersIndustrialBatchSuccess: false,
        };
      }
      case "OFFERS_INDUSTRIAL_BATCH_POST_SUCCESS": {
        return {
          ...state,
          offersIndustrialBatchLoading: false,
          offersIndustrialBatchSuccess: true,
        };
      }
      case "OFFERS_INDUSTRIAL_BATCH_POST_FAILED": {
        return {
          ...state,
          offersIndustrialBatchLoading: false,
          offersIndustrialBatchSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOffersAll() {
  try {
    const offersAll = yield axios.get(`/offers/all/`);
    yield put({
      type: "FETCH_OFFERS_ALL_SUCCESS",
      payload: offersAll.data || [{ error: offersAll.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlists(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlists = yield axios.get(`/offers/${filters}`);
    yield put({
      type: "FETCH_SHORTLISTS_SUCCESS",
      payload: shortlists.data || [{ error: shortlists.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_SHORTLISTS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchShortlistsLandlord(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlists = yield axios.get(`/offers-landlord/${filters}`);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_SUCCESS",
      payload: shortlists.data || [{ error: shortlists.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchMoreShortlistsLandlord(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const shortlistsMore = yield axios.get(`/offers-landlord/${filters}`);
    yield put({
      type: "FETCH_MORE_SHORTLISTS_LANDLORD_SUCCESS",
      payload: shortlistsMore.data || [{ error: shortlistsMore.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_MORE_SHORTLISTS_LANDLORD_FAILED",
      payload: err.status,
      meta: actionNotification(
        "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* fetchShortlistPdf(action) {
  try {
    const shortlistID = action.payload;
    const shortlistPdf = yield axios.get(
      `/offers/${shortlistID}/get_presentation_data/`
    );
    yield put({
      type: "FETCH_SHORTLIST_PDF_SUCCESS",
      payload: shortlistPdf.data || [{ error: shortlistPdf.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnits(action) {
  try {
    const shortlistID = action.payload;
    const shortlistUnits = yield axios.get(`/offers/${shortlistID}/`);
    yield put({
      type: "FETCH_SHORTLIST_UNITS_SUCCESS",
      payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsLandlord(action) {
  try {
    const params = action.payload.params;
    const officeID = params.officeId || null;
    const shortlistUnits = yield axios.get(`/offers-landlord/${officeID}/`);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_UNITS_SUCCESS",
      payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistLandlordReset(action) {
  const initOrderBy = action.payload;
  const filtersReset = resetFilters(initOrderBy);
  try {
    const shortlistsLandlord = yield axios.get(
      `/offers-landlord/${filtersReset}`
    );
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_SUCCESS",
      payload: shortlistsLandlord.data || [
        { error: shortlistsLandlord.statusText },
      ],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsReset() {
  try {
    yield put({ type: "FETCH_SHORTLIST_UNITS_RESET_SUCCESS" });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsLandlordReset() {
  try {
    yield put({ type: "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_SUCCESS" });
  } catch (err) {
    console.log(err);
  }
}
function* fetchOfferBuildings(action) {
  try {
    const offerID = action.payload;
    const offerBuildings = yield axios.get(`/offers/${offerID}/batch_list`);
    yield put({
      type: "FETCH_OFFER_BUILDINGS_SUCCESS",
      payload: offerBuildings.data || [{ error: offerBuildings.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchOfferBuildingsIndustrial(action) {
  try {
    const offerID = action.payload;
    const offerBuildingsIndustrial = yield axios.get(
      `/offers-industrial/${offerID}/batch_list`
    );
    yield put({
      type: "FETCH_OFFER_BUILDINGS_INDUSTRIAL_SUCCESS",
      payload: offerBuildingsIndustrial.data || [
        { error: offerBuildingsIndustrial.statusText },
      ],
    });
  } catch (err) {
    console.log(err);
  }
}

// FETCH OFFERS ONLINE DATA
function* fetchOfferOnline(action) {
  const { offerID, email, market } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  const filterMarket = market ? `${email ? "&" : "?"}market=${market}` : "";
  const filter = `${authMail}${filterMarket}`;
  try {
    const offer = yield axios.get(`/offers/${offerID}/details/${filter}`);
    yield put({
      type: "FETCH_OFFER_ONLINE_SUCCESS",
      payload: offer.data || [{ error: offer.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineOffice(action) {
  const { offerID, officeID, email } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  try {
    const offerOffice = yield axios.get(
      `/offers/${offerID}/details/${officeID}/${authMail}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_SUCCESS",
      payload: offerOffice.data || [{ error: offerOffice.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later..",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineOfficeContact(action) {
  const { offerID, officeID, email } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  try {
    const offerContact = yield axios.get(
      `/offers/${offerID}/details/${officeID}/contact/${authMail}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_SUCCESS",
      payload: offerContact.data || [{ error: offerContact.statusText }],
    });
  } catch (err) {
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later..",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineBranding(action) {
  const { offerID } = action.payload;
  try {
    const offerBranding = yield axios.get(`/offers/${offerID}/branding/`);
    yield put({
      type: "FETCH_OFFER_ONLINE_BRANDING_SUCCESS",
      payload: offerBranding.data || [{ error: offerBranding.statusText }],
    });
  } catch (err) {
    yield put({
      type: "FETCH_OFFER_ONLINE_BRANDING_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineListPhotos(action) {
  const { offerID, email } = action.payload;
  try {
    const offerPhotos = yield axios.get(
      `/offers/${offerID}/get-main-photos/?email=${email}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_SUCCESS",
      payload: offerPhotos.data || [{ error: offerPhotos.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// FETCH OFFERS ONLINE ANALYTICS DATA
function* fetchOffersAnalytics() {
  try {
    const offersAnalytics = yield axios.get(`/offers/analytics/`);
    yield put({
      type: "FETCH_OFFERS_ONLINE_ANALYTICS_SUCCESS",
      payload: offersAnalytics.data || [{ error: offersAnalytics.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFERS_ONLINE_ANALYTICS_FAILED",
    });
  }
}
function* fetchOffersAnalyticsCharts(action) {
  try {
    const { year } = action.payload;
    const offersAnalyticsCharts = yield axios.get(`/offers/charts/${year}/`);
    yield put({
      type: "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_SUCCESS",
      payload: offersAnalyticsCharts.data || [
        { error: offersAnalyticsCharts.statusText },
      ],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_FAILED",
    });
  }
}
function* fetchOffersOfficeAnalytics(action) {
  try {
    const { offerID } = action.payload;
    const offerDetailsAnalytics = yield axios.get(
      `/offers/${offerID}/analytics/`
    );
    yield put({
      type: "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_SUCCESS",
      payload: offerDetailsAnalytics.data || [
        { error: offerDetailsAnalytics.statusText },
      ],
    });
  } catch (err) {
    console.log(err);
  }
}

// OFFER ACTION
function* addShortlist(action) {
  const { data } = action.payload;
  try {
    const newShortlist = yield axios.post(`/offers/`, data);
    yield put({
      type: "ADD_SHORTLIST_SUCCESS",
      payload: newShortlist.data,
      meta: actionNotification("Shortlist has been added.", "success"),
    });
  } catch (err) {
    yield put({
      type: "ADD_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name) ||
          "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* addUnitToExistingShortlist(action) {
  const { id, units } = action.payload;
  try {
    const unitToShortlist = yield axios.post(`/offers/${id}/add_unit/`, {
      units,
    });
    yield put({
      type: "ADD_UNIT_TO_EXISTING_SHORTLIST_SUCCESS",
      payload: unitToShortlist.data,
      meta: actionNotification(
        "Unit has been added to selected shortlist.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "ADD_UNIT_TO_EXISTING_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* addUnitToNewShortlist(action) {
  const { name, units, forTesting } = action.payload;
  try {
    const unitToShortlist = yield axios.post(`/offers/`, { name, units, forTesting });
    yield put({
      type: "ADD_UNIT_TO_NEW_SHORTLIST_SUCCESS",
      payload: unitToShortlist.data,
      meta: actionNotification(
        "Unit has been added to new shortlist.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "ADD_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name) ||
          "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* deleteShortlist(action) {
  const { shortlistId } = action.payload;
  try {
    yield axios.delete(`/offers/${shortlistId}/`);
    yield put({
      type: "DELETE_SHORTLIST_SUCCESS",
      payload: shortlistId,
      meta: actionNotification("Shortlist has been deleted.", "success"),
    });
  } catch (err) {
    console.log(err);
  }
}
function* deleteMultipleShortlist(action) {
  const shortLists = action.payload.shortLists;
  try {
    yield axios.delete("/offers/delete_many/", { data: shortLists });
    yield put({
      type: "DELETE_MULTIPLE_SHORTLIST_SUCCESS",
      payload: shortLists,
      meta: actionNotification(
        "Selected shortlists has been deleted.",
        "success"
      ),
    });
  } catch (err) {
    console.log(err);
  }
}
function* updateOfferClients(action) {
  const { offerID, data, notificationDisabled } = action.payload;
  try {
    const clients = yield axios.post(`/offers/${offerID}/give-access/`, data);
    yield put({
      type: "UPDATE_OFFER_CLIENTS_SUCCESS",
      payload: clients.data,
      meta: notificationDisabled
        ? null
        : actionNotification("Offer clients list has been updated.", "success"),
    });
  } catch (err) {
    yield put({
      type: "UPDATE_OFFER_CLIENTS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* shareOfferOnline(action) {
  try {
    const { data, offerID } = action.payload;
    const offerShare = yield axios.post(
      `/offers/${offerID}/send_offer_share/`,
      data
    );
    yield put({
      type: "SHARE_OFFER_ONLINE_SUCCESS",
      payload: offerShare.data,
    });
  } catch (err) {
    yield put({
      type: "SHARE_OFFER_ONLINE_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* updateOffer(action) {
  const { offerID, data } = action.payload;
  try {
    const clients = yield axios.patch(`/offers/${offerID}/`, data);
    yield put({
      type: "UPDATE_OFFER_SUCCESS",
      payload: clients.data,
      meta: actionNotification("Offer has been updated.", "success"),
    });
  } catch (err) {
    yield put({
      type: "UPDATE_OFFER_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER ONLINE ACTIONS
function* verifyOfferOnlineAccess(action) {
  try {
    const { offerID, email } = action.payload;
    const authMail = email ? `?email=${email}` : "";
    const offerVerifyAccess = yield axios.get(
      `/offers/${offerID}/verify/${authMail}`
    );
    const isNotAllowed =
      offerVerifyAccess &&
      offerVerifyAccess.data &&
      !offerVerifyAccess.data.hasAccess;
    yield put({
      type: "VERIFY_OFFER_ONLINE_ACCESS_SUCCESS",
      payload: offerVerifyAccess.data,
      meta:
        isNotAllowed &&
        actionNotification(
          "You are not allowed to view this Offer. You can request access to it by clicking link above.",
          "warning"
        ),
    });
  } catch (err) {
    yield put({
      type: "VERIFY_OFFER_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* requestOfferOnlineAccess(action) {
  try {
    const { offerID, email } = action.payload;
    const authMail = email ? `?email=${email}` : "";
    const offerRequestAccess = yield axios.get(
      `/offers/${offerID}/get-access/${authMail}`
    );
    yield put({
      type: "REQUEST_OFFER_ONLINE_ACCESS_SUCCESS",
      payload: offerRequestAccess.data,
      meta: actionNotification(
        "Your request for access to this Offer has been sent.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "REQUEST_OFFER_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER KUDOS SEND MAIL
function* sendKudosEmailConfirmation(action) {
  try {
    const { data } = action.payload;
    const kudosConfirm = yield axios.post(`/offers/send_kudos/`, data);
    yield put({
      type: "SEND_KUDOS_EMAIL_SUCCESS",
      payload: kudosConfirm.data,
    });
  } catch (err) {
    yield put({
      type: "SEND_KUDOS_EMAIL_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* createKudosEmailSignInLog(action) {
  try {
    const { data } = action.payload;
    const kudosLog = yield axios.post(
      `/offers/create_mail_log_in_redd_record/`,
      data
    );
    yield put({
      type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data,
    });
    window.location.href = "/";
  } catch (err) {
    yield put({
      type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* createRealTimeEmailSignInLog(action) {
  try {
    const { data } = action.payload;
    const kudosLog = yield axios.post(
      `/offers/create_mail_check_module_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
function* createRealTimeEmailWeeklySummaryLog(action) {
  try {
    const { data } = action.payload;
    const weeklySummaryLog = yield axios.post(
      `/offers/create_offer_weekly_summary_check_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_SUCCESS",
      payload: weeklySummaryLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER FIRST OPEN - BUTTON REDIRECT
function* createRealTimeEmailFirstOpenLog(action) {
  try {
    const { data } = action.payload;
    const firstOpenLog = yield axios.post(
      `/offers/create_offer_first_time_opened_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_SUCCESS",
      payload: firstOpenLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER SHARE - OFFER OPEN - BUTTON REDIRECT
function* createRealTimeEmailOfferOpenLog(action) {
  try {
    const { data } = action.payload;
    const offerOpenLog = yield axios.post(
      `/offers/create_offer_real_time_mail_check_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_SUCCESS",
      payload: offerOpenLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFERS BATCH - POST
function* postOffersBatch(action) {
  try {
    const { data } = action.payload;
    yield axios.post(`/offers/batch/`, data);
    yield put({
      type: "OFFERS_BATCH_POST_SUCCESS",
    });
  } catch (err) {
    console.log(err);

    yield put({
      type: "OFFERS_BATCH_POST_FAILED",
      payload: err.status,
      meta: actionNotification(
        "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFERS BATCH - POST
function* postOffersIndustrialBatch(action) {
  try {
    const { data } = action.payload;
    yield axios.post(`/offers-industrial/batch/`, data);
    yield put({
      type: "OFFERS_INDUSTRIAL_BATCH_POST_SUCCESS",
    });
  } catch (err) {
    console.log(err);

    yield put({
      type: "OFFERS_INDUSTRIAL_BATCH_POST_FAILED",
      payload: err.status,
      meta: actionNotification(
        "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

export function* saga() {
  // OFFER
  yield takeLatest("FETCH_OFFERS_ALL_REQUEST", fetchOffersAll);
  yield takeLatest("FETCH_SHORTLISTS_REQUEST", fetchShortlists);
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_REQUEST",
    fetchShortlistsLandlord
  );
  yield takeLatest(
    "FETCH_MORE_SHORTLISTS_LANDLORD_REQUEST",
    fetchMoreShortlistsLandlord
  );
  yield takeLatest("FETCH_SHORTLIST_PDF_REQUEST", fetchShortlistPdf);
  yield takeLatest("FETCH_SHORTLIST_UNITS_REQUEST", fetchShortlistUnits);
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST",
    fetchShortlistUnitsLandlord
  );
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST",
    fetchShortlistLandlordReset
  );
  yield takeLatest(
    "FETCH_SHORTLIST_UNITS_RESET_REQUEST",
    fetchShortlistUnitsReset
  );
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_REQUEST",
    fetchShortlistUnitsLandlordReset
  );
  yield takeLatest("FETCH_OFFER_BUILDINGS_REQUEST", fetchOfferBuildings);
  yield takeLatest(
    "FETCH_OFFER_BUILDINGS_INDUSTRIAL_REQUEST",
    fetchOfferBuildingsIndustrial
  );
  // OFFER ONLINE
  yield takeLatest("FETCH_OFFER_ONLINE_REQUEST", fetchOfferOnline);
  yield takeLatest("FETCH_OFFER_ONLINE_OFFICE_REQUEST", fetchOfferOnlineOffice);
  yield takeLatest(
    "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST",
    fetchOfferOnlineOfficeContact
  );
  yield takeLatest(
    "FETCH_OFFER_ONLINE_BRANDING_REQUEST",
    fetchOfferOnlineBranding
  );
  yield takeLatest(
    "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST",
    fetchOfferOnlineListPhotos
  );
  // OFFER ONLINE ANALYTICS
  yield takeLatest(
    "FETCH_OFFERS_ONLINE_ANALYTICS_REQUEST",
    fetchOffersAnalytics
  );
  yield takeLatest(
    "FETCH_OFFERS_ONLINE_ANALYTICS_CHARTS_REQUEST",
    fetchOffersAnalyticsCharts
  );
  yield takeLatest(
    "FETCH_OFFERS_ONLINE_OFFICE_ANALYTICS_REQUEST",
    fetchOffersOfficeAnalytics
  );
  // OFFER ACTIONS
  yield takeLatest("ADD_SHORTLIST_REQUEST", addShortlist);
  yield takeLatest(
    "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST",
    addUnitToExistingShortlist
  );
  yield takeLatest("ADD_UNIT_TO_NEW_SHORTLIST_REQUEST", addUnitToNewShortlist);
  yield takeLatest("DELETE_SHORTLIST_REQUEST", deleteShortlist);
  yield takeLatest(
    "DELETE_MULTIPLE_SHORTLIST_REQUEST",
    deleteMultipleShortlist
  );
  yield takeLatest("UPDATE_OFFER_CLIENTS_REQUEST", updateOfferClients);
  yield takeLatest("UPDATE_OFFER_REQUEST", updateOffer);
  yield takeLatest("SHARE_OFFER_ONLINE_REQUEST", shareOfferOnline);
  // OFFER ONLINE ACTIONS
  yield takeLatest(
    "VERIFY_OFFER_ONLINE_ACCESS_REQUEST",
    verifyOfferOnlineAccess
  );
  yield takeLatest(
    "REQUEST_OFFER_ONLINE_ACCESS_REQUEST",
    requestOfferOnlineAccess
  );
  // OFFER KUDOS SEND MAIL
  yield takeLatest("SEND_KUDOS_EMAIL_REQUEST", sendKudosEmailConfirmation);
  yield takeLatest(
    "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_REQUEST",
    createKudosEmailSignInLog
  );
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST",
    createRealTimeEmailSignInLog
  );
  // OFFER WEEKLY SUMMARY - BUTTON REDIRECT
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST",
    createRealTimeEmailWeeklySummaryLog
  );
  // OFFER FIRST OPEN - BUTTON REDIRECT
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_REQUEST",
    createRealTimeEmailFirstOpenLog
  );
  // OFFER FIRST OPEN - BUTTON REDIRECT
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_REQUEST",
    createRealTimeEmailOfferOpenLog
  );
  // OFFERS BATCH - POST
  yield takeLatest("OFFERS_BATCH_POST_REQUEST", postOffersBatch);
  // OFFERS INDUSTRIAL BATCH - POST
  yield takeLatest(
    "OFFERS_INDUSTRIAL_BATCH_POST_REQUEST",
    postOffersIndustrialBatch
  );
}
