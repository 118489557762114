import React from "react";
import styles from "./index.module.scss";
import {Portlet, PortletBody, PortletHeader} from "../../content/Portlet";
import ButtonCustom from "../ButtonCustom";
import {Typography} from "@material-ui/core";
import IpiHandler from "./IpiHandler";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchIndustrialParkUnits,
  fetchIndustrialParkUnitsIPIEditMode,
  fetchIndustrialWarehouseUnits,
  fetchIndustrialWarehouseUnitsIPIEditMode,
  updateIndustrialUnitsIPI,
  updateIndustrialWarehouseUnit
} from "app/crud/estates/industrial/industrial.crud";
import ModalCustom from "app/partials/components/ModalCustom";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import _ from "lodash";
import UploadIpiPlan from "./components/UploadIpiPlan";
import IPI_PLAN_SOURCE from "../../../constants/IPI_PLAN_SOURCE";
import SelectActiveIpiUnit from "./components/SelectActiveIpiUnit";
import LoadingScreen from "../LoadingScreen";

const initialShape = [
  {x: 75, y: 75},
  {x: 125.1, y: 75},
  {x: 125, y: 125},
  {x: 75, y: 125}
];

class IPI extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.imageRef = React.createRef();
    this.state = {
      reset: false,
      svg: null,
      polygons: [],
      initialPolygons: null,
      scaleParams: this.props.unitsPolygons?.map((item) => item?.scale),
      scaledPoints: null,
      editMode: false,
      editModeUnitsList: null,
      activeUnitID: props?.unitId,
      sizeModeOrigin: false,
      ipiHasBeenChanged: false,
      units: props?.units
    };
  }

  componentDidMount() {
    const {
      parkUnitsLoading,
      fetchIndustrialParkUnits,
      warehouseUnitsLoading,
      fetchIndustrialWarehouseUnits,
      parkID,
      buildingID,
      planWorkspace,
      fetchIndustrialParkUnitsIPIEditMode,
      fetchIndustrialWarehouseUnitsIPIEditMode,
      isEditable,
      units
    } = this.props;

    // Handle Fetch Workspace Units
    if (!units && planWorkspace === IPI_PLAN_SOURCE.PARK && !parkUnitsLoading) {
      fetchIndustrialParkUnits && parkID && fetchIndustrialParkUnits(parkID);
    } else if (!units && planWorkspace === IPI_PLAN_SOURCE.WAREHOUSE && !warehouseUnitsLoading) {
      fetchIndustrialWarehouseUnits && buildingID && fetchIndustrialWarehouseUnits(buildingID);
    }

    // Fetch Edit Mode Units List
    if (isEditable) {
      if (planWorkspace === IPI_PLAN_SOURCE.PARK && parkID) {
        fetchIndustrialParkUnitsIPIEditMode && fetchIndustrialParkUnitsIPIEditMode(parkID);
      } else if (planWorkspace === IPI_PLAN_SOURCE.WAREHOUSE && buildingID) {
        fetchIndustrialWarehouseUnitsIPIEditMode && fetchIndustrialWarehouseUnitsIPIEditMode(buildingID);
      }
    }

    this.setState({
      units
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      parkUnits,
      warehouseUnits,
      planWorkspace,
      units
    } = this.props;

    // Set Initial Polygons
    const unitsDynamic = units ? units : planWorkspace === IPI_PLAN_SOURCE.PARK ? parkUnits : planWorkspace === IPI_PLAN_SOURCE.WAREHOUSE && warehouseUnits;
    const unitsPolygons = unitsDynamic?.filter(item => item?.[planWorkspace]).map((item) => {
      return {
        unitId: item?.id,
        points: item?.[planWorkspace]?.points
      }
    });
    const initialPolygons = [
      ...unitsPolygons ? unitsPolygons : []
    ];

    if (!this.state.initialPolygons && !_.isEqual(prevState.polygons, initialPolygons)) {
      this.setState({
        polygons: initialPolygons,
        initialPolygons
      });
    }
    if (this.state.initialPolygons && this.state.polygons && !_.isEqual(this.state.polygons, initialPolygons) && !prevState.ipiHasBeenChanged) {
      this.setState({
        ipiHasBeenChanged: true
      }, () => this.props.onChange && this.props.onChange(true));
    }

    // Handle Save IPI Changes
    if (!prevProps.savingIPI && this.props.savingIPI && prevProps.savingIPI !== this.props.savingIPI) {
      this.handleSaveIPI();
      this.props.onSavedIPI && this.props.onSavedIPI();
    }
  }

  updatePoints = (polygons) => {
    this.setState((s) => ({...s, polygons}));
  };
  handleResetIPI = () => {
    this.setState((s) => ({...s, polygons: this.state.initialPolygons}));
  };
  handleSaveIPI = () => {
    const {updateIndustrialUnitsIPI, planWorkspace} = this.props;
    const unitsPolygons = this.state.initialPolygons;

    this.setState({
      initialPolygons: this.state.polygons
    }, () => {
      const primaryArray = unitsPolygons.length > this.state.polygons.length ? unitsPolygons : this.state.polygons;
      const secondaryArray = unitsPolygons.length > this.state.polygons.length ? this.state.polygons : unitsPolygons;
      const changedUnits = !_.isEmpty(unitsPolygons) && !_.isEmpty(this.state.polygons) ? _.difference(primaryArray, secondaryArray) : this.state.polygons;

      if (changedUnits) {
        const data = changedUnits.filter(unit => !_.isEqual(unit?.points, initialShape)).map(unit => {
          return {
            id: unit?.unitId,
            [planWorkspace]: unit?.points ? unit : null
          };
        })

        updateIndustrialUnitsIPI && updateIndustrialUnitsIPI(data);
      }
    });
  };
  handleDeleteIPI = () => {
    const {activeUnitID} = this.state;
    const updatedPolygons = this.state.polygons?.map(polygon => {
      if (polygon?.unitId === activeUnitID) {
        return {
          unitId: polygon?.unitId,
          points: null
        }
      }
      return polygon;
    });
    this.setState({
      polygons: updatedPolygons
    });
  };

  handleToggleEditMode = () => {
    this.setState({
      editMode: !this.state.editMode
    })
  }

  handleUpdateActiveUnitID = (id) => {
    const {planWorkspace, parkUnits, warehouseUnits, title, onActiveUnitChanged, units} = this.props;
    const isWorkspacePark = planWorkspace === IPI_PLAN_SOURCE.PARK;
    const isWorkspaceWarehouse = planWorkspace === IPI_PLAN_SOURCE.WAREHOUSE;
    const unitsDynamic = units ? units : isWorkspacePark ? parkUnits : isWorkspaceWarehouse && warehouseUnits;

    const parkName = title.split("|")[0];
    const newActiveUnitData = unitsDynamic.filter(unit => unit?.id === id)?.[0];
    const newTitle = [
      ...parkName ? [parkName] : [],
      ...newActiveUnitData?.warehouseName ? [newActiveUnitData?.warehouseName] : [],
      ...newActiveUnitData?.area ? [`${newActiveUnitData?.area} m.kw.`] : []
    ].join(" | ");
    this.setState({
      activeUnitID: id
    }, () => onActiveUnitChanged && onActiveUnitChanged(newTitle));
  }
  handleActiveUnitEditModeChange = (unitId) => {
    const {polygons} = this.state;

    const newPolygonPoints = _.isEmpty(_.find(polygons, (unit) => unit?.unitId === unitId)) ? initialShape : null;
    const newPolygon = newPolygonPoints ? [{unitId, points: newPolygonPoints}] : null;
    const updatedPolygons = [
      ...polygons ? polygons : [],
      ...newPolygon ? newPolygon : []
    ];

    this.setState({
      polygons: updatedPolygons
    }, () => {
      this.handleUpdateActiveUnitID(unitId);
    });
  }
  handleToggleSizeMode = () => {
    this.setState({
      sizeModeOrigin: !this.state.sizeModeOrigin
    });
  }

  render() {
    const {
      title,
      image,
      planSource,
      parkID,
      buildingID,
      planWorkspace,
      parkUnitsEditMode,
      warehouseUnitsEditMode,
      parkUnits,
      warehouseUnits,
      parkUnitsLoading,
      warehouseUnitsLoading,
      units,
      isEditable
    } = this.props;
    const {polygons, scaleParams, editMode, activeUnitID, sizeModeOrigin} = this.state;
    const isWorkspacePark = planWorkspace === IPI_PLAN_SOURCE.PARK;
    const isWorkspaceWarehouse = planWorkspace === IPI_PLAN_SOURCE.WAREHOUSE;
    const subtitle = isWorkspaceWarehouse ?
      <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_PLAN_WORKSPACE_WAREHOUSE"/> : isWorkspacePark ?
        <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_PLAN_WORKSPACE_PARK"/> : null;
    const unitsDynamic = units ? units : isWorkspacePark ? parkUnits : isWorkspaceWarehouse && warehouseUnits;
    const unitsLoading = parkUnitsLoading || warehouseUnitsLoading;

    return (
      <Portlet fluidHeight>
        <PortletHeader
          title={
            <div className={styles.ipiTitleWrapper}>
              <Typography variant="h3">{title}</Typography>
              {subtitle && editMode && <Typography variant="h4">({subtitle})</Typography>}
            </div>
          }
          toolbar={
            <div className={styles.toolbarWrapper}>
              {!editMode &&
                <ButtonCustom
                  label={sizeModeOrigin ? <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_MODE_SIZE_ORIGIN"/> :
                    <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_MODE_SIZE_FIT"/>}
                  icon="cached"
                  color="neutral"
                  onClick={() => this.handleToggleSizeMode()}
                />
              }
              {isEditable &&
              <>
                {editMode && (
                  <>
                    {activeUnitID &&
                      <ButtonCustom
                        icon="clear"
                        label={
                          <FormattedMessage id="INDUSTRIAL.PHRASE.DELETE_UNIT_IPI"/>
                        }
                        color="default"
                        onClick={this.handleDeleteIPI}
                      />
                    }
                    {planWorkspace !== planSource &&
                      <UploadIpiPlan
                        planTarget={planSource}
                        planTargetID={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? buildingID : planSource === IPI_PLAN_SOURCE.PARK && parkID}
                        btnLabel={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? <FormattedMessage
                          id="INDUSTRIAL.PHRASE.UPLOAD_IPI_WAREHOUSE_PLAN"/> : planSource === IPI_PLAN_SOURCE.PARK &&
                          <FormattedMessage id="INDUSTRIAL.PHRASE.UPLOAD_IPI_PARK_PLAN"/>}
                      />
                    }
                    <UploadIpiPlan
                      planTarget={planWorkspace}
                      planTargetID={isWorkspaceWarehouse ? buildingID : isWorkspacePark && parkID}
                      planSource={planSource}
                      planSourceID={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? buildingID : planSource === IPI_PLAN_SOURCE.PARK && parkID}
                      btnLabel={isWorkspaceWarehouse ? <FormattedMessage
                        id="INDUSTRIAL.PHRASE.REPLACE_IPI_WAREHOUSE_PLAN"/> : isWorkspacePark &&
                        <FormattedMessage id="INDUSTRIAL.PHRASE.REPLACE_IPI_PARK_PLAN"/>}
                      btnColor="warning"
                      btnIcon="edit"
                    />
                    <SelectActiveIpiUnit
                      data={isWorkspacePark ? parkUnitsEditMode : isWorkspaceWarehouse && warehouseUnitsEditMode}
                      selected={activeUnitID}
                      onChange={this.handleActiveUnitEditModeChange}
                    />
                  </>
                )}
                <ButtonCustom
                  label={editMode ? <FormattedMessage id="GENERAL.PHRASE.PREVIEW_MODE"/> :
                    <FormattedMessage id="GENERAL.PHRASE.EDIT_MODE"/>}
                  color={editMode ? "success" : "danger"}
                  onClick={() => this.handleToggleEditMode()}
                />
              </>
              }
            </div>
          }
        />
        <PortletBody fit>
          <div className={styles.ipiParentContainer}>
            {editMode &&
              <div className={styles.ipiInfoWrapper}>
                <ModalCustom
                  ref={this.child}
                  btn={
                    <ModalTrigger
                      label={
                        <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI"/>
                      }
                      icon="help_outline"
                      color="danger"
                      directIcon
                      iconSizeInherit
                      style={{fontSize: "3rem"}}
                      tooltipPosition="right"
                    />
                  }
                  btnConfirm={
                    <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_CLOSE"/>
                  }
                  title={
                    <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_TITLE"/>
                  }
                >
                  <ul>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_MOVE_POLYGON"/>
                    </li>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_MOVE_DOT"/>
                    </li>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_ADD_DOT"/>
                    </li>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_REMOVE_DOT"/>
                    </li>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_DELETE"/>
                    </li>
                    <li>
                      <FormattedMessage id="INDUSTRIAL.PHRASE.EXPLAIN_UNIT_IPI_SAVE"/>
                    </li>
                  </ul>
                </ModalCustom>
              </div>
            }
            {unitsLoading ? <LoadingScreen/> :
              <IpiHandler
                imageRef={this.imageRef}
                image={image}
                scaleParams={scaleParams}
                editMode={editMode}
                polygons={polygons}
                units={unitsDynamic}
                unitId={activeUnitID}
                sizeModeOrigin={sizeModeOrigin}
                updatePoints={this.updatePoints}
                onReset={this.handleResetIPI}
                onActiveUnitChange={this.handleUpdateActiveUnitID}
              />
            }
          </div>
        </PortletBody>
      </Portlet>
    );
  }
}

const mapStateToProps = store => ({
  parkUnits: store.industrial.parkUnits,
  parkUnitsLoading: store.industrial.parkUnitsLoading,
  warehouseUnits: store.industrial.warehouseUnits,
  warehouseUnitsLoading: store.industrial.warehouseUnitsLoading,
  parkUnitsEditMode: store.industrial.ipiParkUnitsEditMode,
  warehouseUnitsEditMode: store.industrial.ipiWarehouseUnitsEditMode
});
const mapDispatchToProps = {
  fetchIndustrialParkUnits: (parkID) => fetchIndustrialParkUnits(parkID),
  fetchIndustrialWarehouseUnits: (warehouseID) => fetchIndustrialWarehouseUnits(warehouseID),
  fetchIndustrialParkUnitsIPIEditMode: (parkID) => fetchIndustrialParkUnitsIPIEditMode(parkID),
  fetchIndustrialWarehouseUnitsIPIEditMode: (warehouseID) => fetchIndustrialWarehouseUnitsIPIEditMode(warehouseID),
  updateIndustrialWarehouseUnit: (unitId, data) => updateIndustrialWarehouseUnit(unitId, data),
  updateIndustrialUnitsIPI: (data) => updateIndustrialUnitsIPI(data)
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPI));
