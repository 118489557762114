import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "../../../ButtonCustom";
import LoadingScreen from "../../../LoadingScreen";
import {setTeamMemberAsOwner} from "../../../../../crud/team.crud";

class EditTeamMemberAsOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOwner: props?.data?.isOwner
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      isOwner: nextProps?.data?.isOwner
    });
  }
  // Handlers
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSave = () => {
    const {setTeamMemberAsOwner, data} = this.props;
    const {isOwner} = this.state;
    const memberId = data?.id;

    if(memberId) {
      this.setState({
        isOwner: !isOwner
      }, () =>
        setTeamMemberAsOwner({memberId})
      );
    }
  };

  render() {
    const {updatingTeamMember} = this.props;
    const {isOwner} = this.state;

    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{height: "100%"}}>
        {isOwner ?
        <Grid item style={{textAlign: "center"}}>
          <Typography variant="h3">
            <FormattedMessage id="TEAM.PHRASE.User is a Team Owner"/><br/>
          </Typography>
        </Grid> :
        <Grid item style={{textAlign: "center"}}>
          <Typography variant="h3" style={{marginBottom: "2rem"}}>
            <FormattedMessage id="TEAM.PHRASE.SET_AS_TEAM_OWNER_MESSAGE"/><br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
          {updatingTeamMember ? <LoadingScreen/> :
          <ButtonCustom
            label={<FormattedMessage id="GENERAL.PHRASE.SET"/>}
            color="danger"
            solid
            onClick={() => this.handleSave()}
          />
          }
        </Grid>
        }
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  updatingTeamMember: store.team.updatingTeamMember
});
const mapDispatchToProps = {
  setTeamMemberAsOwner: memberId => setTeamMemberAsOwner(memberId)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditTeamMemberAsOwner)
);