import React from "react";
import PropertyHeaderTitle from "app/pages/common/components/PropertyPage/PropertyHeaderTitle";
import {injectIntl} from "react-intl";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import PropertyHeaderMain from "app/pages/common/components/PropertyPage/PropertyHeaderMain";
import PropertyHeaderNav from "app/pages/common/components/PropertyPage/PropertyHeaderNav";
import LoadingScreen from "app/partials/components/LoadingScreen";
import _ from "lodash";
import SimpleMap from "app/partials/components/SimpleMap";
import EditModal from "app/partials/components/EditModal";
import ReactVisibilitySensor from "react-visibility-sensor";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import cn from "classnames";
import ParkAddToOffer from "../../../components/ParkAddToOffer";
import LeadsManagementAddProperty from "../../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import IsAdmin from "../../../../../utils/IsAdmin";

class PropertyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      initOwner: false,
      initContacts: false,
      initPhotos: false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      fetchIndustrialParkOwner,
      fetchIndustrialParkLeasingContacts,
      fetchIndustrialParkPhotos,
      ownerLoading,
      leasingContactsLoading,
      photosLoading,
      match
    } = this.props;
    const {initLoaded, initOwner, initContacts, initPhotos} = this.state;
    const {id} = match?.params;

    if(initLoaded && !initOwner && !ownerLoading) {
      this.setState({
        initOwner: true
      }, () => fetchIndustrialParkOwner(id));
    }
    if(initLoaded && initOwner && !initContacts && !leasingContactsLoading) {
      this.setState({
        initContacts: true
      }, () => fetchIndustrialParkLeasingContacts(id));
    }
    if(initLoaded && initOwner && initContacts && !initPhotos && !photosLoading && fetchIndustrialParkPhotos) {
      this.setState({
        initPhotos: true
      }, () => fetchIndustrialParkPhotos(id));
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialParkBasic,
      basic,
      basicLoading,
      resetting,
      match,
      callReddClickEvent
    } = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !basicLoading && _.isEmpty(basic) && id && !resetting) {
      if(id && callReddClickEvent) {
        callReddClickEvent(
          "industrial-park-detail",
          "park",
          null,
          id
        );
      }
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialParkBasic(id));
    }
  }

  render() {
    const {
      basic,
      basicLoading,
      onScrollToSection,
      owner,
      leasingContacts,
      photos,
      userWatchlist,
      isStaff,
    } = this.props;
    const propertyLocation = [
      ...basic?.addressCity ? [basic?.addressCity] : [],
      ...basic?.addressStreet ? [basic?.addressStreet] : []
    ].join(", ");
    const isParkEditable = basic?.isEditable;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <div className={cn(
          styles.propertyHeaderContainer,
          styles.industrialHeight
        )}>
          {
            !basicLoading && basic ?
              <>
              <PropertyHeaderTitle
                title={basic?.name}
                location={propertyLocation}
                titleActions={
                <>
                  {(basic?.isEditable || basic?.ipiEditable) &&
                  <EditModal
                    target="park"
                    parentData={basic}
                    isPropertyEditable={isParkEditable}
                    {...this.props}
                  />
                  }
                </>
                }
                toolbar={
                <>
                  <IsAdmin>
                    <LeadsManagementAddProperty propertyID={basic?.id} btnListMode tooltip="Admin Only"/>
                  </IsAdmin>
                  <ParkAddToOffer
                    parkId={basic?.id}
                    isAddToNewOfferEnable={basic?.offerEnable}
                    isAddToExistingOfferEnable={basic?.offerEnable}
                  />
                </>
                }
                locationActions={
                <SimpleMap lat={basic?.geoLatitude} lng={basic?.geoLongitude} propertyID={basic?.id} />
                }
              />
              <PropertyHeaderMain
                data={basic}
                owner={owner}
                contacts={leasingContacts}
                images={photos}
                userWatchlist={userWatchlist}
                isStaff={isStaff}
                isEditable={basic?.isEditable}
              />
              <PropertyHeaderNav
                propertyID={basic?.id}
                activeItem={0}
                onScrollTo={onScrollToSection}
              />
              </> :
              <LoadingScreen absolute/>
          }
        </div>
      </ReactVisibilitySensor>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeader)
);