import React from "react";
import TableMUI from "../../../../partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {handleTableCellAlign, toDateFormat} from "../../../../utils/helpers";
import styles from "app/pages/common/components/LeadsPages/index.module.scss";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import PropertySubscription from "app/pages/common/components/LeadsPages/components/PropertySubscription";
import NoData from "../../../../partials/components/NoData/NoData";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import {FormattedMessage, injectIntl} from "react-intl";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";

class CompaniesView extends React.Component {
  // Handlers
  handleTrackPropertyRedirect = (leadID, propertyID) => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    callReddClickEvent && callReddClickEvent(
      isOfficeView ? 'finne-leads-offices-visitor-property-click' : 'finne-leads-industrial-visitor-property-click',
      'pageview',
      null,
      leadID,
      {propertyID}
    );
  }
  handleTrackCompanyRedirect = (leadID) => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    callReddClickEvent && callReddClickEvent(
      isOfficeView ? 'finne-leads-offices-visitor-company-click' : 'finne-leads-offices-visitor-company-click',
      'pageview',
      null,
      leadID
    );
  }
  // Helpers
  companySizeTranslation = (companySize) => {
    if(companySize) {
      const onlySizeRange = companySize?.replace("employees", "").trim();
      return <FormattedMessage id="LEADS.PHRASE.EMPLOYEES" values={{value: onlySizeRange}}/>
    }
    return "-";
  }

  render() {
    const {
      team,
      companiesItems,
      companiesItemsPerPage,
      companiesItemsCount,
      companiesLoading,
      fetchMarketplaceLeadsCompanies,
      currentApplicationView
    } = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const tHead = [
        {id: 'company_name', numeric: false, disablePadding: false, label: 'FORM.FIELD.COMPANY', sort: false},
        {id: 'company_size', numeric: false, disablePadding: false, label: 'FORM.FIELD.COMPANY_SIZE', sort: false},
        {id: 'building_name', numeric: false, disablePadding: false, label: isOfficeView ? 'FORM.FIELD.BUILDING' : 'FORM.FIELD.PARK', sort: true},
        {id: 'company_industry', numeric: false, disablePadding: false, label: 'FORM.FIELD.INDUSTRY', sort: false},
        {id: 'created_at', numeric: false, disablePadding: false, label: 'FORM.FIELD.LAST_VISIT', sort: true}
    ];

    if(team) {
      if(companiesItems && companiesItems?.length > 0) {
        return(
          <TableMUI
            tHead={tHead}
            itemsPerPage={companiesItemsPerPage}
            totalItems={companiesItemsCount}
            loading={companiesLoading}
            initialOrderBy="created_at"
            initialRowsPerPage={20}
            handleFetchData={fetchMarketplaceLeadsCompanies}
            containerStyle={{maxHeight: "100%"}}
          >
            {companiesItems && Array.isArray(companiesItems) && companiesItems.map((item, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell align={handleTableCellAlign(tHead, 0)}>
                    <a
                      href={`${item?.companyWebsite}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className={styles.companyUrl}
                      onClick={() => this.handleTrackCompanyRedirect(item?.id)}
                    >
                      <IconColorMUI icon="open_in_new" style={{paddingRight: "5px"}}/>
                      {item?.companyName}
                    </a>
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 1)}>
                    {this.companySizeTranslation(item?.companySize)}
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 2)}>
                    <span className={styles.propertyNameWrapper}>
                      <PropertyCardPreview
                        propertyID={item?.buildingId}
                        marketID={item?.marketId}
                        route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                        routeNewTab
                      >
                        <span
                          className={styles.propertyName}
                          onClick={() => this.handleTrackPropertyRedirect(item?.id, item?.buildingId)}
                        >{item?.buildingName}</span>
                      </PropertyCardPreview>
                      <PropertySubscription subscription={item?.buildingSubscription}/>
                    </span>
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 3)}>
                    {item.companyIndustry}
                  </TableCell>
                  <TableCell align={handleTableCellAlign(tHead, 4)}>
                    <span style={{color: "#636363"}}>{toDateFormat(item?.createdAt, true)}</span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableMUI>
        );
      }
      else if((!companiesItems || companiesItems?.length <= 0) && companiesLoading) {
        return <LoadingScreen/>;
      }
      return(
        <NoData
          message={<FormattedMessage id="See the companies that are interested in your buildings"/>}
          styleMessage={{fontWeight: "600"}}
          extraMessage={<FormattedMessage id="Want to attract tenants without commission? Discover more opportunities with REDD and promote your properties on Finne."/>}
          action={
          <PaywallModal
            triggerLabel={<FormattedMessage id="LEADS.PHRASE.UNLOCK"/>}
            triggerButtonVariant={this.props.intl.formatMessage({id: "LEADS.PHRASE.UNLOCK"})}
          />
          }
        />
      );
    }
    return <span/>;
  }
}

export default injectIntl(CompaniesView);