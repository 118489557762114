import React from "react";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import {toNumberFormat, toTimeFromDate} from "../../../../../utils/helpers";
import {ReactComponent as IconArrows} from "app/media/icons/arrows_change.svg";
import cn from "classnames";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import NoData from "../../../../../partials/components/NoData/NoData";
import {FormattedMessage} from "react-intl";
import HighlightLabel from "../../../../../partials/components/HighlightLabel";
import IsPro from "../../../../../utils/IsPro";
import PaywallBannerSimple from "../../../../../partials/components/PayWall/banners/PaywallBannerSimple";
import PaywallBlurContent from "../../../../../partials/components/PayWall/components/PaywallBlurContent";

export default class PropertyChangesTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkChanges, changes, changesLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !changesLoading && _.isEmpty(changes) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialParkChanges(id));
    }
  }

  render() {
    const {changes, changesLoading, parentRef} = this.props;

    return(
      <div ref={parentRef} className={styles.changesContainer}>
        <PropertySectionTitle
          title={<FormattedMessage id="VOCABULARY.Leasing activity"/>}
        />
        <IsPro
          paywall
          paywallComponent={
          <PaywallBlurContent
            blurImage="media/placeholders/table-office-transactions-blur-placeholder.png"
            style={{height: "100%"}}
          >
            <PaywallBannerSimple/>
          </PaywallBlurContent>
          }
          overwriteRule={this.props?.basic?.isEditable}
        >
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
          >
            <div className={styles.changesTrackerWrapper}>
            {
              changesLoading ? <LoadingScreen/> :
                changes && changes?.length > 0 ?
                <table>
                  <thead>
                    <tr>
                      <th><FormattedMessage id="VOCABULARY.Area"/></th>
                      <th><FormattedMessage id="VOCABULARY.Status"/></th>
                      <th><FormattedMessage id="VOCABULARY.Date"/></th>
                    </tr>
                  </thead>
                  <tbody>
                    {changes?.map((item, index) => {
                      return(
                      <tr key={index}>
                        <td>
                          <span className={styles.cellWrapper}>
                            <IconArrows/>
                            <span className={styles.typeValue}>
                              {
                                item?.isOffice ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
                                  <FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/>
                              }
                            </span>
                            <span className={styles.value}>{toNumberFormat(item?.area, 'area')}</span>
                          </span>
                        </td>
                        <td>
                          <HighlightLabel
                            color={item?.status === "ADDED" ? "success" : "warning"}
                            style={{fontSize: "1.2rem"}}
                          >
                            <FormattedMessage id={`${item?.status}`}/>
                          </HighlightLabel>
                        </td>
                        <td>
                          <span className={styles.cellWrapper}>
                            <span className={cn(styles.value, styles.timeValue)}>{toTimeFromDate(item?.date)}</span>
                          </span>
                        </td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
                 :
                <NoData/>
            }
          </div>
          </ReactVisibilitySensor>
        </IsPro>
      </div>
    );
  }
}