import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import styles from "./index.module.scss";
import cx from "classnames";
import Tab1 from "app/partials/components/SharePropertyParticular/components/Tab1";
import ButtonCustom from "../ButtonCustom";
import {handleGoogleAnalyticsTracking,} from "../../../utils/helpers";

class SharePropertyParticular extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: "tab_1",
      copied: false,
    };
  }
  // Function to change/set new Active Tab
  handleChangeActiveTab = (event, newValue) => {
    this.setState({
      activeTab: newValue,
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  render() {
    const { target, onOpen, ppLink } = this.props;
    const { activeTab } = this.state;
    // Function to determine if given Tab is a Active Tab
    const isActiveTabBarItem = (tab) => {
      return tab === activeTab;
    };
    // Overwrite some base Tabs Bar Styles
    const tabsBarStyles = {
      marginBottom: 18,
      backgroundColor: "transparent",
      color: "#3a3a3a",
      boxShadow: "none",
    };

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="reply"
            color="success"
            label={
              <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SHARE_PP" />
            }
            directBtn
            iconReverse
            onClick={() =>
              handleGoogleAnalyticsTracking(
                "Button",
                "Click",
                "Share Property Particulars - Header"
              )
            }
          />
        }
        defaultThemeProvider
        btnRejectDisabled
        onOpen={onOpen}
      >
        <div className={styles.tabsWrapper}>
          <div className={styles.tabsBarWrapper}>
            <AppBar position="static" style={tabsBarStyles}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FF4626",
                  },
                }}
                value={activeTab}
                onChange={this.handleChangeActiveTab}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  value="tab_1"
                  className={cx(
                    styles.tabBarItem,
                    isActiveTabBarItem("tab_1") && styles.active
                  )}
                  label={
                    <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SHARE" />
                  }
                  target={target}
                  wrapped
                />
                {/*<Tab*/}
                {/*value="tab_2"*/}
                {/*className={cx(styles.tabBarItem, isActiveTabBarItem("tab_2") && styles.active)}*/}
                {/*label="Social"*/}
                {/*wrapped*/}
                {/*/>*/}
              </Tabs>
            </AppBar>
            <ButtonCustom
              label={<FormattedMessage
                id="PROPERTY_PARTICULARS.PHRASE.REDIRECT_TO_PP_ONLINE"/>}
              color="danger"
              redirect={ppLink}
              redirectTarget="_blank"
            />
          </div>
          {activeTab === "tab_1" && <Tab1 {...this.props} />}
        </div>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.data,
});
// const mapDispatchToProps = {
//   updateUnit: (unitId, data) => updateUnit(unitId, data)
// };

export default injectIntl(
  connect(mapStateToProps, null)(SharePropertyParticular)
);
