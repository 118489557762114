import React from "react";
import ConfirmationDialog from "app/partials/components/ConfirmationDialog";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {updateUserMarket} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

class RedirectPropertyMarket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmRedirectOpen: false
        }
    }

    handleConfirmOpen = () => {
        this.setState({
            confirmRedirectOpen: true
        });
    };
    handleConfirmClose = () => {
        this.setState({
            confirmRedirectOpen: false
        });
    };
    handleProceed = () => {
        const {updateUserMarket, marketID, propertyID, route} = this.props;
        updateUserMarket(marketID, `${route}/${propertyID}`);
        this.handleConfirmClose();
    };

    render() {
        const {propertyID, marketID, children, route, openNewTab} = this.props;
        const diffMarket = marketID !== this.props.userMarket;
        if (diffMarket && propertyID && marketID) {
            return (
                <>
                    <span onClick={() => this.handleConfirmOpen()} style={{cursor: "pointer"}}>{children}</span>
                    <ConfirmationDialog
                        open={this.state.confirmRedirectOpen}
                        onClose={() => this.handleConfirmClose()}
                        onConfirm={() => this.handleProceed()}
                        message={<FormattedMessage id="ACQUISITIONS.PHRASE.DIFF_MARKET_MESSAGE"/>}
                    />
                </>
            );
        } else if (propertyID) {
            return (
                <RouterLink to={propertyID ? `${route}/${propertyID}` : false} target={openNewTab ? "_blank" : undefined}>
                    {children}
                </RouterLink>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    userMarket: state.user.data.market
});
const mapDispatchToProps = dispatch => ({
    updateUserMarket: (market, redirect) => dispatch(updateUserMarket(market, redirect)),
});

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(RedirectPropertyMarket))
);