export default {
  BUILDING_DETAILS: {
    teamLandlord: '',
    addressStreet: '',
    neighborhood: '',
    market: '',
    buildingClass: '',
    buildingStatus: '',
    buildingType: '',
    certification: '',
    commissioningYearFrom: '',
    commissioningYearTo: ''
  },
  UNIT_DETAILS: {
    floorFrom: '',
    floorTo: '',
    publishDateFrom: '',
    publishDateTo: '',
    availableFrom: '',
    availableTo: '',
    unitType: '',
    status: '',
    is_sublease: ''
  },
  COWORKS_DETAILS: {
    hotDesks: '',
    privateDesks: '',
    amentities: '',
    availableFrom: ''
  },
  RENT_TERMS: {
    rentFrom: '',
    rentTo: '',
    minRentalYearsFrom: '',
    minRentalYearsTo: '',
    serviceChargeFrom: '',
    serviceChargeTo: '',
    commonAreaFactorFrom: '',
    commonAreaFactorTo: '',
    parkingFactorNumberFrom: '',
    parkingFactorNumberTo: '',
    parkingFactorAreaFrom: '',
    parkingFactorAreaTo: '',
    parkingGroundRentFrom: '',
    parkingGroundRentTo: '',
    parkingUndergroundRentFrom: '',
    parkingUndergroundRentTo: ''
  },
  AREA_FILTERS: {
    totalAreaAvailableFrom: '',
    totalAreaAvailableTo: '',
    totalAreaBruttoFrom: '',
    totalAreaBruttoTo: ''
  },
  PREMIUM_FILTERS: {
    vacancyRateFrom: '',
    vacancyRateTo: '',
    owners: ''
  },
  OFFICES_FILTERS: {
    market: '',
    district: '',
    areaFrom: '',
    areaTo: '',
    roomTypeFrom: '',
    roomTypeTo: '',
    roomPriceFrom: '',
    roomPriceTo: '',
    buildingName: '',
    ownerName: '',
    totalAreaFrom: '',
    totalAreaTo: '',
    totalVacancyFrom: '',
    totalVacancyTo: '',
    company: '',
    priceFrom: '',
    priceTo: '',
    transactionDateFrom: '',
    transactionDateTo: '',
    onlyPromoted: ''
  },
  INDUSTRIAL_FILTERS: {
    parkName: '',
    warehouseParkName: '',
    areaFrom: '',
    areaTo: '',
    isOffice: '',
    market: '',
    addressCity: '',
    docksFrom: '',
    docksTo: '',
    onlyPromoted: ''
  },
  INDUSTRIAL_BUILDING_DETAILS: {
    teamLandlord: '',
    buildingClass: '',
    buildingStatus: '',
    buildingType: '',
    certification: '',
    commissioningYearFrom: '',
    commissioningYearTo: '',
    vacancyRateFrom: '',
    vacancyRateTo: '',
    owners: ''
  },
  INDUSTRIAL_RENT_TERMS: {
    warehouseRentFrom: '',
    warehouseRentTo: '',
    officeRentFrom: '',
    officeRentTo: '',
    warehouseServiceChargeFrom: '',
    warehouseServiceChargeTo: '',
    officeServiceChargeFrom: '',
    officeServiceChargeTo: '',
    minRentalYearsFrom: '',
    minRentalYearsTo: '',
    minAreaForRentFrom: '',
    minAreaForRentTo: ''
  },
  INDUSTRIAL_TECHNICAL_DETAILS: {
    storageHeightFrom: '',
    storageHeightTo: '',
    floorLoadingCapacityFrom: '',
    floorLoadingCapacityTo: '',
    parkingTruckSlotsFrom: '',
    parkingTruckSlotsTo: '',
    parkingCarSlotsFrom: '',
    parkingCarSlotsTo: '',
    loadingDocksFrom: '',
    loadingDocksTo: '',
    columnGridFrom: '',
    columnGridTo: '',
    amenities: ''
  },
  ORDER: {
    order: '-',
    orderBy: ''
  },
  ROWS_PER_PAGE: {
    size: '',
  },
  OWNERS_FILTERS: {
    ownerName: '',
    totalAreaFrom: '',
    totalAreaTo: '',
    totalVacancyFrom: '',
    totalVacancyTo: ''
  },
  SYMMCO: {
    marketId: '',
    zone: '',
    netDeal: '',
    dealType: '',
    quater: ''
  },
  LATEST_TRANSACTIONS: {
    market: ''
  },
  SUPPLY_BOOST: {
    market: ''
  },
  LATEST_TRANSACTIONS_INDUSTRIAL: {
    market: ''
  },
  SUPPLY_BOOST_INDUSTRIAL: {
    market: ''
  }
};