import React from "react";
import PropertySectionTitle from "../../../common/components/PropertyPage/PropertySectionTitle";
import TableMUI from "../../../../partials/components/TableMUI";
import {Checkbox, Grid, TableCell, TableRow} from "@material-ui/core";
import {handleEmptyValue, handleTableCellAlign, toDateFormat} from "../../../../utils/helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import ReactVisibilitySensor from "react-visibility-sensor";
import NoData from "../../../../partials/components/NoData/NoData";
import {fetchIndustrialParkFiles,} from "app/crud/estates/industrial/industrialPark.crud";
import {fetchIndustrialWarehouseFiles} from "app/crud/estates/industrial/industrialWarehouse.crud";
import connect from "react-redux/es/connect/connect";
import IndustrialAddFile from "./IndustrialAddFile";
import INDUSTRIAL_FILES_FORMATS from "../../../../constants/INDUSTRIAL_FILES_FORMATS";
import {toAbsoluteUrl} from "../../../../../_metronic";
import {FILTERS_SELECTS} from "../../../../partials/components/FiltersDrawer/constants";
import IndustrialToggleFileGalleryStatus from "./IndustrialToggleFileGalleryStatus";
import IndustrialPreviewFile from "./IndustrialPreviewFile";
import IndustrialDownloadFiles from "./IndustrialDownloadFiles";
import IndustrialUpdateFile from "./IndustrialUpdateFile";
import IndustrialRemoveFile from "./IndustrialRemoveFile";

class IndustrialFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      selected: []
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialParkFiles,
      parkFilesLoading,
      fetchIndustrialWarehouseFiles,
      warehouseFilesLoading,
      basic,
      view
    } = this.props;
    const {initLoaded} = this.state;
    const isIndustrialWarehouseView = view === "industrialWarehouse";
    const id = basic?.id;

    setTimeout(() => {
      if (isIndustrialWarehouseView && isVisible && !initLoaded && !warehouseFilesLoading && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialWarehouseFiles(id));
      }
      else if (isVisible && !initLoaded && !parkFilesLoading && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkFiles(id));
      }
    }, 200);
  }
  handleFileFormat = (fileType) => {
    const selectedFormat = Object.entries(INDUSTRIAL_FILES_FORMATS).filter(item => item[1].id === fileType);
    const icon = selectedFormat && selectedFormat[0] && selectedFormat[0][1] && selectedFormat[0][1].icon;
    return toAbsoluteUrl(icon);
  };
  handleSelectRow = (event) => {
    const {selected} = this.state;
    const isChecked = event?.target?.checked;
    const value = Number(event?.target?.value);
    let selectedUpdated;

    if(isChecked) {
      selectedUpdated = [
        ...selected,
        value
      ];
    }
    else {
      selectedUpdated = selected.filter(item => item !== value);
    }
    this.setState({
      selected: selectedUpdated
    });
  }
  handleSelectAll = (tBody, checked) => {
    const selectedUpdated = checked ? tBody?.map(item => item?.id) : [];
    this.setState({
      selected: selectedUpdated
    });
  }
  // Helpers
  isSelected = (item) => this.state.selected?.indexOf(item) !== -1;

  render() {
    const {
      parentRef,
      basic,
      parkFiles,
      parkFilesLoading,
      warehouseFiles,
      warehouseFilesLoading,
      intl,
      view
    } = this.props;
    const {selected} = this.state;
    const isIndustrialParkView = view === "industrialPark";
    const isIndustrialWarehouseView = view === "industrialWarehouse";
    const isLandPL = intl?.locale === "pl";
    const tHead = [
      {id: 'name', numeric: false, disablePadding: false, label: 'VOCABULARY.File name', sort: false},
      {id: 'format', numeric: false, disablePadding: false, label: 'VOCABULARY.Format', sort: false},
      {id: 'category', numeric: false, disablePadding: false, label: 'VOCABULARY.Category', sort: false},
      {id: 'uploaded_at', numeric: false, disablePadding: false, label: 'VOCABULARY.Uploaded at', sort: false},
      {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];
    const tBody = isIndustrialWarehouseView ? (warehouseFiles?.length > 0 ? warehouseFiles : null) :
      parkFiles?.length > 0 ? parkFiles : null;
    const isPropertyEditable = basic?.isEditable;
    const propertyID = isIndustrialWarehouseView ? basic?.parkId : basic?.id;
    const propertyName = isIndustrialWarehouseView ? basic?.parkName : basic?.name;

    return (
      <>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
          intervalCheck={true}
          intervalDelay={1000}
        >
          <PropertySectionTitle
            parentRef={parentRef}
            title={<FormattedMessage id="VOCABULARY.Files"/>}
            titleActions={
              selected?.length > 0 && (
                <IndustrialDownloadFiles
                  parkID={propertyID}
                  parentData={{
                    parentName: basic?.name,
                  }}
                  selectedFiles={selected}
                  btnMultiselect
                />
              )
            }
            toolbar={
            basic?.isEditable &&
              <IndustrialAddFile
                propertyID={basic?.id}
                dataSource={view}
              />
            }
            style={{
              marginBottom: "1.6rem"
            }}
          />
        </ReactVisibilitySensor>
        <TableMUI
          tHead={tHead}
          loading={isIndustrialWarehouseView ? warehouseFilesLoading : parkFilesLoading}
          containerStyle={{minHeight: "32rem"}}
          totalItems={tBody?.length}
          noData={!tBody || tBody?.length <= 0}
          checkable={tBody?.length > 0}
          selected={selected}
          onSelectAll={(checked) => this.handleSelectAll(tBody, checked)}
          noDataMessage={
          isPropertyEditable ?
            <NoData
                absolute
                withIcon
                style={{paddingTop: "6.4rem"}}
                message={<FormattedMessage id="VOCABULARY.No files"/>}
                action={
                  basic?.isEditable &&
                  <IndustrialAddFile
                    propertyID={basic?.id}
                    dataSource={view}
                  />
                }
              /> :
              <NoData
                message={<FormattedMessage id="VOCABULARY.No files"/>}
                absolute
                withIcon
                style={{paddingTop: "6.4rem"}}
              />
          }
        >
          {tBody?.length > 0 && tBody?.map((file, index) => {
            const isGalleryIncludeAvailable = file?.fileType === INDUSTRIAL_FILES_FORMATS.TYPE_JPG.id || file?.fileType === INDUSTRIAL_FILES_FORMATS.TYPE_PNG.id;
            const isFilePreviewAvailable = file?.fileType === INDUSTRIAL_FILES_FORMATS.TYPE_PNG.id || file?.fileType === INDUSTRIAL_FILES_FORMATS.TYPE_JPG.id || file?.fileType === INDUSTRIAL_FILES_FORMATS.TYPE_PDF.id;
            const fileCategory = FILTERS_SELECTS.INDUSTRIAL_FILES_CATEGORIES.filter(cat => cat.id === file?.fileCategory)?.[0]?.label;

            return (
              <TableRow key={index}>
                <TableCell style={{width: "3.5rem", paddingRight: "0"}}>
                  <Checkbox
                    value={file?.id}
                    checked={this.isSelected(file?.id)}
                    inputProps={{"aria-labelledby": file?.id}}
                    onChange={this.handleSelectRow}
                  />
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 0)}>
                  {handleEmptyValue(isLandPL ? file?.name : file?.nameEn)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 1)}>
                  <img src={this.handleFileFormat(file?.fileType)} alt="File format"/>
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 2)}>
                  {handleEmptyValue(fileCategory)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 3)}>
                  {toDateFormat(file?.uploadedAt)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 4)}>
                  <Grid container justifyContent="flex-end">
                    {isGalleryIncludeAvailable && file?.isEditable &&
                    <Grid item>
                        <IndustrialToggleFileGalleryStatus
                            fileID={file?.id}
                            fileAssignedBuilding={file?.buildingName}
                            isGalleryIncluded={file?.isGalleryIncluded}
                            view="industrialPark"
                        />
                    </Grid>
                    }
                    {isFilePreviewAvailable && (
                    <Grid item>
                        <IndustrialPreviewFile fileUrl={file?.file} fileType={file?.fileType}/>
                    </Grid>
                    )}
                    <Grid item>
                        <IndustrialDownloadFiles
                            parkID={propertyID}
                            parentData={{
                                fileName: isLandPL ? file?.name : file?.nameEn,
                                parentName: propertyName
                            }}
                            selectedFiles={[file?.id]}
                            label={<FormattedMessage id="GENERAL.PHRASE.DOWNLOAD"/>}
                            directDownload
                        />
                    </Grid>
                    {basic?.isEditable &&
                    <Grid item>
                        <IndustrialUpdateFile
                            data={file}
                            dataSource={view}
                            fileID={file?.id}
                        />
                    </Grid>
                    }
                    {basic?.isEditable &&
                    <Grid item>
                       <IndustrialRemoveFile
                         fileID={file?.id}
                         parkID={isIndustrialParkView && basic?.id}
                         warehouseID={isIndustrialWarehouseView && basic?.id}
                       />
                    </Grid>
                    }
                </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableMUI>
      </>
    );
  }
}

const mapStateToProps = store => ({
  parkFiles: store.industrialPark.files,
  parkFilesLoading: store.industrialPark.filesLoading,
  warehouseFiles: store.industrialWarehouse.files,
  warehouseFilesLoading: store.industrialWarehouse.filesLoading
});
const mapDispatchToProps = {
  fetchIndustrialParkFiles: (parkID) => fetchIndustrialParkFiles(parkID),
  fetchIndustrialWarehouseFiles: (parkID) => fetchIndustrialWarehouseFiles(parkID)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialFiles)
);