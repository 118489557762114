// FETCH DATA
export const fetchPropertyParticulars = (teamID, email, market) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      teamID,
      email,
      market
  },
});
export const fetchPropertyParticularsOffice = (teamID, officeID, email) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_OFFICE_REQUEST',
  payload: {
      teamID,
      officeID,
      email
  },
});
export const fetchPropertyParticularsOfficeContact = (teamID, officeID, email) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_REQUEST',
  payload: {
      teamID,
      officeID,
      email
  },
});
export const fetchPropertyParticularsBranding = (teamID) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_BRANDING_REQUEST',
  payload: {
      teamID
  },
});
export const fetchPropertyParticularsListPhotos = (teamID, email) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const fetchPropertyParticularsOffices = (teamID) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_OFFICES_REQUEST',
  payload: {
      teamID
  }
});
export const fetchPropertyParticularsAnalytics = (teamID) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_REQUEST',
  payload: teamID
});
export const fetchPropertyParticularsAnalyticsCharts = (teamID, year) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_REQUEST',
  payload: {
    teamID,
    year
  }
});
export const fetchPropertyParticularsAnalyticsDetails = (teamID, officeID) => ({
  type: 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_REQUEST',
  payload: {
    teamID,
    officeID
  }
});
// USER ACTIONS
export const verifyPropertyParticularsAccess = (teamID, email) => ({
  type: 'VERIFY_PROPERTY_PARTICULARS_ACCESS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const verifyPropertyParticularsAccessReset = () => ({
  type: 'VERIFY_PROPERTY_PARTICULARS_ACCESS_RESET_REQUEST'
});
export const requestPropertyParticularsAccess = (teamID, email) => ({
  type: 'REQUEST_PROPERTY_PARTICULARS_ACCESS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const sharePropertyParticulars = (data, teamID) => ({
  type: 'SHARE_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      data,
      teamID
  },
});
export const sharePropertyParticularsRealTimeLog = (data) => ({
  type: 'SHARE_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST',
  payload: data,
});
export const shareUpdatedPropertyParticulars = (teamID) => ({
  type: 'SHARE_UPDATED_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      teamID
  },
});
export const shareUpdatedPropertyParticularsRealTimeLog = (data) => ({
  type: 'SHARE_UPDATED_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST',
  payload: data,
});
export const setPropertyParticularsOfficesAsVisible = (teamID, data) => ({
  type: 'SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_REQUEST',
  payload: {
      teamID,
      data
  }
});

// RESET OFFICES PROPERTY PARTICULARS LOADING STATUS
export const resetOfficePropertyParticularsLoadingStates = () => ({
  type: 'RESET_OFFICE_PROPERTY_PARTICULARS_LOADING_STATES_REQUEST'
});