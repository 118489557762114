import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse, handleGoogleAnalyticsTracking} from "../../../utils/helpers";
import _ from "lodash";

const initialState = {
  isUser: false,
  isSubscriber: false,
  isNotAllowed: false,
  isNotPro: false,
  ppItems: null,
  ppOffice: null,
  ppContact: null,
  ppBranding: null,
  ppPhotos: null,
  verifyingAccess: false,
  requestingAccess: false,
  loadingItems: false,
  loadingOffice: false,
  loadingBranding: false,
  loadingContact: false,
  loadingPhotos: false,
  shared: false,
  shareLoading: false,
  sharingUpdated: false,
  sharedUpdated: false,
  ppOffices: null,
  ppOfficesLoading: false,
  ppVisibleOffices: null,
  ppVisibleOfficesLoading: false,
  ppAnalytics: null,
  ppAnalyticsLoading: false,
  ppAnalyticsCharts: null,
  loadingAnalyticsCharts: false,
  ppAnalyticsDetails: null,
  ppAnalyticsDetailsLoading: false
};
const handleToAllowed = (isSubscriber, isUser) => {
    return !isUser && !isSubscriber;
};
const handlePPUpdate = (originPP, newPPUpdate) => {
  _.update(originPP, 'ppUpdate', () => newPPUpdate);
  return originPP;
}

export const reducer = persistReducer(
  { storage, key: "ppOnlineOffices" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICE PROPERTY PARTICULARS LOADING STATES
      case 'RESET_OFFICE_PROPERTY_PARTICULARS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loadingItems: false,
          loadingOffice: false,
          loadingBranding: false,
          loadingContact: false,
          loadingPhotos: false,
          shareLoading: false,
          ppOfficesLoading: false,
          ppVisibleOfficesLoading: false,
          ppAnalyticsLoading: false,
          loadingAnalyticsCharts: false,
          ppAnalyticsDetailsLoading: false
        };
      }
      //
      case 'FETCH_PROPERTY_PARTICULARS_REQUEST': {
        return { ...state, loadingItems: true, ppItems: null, isNotPro: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_SUCCESS': {
        return { ...state, ppItems: action.payload, loadingItems: false, isNotPro: false, ppPhotos: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_FAILED': {
        return { ...state, ppItems: null, loadingItems: false, isNotPro: action.payload === 403 };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_REQUEST': {
        return { ...state, loadingOffice: true, ppOffice: null, isNotPro: false, ppContact: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_SUCCESS': {
        return { ...state, ppOffice: action.payload, loadingOffice: false, isNotPro: false, ppContact: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_FAILED': {
        return { ...state, ppOffice: null, loadingOffice: false, isNotPro: action.payload === 403, ppContact: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_REQUEST': {
        return { ...state, loadingContact: true, ppContact: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_SUCCESS': {
        return { ...state, ppContact: action.payload, loadingContact: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_FAILED': {
        return { ...state, ppContact: null, loadingContact: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_BRANDING_REQUEST': {
        return { ...state, loadingBranding: true, ppBranding: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_BRANDING_SUCCESS': {
        return { ...state, ppBranding: action.payload, loadingBranding: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_BRANDING_FAILED': {
        return { ...state, ppBranding: null, loadingBranding: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_REQUEST': {
        return { ...state, loadingPhotos: true, ppPhotos: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_SUCCESS': {
        return { ...state, loadingPhotos: false, ppPhotos: action.payload };
      }
      case 'FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_FAILED': {
        return { ...state, loadingPhotos: false, ppPhotos: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICES_REQUEST': {
        return { ...state, ppOfficesLoading: true, ppOffices: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICES_SUCCESS': {
        return { ...state, ppOfficesLoading: false, ppOffices: action.payload };
      }
      case 'FETCH_PROPERTY_PARTICULARS_OFFICES_FAILED': {
        return { ...state, ppOfficesLoading: false, ppOffices: null };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_REQUEST': {
        return { ...state, ppAnalyticsLoading: true };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_SUCCESS': {
        return { ...state, ppAnalytics: action.payload, ppAnalyticsLoading: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_REQUEST': {
        return { ...state, loadingAnalyticsCharts: true };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_SUCCESS': {
        return { ...state, ppAnalyticsCharts: action.payload, loadingAnalyticsCharts: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_REQUEST': {
        return { ...state, ppAnalyticsDetailsLoading: true };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_SUCCESS': {
        return { ...state, ppAnalyticsDetails: action.payload, ppAnalyticsDetailsLoading: false };
      }
      case 'FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_FAILED': {
        return { ...state, ppAnalyticsDetails: action.payload, ppAnalyticsDetailsLoading: false };
      }
      // ACTIONS
      case 'VERIFY_PROPERTY_PARTICULARS_ACCESS_REQUEST': {
        return { ...state, verifyingAccess: true, isUser: false, isSubscriber: false, isNotAllowed: false };
      }
      case 'VERIFY_PROPERTY_PARTICULARS_ACCESS_SUCCESS': {
        const {isUser, isSubscriber} = action.payload;
        return { ...state, isUser, isSubscriber, verifyingAccess: false, isNotAllowed: handleToAllowed(isSubscriber, isUser) };
      }
      case 'VERIFY_PROPERTY_PARTICULARS_ACCESS_FAILED': {
        return { ...state, isUser: false, isSubscriber: false, verifyingAccess: false, isNotAllowed: true };
      }
      case 'VERIFY_PROPERTY_PARTICULARS_ACCESS_RESET_REQUEST': {
        return { ...state, isUser: false, isSubscriber: false, verifyingAccess: true, isNoAllowed: false };
      }
      case 'VERIFY_PROPERTY_PARTICULARS_ACCESS_RESET_SUCCESS': {
        return { ...state, verifyingAccess: false };
      }
      case 'REQUEST_PROPERTY_PARTICULARS_ACCESS_REQUEST': {
        return { ...state, requestingAccess: true };
      }
      case 'REQUEST_PROPERTY_PARTICULARS_ACCESS_SUCCESS': {
        return { ...state, requestingAccess: false };
      }
      case 'REQUEST_PROPERTY_PARTICULARS_ACCESS_FAILED': {
        return { ...state, requestingAccess: false };
      }
      case 'SHARE_PROPERTY_PARTICULARS_REQUEST': {
        return { ...state, shareLoading: true, shared: false };
      }
      case 'SHARE_PROPERTY_PARTICULARS_SUCCESS': {
        handleGoogleAnalyticsTracking('Button', 'Click', 'Property Particulars - Share');
        return { ...state, shareLoading: false, shared: true };
      }
      case 'SHARE_PROPERTY_PARTICULARS_FAILED': {
        return { ...state, shareLoading: false, shared: false };
      }
      case 'SHARE_UPDATED_PROPERTY_PARTICULARS_REQUEST': {
        return { ...state, sharingUpdated: true, sharedUpdated: false };
      }
      case 'SHARE_UPDATED_PROPERTY_PARTICULARS_SUCCESS': {
        handleGoogleAnalyticsTracking('Button', 'Click', 'Property Particulars - Share Updated');
        return { ...state, sharingUpdated: false, sharedUpdated: true, ppAnalytics: handlePPUpdate(state.ppAnalytics, action.payload?.ppUpdate) };
      }
      case 'SHARE_UPDATED_PROPERTY_PARTICULARS_FAILED': {
        return { ...state, sharingUpdated: false, sharedUpdated: false };
      }
      case 'SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_REQUEST': {
        return { ...state, ppVisibleOfficesLoading: true, ppVisibleOffices: null };
      }
      case 'SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_SUCCESS': {
        return { ...state, ppVisibleOfficesLoading: false, ppVisibleOffices: action.payload };
      }
      case 'SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_FAILED': {
        return { ...state, ppVisibleOfficesLoading: false, ppVisibleOffices: null };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchPropertyParticulars(action) {
  const {teamID, email, market} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  const filterMarket = market ? `${email ? '&' : '?'}market=${market}` : '';
  const filter = `${authMail}${filterMarket}`;
  try {
    const pp = yield axios.get(`/pp/${teamID}/${filter}`);
    yield put({ type: "FETCH_PROPERTY_PARTICULARS_SUCCESS", payload: pp.data || [{ error: pp.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchPropertyParticularsOffice(action) {
  const {teamID, officeID, email} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  try {
    const ppOffice = yield axios.get(`/pp/${teamID}/details/${officeID}/${authMail}`);
    yield put({ type: "FETCH_PROPERTY_PARTICULARS_OFFICE_SUCCESS", payload: ppOffice.data || [{ error: ppOffice.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later..', 'error')
    });
  }
}
function* fetchPropertyParticularsOfficeContact(action) {
  const {teamID, officeID, email} = action.payload;
  const authMail = email ? `?email=${email}` : '';
  try {
    const ppContact = yield axios.get(`/pp/${teamID}/details/${officeID}/contact/${authMail}`);
    yield put({ type: "FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_SUCCESS", payload: ppContact.data || [{ error: ppContact.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later..', 'error')
    });
  }
}
function* fetchPropertyParticularsBranding(action) {
  const {teamID} = action.payload;
  try {
    const ppBranding = yield axios.get(`/pp/${teamID}/branding/`);
    yield put({ type: "FETCH_PROPERTY_PARTICULARS_BRANDING_SUCCESS", payload: ppBranding.data || [{ error: ppBranding.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_BRANDING_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchPropertyParticularsListPhotos(action) {
  const {teamID, email} = action.payload;
  try {
    const ppPhotos = yield axios.get(`/pp/${teamID}/get-main-photos/?email=${email}`);
    yield put({ type: "FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_SUCCESS", payload: ppPhotos.data || [{ error: ppPhotos.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchPropertyParticularsOffices(action) {
  const {teamID} = action.payload;
  try {
    const ppOffices = yield axios.get(`/pp/${teamID}/get-pp-offices/`);
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_OFFICES_SUCCESS",
      payload: ppOffices.data || [{error: ppOffices.statusText}]
    })
  }
  catch(err) {
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_OFFICES_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchPropertyParticularsAnalytics(action) {
  try {
    const teamID = action.payload;
    const ppAnalytics = yield axios.get(`/pp/${teamID}/analytics/`);
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_ANALYTICS_SUCCESS",
      payload: ppAnalytics.data || [{error: ppAnalytics.statusText}]
    });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchPropertyParticularsAnalyticsCharts(action) {
  try {
    const {teamID, year} = action.payload;
    const ppAnalyticsCharts = yield axios.get(`/pp/${teamID}/charts/${year}/`);
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_SUCCESS",
      payload: ppAnalyticsCharts.data || [{error: ppAnalyticsCharts.statusText}]
    });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchPropertyParticularsAnalyticsDetails(action) {
  try {
    const {teamID, officeID} = action.payload;
    const ppAnalytics = yield axios.get(`/pp/${teamID}/analytics/${officeID}/`);
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_SUCCESS",
      payload: ppAnalytics.data || [{error: ppAnalytics.statusText}]
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_FAILED"
    });
  }
}

// USER ACTIONS
function* verifyPropertyParticularsAccess(action) {
  try {
    const {teamID, email} = action.payload;
    const authMail = email ? `?email=${email}` : '';
    const ppVerifyAccess = yield axios.get(`/pp/${teamID}/verify/${authMail}`);
    const isNotAllowed = ppVerifyAccess && ppVerifyAccess.data && !ppVerifyAccess.data.isSubscriber && !ppVerifyAccess.data.isUser;
    const isUser = ppVerifyAccess && ppVerifyAccess.data && ppVerifyAccess.data.isUser;
    yield put({
      type: "VERIFY_PROPERTY_PARTICULARS_ACCESS_SUCCESS",
      payload: ppVerifyAccess.data,
      meta: (isNotAllowed || isUser) && actionNotification(isNotAllowed ? 'You are not allowed to view this Property Particulars. You can request access to it by clicking link above.' : isUser && 'Last step. Provide password to your REDD Pro account.', isNotAllowed ? 'warning' : 'success')
    });
  }
  catch(err) {
    yield put({
      type: "VERIFY_PROPERTY_PARTICULARS_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* verifyPropertyParticularsAccessReset() {
  try {
    yield put({
      type: "VERIFY_PROPERTY_PARTICULARS_ACCESS_RESET_SUCCESS"
    });
  }
  catch(err) {
    console.log(err);
  }
}
function* requestPropertyParticularsAccess(action) {
  try {
    const {teamID, email} = action.payload;
    const authMail = email ? `?email=${email}` : '';
    const ppRequestAccess = yield axios.get(`/pp/${teamID}/get-access/${authMail}`);
    yield put({
      type: "REQUEST_PROPERTY_PARTICULARS_ACCESS_SUCCESS",
      payload: ppRequestAccess.data,
      meta: actionNotification('Your request for access to Property Particulars has been sent.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REQUEST_PROPERTY_PARTICULARS_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* sharePropertyParticulars(action) {
  try {
    const {data, teamID} = action.payload;
    const ppRequestAccess = yield axios.post(`/pp/${teamID}/send_pp_share/`, data);
    yield put({
      type: "SHARE_PROPERTY_PARTICULARS_SUCCESS",
      payload: ppRequestAccess.data
    });
  }
  catch(err) {
    yield put({
      type: "SHARE_PROPERTY_PARTICULARS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* sharePropertyParticularsRealTimeLog(action) {
  try {
    const data = action.payload;
    const ppRequestAccess = yield axios.post(`/pp/create_pp_real_time_mail_check_record/`, data);
    yield put({
      type: "SHARE_PROPERTY_PARTICULARS_REAL_TIME_LOG_SUCCESS",
      payload: ppRequestAccess.data
    });
  }
  catch(err) {
    yield put({
      type: "SHARE_PROPERTY_PARTICULARS_REAL_TIME_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* shareUpdatedPropertyParticulars(action) {
  try {
    const {teamID} = action.payload;
    const ppShareUpdated = yield axios.post(`/pp/${teamID}/send_pp_updated/`);
    yield put({
      type: "SHARE_UPDATED_PROPERTY_PARTICULARS_SUCCESS",
      payload: ppShareUpdated.data,
      meta: actionNotification('Updated PP has been sent.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "SHARE_UPDATED_PROPERTY_PARTICULARS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* shareUpdatedPropertyParticularsRealTimeLog(action) {
  try {
    const data = action.payload;
    const ppRequestAccess = yield axios.post(`/pp/create_pp_updated_login_mail_record/`, data);
    yield put({
      type: "SHARE_UPDATED_PROPERTY_PARTICULARS_REAL_TIME_LOG_SUCCESS",
      payload: ppRequestAccess.data
    });
  }
  catch(err) {
    yield put({
      type: "SHARE_UPDATED_PROPERTY_PARTICULARS_REAL_TIME_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* setPropertyParticularsOfficesAsVisible(action) {
  const {teamID, data} = action.payload;
  try {
    const setOffices = yield axios.post(`/pp/${teamID}/set-pp-offices/`, data || []);
    yield put({
      type: "SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_SUCCESS",
      payload: setOffices.data || [{error: setOffices.statusText}],
      meta: actionNotification('Property Particulars visible Offices list has been updated.', 'success')
    })
  }
  catch(err) {
    yield put({
      type: "SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}


export function* saga() {
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_REQUEST', fetchPropertyParticulars);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_OFFICE_REQUEST', fetchPropertyParticularsOffice);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_OFFICE_CONTACT_REQUEST', fetchPropertyParticularsOfficeContact);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_BRANDING_REQUEST', fetchPropertyParticularsBranding);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_LIST_PHOTOS_REQUEST', fetchPropertyParticularsListPhotos);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_OFFICES_REQUEST', fetchPropertyParticularsOffices);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_ANALYTICS_REQUEST', fetchPropertyParticularsAnalytics);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_REQUEST', fetchPropertyParticularsAnalyticsCharts);
  yield takeLatest('FETCH_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_REQUEST', fetchPropertyParticularsAnalyticsDetails);
  // USER ACTIONS
  yield takeLatest('VERIFY_PROPERTY_PARTICULARS_ACCESS_REQUEST', verifyPropertyParticularsAccess);
  yield takeLatest('VERIFY_PROPERTY_PARTICULARS_ACCESS_RESET_REQUEST', verifyPropertyParticularsAccessReset);
  yield takeLatest('REQUEST_PROPERTY_PARTICULARS_ACCESS_REQUEST', requestPropertyParticularsAccess);
  yield takeLatest('SHARE_PROPERTY_PARTICULARS_REQUEST', sharePropertyParticulars);
  yield takeLatest('SHARE_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST', sharePropertyParticularsRealTimeLog);
  yield takeLatest('SHARE_UPDATED_PROPERTY_PARTICULARS_REQUEST', shareUpdatedPropertyParticulars);
  yield takeLatest('SHARE_UPDATED_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST', shareUpdatedPropertyParticularsRealTimeLog);
  yield takeLatest('SET_PROPERTY_PARTICULARS_OFFICES_AS_VISIBLE_REQUEST', setPropertyParticularsOfficesAsVisible);
}
