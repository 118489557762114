import React from "react";
import {withRouter} from "react-router-dom";
import TableProperties from "../../../partials/components/TableProperties/TableProperties";
import {TABLE_CELLS} from "../../../partials/components/TableProperties";
import store from "../../../store/store";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../partials/content/Portlet";
import {isFiltersActive} from "../../../partials/components/FiltersManageBar/constants";
import styles from "../../offices/BuildingsPage/components/index.module.scss";
import {Grid, Typography} from "@material-ui/core";
import tableStyles from "../../../partials/components/TableProperties/index.module.scss";
import MapLegend from "../../offices/components/MapView/MapLegend";
import MapView from "./components/MapView";
import {VIEW_TYPES} from "../../../constants";
import IsAdmin from "../../../utils/IsAdmin";
import ReactVisibilitySensor from "react-visibility-sensor";

let prevLocation;

class WarehousesPage extends React.Component {
  constructor(props) {
    super(props);
    // Set previous route 'pathname'
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    //
    this.state = {
      viewType: props.viewType,
      isReady: false,
      initLoaded: false
    }
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-building/');
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(["market", "isEditable"]);
    }
  }
  componentDidUpdate() {
    const {viewType, fetchIndustrialWarehouses, fetchIndustrialWarehousesClusters} = this.props;
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-building/');
    // Flag to check if current page view is a Map View
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    // Execute this if current View Type is different then View Type before component Update
    if (viewType !== this.state.viewType) {
      let params = {
        page: 1,
        ...store.getState().filters
      };
      // Change params set if current page view is not a Map View
      if (!isMapView) {
        params = {
          page: this.state.page,
          ...store.getState().filters,
          order: '-',
          orderBy: 'updated_at_by_user'
        };
      }
      // Update View Type
      this.setState({
        viewType
      }, () => {
        if (updateData) {
          if (viewType === VIEW_TYPES.MAP_VIEW) {
            // Update Office Clusters if View Type is Map View
            fetchIndustrialWarehousesClusters({params});
          } else {
            // Update Offices List if View Type is not Map View
            fetchIndustrialWarehouses({params});
          }
        }
      });
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialWarehouses,
      fetchIndustrialWarehousesClusters,
      callReddClickEvent,
      loading
    } = this.props;
    const {initLoaded, viewType} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-building/');
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        // Params to update data: new page number, all selected filters
        if (updateData) {
          const params = {
            isFilterSet: 0,
            forceUpdateChilds: [],
            ...store.getState().filters,
            orderBy: 'updated_at_by_user'
          };
          if (isMapView) {
            fetchIndustrialWarehousesClusters({params});
          } else {
            fetchIndustrialWarehouses({params});
          }
        }
      });
    }
    if (isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "industrial-warehouse-list",
          "warehouse"
        );
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  // Helpers
  renderMapView = () => {
    const {
      clusters,
      clustersLoaded,
      fetchIndustrialPark,
      fetchIndustrialParkUnits,
      park,
      parkLoading,
      parkUnits,
      parkUnitsLoading,
      clustersLoading,
      allUserStates
    } = this.props;
    const {markers, market} = clusters;
    const totalBuildings = markers.length;

    return (
      <Portlet style={{marginBottom: 0}} className={isFiltersActive() && styles.filterBarEnable}>
        <PortletHeader
          title={
            <Typography variant="h3">
              <FormattedMessage id="INDUSTRIAL.PHRASE.INDUSTRIAL_PARKS"/>
              {totalBuildings && <FormattedMessage id="GENERAL.PHRASE.TOTAL_RESULTS" values={{
                results: <span className={tableStyles.resultsValue}>{totalBuildings}</span>,
                prefix: " - "
              }}/>}
            </Typography>
          }
          toolbar={
            <PortletHeaderToolbar>
              <MapLegend forceLegend/>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody fit>
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewport(isVisible)}
            scrollCheck
            partialVisibility
          >
            <Grid container spacing={0} direction="column">
              <Grid item xs={12}>
                {clustersLoaded ?
                  <MapView
                    getProperty={fetchIndustrialPark}
                    getPropertyChilds={fetchIndustrialParkUnits}
                    markers={markers}
                    market={market}
                    property={park}
                    propertyLoading={parkLoading}
                    propertyChilds={parkUnits}
                    propertyChildsLoading={parkUnitsLoading}
                    clustersLoading={clustersLoading}
                    allUserStates={allUserStates}
                  /> : <LoadingScreen/>}

              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </PortletBody>
      </Portlet>
    );
  };

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      total,
      loading,
      fetchIndustrialWarehouses,
      clustersLoaded
    } = this.props;
    const {viewType} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;

    return (
      isMapView ? (clustersLoaded ? this.renderMapView() : <LoadingScreen/>) :
        <TableProperties
          tableLabel={<FormattedMessage id="INDUSTRIAL.PHRASE.INDUSTRIAL_BUILDINGS"/>}
          headRows={[
            ...IsAdmin() ? [{
                id: 'checkable',
                numeric: false,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.PARK_NAME',
                sort: false
              }] : [],
            {
              id: 'park_name',
              numeric: false,
              disablePadding: false,
              label: 'VOCABULARY.Building',
              sort: true
            },
            {id: 'status', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.STATUS', sort: true},
            {
              id: 'total_area_available',
              numeric: true,
              disablePadding: false,
              label: 'VOCABULARY.Available space',
              sort: true
            },
            {
              id: 'available_from',
              numeric: false,
              disablePadding: false,
              label: 'VOCABULARY.Availability',
              sort: true
            },
            {
              id: 'updated_at_by_user',
              numeric: false,
              disablePadding: false,
              label: 'BUILDING.PHRASE.UPDATED_AT',
              sort: true
            },
            {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false}
          ]}
          bodyRows={items}
          dataSource="industrial"
          dataTarget={TABLE_CELLS.INDUSTRIAL_BUILDINGS}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={total}
          loading={loading}
          filterBarEnable
          handleFetchData={fetchIndustrialWarehouses}
          onEnterViewport={this.handleOnViewport}
        />
    );
  }
}

export default withRouter(WarehousesPage);