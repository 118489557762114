import React from 'react';
import {TextField, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {updateIndustrialWarehouse} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import InputSwitcher from "../InputSwitcher";

class LackOfAvailableArea extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      isLackOfAvailableArea: props.lackOfAvailableArea,
      buildingAvailableFrom: props.buildingAvailableFrom || ''
    };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      isLackOfAvailableArea: nextProps.lackOfAvailableArea,
      buildingAvailableFrom: nextProps.buildingAvailableFrom
    });
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSave = () => {
    const {updateOffice, updateIndustrialWarehouse, propertyID, target} = this.props;
    const {buildingAvailableFrom, isLackOfAvailableArea} = this.state;
    const onUpdate = target === "office" ? updateOffice : target === "industrial" && updateIndustrialWarehouse;
    const dateAvailableFrom = buildingAvailableFrom && buildingAvailableFrom !== "" ? buildingAvailableFrom : null;
    this.setState({
      isLackOfAvailableArea: !isLackOfAvailableArea
    }, () =>
      onUpdate(
        propertyID,
        {buildingAvailableFrom: dateAvailableFrom,lackOfAvailableArea: this.state.isLackOfAvailableArea},
        "basic"
      )
    );
    if(!this.state.isLackOfAvailableArea) {
      this.handleCloseModal();
    }
  };

  render() {
    const {directBtn, directIcon, tooltipPosition, modeSwitcher} = this.props;
    const {buildingAvailableFrom, isLackOfAvailableArea} = this.state;
    if(modeSwitcher) {
      if(!isLackOfAvailableArea) {
        return(
          <ModalCustom
            ref={this.child}
            btn={
              <InputSwitcher
                checked={isLackOfAvailableArea}
                value={isLackOfAvailableArea}
                label={<FormattedMessage id="BUILDING.PHRASE.LACK_OF_AVAILABLE_AREA"/>}
              />
            }
            btnConfirm={<FormattedMessage id="GENERAL.PHRASE.CONFIRM"/>}
            title={<FormattedMessage id="BUILDING.PHRASE.SET_LACK_OF_AVAILABLE_AREA_TITLE"/>}
            handleSave={this.handleSave}
          >
            <Typography><FormattedMessage id="BUILDING.PHRASE.SET_LACK_OF_AVAILABLE_AREA_MESSAGE"/></Typography>
            <TextField
              onChange={this.handleChange}
              value={buildingAvailableFrom}
              id="buildingAvailableFrom"
              label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_AVAILABLE_FROM"/>}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </ModalCustom>
        );
      }
      return (
        <InputSwitcher
          checked={isLackOfAvailableArea}
          value={isLackOfAvailableArea}
          label={<FormattedMessage id="BUILDING.PHRASE.LACK_OF_AVAILABLE_AREA"/>}
          onChange={this.handleSave}
        />
      );
    }
    else {
      if(!isLackOfAvailableArea) {
        return(
          <ModalCustom
            ref={this.child}
            btn={
              <ModalTrigger
                icon="flip_to_front"
                label={<FormattedMessage id="BUILDING.PHRASE.SET_LACK_OF_AVAILABLE_AREA"/>}
                directBtn={directBtn}
                directIcon={directIcon}
                color="warning"
                tooltipPosition={tooltipPosition}
              />
            }
            btnConfirm={<FormattedMessage id="GENERAL.PHRASE.CONFIRM"/>}
            title={<FormattedMessage id="BUILDING.PHRASE.SET_LACK_OF_AVAILABLE_AREA_TITLE"/>}
            handleSave={this.handleSave}
          >
            <Typography><FormattedMessage id="BUILDING.PHRASE.SET_LACK_OF_AVAILABLE_AREA_MESSAGE"/></Typography>
            <TextField
              onChange={this.handleChange}
              value={buildingAvailableFrom}
              id="buildingAvailableFrom"
              label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_AVAILABLE_FROM"/>}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </ModalCustom>
        );
      }
      return (
        <ModalTrigger
          icon="flip_to_front"
          label={<FormattedMessage id="BUILDING.PHRASE.UNSET_LACK_OF_AVAILABLE_AREA" values={{date: buildingAvailableFrom}}/>}
          directBtn={directBtn}
          directIcon={directIcon}
          color="success"
          onClick={this.handleSave}
          tooltipPosition={tooltipPosition}
        />
      );
    }
  }
}

const mapDispatchToProps = {
  updateOffice: (propertyID, data, backgroundRefresh) => updateOffice(propertyID, data, backgroundRefresh),
  updateIndustrialWarehouse: (warehouseID, data, backgroundRefresh) => updateIndustrialWarehouse(warehouseID, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(LackOfAvailableArea)
);