import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import {toNumberFormat} from "../../../../../utils/helpers";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import {BUILDING_TYPES} from "../../../../../constants";
import {FormattedMessage} from "react-intl";
import EditModal from "../../../../../partials/components/EditModal";

export default class PropertyMetrics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchOfficeMetrics, metrics, metricsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !metricsLoading && _.isEmpty(metrics) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchOfficeMetrics(id));
    }
  }
  handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  render() {
    const {parentRef, metrics, metricsLoading, basic} = this.props;
    const isOfficeEditable = basic?.isEditable;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Metric data"/>}
          titleActions={
          basic?.isEditable &&
            <EditModal
              target="office"
              parentData={basic}
              isPropertyEditable={isOfficeEditable}
            />
          }
        />
        <div className={styles.metricsWrapper}>
          <table className={styles.simpleTable}>
            <tbody>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Type"/></td>
                <td>
                  {this.handleValueLoading(
                      metricsLoading,
                      metrics?.buildingType === BUILDING_TYPES.OFFICE_BUILDING.id ? <FormattedMessage id={BUILDING_TYPES.OFFICE_BUILDING.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.APARTMENT_HOUSE.id ? <FormattedMessage id={BUILDING_TYPES.APARTMENT_HOUSE.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.WAREHOUSE.id ? <FormattedMessage id={BUILDING_TYPES.WAREHOUSE.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.SHOPPING_CENTER.id ? <FormattedMessage id={BUILDING_TYPES.SHOPPING_CENTER.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.HQ.id ? <FormattedMessage id={BUILDING_TYPES.HQ.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.TENEMENT_HOUSE.id ? <FormattedMessage id={BUILDING_TYPES.TENEMENT_HOUSE.name}/> :
                      metrics?.buildingType === BUILDING_TYPES.OTHER.id && <FormattedMessage id={BUILDING_TYPES.OTHER.name}/>
                    )}
                </td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Class"/></td>
                <td>{this.handleValueLoading(metricsLoading, metrics?.buildingClass)}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Completion year"/></td>
                <td>{this.handleValueLoading(metricsLoading, metrics?.commissioningYear)}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Gross leasable area (GLA)"/></td>
                <td>{this.handleValueLoading(metricsLoading, toNumberFormat(metrics?.totalAreaBrutto, 'area'))}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="VOCABULARY.Net leasable area (NLA)"/></td>
                <td>{this.handleValueLoading(metricsLoading, toNumberFormat(metrics?.totalAreaNetto, 'area'))}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </>
      </ReactVisibilitySensor>
    );
  }
}