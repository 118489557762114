import GoogleMap from "app/pages/offices/components/MapView/GoogleMap/index";
import React from "react";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import styles from "app/pages/offices/components/MapView/MapView.module.scss";
import {withState} from "recompose";
import TableDrawer from "app/partials/components/TableDrawer";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {toAbsoluteUrl} from "_metronic";
import {Marker} from "react-google-maps";
import {MarkerClusterer} from "react-google-maps/lib/components/addons/MarkerClusterer";
import LoadingScreen from "app/partials/components/LoadingScreen";
import clsx from "clsx";
import GOOGLE_MAPS_OPTIONS from "app/constants/GOOGLE_MAPS_OPTIONS";
import {ROUTES} from "../../../../constants";
import PropertyExpandedCardPreview from "../../../../partials/components/_DataParts/PropertyExpandedCardPreview";

// Initial map LatLng and zoom settings
const DEFAULT_LOCATION = {lat: 52.38229994194257, lng: 18.903042342058622};
const DEFAULT_ZOOM = 6;

// Component that handle Map View for Properties data (offices, units)

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    // Initial Map Settings
    this.state = {
      zoom: DEFAULT_ZOOM,
      center: DEFAULT_LOCATION,
      drawerOpen: false,
      clusteringGrid: 60,
      sourceMarker: false,
      openInfoWindowMarkerId: '',
      isOfficeEditable: false
    };
  }

  // Handle Map zoom and cluster grid
  handleZoomChange = () => {
    const zoomLevel = this.mapRef.current.getZoom();
    this.setState({
      zoom: zoomLevel,
      clusteringGrid: zoomLevel < 17 ? 50 : 0
    });
  };
  // Handle close TableDrawer
  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
      sourceMarker: false
    });
  };
  // Function to handle map fit bounds
  fitToBounds = (markers) => {
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((marker) => {
        bounds.extend(new window.google.maps.LatLng(
          marker.lat,
          marker.lng
        ));
        return true;
      });
      this.mapRef.current.fitBounds(bounds);
    }
  };
  // Map interactions
  handleMarkerClick = (propertyID, lat, lng, index) => {
    if (propertyID) {
      this.props.getProperty(propertyID);
      this.props.getPropertyChilds(propertyID);

      this.setState({
        sourceMarker: true,
        center: {lat: lat, lng: lng},
        zoom: 15,
        clusteringGrid: 0,
        drawerOpen: true
      });
    } else {
      this.setState({
        openInfoWindowMarkerId: index
      });
    }
  };
  handleCloseInfoWindow = () => {
    this.setState({
      openInfoWindowMarkerId: ''
    });
  };
  // Render Marker - icon type based on permissions and buildings status
  renderMarkerIcon = (markerColor, isActive) => {
    const pinGreen = toAbsoluteUrl("media/icons/markers/pin-green.png");
    const pinOrange = toAbsoluteUrl("media/icons/markers/pin-orange.png");
    const pinRed = toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const pinBlue = toAbsoluteUrl("media/icons/markers/pin-blue.png");
    const pinBlack = toAbsoluteUrl("media/icons/markers/pin-black.png");
    let pin = pinBlack;
    if (isActive) {
      pin = markerColor !== null ? pinBlack : pinRed;
    } else if (markerColor === "green") {
      pin = pinGreen;
    } else if (markerColor === "orange") {
      pin = pinOrange;
    } else if (markerColor === "red") {
      pin = pinRed;
    } else if (markerColor === "blue") {
      pin = pinBlue;
    }
    return pin;
  };

  render() {
    const {
      markers,
      options,
      property,
      clustersLoading,
      propertyLoading,
      limitBarEnable,
      propertyChilds,
      propertyChildsLoading
    } = this.props;
    const {drawerOpen, clusteringGrid} = this.state;
    const propertyDataReady = !propertyLoading && property;
    const isUnitsActionsPermitted = property?.isEditable;

    return (
      <div className={clsx(styles.mapWidth, limitBarEnable && styles.limitBarMapHeight)}>
        {clustersLoading ? <LoadingScreen/> : (
          <GoogleMap
            mapRef={this.mapRef}
            options={options}
            center={this.state.center}
            zoom={this.state.zoom}
            onZoomChanged={this.handleZoomChange}
            onFitBounds={() => this.fitToBounds(markers)}
            onClick={() => this.handleCloseInfoWindow()}
          >
            <MarkerClusterer
              averageCenter
              enableRetinaIcons
              gridSize={clusteringGrid}
              styles={[
                {
                  url: toAbsoluteUrl('media/icons/clusters/m1.png'),
                  height: 52,
                  width: 53,
                  textColor: "#FFF",
                },
                {
                  url: toAbsoluteUrl('media/icons/clusters/m2.png'),
                  height: 55,
                  width: 56,
                  textColor: "#FFF",
                },
                {
                  url: toAbsoluteUrl('media/icons/clusters/m3.png'),
                  height: 65,
                  width: 66,
                  textColor: "#FFF",
                }
              ]}
            >
              {markers.map((marker, index) => {
                const lat = Number(marker.lat);
                const lng = Number(marker.lng);

                return (
                  <Marker
                    key={index}
                    position={{lat: lat, lng: lng}}
                    onClick={() => this.handleMarkerClick(marker.park, lat, lng, index)}
                    icon={this.renderMarkerIcon(marker.mapColor, this.state.center.lat === lat)}
                    opacity={marker.park ? 1 : 0.5}
                  />
                )
              })}
            </MarkerClusterer>
          </GoogleMap>
        )}
        {/* Table Units List open on marker click or units list item */}
        <TableDrawer
          title={
            <PropertyExpandedCardPreview
              propertyID={property?.id}
              title={property?.name}
              thumb={property?.thumbnail}
              route={ROUTES.INDUSTRIAL_PARK}
            />
          }
          titleAdvanced
          headRows={[
            ...isUnitsActionsPermitted ? [{
              id: 'checkable',
              numeric: false,
              disablePadding: false,
              label: '',
              sort: false
            }] : [],
            {
              id: 'area',
              numeric: true,
              disablePadding: false,
              label: 'GENERAL.PHRASE.AREA',
              sort: false
            },
            {
              id: 'is_office',
              numeric: true,
              disablePadding: false,
              label: 'GENERAL.PHRASE.TYPE',
              sort: false
            },
            {
              id: 'status',
              numeric: true,
              disablePadding: false,
              label: 'INDUSTRIAL.TABLE.STATUS',
              sort: false
            },
            {
              id: 'available_from',
              numeric: true,
              disablePadding: false,
              label: 'BUILDING.PHRASE.AVAILABLE_FROM',
              sort: false
            },
            {
              id: 'updated_at',
              numeric: false,
              disablePadding: false,
              label: 'BUILDING.PHRASE.UPDATED_AT',
              sort: false
            },
            {
              id: 'docks',
              numeric: true,
              disablePadding: false,
              label: 'INDUSTRIAL.PHRASE.LOADING_DOCKS',
              sort: false
            },
            {
              id: 'actions',
              numeric: true,
              disablePadding: false,
              label: '',
              sort: false
            }
          ]}
          bodyRows={propertyChilds}
          bodyRowsReady={propertyChildsLoading || propertyLoading}
          target={TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS}
          parentId={propertyDataReady && property.id}
          parentData={property}
          parentPreviewData={property}
          externalDrawerState={drawerOpen}
          handleExternalClose={() => this.handleDrawerClose()}
          isEditable={property?.isEditable}
        />
      </div>
    );
  }
}

export const withCluster = compose(
  defaultProps({
    style: {},
    options: {
      minZoom: 3,
      maxZoom: 20,
      styles: GOOGLE_MAPS_OPTIONS.STYLES,
    },
  }),
  withState('mapProps')
);

export default withCluster(MapView);
