import React from "react";
import {Drawer} from "@material-ui/core";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import styles from "./index.module.scss";
import {withRouter} from "react-router-dom";
import LoadingScreen from "app/partials/components/LoadingScreen";
import clsx from "clsx";
import BuildingExpandedCardPreview from "app/partials/components/_DataParts/BuildingExpandedCardPreview";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import PaywallBlurContent from "../PayWall/components/PaywallBlurContent";
import IsPro from "../../../utils/IsPro";
import PaywallBannerSimple from "../PayWall/banners/PaywallBannerSimple";

class TableDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  toggleDrawer = (open) => event => {
    const {onClick, onClose, onOpen, onRefresh, parentId} = this.props;
    if((onClick || onOpen) && open) {
      onClick && onClick(parentId);
      onOpen && onOpen();
    }
    else if(onClose && !open) {
      const params = {
        page: 1
      };
      onClose();
      onRefresh && onRefresh({params});
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({
      open
    });
  };
  render() {
    const {
      children,
      headRows,
      bodyRows,
      title,
      titleAdvanced,
      target,
      parentId,
      parentData,
      isEditable,
      externalDrawerState,
      handleExternalClose,
      parentPreviewData,
      bodyRowsReady,
      proNotMandatory
    } = this.props;
    let hasChildrens;
    if(target === TABLE_CELLS.COWORK_DESKS) {
      hasChildrens = parentPreviewData && parentPreviewData?.totalAvailableRooms !== 0;
    } else if(target === TABLE_CELLS.SHORTLIST_UNITS) {
      hasChildrens = parentPreviewData && parentPreviewData?.units.length !== 0;
    } else if(target === TABLE_CELLS.SHORTLIST_LANDLORD_UNITS) {
      hasChildrens = parentPreviewData && parentPreviewData?.shortlistsCount > 0;
    } else if(target === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS) {
      hasChildrens = parentPreviewData && parentPreviewData?.numberOfUnitsAvailable > 0;
    } else if(target === TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES) {
      hasChildrens = parentPreviewData && parentPreviewData?.numberOfWarehouses > 0;
    } else {
      hasChildrens = parentPreviewData && parentPreviewData?.numberOfUnitsAvailable !== 0;
    }
    const isActive = parentPreviewData && (hasChildrens || isEditable);
    let offersRows = [];
    if(bodyRows !== undefined) {
      if(target === TABLE_CELLS.SHORTLIST_UNITS) {
        offersRows = bodyRows && bodyRows?.unitsReadOnly;
      } else {
        offersRows = bodyRows;
      }
    }
    const isOpen = externalDrawerState ? externalDrawerState : this.state.open;
    const inactiveState = !isEditable && (offersRows && offersRows.length === 0);

    return(
      <div>
        <div onClick={this.toggleDrawer(isActive)} className={clsx(!isActive && inactiveState ? styles.inactive : '', styles.btnDrawer)}>{children}</div>
        <Drawer
            container={document.getElementById("root")}
            anchor="bottom"
            open={isOpen}
            onClose={handleExternalClose ? handleExternalClose : this.toggleDrawer(false)}
        >
          <div
            role="presentation"
            className={styles.bodyContainer}
          >
            <IsPro
              paywall
              paywallComponent={
              <PaywallBlurContent
                blurImage="media/placeholders/table-office-units-drawer-blur-placeholder.png"
              >
                <PaywallBannerSimple/>
              </PaywallBlurContent>
              }
              overwriteRule={isEditable || proNotMandatory}
            >
              {isOpen && offersRows !== null && !bodyRowsReady ?
                <TableProperties
                  headRows={headRows}
                  bodyRows={offersRows}
                  tableLabel={title ? title : <BuildingExpandedCardPreview data={parentPreviewData} simpleView={isEditable}/>}
                  titleAdvanced={titleAdvanced}
                  dataTarget={target}
                  parentId={parentId}
                  parentData={parentData}
                  parentPreviewData={parentPreviewData}
                /> : <LoadingScreen/>
              }
            </IsPro>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(TableDrawer);