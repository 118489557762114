import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import ButtonCustom from "app/partials/components/ButtonCustom";

export default class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open
      });
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    }, () => this.props.onClose && this.props.onClose());
  };
  handleConfirm = () => {
    this.props.onConfirm();
  };

  // componentWillReceiveProps(nextProps) {
  //   if(this.state.open !== nextProps.open) {
  //     this.setState({
  //       open: nextProps.open
  //     });
  //   }
  // };

  render() {
    const {btnCancel, btnConfirm, title} = this.props;
    const { open } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography>
          <Typography variant="h3">{title ? title : <FormattedMessage id="GENERAL.PHRASE.CONFIRMATION_REQUIRED"/>}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{this.props.message}</Typography>
        </DialogContent>
        <DialogActions>
          <ButtonCustom
              label={btnCancel ? btnCancel : <FormattedMessage id="GENERAL.PHRASE.NO"/>}
              solid
              onClick={() => this.handleClose()}
            />
            <ButtonCustom
              label={btnConfirm ? btnConfirm : <FormattedMessage id="GENERAL.PHRASE.YES"/>}
              color="danger"
              solid
              onClick={() => this.handleConfirm()}
            />
        </DialogActions>
      </Dialog>
    );
  }
}