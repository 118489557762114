import React from "react";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "../../../../partials/components/ModalCustom/ModalTrigger";
import {FormattedMessage} from "react-intl";
import FinneCrossSelling from "../../../../partials/components/FinneCrossSelling";
import {addLead} from "../../../../crud/leadsManagement.crud";

class LeadsManagementAddLead extends React.Component {
  render() {
    const {addingLead, addLead} = this.props;

    return(
      <FinneCrossSelling
        btnTrigger={
        <ModalTrigger
          label={<FormattedMessage id="LM.PHRASE.CREATE_LEAD"/>}
          color="success"
          icon="add"
          directBtn
        />
        }
        modalTitle={<FormattedMessage id="LM.PHRASE.CREATE_LEAD_MANUALLY"/>}
        mode="create"
        sending={addingLead}
        onCreate={(data) => addLead(data)}
      />
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  addingLead: leadsManagement?.addingLead
});
const mapDispatchToProps = {
  addLead: (data) => addLead(data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementAddLead);