export default {
    UNITS: [
        {id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false},
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: true},
        {
            id: 'building_status',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.BUILDING_STATUS',
            sort: false
        },
        {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: true},
        {id: 'entrance', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.ENTRANCE', sort: true},
        {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: true},
        {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: true},
        {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: true
        },
        {id: 'updated_at', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.UPDATED_AT', sort: false},
        {
            id: 'rent_esitmation',
            numeric: true,
            disablePadding: false,
            label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICES: [
        {
            id: 'office_name',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.BUILDING_DETAILS',
            sort: true
        },
        {id: 'building_status', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {id: 'rent_details', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.RENT_DETAILS', sort: false},
        {
            id: 'available_area_details',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA_DETAILS',
            infoTooltip: 'BUILDING.PHRASE.AREA_AVAILABLE_FROM_NOW_ON',
            sort: false
        },
        {id: 'actions', numeric: false, disablePadding: false, label: '', sort: false},
        {
            id: 'occupancy_rate',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.OCCUPANCY_RATE',
            sort: false
        },
    ],
    OFFICES_LANDLORD: [
        {
            id: 'office_name',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.BUILDING_DETAILS',
            sort: true
        },
        {id: 'building_status', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {id: 'rent_details', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.RENT_DETAILS', sort: false},
        {
            id: 'available_area_details',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA_DETAILS',
            sort: false
        },
        {id: 'actions', numeric: false, disablePadding: false, label: '', sort: false},
        {
            id: 'occupancy_rate',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.OCCUPANCY_RATE',
            sort: false
        },
    ],
    OFFICE_UNITS: [
        {id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false},
        {id: 'entrance', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.ENTRANCE', sort: true},
        {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false},
        {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: false
        },
        {
            id: 'update_level_cache',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.UPDATED_AT',
            sort: false
        },
        {
            id: 'rent_esitmation',
            numeric: true,
            disablePadding: false,
            label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICE_UNITS_LANDLORD: [
        {id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false},
        {id: 'entrance', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.ENTRANCE', sort: true},
        {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false},
        {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: false
        },
        {
            id: 'update_level_cache',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.UPDATED_AT',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICES_PREMIUM_LATEST_RELEASES: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {
            id: 'recent_freed_modules_no',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.NO_OF_LATEST_RELEASES',
            sort: false
        },
        {
            id: 'number_of_units_available',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_UNITS',
            sort: false
        },
        {
            id: 'total_available_area',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA',
            sort: false
        },
        {id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false},
        {
            id: 'service_charge',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.SERVICE_CHARGE',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICES_PREMIUM_LATEST_RENT_CHANGED: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {
            id: 'recent_rent_change',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.RENT_REDUCTION',
            sort: true
        },
        {
            id: 'number_of_units_available',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_UNITS',
            sort: false
        },
        {
            id: 'total_available_area',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA',
            sort: false
        },
        {id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false},
        {
            id: 'service_charge',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.SERVICE_CHARGE',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICES_PREMIUM_LATEST_RENTED: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {
            id: 'recent_rented_modules_no',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.NO_OF_RENTED_UNITS',
            sort: false
        },
        {
            id: 'number_of_units_available',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_UNITS',
            sort: false
        },
        {
            id: 'total_available_area',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA',
            sort: false
        },
        {id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false},
        {
            id: 'service_charge',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.SERVICE_CHARGE',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OFFICES_PREMIUM_LATEST_SOLD_OFFICES: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {
            id: 'last_transaction_date',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.LAST_TRANSACTION_DATA',
            sort: false
        },
        {
            id: 'number_of_units_available',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_UNITS',
            sort: false
        },
        {
            id: 'total_available_area',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA',
            sort: false
        },
        {id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false},
        {
            id: 'service_charge',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.SERVICE_CHARGE',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    SHORTLISTS: [
        {id: 'shortlist_name', numeric: false, disablePadding: false, label: 'SHORTLIST.PHRASE.NAME', sort: true},
        {
            id: 'shortlist_units',
            numeric: true,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.NO_OF_UNITS',
            sort: true
        },
        {
            id: 'shortlist_create_date',
            numeric: true,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.CREATE_DATE',
            sort: true
        },
        {
            id: 'shortlist_last_update',
            numeric: true,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.LAST_UPDATE',
            sort: true
        },
        {id: 'shortlist_pdf', numeric: true, disablePadding: false, label: 'SHORTLIST.PHRASE.PDF', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    SHORTLIST_UNITS: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false},
        {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false},
        {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: false
        },
        {
            id: 'update_level_cache',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.UPDATED_AT',
            sort: false
        },
        {
            id: 'rent_esitmation',
            numeric: true,
            disablePadding: false,
            label: 'UNITS.PHRASE.FORECAST_FEES_PREMIUM',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    SHORTLIST_LANDLORD: [
        {
            id: 'shortlist_building_name',
            numeric: false,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.BUILDING',
            sort: false
        },
        {
            id: 'shortlist_create_date',
            numeric: true,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.CREATE_DATE',
            sort: false
        },
        {
            id: 'shortlist_last_update',
            numeric: true,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.LAST_UPDATE',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    SHORTLIST_LANDLORD_UNITS: [
        {id: 'company_name', numeric: false, disablePadding: false, label: 'FORM.FIELD.COMPANY_NAME', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'SHORTLIST.PHRASE.UNIT', sort: false},
        {id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {id: 'type', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
        {
            id: 'shortlist_create_date',
            numeric: false,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.CREATE_DATE',
            sort: false
        },
        {
            id: 'shortlist_last_update',
            numeric: false,
            disablePadding: false,
            label: 'SHORTLIST.PHRASE.LAST_UPDATE',
            sort: false
        },
    ],
    TEAM: [
        {id: 'member_name', numeric: false, disablePadding: false, label: 'FORM.FIELD.FULLNAME', sort: false},
        {id: 'member_email', numeric: false, disablePadding: false, label: 'FORM.FIELD.EMAIL_ADDRESS', sort: false},
        {id: 'member_phone', numeric: false, disablePadding: false, label: 'FORM.FIELD.PHONE_NUMBER', sort: false},
        {id: 'team_owner', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.OWNER', sort: false},
        {
            id: 'team_contact_person',
            numeric: true,
            disablePadding: false,
            label: 'GENERAL.PHRASE.CONTACT_PERSON',
            sort: false
        },
        {
            id: 'member_active_subscription',
            numeric: true,
            disablePadding: false,
            label: 'TEAM.PHRASE.SUBSCRIPTION',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    SUBSCRIBERS: [
        {id: 'subscriber_email', numeric: false, disablePadding: false, label: 'FORM.FIELD.EMAIL_ADDRESS', sort: false},
        {
            id: 'subscriber_approved',
            numeric: true,
            disablePadding: false,
            label: 'GENERAL.PHRASE.APPROVED',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ],
    OWNERS: [
        {id: 'name', numeric: false, disablePadding: false, label: 'FORM.FIELD.COMPANY', sort: true},
        {id: 'ownerdetail__portfolio', numeric: true, disablePadding: false, label: 'FORM.FIELD.PORTFOLIO', sort: true},
        {id: 'ownerdetail__markets', numeric: true, disablePadding: false, label: 'FORM.FIELD.MARKETS', sort: true},
        {
            id: 'ownerdetail__total_area',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.NET_TOTAL_AREA',
            sort: true
        },
        {
            id: 'ownerdetail__total_vacancy',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.AVG_VACANCY_EXIST',
            sort: true,
            infoTooltip: "FORM.FIELD.AVG_VACANCY_EXIST_DESC"
        },
        {
            id: 'ownerdetail__total_vacancy_under_construction',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.AVG_VACANCY_UNDER_CONSTRUCTION',
            sort: true,
            infoTooltip: "FORM.FIELD.AVG_VACANCY_UNDER_CONSTRUCTION_DESC"
        },
        {
            id: 'ownerdetail__finance__total_operating_revenue',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.TOTAL_SALES',
            sort: true
        },
        {id: 'ownerdetail__finance__ebit', numeric: true, disablePadding: false, label: 'FORM.FIELD.EBIT', sort: true},
        {
            id: 'ownerdetail__finance__ebitda',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.EBITDA',
            sort: true
        },
        {
            id: 'ownerdetail__finance__total_assets',
            numeric: true,
            disablePadding: false,
            label: 'FORM.FIELD.TOTAL_ASSETS',
            sort: true
        },
    ],
    OWNER_PORTFOLIO: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false},
        {id: 'owner_name', numeric: false, disablePadding: false, label: 'OWNERS.PHRASE.SPV', sort: false},
        {id: 'office_finance', numeric: true, disablePadding: false, label: 'OWNERS.PHRASE.FINANCES', sort: false},
        {id: 'building_status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false},
        {id: 'for_sale', numeric: true, disablePadding: false, label: 'OWNERS.PHRASE.FOR_SALE', sort: false},
        {
            id: 'total_area_available',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_AREA',
            sort: false
        },
        {id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false},
        {
            id: 'service_charge',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.SERVICE_CHARGE',
            sort: false
        },
        {id: 'capacity', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.CAPACITY', sort: false}
    ],
    ACQUISITIONS: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'ACQUISITIONS.PHRASE.BUILDING', sort: false},
        {
            id: 'acquiring_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.BUYER',
            sort: false
        },
        {
            id: 'seller_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.SELLER',
            sort: false
        },
        {id: 'transaction_type', numeric: false, disablePadding: false, label: 'ACQUISITIONS.PHRASE.TRANSACTION_TYPE', sort: false},
        {id: 'transaction_date', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.DATE', sort: true},
        {id: 'share', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.STAKE', sort: true},
        {id: 'price', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.VALUE', sort: true}
    ],
    ACQUISITIONS_OFFICE: [
        {
            id: 'acquiring_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.BUYER',
            sort: false
        },
        {
            id: 'seller_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.SELLER',
            sort: false
        },
        {id: 'transaction_date', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.DATE', sort: false},
        {id: 'share', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.STAKE', sort: false},
        {id: 'price', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.VALUE', sort: false}
    ],
    ACQUISITIONS_OWNER: [
        {id: 'office_name', numeric: false, disablePadding: false, label: 'ACQUISITIONS.PHRASE.BUILDING', sort: false},
        {
            id: 'acquiring_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.BUYER',
            sort: false
        },
        {
            id: 'seller_companies',
            numeric: false,
            disablePadding: false,
            label: 'ACQUISITIONS.PHRASE.SELLER',
            sort: false
        },
        {id: 'transaction_date', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.DATE', sort: false},
        {id: 'share', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.STAKE', sort: false},
        {id: 'price', numeric: true, disablePadding: false, label: 'ACQUISITIONS.PHRASE.VALUE', sort: false}
    ],
    USER_INVOICES: [
        {id: 'invoice_no', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.INVOICE_NO', sort: false},
        {id: 'date', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.DATE', sort: false},
        {id: 'amount', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AMOUNT', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'FILTER.FORM.FIELD.STATUS', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false}
    ],
    // INDUSTRIAL HEAD_ROWS
    INDUSTRIAL_PARKS: [
        {id: 'checkable', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.PARK_NAME', sort: true},
        {id: 'park_name', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.PARK_NAME', sort: true},
        {
            id: 'total_area_available',
            numeric: false,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.AVAILABLE_AREA_DETAILS',
            sort: true
        },
        {
            id: 'park_rent_details',
            numeric: false,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.RENT_DETAILS',
            sort: false
        },
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
        {
            id: 'park_occupancy_rate',
            numeric: true,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.OCCUPANCY_RATE',
            sort: false
        },
    ],
    INDUSTRIAL_BUILDINGS: [
        {id: 'park_name', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.STATUS', sort: true},
        {
            id: 'total_area_available',
            numeric: false,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.AVAILABLE_AREA',
            sort: true
        },
        {id: 'total_area', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.BUILDING_AREA', sort: true},
        {id: 'actions', numeric: false, disablePadding: false, label: '', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.BUILDING_AVAILABILITY',
            sort: true
        }
    ],
    INDUSTRIAL_PARK_BUILDINGS: [
        {id: 'park_name', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.STATUS', sort: true},
        {
            id: 'total_area_available',
            numeric: false,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.AVAILABLE_AREA',
            sort: true
        },
        {id: 'total_area', numeric: false, disablePadding: false, label: 'INDUSTRIAL.TABLE.BUILDING_AREA', sort: true},
        {id: 'actions', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.UNITS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.BUILDING_AVAILABILITY',
            sort: true
        }
    ],
    INDUSTRIAL_BUILDING_UNITS: [
        {id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false},
        {id: 'area', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.AREA', sort: false},
        {id: 'is_office', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: false},
        {id: 'status', numeric: true, disablePadding: false, label: 'INDUSTRIAL.TABLE.STATUS', sort: false},
        {
            id: 'available_from',
            numeric: true,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: false
        },
        {id: 'updated_at', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.UPDATED_AT', sort: false},
        {id: 'docks', numeric: true, disablePadding: false, label: 'INDUSTRIAL.PHRASE.LOADING_DOCKS', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false}
    ],
    INDUSTRIAL_FILES: [
        {id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false},
        {id: 'file_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.FILE_NAME', sort: false},
        {id: 'file_type', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.FILE_FORMAT', sort: false},
        {id: 'file_category', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.FILE_CATEGORY', sort: false},
        {
            id: 'assigned_building',
            numeric: false,
            disablePadding: false,
            label: 'GENERAL.PHRASE.ASSIGNED_BUILDING',
            sort: false
        },
        {id: 'uploaded_at', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.UPLOADED_AT', sort: false},
        {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false}
    ]
};
