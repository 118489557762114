import React from "react";
import {Grid, Icon, Tooltip, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import InputSwitcher from "app/partials/components/InputSwitcher";
import {updateUnit} from "app/crud/estates/offices/units.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {isNumber} from "app/utils/helpers";

class UnitFlexible extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    let dividedUnitsAreas = [];
    props.data &&
      props.data.dividedUnits &&
      props.data.dividedUnits.map((unit) => dividedUnitsAreas.push(unit.area));

    this.state = {
      canBeDivided: props.data.canBeDivided,
      dividedMinValue: props.data.dividedMinValue,
      dividedUnits: dividedUnitsAreas.length ? dividedUnitsAreas : ["", ""],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data?.canBeDivided !== this.state.canBeDivided) {
      let dividedUnitsAreas = [];
      nextProps.data &&
        nextProps.data.dividedUnits &&
        nextProps.data.dividedUnits.map((unit) =>
          dividedUnitsAreas.push(unit.area)
        );
      this.setState({
        canBeDivided: nextProps.data.canBeDivided,
        dividedMinValue: nextProps.data.dividedMinValue,
        dividedUnits: dividedUnitsAreas.length ? dividedUnitsAreas : ["", ""],
      });
    }
  }

  removeElementFromArray = (array, elem) => {
    const index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSetPartialUnitAsLeased = (
    unitId,
    unitArea,
    dividedMinValue,
    dividedAreas,
    dividedIndex,
    updateUnit
  ) => {
    const dividedArea = dividedAreas[dividedIndex];
    const newTotalUnitArea = parseInt(unitArea) - parseInt(dividedArea);
    this.removeElementFromArray(dividedAreas, dividedArea);
    let newDividedUnitsSet = [];
    dividedAreas &&
      dividedAreas.map((unit) => newDividedUnitsSet.push({ area: unit }));
    const lastUnit =
      dividedMinValue === newTotalUnitArea ||
      (dividedAreas.length === 1 && dividedAreas[0] === newTotalUnitArea);
    // 1. Set new TOTAL UNIT AREA to "newTotalUnitArea" - function updateOfficeUnit
    // 2. Update UNIT "dividedUnits" with "dividedAreas" - function updateOfficeUnit
    // 3. Add new UNIT to OFFICE BUILDING with area equal to "dividedArea" with status unavailable - function addOfficeUnit
    updateUnit(unitId, {
      area: newTotalUnitArea,
      dividedUnits: newDividedUnitsSet,
      canBeDivided: !lastUnit,
    });
    this.handleCloseModal();
  };
  handleChangeMinDivided = (event) => {
    this.setState({
      dividedMinValue: isNumber(event.target.value) ? event.target.value : "",
    });
  };

  handleChangeSwitcher = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      () => {
        this.props.updateUnit(this.props.unitId, {
          canBeDivided: this.state.canBeDivided,
        });
      }
    );
  };
  handleChangeDividedUnit = (event) => {
    const unitIndex = event.target.name.replace("dividedUnit_", "");
    const newDividedUnits = this.state.dividedUnits.slice();
    newDividedUnits[unitIndex] = Number(event.target.value);
    this.setState({
      dividedUnits: isNumber(event.target.value)
        ? newDividedUnits
        : this.state.dividedUnits,
    });
  };
  handleRemoveField = (targetIndex) => {
    const newDividedUnits = this.state.dividedUnits.filter(
      (unit, index) => index !== targetIndex
    );
    this.setState({
      dividedUnits: [...newDividedUnits],
    });
  };
  handleAddField = () => {
    this.setState({
      dividedUnits: [...this.state.dividedUnits, Number("")],
    });
  };
  handleSave = () => {
    const { unitId, updateUnit } = this.props;
    const { dividedMinValue, dividedUnits, canBeDivided } = this.state;
    const dividedUnitsToPass = dividedUnits.filter((unit) => unit !== "");
    let newDividedUnits = [];
    dividedUnitsToPass &&
      dividedUnitsToPass.map((unit) => newDividedUnits.push({ area: unit }));
    const data = {
      dividedMinValue,
      dividedUnits: newDividedUnits,
      canBeDivided,
    };
    updateUnit(unitId, data);
  };

  render() {
    const {
      data: { area, id },
      directBtn,
      directIcon,
      updateUnit,
      isOfficeEditable
    } = this.props;
    const { dividedUnits, dividedMinValue, canBeDivided } = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            icon="tab_unselected"
            label={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
            directBtn={directBtn}
            directIcon={directIcon}
            color={canBeDivided ? "danger" : "gray"}
          />
        }
        maxWidth="sm"
        handleSave={isOfficeEditable && canBeDivided && this.handleSave}
        btnReject={
          isOfficeEditable ? (
            <FormattedMessage id="GENERAL.PHRASE.CANCEL" />
          ) : (
            <FormattedMessage id="GENERAL.PHRASE.CLOSE" />
          )
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE" />}
        title={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
      >
        <Grid container direction="column" spacing={2}>
          {isOfficeEditable && (
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography>
                    <FormattedMessage id="UNITS.PHRASE.IS_FLEXIBLE" />
                  </Typography>
                </Grid>
                <Grid item>
                  <InputSwitcher
                    checked={canBeDivided}
                    value={canBeDivided}
                    label={<FormattedMessage id="GENERAL.PHRASE.YES" />}
                    onChange={this.handleChangeSwitcher("canBeDivided")}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {canBeDivided && (
            <>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item xs={7}>
                    <InputIcon
                      name="dividedMinValue"
                      label={
                        <FormattedMessage id="UNITS.PHRASE.MINIMAL_AREA" />
                      }
                      icon={<Icon>tab_unselected</Icon>}
                      value={dividedMinValue || ""}
                      disabled={!isOfficeEditable}
                      onChange={this.handleChangeMinDivided}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      <FormattedMessage id="UNITS.PHRASE.TOTAL_UNIT_AREA" />:
                    </Typography>
                    <Typography variant="h3">{area}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography>
                      <FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />:
                    </Typography>
                  </Grid>
                  {isOfficeEditable && (
                    <Grid item>
                      <ButtonCustom
                        label={<FormattedMessage id="UNITS.PHRASE.ADD_UNIT" />}
                        color="success"
                        icon="add"
                        onClick={this.handleAddField}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  wrap="wrap"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  {dividedUnits.map((unit, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={6}
                        style={{ position: "relative" }}
                      >
                        <InputIcon
                          name={`dividedUnit_${index}`}
                          label={
                            <FormattedMessage
                              id="UNITS.PHRASE.DIVIDED_UNIT_INDEX_LABEL"
                              values={{ index: index }}
                            />
                          }
                          icon={<Icon>flip_to_front</Icon>}
                          value={unit}
                          disabled={!isOfficeEditable}
                          onChange={this.handleChangeDividedUnit}
                        />
                        {isOfficeEditable && (
                          <>
                            <span
                              style={{
                                position: "absolute",
                                right: 7,
                                bottom: 13,
                              }}
                            >
                              <Tooltip
                                title={
                                  <Typography variant="body2" placement="top">
                                    <FormattedMessage id="UNITS.PHRASE.DELETE_UNIT" />
                                  </Typography>
                                }
                              >
                                <span>
                                  <ButtonCustom
                                    icon="close"
                                    color="danger"
                                    solid
                                    iconOnly
                                    noPadding
                                    onClick={() =>
                                      this.handleRemoveField(index)
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </span>
                            <span
                              style={{
                                position: "absolute",
                                right: 31,
                                bottom: 13,
                              }}
                            >
                              <Tooltip
                                title={
                                  <Typography variant="body2" placement="top">
                                    <FormattedMessage id="UNITS.PHRASE.RENT_UNIT" />
                                  </Typography>
                                }
                              >
                                <span>
                                  <ButtonCustom
                                    icon="check"
                                    color="success"
                                    solid
                                    iconOnly
                                    noPadding
                                    onClick={() =>
                                      this.handleSetPartialUnitAsLeased(
                                        id,
                                        area,
                                        dividedMinValue,
                                        dividedUnits,
                                        index,
                                        updateUnit
                                      )
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </span>
                          </>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  updateUnit: (unitId, data) => updateUnit(unitId, data),
};

export default injectIntl(
  connect(null, mapDispatchToProps)(UnitFlexible)
);
