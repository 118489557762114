import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {fetchOffersAnalytics, fetchOffersAnalyticsCharts, fetchOffersOfficeAnalytics} from "app/crud/offers.crud";
import OffersOnlineAnalysisPage from "./OffersOnlineAnalysisPage";

const mapStateToProps = (state) => ({
  offersAnalytics: state.shortlists.offersOnlineAnalytics,
  offersCharts: state.shortlists.offersOnlineCharts,
  offerDetailsAnalytics: state.shortlists.offersOnlineDetailsAnalytics,
  loadingOffersAnalytics: state.shortlists.loadingOffersOnlineAnalytics,
  loadingOffersChars: state.shortlists.loadingOffersOnlineCharts,
  loadingOfferDetailsAnalytics: state.shortlists.loadingOffersOnlineDetailsAnalytics
});
const mapDispatchToProps = {
  fetchOffersAnalytics: () => fetchOffersAnalytics(),
  fetchOffersAnalyticsCharts: (year) => fetchOffersAnalyticsCharts(year),
  fetchOffersOfficeAnalytics: (offerID) => fetchOffersOfficeAnalytics(offerID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffersOnlineAnalysisPage)
);
