import React from 'react';
import {TextField} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import {updateAccountRequest} from "../../../../crud/accountRequest.crud";

class AccountRequestNote extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      note: props?.note || ''
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.updating && !this.props.updating) {
      this.handleCloseModal();
    }
  }

  // Handlers
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    this.setState({
      [name]: value
    });
  }
  handleSave = () => {
    const {updateAccountRequest, id} = this.props;
    const {note} = this.state;
    const data = {
      note: note || ''
    };
    updateAccountRequest(id, data);
  };

  render() {
    const {updating} = this.props;
    const {note} = this.state;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ModalTrigger
            label={<FormattedMessage id="AR.PHRASE.INTERNAL_NOTE"/>}
            color={note?.length > 0 ? "success" : "gray"}
            icon="edit_document"
            directIcon
          />
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
        title={<FormattedMessage id="AR.PHRASE.INTERNAL_NOTE"/>}
        handleSave={this.handleSave}
        saving={updating}
      >
        <TextField
          name="note"
          label={<FormattedMessage id="AR.PHRASE.NOTE"/>}
          value={note}
          multiline
          fullWidth
          minRows={4}
          style={{
            width: "30rem"
          }}
          onChange={this.handleChange}
        />
      </ModalCustom>
    );
  }
}

const mapStateToProps =({accountRequest}) => ({
  updating: accountRequest?.updating
});
const mapDispatchToProps = {
  updateAccountRequest: (requestID, data) => updateAccountRequest(requestID, data)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountRequestNote)
);