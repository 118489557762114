import React from 'react';
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {refreshSelectedIndustrialWarehouseUnits} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";

class IndustrialUnitRefreshSelected extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    handleCloseModal = () => {
        this.child.current.handleClose();
    };
    handleSave = () => {
        const {warehouseID, units, refreshSelectedIndustrialWarehouseUnits, ordering, onRefreshComplete} = this.props;
        const backgroundRefreshObject = {
          ordering
        };
        refreshSelectedIndustrialWarehouseUnits(warehouseID, units, backgroundRefreshObject);
        onRefreshComplete && onRefreshComplete();
        this.handleCloseModal();
    };

    render() {
        const {directBtn, directIcon, solid, color = "danger"} = this.props;
        return (
            <ModalCustom
                ref={this.child}
                btn={
                    <ModalTrigger
                        icon="refresh"
                        label={<FormattedMessage id="UNITS.PHRASE.REFRESH_SELECTED_OFFICE_UNITS"/>}
                        directBtn={directBtn}
                        directIcon={directIcon}
                        solid={solid}
                        color={color}
                    />
                }
                btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REFRESH"/>}
                title={<FormattedMessage id="UNITS.PHRASE.REFRESH_SELECTED_OFFICE_UNITS"/>}
                handleSave={this.handleSave}
            >
                <Typography><FormattedMessage id="UNITS.PHRASE.REFRESH_SELECTED_OFFICE_UNITS_MESSAGE"/>
                    <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
            </ModalCustom>
        );
    }
}

const mapDispatchToProps = {
    refreshSelectedIndustrialWarehouseUnits: (warehouseID, units, backgroundRefreshObject) => refreshSelectedIndustrialWarehouseUnits(warehouseID, units, backgroundRefreshObject)
};

export default injectIntl(
    connect(
        null,
        mapDispatchToProps
    )(IndustrialUnitRefreshSelected)
);