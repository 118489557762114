import React, {useEffect} from "react";
import {fetchIndustrialParkEditFormsData} from "../../../../../crud/estates/industrial/industrialPark.crud";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import EditParkBasics from "./EditParkBasics";
import EditParkLeaseTerms from "./EditParkLeaseTerms";
import EditParkLocation from "./EditParkLocation";
import EditParkContacts from "./EditParkContacts";
import EditParkOwners from "./EditParkOwners";
import EditParkDeleteProperty from "./EditParkDeleteProperty";
import EditPropertyManageImages from "../common/EditPropertyManageImages";

function EditPark({data, loading, parentData, activeTab, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  useEffect(() => {
    if(!loading && parentData?.id) {
      props.fetchIndustrialParkEditFormsData(parentData?.id);
    }
 // eslint-disable-next-line
  }, []);

  if(loading) {
    return <LoadingScreen/>
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" &&
        <EditParkBasics
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "leaseTerms" &&
        <EditParkLeaseTerms
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "location" &&
        <EditParkLocation
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "images" &&
        <EditPropertyManageImages data={data} view="industrialPark"/>
        }
        {activeTab === "owner" &&
        <EditParkOwners parkID={data?.id}/>
        }
        {activeTab === "contacts" &&
        <EditParkContacts
          parkID={data?.id}
        />
        }
        {activeTab === "deleteProperty" &&
        <EditParkDeleteProperty parkID={data?.id}/>
        }
      </>
    );
  }
  return <NoData/>;
}

const mapStateToProps = (store) => ({
  data: store.industrialPark.editFormsData,
  loading: store.industrialPark.editFormsDataLoading
});
const mapDispatchToProps = {
  fetchIndustrialParkEditFormsData: (parkID) => fetchIndustrialParkEditFormsData(parkID)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPark)
);
