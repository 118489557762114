import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import LoadingScreen from "../../partials/components/LoadingScreen";
import connect from "react-redux/es/connect/connect";
import {FormattedMessage, injectIntl} from "react-intl";
import {authOneTimeLogin, leadTracking} from "../../crud/authApp.crud";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../_metronic";
import cn from "classnames";
import ButtonCustom from "../../partials/components/ButtonCustom";

class RedirectPage extends React.Component {
  componentDidMount() {
    const {leadTracking, authOneTimeLogin, location} = this.props;
    const query = location?.search;
    const checksum = new URLSearchParams(query).get("checksum");
    const interaction = new URLSearchParams(query).get("interaction");
    const url = new URLSearchParams(query).get("url");
    const firstLogin = new URLSearchParams(query).get("first_login");
    // Auto logged in
    const otp = new URLSearchParams(query).get("otp-redd");
    const recipient = new URLSearchParams(query).get("recipient");
    const isAuth = localStorage.getItem("storageToken");
    //
    const finalUrl = firstLogin === "true" ? `${url}?first_login=${firstLogin}` : url;
    // Send
    if(!isAuth && otp && recipient && authOneTimeLogin) {
      setTimeout(() => {
        authOneTimeLogin(otp, recipient, url);
      }, 2000);
    }
    else {
      if(checksum && interaction && url) {
        leadTracking && leadTracking(checksum, interaction, finalUrl);
      }
      else {
        if(url) {
          window.location.href = finalUrl;
          return true;
        }
        else {
          window.location.href = "/";
          return true;
        }
      }
    }
  }

  render() {
    const {location, oneTimeLoginMessage} = this.props;
    const query = location?.search;
    const otp = new URLSearchParams(query).get("otp-redd");

    if(otp) {
      return(
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div className={cn(
            "kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1",
            styles.redirectPageWrapper
          )}>
            <div style={{textAlign: "center"}}>
              <img src={toAbsoluteUrl("media/branding/redd-logo-neon.png")} style={{height: 60, marginBottom: 100}} alt="REDD Logo" />
              <h1 style={{fontSize: 30, color: "#ffffff"}}>
                <FormattedMessage id={oneTimeLoginMessage ? "Error occurred" : "Authentication in progress"}/>
              </h1>
              <p style={{fontSize: 20, color: "#ffffff", marginTop: 25, marginBottom: 25}}>
                <FormattedMessage id={oneTimeLoginMessage ? oneTimeLoginMessage : "Redirection will take place shortly..."} />
              </p>
              {
                oneTimeLoginMessage ?
                  <ButtonCustom
                    label={<FormattedMessage id="Back to the login screen" />}
                    color="danger"
                    solid
                    redirect="/"
                  /> : <LoadingScreen/>
              }
            </div>
          </div>
        </div>
      );
    }
    return(
      <div className={styles.redirectPageWrapper}>
        <LoadingScreen/>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  oneTimeLoginMessage: store.authApp.oneTimeLoginMessage
})
const mapDispatchToProps = {
  leadTracking: (checksum, interaction, url) => leadTracking(checksum, interaction, url),
  authOneTimeLogin: (otp, recipient, url) => authOneTimeLogin(otp, recipient, url)
}
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RedirectPage)
);