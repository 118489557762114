import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import clsx from "clsx";
import styles from "app/partials/components/PartialComponents.module.scss";
import Icon from "@material-ui/core/Icon/Icon";
import ModalCustom from "app/partials/components/ModalCustom";
import {addUnitToExistingShortlist, fetchOffersAll,} from "app/crud/offers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {ModalTrigger} from "app/partials/components/ModalCustom/ModalTrigger";
import LoadingScreen from "app/partials/components/LoadingScreen";

class UnitAddToExistingShortlist extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      selected: "",
    };
    props.fetchOffersAll();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.offerUpdated &&
      this.props.offerUpdated &&
      this.props.onComplete
    ) {
      this.props.onComplete([]);
    }
  }

  handleFetchOffers = () => {
    this.props.fetchOffersAll();
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleSave = () => {
    const { unitId, addUnitToExistingShortlist } = this.props;
    const { selected } = this.state;
    const units = this.props.units || [unitId];
    if (selected !== "") {
      addUnitToExistingShortlist(selected, units);
      this.handleCloseModal();
    }
  };

  render() {
    const {
      offersAll,
      loadingOffersAll,
      unitId,
      btn,
      btnMultiselect,
      solid,
      inactive,
    } = this.props;
    const { open, selected } = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          btn ? (
            <ModalTrigger
              color={btnMultiselect ? "danger" : "success"}
              label={
                <FormattedMessage id="UNITS.PHRASE.ADD_TO_EXISTING_SHORTLIST" />
              }
              directBtn
              solid={solid}
              inactive={inactive}
            />
          ) : (
            <span
              className={clsx("kt-nav__link", styles.actionButton)}
            >
              <span className={styles.actionIcon}>
                <Icon>work</Icon>
              </span>
              <span className="kt-nav__link-text">
                <FormattedMessage id="UNITS.PHRASE.ADD_TO_EXISTING_SHORTLIST" />
              </span>
            </span>
          )
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
        title={
          <FormattedMessage id="UNITS.PHRASE.ADD_UNIT_TO_EXISTING_SHORTLIST" />
        }
        handleSave={this.handleSave}
        fetch={this.handleFetchOffers}
        inactive={inactive}
      >
        {loadingOffersAll ? (
          <LoadingScreen />
        ) : (
          <FormControl>
            <InputLabel htmlFor="existing_shortlist_select">
              <FormattedMessage id="SHORTLIST.PHRASE.SELECT_SHORTLIST" />
            </InputLabel>
            <Select
              open={open}
              onClose={this.handleToggleSelect}
              onOpen={this.handleToggleSelect}
              value={selected}
              onChange={this.handleChange}
              inputProps={{
                name: "existing_shortlist",
                id: "existing_shortlist_select",
              }}
            >
              <MenuItem value="">
                <em>
                  <FormattedMessage id="SHORTLIST.PHRASE.SHORTLIST_NAME" />
                </em>
              </MenuItem>
              {offersAll &&
                offersAll.map((offer, index) => {
                  const units = this.props.units || unitId;
                  const disableOffer =
                    units && Array.isArray(units)
                      ? units?.filter((unit) => offer?.units?.includes(unit))
                          .length > 0
                      : offer?.units && offer?.units?.includes(unitId);
                  return (
                    <MenuItem
                      key={index}
                      value={offer.id}
                      disabled={disableOffer}
                    >
                      {offer.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  shortlists: state.shortlists.items,
  offersAll: state.shortlists.offersAll,
  loadingOffersAll: state.shortlists.loadingOffersAll,
  offerUpdated: state?.shortlists?.offerUpdated,
});
const mapDispatchToProps = {
  fetchOffersAll: () => fetchOffersAll(),
  addUnitToExistingShortlist: (id, units) =>
    addUnitToExistingShortlist(id, units),
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(UnitAddToExistingShortlist)
);
