import React from "react";
import {Image} from "react-bootstrap";

export class Avatar extends React.Component {
  render() {
    const { avatar, size = "5rem", margin, width } = this.props;
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "1rem",
          width: size,
          height: size,
        }}
      >
        <Image
          src={avatar}
          style={{
            marginTop: margin ? "1rem" : 0,
            marginBottom: margin ? "1rem" : 0,
            maxWidth: size,
            maxHeight: size,
            width: width || "100%",
            height: "auto"
          }}
          rounded
        />
      </span>
    );
  }
}
