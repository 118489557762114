import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import ConfirmationDialog from "../../ConfirmationDialog";
import {FormattedMessage} from "react-intl";
import EDIT_NAVIGATION from "../constants/EDIT_NAVIGATION";
import EditOffice from "../editForms/offices/EditOffice";
import EditUnit from "../editForms/units/EditUnit";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import EditPark from "../editForms/parks/EditPark";
import EditWarehouse from "../editForms/warehouses/EditWarehouse";
import EditWarehouseUnit from "../editForms/warehouseUnits/EditWarehouseUnit";
import EditTeamMember from "../editForms/teamMember/EditTeamMember";
import IsAdmin from "../../../../utils/IsAdmin";
import IsPro from "../../../../utils/IsPro";
import BadgeLabel from "../../BadgeLabel";

function EditBody({
                                   target,
                                   parentData,
                                   initialTab,
                                   isEditable,
                                   requestSave,
                                   onRequestSaving,
                                   onSaveDisabled,
                                   ...props
                                 }) {
  const [activeTab, setActiveTab] = useState(initialTab || 'basic');
  const [pendingTab, setPendingTab] = useState('');
  const [unsavedData, setUnsavedData] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  // On Change
  useEffect(() => {
    if (
      // Office/Park/Warehouse
      activeTab === "images" ||
      activeTab === "owner" ||
      activeTab === "contacts" ||
      activeTab === "deleteProperty" ||
      activeTab === "commercialization" ||
      // Unit
      activeTab === "subleaseTerms" ||
      activeTab === "contact" ||
      activeTab === "deleteUnit" ||
      // Team
      activeTab === "contactPerson" ||
      activeTab === "teamOwner" ||
      activeTab === "removeMember"
    ) {
      onSaveDisabled(true);
    } else {
      onSaveDisabled(false);
    }
  }, [activeTab, onSaveDisabled]);
  // Handlers
  const handleTabChange = (tabId) => {
    if (unsavedData) {
      setPendingTab(tabId);
      setConfirmationOpen(true);
    } else {
      setActiveTab(tabId);
    }
  }
  const handleTabChangeConfirmation = () => {
    if (pendingTab) {
      setUnsavedData(false);
      setConfirmationOpen(false);
      setActiveTab(pendingTab);
      setPendingTab('');
    }
  }
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  }
  // Variables
  const editList =
    target === "office" ? EDIT_NAVIGATION.OFFICE :
      target === "unit" ? EDIT_NAVIGATION.UNIT :
        target === "park" ? EDIT_NAVIGATION.PARK :
          target === "warehouse" ? EDIT_NAVIGATION.WAREHOUSE :
            target === "warehouseUnit" ? EDIT_NAVIGATION.WAREHOUSE_UNIT :
              target === "teamMember" ? EDIT_NAVIGATION.TEAM_MEMBER : null;

  return (
    <div className={styles.editBodyContainer}>
      <div className={styles.editListWrapper}>
        {editList?.map((item, index) => {
          const isEditableRequired = item?.editableRequired;
          const isAdminRequired = item?.adminOnly;
          const isProRequired = item?.proRequired;
          const isActive = activeTab === item?.id;
          const isItemPermitted = isAdminRequired ? IsAdmin() :
            isProRequired ? IsPro({}) :
            isEditableRequired ? isEditable : true;

          if (isItemPermitted) {
            return (
              <button
                key={index}
                className={cn(
                  styles.editListItem,
                  isActive ? styles.active : undefined
                )}
                onClick={() => handleTabChange(item?.id)}
              >
                {item?.label}
                {item?.adminOnly &&
                  <BadgeLabel label="AO" type="admin"/>
                }
              </button>
            );
          }
          return null;
        })}
      </div>
      <div className={styles.editFormBodyWrapper}>
        {target === "office" &&
          <EditOffice
            parentData={parentData}
            activeTab={activeTab}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
        {target === "unit" &&
          <EditUnit
            parentData={parentData}
            activeTab={activeTab}
            isEditable={isEditable}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
        {target === "park" &&
          <EditPark
            parentData={parentData}
            activeTab={activeTab}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
        {target === "warehouse" &&
          <EditWarehouse
            parentData={parentData}
            activeTab={activeTab}
            isEditable={isEditable}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
        {target === "warehouseUnit" &&
          <EditWarehouseUnit
            parentData={parentData}
            activeTab={activeTab}
            isEditable={isEditable}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
        {target === "teamMember" &&
          <EditTeamMember
            activeTab={activeTab}
            isEditable={isEditable}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={setUnsavedData}
            {...props}
          />
        }
      </div>
      <ConfirmationDialog
        open={confirmationOpen}
        title={<FormattedMessage id="VOCABULARY.Unsaved changes detected"/>}
        btnCancel={<FormattedMessage id="VOCABULARY.Back"/>}
        btnConfirm={<FormattedMessage id="VOCABULARY.Reject changes and continue"/>}
        message={<FormattedMessage id="VOCABULARY.Changing the view will result in unsaved changes being lost."/>}
        onConfirm={handleTabChangeConfirmation}
        onClose={handleConfirmationClose}
      />
    </div>
  );
}

export default withRouter(
  connect()(EditBody)
);