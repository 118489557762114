import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableDrawer from "app/partials/components/TableDrawer/index";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import BuildingUnitsCountPreview from "app/partials/components/_DataParts/BuildingUnitsCountPreview";
import {toNumberFormat} from "app/utils/helpers";
import {fetchOwnerPortfolio} from "app/crud/owners.crud";
import OwnerCardPreview from "app/partials/components/_DataParts/OwnerCardPreview";
import styles from "../index.module.scss";

class TableOwnersCells extends React.Component {
  render() {
    const {row, ownerPortfolio, ownerPortfolioLoading, fetchOwnerPortfolio} = this.props;
    const displayName = row.shortName !== null ? row.shortName : row.name !== null ? row.name : 'N/A';
    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          <OwnerCardPreview
            name={displayName}
            street={row.parent}
            thumb={row.logo || row.photo}
            ownerID={row.id}
          />
        </TableCell>
        <TableCell align="right">
          <TableDrawer
            title={<OwnerCardPreview name={displayName} street={row.parent} thumb={row.logo || row.photo} ownerID={row.id}/>}
            titleAdvance
            headRows={
              [
                { id: 'office_name', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.BUILDING', sort: false },
                { id: 'owner_name', numeric: false, disablePadding: false, label: 'OWNERS.PHRASE.SPV', sort: false },
                { id: 'office_finance', numeric: true, disablePadding: false, label: 'OWNERS.PHRASE.FINANCES', sort: false },
                { id: 'building_status', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: false },
                { id: 'for_sale', numeric: true, disablePadding: false, label: 'OWNERS.PHRASE.FOR_SALE', sort: false },
                { id: 'total_area_available', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.AVAILABLE_AREA', sort: false },
                { id: 'rent_from', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.RENT_FROM', sort: false },
                { id: 'service_charge', numeric: true, disablePadding: false, label: 'BUILDING.PHRASE.SERVICE_CHARGE', sort: false },
                { id: 'capacity', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.CAPACITY', sort: false }
              ]
            }
            bodyRows={ownerPortfolio}
            bodyRowsReady={ownerPortfolioLoading}
            target={TABLE_CELLS.OWNER_PORTFOLIO}
            parentId={row.id}
            parentPreviewData={row}
            onClick={fetchOwnerPortfolio}
          >
            <BuildingUnitsCountPreview
                units={toNumberFormat(row.portfolio)}
                label={<FormattedMessage id="OWNERS.PHRASE.TABS.BUILDINGS"/>}
            />
          </TableDrawer>
        </TableCell>
        <TableCell align="right">{toNumberFormat(row.markets)}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalAreaNetto, 'area')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalVacancy, '%')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalVacancyUnderConstruction, '%')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalOperatingRevenue, 'currency', 'PLN')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.ebit, 'currency', 'PLN')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.ebitda, 'currency', 'PLN')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalAssets, 'currency', 'PLN')}</TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userWatchlist: state.user.data.watchList,
  ownerPortfolio: state.owners.ownerPortfolio,
  ownerPortfolioLoading: state.owners.ownerPortfolioLoading
});
const mapDispatchToProps = {
  fetchOwnerPortfolio: (ownerId) => fetchOwnerPortfolio(ownerId)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableOwnersCells)
);
