import React from "react";
import TableMUI from "../../../../partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {
  areaFormat,
  handleEmptyValue,
  handleTableCellAlign,
  toNumberFormat
} from "../../../../utils/helpers";
import styles from "../index.module.scss";
import NoData from "../../../../partials/components/NoData/NoData";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import cn from "classnames";
import {FormattedMessage} from "react-intl";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import SYMMCO from "../../../../constants/SYMMCO";
import ButtonCustom from "../../../../partials/components/ButtonCustom";
import store from "../../../../store/store";
import TransactionOwned from "./TransactionOwned";

export default class TransactionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: ''
    }
  }

  // Handlers
  handleBlank = (hiddenFields, fieldValue, fieldType) => {
    const isLeasePeriod = fieldType === 'lease_period';
    const isHeadlineRent = fieldType === 'headline_rent';
    const isEffectiveRent = fieldType === 'effective_rent';
    const isHidden = hiddenFields?.includes(fieldType);

    if (isHidden) {
      const blurValue = isLeasePeriod ? '0 years' :
        (isHeadlineRent || isEffectiveRent) ? '1.23' : 'Lorem Ipsum PSA';
      return <span
        className={cn(
          styles.blurValue
        )}
      >{blurValue}</span>;
    }
    return fieldValue;
  }
  handleSortTable = (orderBy, order) => {
    const {fetchTransactions, loading, filterByModules} = this.props;
    if (orderBy && !loading) {
      const params = {
        ...store.getState().filters,
        page: 1,
        order: order || "",
        orderBy: orderBy
      };
      filterByModules({
        order: order || "",
        orderBy: orderBy
      });
      this.setState({
        params
      }, () => fetchTransactions(params));
    }
  }

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      loading,
      fetchTransactions,
      marketsIndustrial,
      onToggleDetails,
      activeTransactionId
    } = this.props;
    const tHead = [
      {id: 'transaction_date', numeric: false, disablePadding: false, label: 'VOCABULARY.Date', sort: true},
      {id: 'market', numeric: false, disablePadding: false, label: 'VOCABULARY.Market', sort: true},
      {id: 'zone', numeric: false, disablePadding: false, label: 'VOCABULARY.Zone', sort: false},
      {id: 'net_deal_size', numeric: true, disablePadding: false, label: 'VOCABULARY.Net deal size', sort: true},
      {id: 'deal_type', numeric: false, disablePadding: false, label: 'VOCABULARY.Deal type', sort: false},
      ...!activeTransactionId ? [{id: 'lease_period', numeric: false, disablePadding: false, label: 'VOCABULARY.Lease period', sort: true}] : [],
      ...!activeTransactionId ? [{id: 'headline_rent', numeric: true, disablePadding: false, label: 'VOCABULARY.Headline rent', sort: true}] : [],
      ...!activeTransactionId ? [{id: 'effective_rent', numeric: true, disablePadding: false, label: 'VOCABULARY.Effective rent', sort: true}] : [],
      {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
    ];

    if (items && items?.length > 0) {
      return (
        <TableMUI
          tHead={tHead}
          itemsPerPage={itemsPerPage || 20}
          totalItems={itemsCount || 10}
          loading={loading}
          initialOrderBy="transaction_date"
          initialRowsPerPage={20}
          handleFetchData={fetchTransactions}
          source="symmco"
          onSort={this.handleSortTable}
          containerStyle={{
            maxHeight: "100%"
          }}
        >
          {items && Array.isArray(items) && items.map((item, index) => {
            const isActive = activeTransactionId === item?.id;
            const transactionDate = [
              ...item?.transactionDateQuater >= 0 ? [`Q${item?.transactionDateQuater+1}`] : [],
              ...item?.transactionDateYear ? [item?.transactionDateYear] : []
            ].join(" ");
            const zoneName = SYMMCO.ZONE.filter(zone => zone?.id === item?.zone)?.[0]?.name;
            const dealTypeName = SYMMCO.DEAL_TYPE.filter(zone => zone?.id === item?.dealType)?.[0]?.name;
            const netDealSize = SYMMCO.NET_DEAL.filter(deal => deal?.id === item?.netDealSize)?.[0]?.name || item?.netDealSize;
            const marketName = marketsIndustrial?.filter(market => market?.id === item?.market)?.[0]?.name;
            const areaLangSuffix = areaFormat();

            return (
              <TableRow
                hover
                tabIndex={-1}
                key={index}
              >
                <TableCell align={handleTableCellAlign(tHead, 0)}>
                  {handleEmptyValue(transactionDate)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 1)}>
                  {handleEmptyValue(marketName)}
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 2)}>
                  <FormattedMessage id={zoneName}/>
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 3)}>
                  <div className={styles.areaWrapper}>
                    {item?.isEditable && store.getState().filters?.isEditable !== "true" && <TransactionOwned style={{marginRight: "1rem"}}/>}
                    <HighlightLabel color="neutral2">
                      {item?.isEditable ? toNumberFormat(netDealSize) : <FormattedMessage id={netDealSize}/>} {areaLangSuffix}
                    </HighlightLabel>
                  </div>
                </TableCell>
                <TableCell align={handleTableCellAlign(tHead, 4)}>
                  <FormattedMessage id={dealTypeName}/>
                </TableCell>
                {!activeTransactionId &&
                <TableCell align={handleTableCellAlign(tHead, 5)}>
                  {this.handleBlank(item?.hiddenFields, <FormattedMessage id="BUILDING.PHRASE.YEARS_VALUE" values={{count: item?.leasePeriod, years: item?.leasePeriod}}/>, 'lease_period')}
                </TableCell>
                }
                {!activeTransactionId &&
                <TableCell align={handleTableCellAlign(tHead, 6)}>
                  {this.handleBlank(item?.hiddenFields, toNumberFormat(item?.headlineRent, 'currency'), 'headline_rent')}
                </TableCell>
                }
                {!activeTransactionId &&
                <TableCell align={handleTableCellAlign(tHead, 7)}>
                  {this.handleBlank(item?.hiddenFields, toNumberFormat(item?.effectiveRent, 'currency'), 'effective_rent')}
                </TableCell>
                }
                <TableCell align={handleTableCellAlign(tHead, activeTransactionId ? 5 : 8)}>
                  <ButtonCustom
                    label={isActive ? <FormattedMessage id="VOCABULARY.Opened"/> : item?.isEditable ? <FormattedMessage id="VOCABULARY.Edit"/> : <FormattedMessage id="VOCABULARY.Details"/>}
                    color={isActive ? "default" : "danger"}
                    disabled={isActive}
                    solid
                    onClick={() => onToggleDetails(item?.id, index)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableMUI>
      );
    }
    else if ((!items || items?.length <= 0) && loading) {
      return <LoadingScreen/>;
    }
    return (
      <NoData
        message={<FormattedMessage id="No transactions for selected criteria."/>}
        styleMessage={{fontWeight: "600"}}
        extraMessage={<FormattedMessage
          id="Expand your search to see comparable transactions."/>}
        sizeExtraMessage="h3"
        style={{
          padding: "15rem 0"
        }}
      />
    );
  }
}