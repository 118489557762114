import React from "react";
import PropertySectionTitle from "./PropertySectionTitle";
import SlickSlider from "../../../../partials/components/SlickSlider";
import {toAbsoluteUrl} from "../../../../../_metronic";
import styles from "./index.module.scss";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {FormattedMessage, injectIntl} from "react-intl";
import {ROUTES} from "../../../../constants";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import RedirectPropertyMarket from "../../../../partials/components/RedirectPropertyMarket";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import SlickNavigation from "../../../../partials/components/SlickSlider/components/SlickNavigation";
import NoData from "../../../../partials/components/NoData/NoData";

class PropertySimilarProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficeSimilarProperties,
      fetchIndustrialParkSimilarProperties,
      fetchIndustrialWarehouseSimilarProperties,
      similarProperties,
      similarPropertiesLoading,
      match,
      currentApplicationView,
      view
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !similarPropertiesLoading && _.isEmpty(similarProperties) && id) {
      this.setState({
        initLoaded: true,
      }, () => {
        if(isOfficeView) {
          fetchOfficeSimilarProperties && fetchOfficeSimilarProperties(id);
        }
        else if(isIndustrialView) {
          if(view === "industrialWarehouse") {
            fetchIndustrialWarehouseSimilarProperties && fetchIndustrialWarehouseSimilarProperties(id);
          }
          else {
            fetchIndustrialParkSimilarProperties && fetchIndustrialParkSimilarProperties(id);
          }
        }
      });
    }
  }
  handleOnRedirect = () => {
    const {callReddClickEvent, basic, currentApplicationView, view} = this.props;
    const propertyID = basic?.id;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    if(propertyID && callReddClickEvent) {
      if(isOfficeView) {
        callReddClickEvent(
          "office-building-page-similar-property-redirect",
          "office",
          null,
          propertyID
        );
      }
      else if(isIndustrialView) {
        if(view === "industrialWarehouse") {
          callReddClickEvent(
            "industrial-warehouse-page-similar-property-redirect",
            "warehouse",
            null,
            propertyID
          );
        }
        else {
          callReddClickEvent(
          "industrial-park-page-similar-property-redirect",
          "park",
          null,
          propertyID
        );
        }
      }
    }
  }

  render() {
    const {parentRef, similarProperties, similarPropertiesLoading, currentApplicationView, view} = this.props;
    const {currentSlide} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const isIndustrialWarehouseView = view === "industrialWarehouse";

    return (
        <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
          >
            <FormattedMessage id="VOCABULARY.Comparable properties"/>
          </ReactVisibilitySensor>
          }
          toolbar={
          similarProperties?.length > 3 &&
          <SlickNavigation
            slides={similarProperties}
            currentSlide={currentSlide}
            onSetCurrentSlide={value => this.setState({currentSlide: value})}
            slidesToShow={3}
            slidesToScroll={1}
            themeDark
          />
          }
        />
        {
          similarPropertiesLoading ? <LoadingScreen/> :
            similarProperties &&
              similarProperties?.length > 0 ?
              <SlickSlider
                slidesToShow={3}
                newSlideRequest={currentSlide}
                className="similarSliderWrapper"
              >
                {similarProperties?.map((item, index) => {
                  const addressCity = [
                    ...item?.addressZipcode ? [item?.addressZipcode] : [],
                    ...item?.addressCity ? [item?.addressCity] : []
                  ].join(" ");
                  const address = [
                    ...item?.addressStreet ? [item?.addressStreet] : [],
                    addressCity
                  ].join(", ");
                  const image = item?.mainPhotoThumbnail600x400 ? item?.mainPhotoThumbnail600x400 :
                    isIndustrialView ? toAbsoluteUrl('media/industrial-placeholder-outside.jpg') : toAbsoluteUrl('media/office-card-placeholder.jpg');

                  return(
                    <span
                      key={index}
                      className={styles.slideItemWrapper}
                      onClick={() => this.handleOnRedirect()}
                    >
                      <RedirectPropertyMarket
                        propertyID={isIndustrialWarehouseView ? item?.parkId : item?.id}
                        route={
                          isOfficeView ? ROUTES.BUILDING_PAGE :
                            isIndustrialView ? ROUTES.INDUSTRIAL_PARK : ""
                        }
                        openNewTab
                      >
                        <div className={styles.similarPropertyItem}>
                          <div className={styles.itemImageWrapper}>
                            <img src={image} alt={`${item?.name}`}/>
                          </div>
                          <div className={styles.itemContent}>
                            <h3>{isIndustrialWarehouseView ? item?.parkName : item?.name}</h3>
                            <p>{address}</p>
                          </div>
                        </div>
                      </RedirectPropertyMarket>
                    </span>
                  );
                })}
              </SlickSlider> :
              <NoData withIcon/>
        }
        </>
    );
  }
}

const mapStateToProps = store => ({
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertySimilarProperties)
);