import React from "react";
import {toAbsoluteUrl} from "_metronic";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Icon,
  ListItem,
  ListItemText,
  Typography,
  withStyles
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "app/partials/content/Portlet";
import OfficeRemoveAllFromWatchlist from "app/partials/components/Office/OfficeRemoveAllFromWatchlist";
import OfficeRemoveFromWatchlist from "app/partials/components/Office/OfficeRemoveFromWatchlist";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {toNumberFormat} from "app/utils/helpers";
import RedirectPropertyMarket from "app/partials/components/RedirectPropertyMarket";
import ReactVisibilitySensor from "react-visibility-sensor";
import {ROUTES} from "../../../constants";

const styles = theme => ({
  media: {
    height: 140,
  },
  listItemRoot: {
    padding: 0,
  },
  listItemText: {
    marginLeft: 10
  }
});

class UserCardContent extends React.Component {
  render() {
    const {children, id} = this.props;
    return(
      <RedirectPropertyMarket
        propertyID={id}
        route={ROUTES.BUILDING_PAGE}
      >
        {children}
      </RedirectPropertyMarket>
    );
  }
}
class WatchlistPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchFollowerOffices, loading} = this.props;
    const {initLoaded} = this.state;

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchFollowerOffices());
    }
  };

  render() {
    const {items, loading, classes} = this.props;
    const isWatchlist = items !== null && items.length > 0;

    return(
      <Portlet>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PortletHeader
            title={<Typography variant="h3"><FormattedMessage id="USER.MENU.WATCHLIST"/></Typography>}
            toolbar={isWatchlist && <PortletHeaderToolbar><OfficeRemoveAllFromWatchlist/></PortletHeaderToolbar>}
          />
        </ReactVisibilitySensor>
        <PortletBody>
          <Grid container spacing={2}>
            {loading ? <LoadingScreen/> : isWatchlist ? items.map((data, index) => {
              const {id, name, addressCity, addressStreet, marketId, availableUnits, totalAreaNoSubleaseOfficeAvailable, rentFromEur, serviceChargesPln, mainPhoto450x150} = data;
              const address = [addressCity, addressStreet].join(", ");
              return (
                <Grid item xs={3} key={index}>
                  <Card>
                    <UserCardContent id={id} marketId={marketId}>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={mainPhoto450x150 ? mainPhoto450x150 : toAbsoluteUrl("media/office-card-placeholder.jpg")}
                          title="Contemplative Reptile"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h3" component="h2">
                            {name}
                          </Typography>
                          <Typography gutterBottom variant="body1" component="h2">
                            {address}
                          </Typography>
                          <Grid container>
                            <Grid item xs={6}>
                              <ListItem disableGutters classes={{root: classes.listItemRoot}}>
                                <span className={styles.listItemIcon}>
                                  <Icon>apps</Icon>
                                </span>
                                <ListItemText primary={availableUnits} secondary={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_UNITS"/>} classes={{root: classes.listItemText}} />
                              </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                              <ListItem disableGutters classes={{root: classes.listItemRoot}}>
                                <span className={styles.listItemIcon}>
                                  <Icon>flip_to_back</Icon>
                                </span>
                                <ListItemText primary={toNumberFormat(totalAreaNoSubleaseOfficeAvailable, 'area')} secondary={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_AREA"/>} classes={{root: classes.listItemText}} />
                              </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                              <ListItem disableGutters classes={{root: classes.listItemRoot}}>
                                <span className={styles.listItemIcon}>
                                  <Icon>apartment</Icon>
                                </span>
                                <ListItemText primary={toNumberFormat(rentFromEur, 'currency')} secondary={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM"/>} classes={{root: classes.listItemText}} />
                              </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                              <ListItem disableGutters classes={{root: classes.listItemRoot}}>
                                <span className={styles.listItemIcon}>
                                  <Icon>local_convenience_store</Icon>
                                </span>
                                <ListItemText primary={toNumberFormat(serviceChargesPln, 'currency', 'PLN')} secondary={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE"/>} classes={{root: classes.listItemText}} />
                              </ListItem>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </UserCardContent>
                    <CardActions>
                      <OfficeRemoveFromWatchlist officeId={id} btnClassic/>
                    </CardActions>
                  </Card>
                </Grid>
              )
            }) :
            <Grid item xs={12}>
              <Typography variant="h3" align="center"><FormattedMessage id="BUILDING.PHRASE.EMPTY_WATCHLIST_MESSAGE" values={{breakingLine: <br/>}}/></Typography>
            </Grid>
            }
          </Grid>
        </PortletBody>
      </Portlet>
    );
  }
}

export default withStyles(styles)(WatchlistPage);