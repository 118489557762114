import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import ButtonCustom from "app/partials/components/ButtonCustom";
import {addShortlistIndustrial} from "app/crud/estates/industrial/offers-industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Icon from "@material-ui/core/Icon/Icon";
import InputIcon from "app/partials/components/InputIcon";
import {callReddClickEvent} from "app/crud/user.crud";
import {withRouter} from "react-router-dom";
import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";

class ShortlistAddNewIndustrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlistName: '',
      initialOpen: false,
      forTesting: false
    };
    this.child = React.createRef();
  }
  componentDidMount() {
    const {location, history} = this.props;
    const queryParams = new URLSearchParams(location.search)
    const isQueryParamAddOffer = queryParams.has("add_offer");

    if(isQueryParamAddOffer && this.state.initialOpen !== isQueryParamAddOffer) {
      this.setState({
        initialOpen: isQueryParamAddOffer
      }, () => {
        queryParams.delete('add_offer')
        history.replace({
          search: queryParams.toString(),
        });
      });
    }
  }

  handleCloseModal = () => {
    if(this.state.initialOpen) {
      this.setState({
        initialOpen: false
      });
    }
    else {
      this.child.current.handleClose();
    }
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value
    })
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleSave = () => {
    const {addShortlist} = this.props;
    const {shortlistName, forTesting} = this.state;
    if(shortlistName !== "") {
      addShortlist({
        name: shortlistName,
        ...forTesting && forTesting !== '' ? {forTesting} : {}
      });
      this.handleCloseModal();
    }
    this.setState({shortlistName: ""})
  };

  render() {
    const {callReddClickEvent, user} = this.props;
    const {shortlistName, initialOpen, forTesting} = this.state;
    const isLoggedInAs = user?.loggedInAs;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <ButtonCustom label={<FormattedMessage id="SHORTLIST.PHRASE.ADD_NEW_SHORTLIST"/>} color="success" icon="add"/>
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.ADD_NEW_SHORTLIST"/>}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        externalModalState={initialOpen}
        onOpen={() => callReddClickEvent("offers-industrial-create-button", "offer")}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <InputIcon
                icon={<Icon>work_outline</Icon>}
                id="new_shortlist_name"
                label={<FormattedMessage id="SHORTLIST.PHRASE.NEW_SHORTLIST_NAME"/>}
                name="new_shortlist_name"
                value={shortlistName}
                onChange={this.handleChange}
                minWidth="20rem"
            />
          </Grid>
          {isLoggedInAs &&
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={forTesting}
                  id="forTesting"
                  value="forTesting"
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
              }
              label={<FormattedMessage id="SHORTLIST.PHRASE.FOR_TESTING"/>}
            />
          </Grid>
          }
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user?.data,
});
const mapDispatchToProps = {
  addShortlist: data => addShortlistIndustrial(data),
  callReddClickEvent: (clickType, contentType) => callReddClickEvent(clickType, contentType)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ShortlistAddNewIndustrial))
);